import "../style/Home.css";
import Tile from "../components/common/Tile";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FAQ from "../components/FAQ";
import { ProductDisplay } from "../components/subscription/ProductDisplay";
import "../style/subscription.css";
import { MessageBox, MessageBoxTypes, Button } from "@ui5/webcomponents-react";
import { userSelector } from "../state/userState";
import { useRecoilValue } from "recoil";
import ContactCard from "../components/ContactCard";

export default function Home(props: any) {
  const { allProducts } = props;
  const userValue = useRecoilValue(userSelector);

  const { t, i18n } = useTranslation();

  const tiles = [
    {
      title: "DATA_COLLECTION_UPLOAD_TITLE",
      path: "dataCollectionUpload",
      subtitle: "DATA_COLLECTION_UPLOAD_SUBTITLE_TILE",
      icon: "sap-icon://upload-to-cloud",
    },
    {
      title: "DATA_COLLECTION_TRANSFER_TITLE",
      path: "dataCollectionTransfer",
      subtitle: "DATA_COLLECTION_TRANSFER_SUBTITLE_TILE",
      icon: "sap-icon://shipping-status",
    },
    {
      title: "POD_TRANSPORT_TITLE",
      path: "podTransport",
      subtitle: "POD_TRANSPORT_SUBTITLE_TILE",
      icon: "sap-icon://sys-monitor",
    },
  ];

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [open, setOpen] = useState(false);

  const [messageValues, setMessageValues] = useState({
    message: "",
    type: "",
  });

  const showMessageBox = (message: string, type: string) => {
    setMessageValues({
      type: type,
      message: message,
    });
    setOpen(true);
    scrollToPlans();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const scrollToPlansRef = useRef(null);

  const scrollToPlans = () => {
    (scrollToPlansRef.current as HTMLElement | null)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const cards = tiles.map((tile) => {
    return (
      <Tile
        key={tile.path}
        title={t(tile.title)}
        path={tile.path}
        subtitle={t(tile.subtitle)}
        iconName={tile.icon}
        showMessageBox={showMessageBox}
      ></Tile>
    );
  });
  const isMobile = width <= 768;
  window.onresize = () => {
    setWidth(window.innerWidth);
  };

  // State variables to hold form data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Handle form submission
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Here you can send the form data to your backend or perform any other action
    console.log({ name, email, message });
    // Reset form fields after submission
    setName("");
    setEmail("");
    setMessage("");
  };
  return (
    <>
      <MessageBox
        draggable
        open={open}
        type={
          messageValues.type as
            | MessageBoxTypes
            | "Confirm"
            | "Error"
            | "Information"
            | "Success"
            | "Warning"
            | undefined
        }
        onClose={handleClose}
        actions={[
          <Button key={"MESSAGE_BOX_HOME"} className="manufacturingButton">
            OK
          </Button>,
        ]}
      >
        <span style={{ fontSize: "1.1rem" }}>{messageValues.message}</span>
      </MessageBox>
      <header className="headerBgImg">{cards}</header>
      <div
        className="containerBottom"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            textAlign: "center",
          }}
        >
          {t("LANDING_PAGE_TITLE")}
        </h1>
        <div ref={scrollToPlansRef} />

        <h4
          style={{
            textAlign: "center",
            width: "auto",
            maxWidth: "1000px",
          }}
        >
          {t("LANDING_PAGE_SUBTITLE")}
        </h4>
        {userValue.user?.role !== "admin" &&
          !userValue.user?.subscription?.priceId && (
            <>
              <h1>{t("PRICING_TABLE_TITLE")}</h1>

              <ProductDisplay allProducts={allProducts} />
            </>
          )}
        <br />
        <br />
        <FAQ></FAQ>
        <br />
        <br />
        <ContactCard></ContactCard>
      </div>
    </>
  );
}
