import { useState } from "react";
import stripeApi from "../../service/stripe";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../../state/userState";
import { Button, MessageBox, MessageBoxTypes } from "@ui5/webcomponents-react";
import ProfileList from "../ProfileList";
import ProfileDataDialog from "../ProfileData";
import { snackbarState } from "../../state/snackbarState";
export const ProductDetails = (props: any) => {
  const { t } = useTranslation();
  const { item, stripePromise } = props;
  const userValue = useRecoilValue(userState);
  const [messageValues, setMessageValues] = useState({
    message: "",
    type: "",
  });
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [profileId, setProfileId] = useState("");
  const [method, setMethod] = useState("");
  const [snackbar, setSnackbarState] = useRecoilState(snackbarState);

  const openDialog = (text: string, id: string | undefined) => {
    setMethod(text);
    setDialogTitle(t(text));
    setProfileId(id ? id : "");
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setMethod("");
    setDialogTitle("");
    setProfileId("");
    setDialogOpen(false);
  };
  const url = window.location.pathname;

  const onSubsrciption = (profilesCountPlan: number) => {
    const profilesCountUser = userValue.user?.dmProfile.length || 0;
    if (profilesCountUser > profilesCountPlan) {
      showMessageBox(
        t("CHOSSEN_PLAN_ONLY_ALLOWS_X_PROFILES", { x: profilesCountPlan }),
        "Warning"
      );
      return;
    }
    stripeApi
      .createCheckoutSession({
        priceId: item.priceId,
      })
      .then((response: any) => {
        handleCheckout(response.sessionId);
      });
  };

  const handleCheckout = async (sessionId: string) => {
    const stripe = await stripePromise;

    if (stripe) {
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) {
        console.log(error);
      }
    }
  };

  const showMessageBox = (message: string, type: string) => {
    setMessageValues({
      type: type,
      message: message,
    });
    setOpen(true);
  };

  const handleClose = (
    event: CustomEvent<{
      action: string;
    }>
  ) => {
    console.log(event.detail.action);
    if (event.detail.action.includes("1")) {
      setOpen(false);
    } else {
      stripeApi
        .createCheckoutSession({
          priceId: item.priceId,
        })
        .then((response: any) => {
          handleCheckout(response.sessionId);
          setOpen(false);
        });
    }
  };
  return (
    <div
      className={item.priceId ? "tileProduct" : "tileProduct disabledProduct"}
    >
      <MessageBox
        draggable
        open={open}
        type={
          messageValues.type as
            | MessageBoxTypes
            | "Confirm"
            | "Error"
            | "Information"
            | "Success"
            | "Warning"
            | undefined
        }
        onClose={handleClose}
        actions={[
          <Button
            disabled={
              item.details["Maximum Profiles"] <
              (userValue.user?.dmProfile?.length || 0)
            }
            id="MESSAGE_BOX_HOME"
            key={"MESSAGE_BOX_HOME_PRICING1"}
            className="manufacturingButton"
          >
            OK
          </Button>,
          <Button
            key={"MESSAGE_BOX_HOME_PRICING2"}
            className="manufacturingButton"
          >
            {t("CLOSE")}
          </Button>,
        ]}
      >
        <br />
        <span style={{ fontSize: "1.1rem" }}>{messageValues.message}</span>
        <br />
        <br />
        <ProfileList
          onlyDeleteAction={true}
          openDialog={openDialog}
          closeDialog={closeDialog}
        ></ProfileList>
      </MessageBox>
      <ProfileDataDialog
        method={method}
        profileId={profileId}
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
        dialogTitle={dialogTitle}
        setMessageToast={setSnackbarState}
      ></ProfileDataDialog>
      <h1> {item.name}</h1>
      <h3>
        {item.price.toLocaleString("en-US")}
        {t(item.currecy)}
        {item.price > 0 ? (
          props.paymentPeriod === "monthly" ? (
            ` / ${t("MONTH")}`
          ) : (
            ` / ${t("YEAR")}`
          )
        ) : (
          <></>
        )}{" "}
      </h3>
      <h3> {t("FEATURES")}:</h3>
      <div
        style={{
          alignItems: "start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p>
            {t("FUNCTIONALITIES")} {":  "}
          </p>
          <p>{t(item.details["Access to all functionalities"])}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p>
            {t("DATASET_UPLOAD")} {":  "}
          </p>
          <p>{t(item.details["Datasets per Upload"])}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p>
            {t("MAX_PROFILE")} {":  "}
          </p>
          <p>{item.details["Maximum Profiles"]}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p>
            {t("UPLOADS_PER_DAY")} {":  "}
          </p>
          <p>{t(item.details["Uploads / Transports per Day"])}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p>
            {t("TRANSPORTS_PER_DAY")} {":  "}
          </p>
          <p>{t(item.details["Uploads / Transports per Day"])}</p>
        </div>
        {item.duration <= 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p>
              {t("DURATION")} {":  "}
            </p>
            <p>{`${item.duration} ${t("MONTH")}`}</p>
          </div>
        )}
      </div>
      <br />
      {item.priceId && userValue.isLoggedIn && (
        <button
          className={
            !item.priceId ||
            item.priceId === userValue?.user?.subscription?.priceId
              ? "subscriptionButton subscribedButton"
              : "subscriptionButton"
          }
          disabled={
            !item.priceId ||
            item.priceId === userValue?.user?.subscription?.priceId
          }
          onClick={() =>
            onSubsrciption(Number(item.details["Maximum Profiles"]) || 0)
          }
        >
          {!item.priceId ||
          item.priceId === userValue?.user?.subscription?.priceId
            ? t("SUBSCRIBED")
            : t("SUBSCRIPTION")}
        </button>
      )}
    </div>
  );
};
