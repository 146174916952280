import { useRef, useState } from "react";
import DcUploadExcelComp from "../img/DC_Excel_Upload_Component.png";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import ProfileDataDialog from "../components/ProfileData";
import ProfileList from "../components/ProfileList";
import { useRecoilState } from "recoil";
import { snackbarState } from "../state/snackbarState";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  Carousel,
  Select,
  Title,
} from "@ui5/webcomponents-react";
import HowTo from "../components/HowTo";

export default function ProfileManagement() {
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [profileId, setProfileId] = useState("");
  const [method, setMethod] = useState("");
  const [snackbar, setSnackbarState] = useRecoilState(snackbarState);
  const howToRef = useRef(null);

  const scrollToHowTo = () => {
    (howToRef.current as HTMLElement | null)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const openDialog = (text: string, id: string | undefined) => {
    setMethod(text);
    setDialogTitle(t(text));
    setProfileId(id ? id : "");
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setMethod("");
    setDialogTitle("");
    setProfileId("");
    setDialogOpen(false);
  };

  return (
    <div
      style={{
        minHeight: "110vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className="headerBgImgApps">
        <div className="topContainer lightFont">
          <h1>{t("PROFILE_MANAGEMENT_TITLE")}</h1>
          <h2>{t("PROFILE_MANAGEMENT_SUBTITLE")}</h2>
          <Button className="manufacturingButton" onClick={scrollToHowTo}>
            <h2>How To?</h2>
          </Button>
        </div>
      </div>
      <div className="">
        <ProfileDataDialog
          method={method}
          profileId={profileId}
          isDialogOpen={isDialogOpen}
          closeDialog={closeDialog}
          dialogTitle={dialogTitle}
          setMessageToast={setSnackbarState}
        ></ProfileDataDialog>
        <div className="box">
          <div className="buttons">
            <div>
              <Button
                className="manufacturingButton"
                design="Emphasized"
                onClick={() => openDialog("ADD", undefined)}
              >
                {t("ADD_PROFILE")}
              </Button>
            </div>
          </div>

          <div
            className="dragNdrop"
            style={{ padding: "10px", width: "auto", minWidth: "60%" }}
          >
            <ProfileList
              openDialog={openDialog}
              closeDialog={closeDialog}
            ></ProfileList>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <HowTo view="profileManagement" />
      <br />
      <br />
      <br />
      <div ref={howToRef} />
    </div>
  );
}
