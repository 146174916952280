import { read, utils } from "xlsx";

export function downloadExcel() {
  // Replace 'example.xlsx' with the actual file name you want to download
  const fileName = "DM_DataCollection_Template.xlsx";
  const url = "" + fileName; // Replace this with the actual file path  /Users/e-255/Documents/Pr ojects/DMC_Internal/DataCollections/DataCollectionTemp.xlsx

  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";

  xhr.onload = function () {
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response], {
        type: "application/octet-stream",
      });
      // Check for IE (Internet Explorer) and Edge
      const nav = window.navigator as any;
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }
    }
  };
  xhr.send();
}

export function convertToJSON(excelFileInput: any, props: any) {
  props.setExcelLog([]);
  props.setPushLog([]);
  props.setUpdate([]);
  props.setSuccessStatusPost("");
  props.setSuccessStatusUpdate("");
  props.setProgressUpdate(0);
  props.setProgressUpload(0);

  const file = excelFileInput[0];
  const reader = new FileReader();

  reader.onload = function (event) {
    const result: string | ArrayBufferLike = event.target?.result
      ? event.target.result
      : new ArrayBuffer(0);
    if (typeof result === "string") {
      return;
    }
    const data = new Uint8Array(result);

    const workbook = read(data, { type: "array" });

    const jsonData: any = {};
    const DCGroupWorksheet: any[] = [];
    const param: any[] = [];
    const assignment: any[] = [];
    let ct = 0;

    const targetSheetNames = [
      "Data Collection Group",
      "Parameter",
      "Assignment",
    ];
    workbook.SheetNames.forEach(function (sheetName) {
      if (targetSheetNames.includes(sheetName)) {
        const worksheet = workbook.Sheets[sheetName];
        jsonData[sheetName] = utils.sheet_to_json(worksheet);

        const WorksheetData = jsonData[sheetName];

        WorksheetData.map((row: any) => {
          if (row["Data Collection Group Name"] !== undefined) {
            if (ct === 0) {
              row["Data Collection Group Name"].trim();
              DCGroupWorksheet.push(row);
            } else if (ct === 1) {
              row["Data Collection Group Name"].trim();
              param.push(row);
            } else {
              row["Data Collection Group Name"].trim();
              assignment.push(row);
            }
          }
        });
        ct++;
      } else {
        props.errorsExcel.push({
          text: "PLEASE_USE_EXCEL_TEMPLATE",
          status: "Negative",
        });
        props.setExcelLog(props.errorsExcel);
        return;
      }
    });
    if (props.errorsExcel.length === 0) {
      groupingDCGroups(DCGroupWorksheet, param, assignment);
    } else {
      return;
    }
  };

  let map: any = {
    "Data Collection Group Name": {
      name: "group",
      dataType: "string",
      letter: "uppercase",
    },
    Version: {
      name: "version",
      dataType: "string",
      letter: "uppercase",
    },
    Plant: {
      name: "plant",
      dataType: "string",
      letter: "lowercase",
    },
    "Current Version": {
      name: "currentVersion",
      dataType: "boolean",
      letter: "lowercase",
    },
    Status: {
      name: "status",
      dataType: ["NEW", "RELEASABLE", "OBSOLENTE", "HOLD"],
      letter: "lowercase",
    },
    "Pass/Fail Group": {
      name: "passFailGroup",
      dataType: "boolean",
      letter: "lowercase",
    },
    "Fail/Reject Number": {
      name: "passFailNumber",
      dataType: "number",
      letter: "lowercase",
    },
    "Allow Multiple Collection": {
      name: "allowMultipleCollection",
      dataType: "boolean",
      letter: "lowercase",
    },
    "Parameter Status": {
      name: "dcParameterStatus",
      dataType: ["ENABLED", "DISABLED"],
      letter: "lowercase",
    },
    "Parameter Type": {
      name: "dcParameterType",
      dataType: ["TEXT", "NUMBER", "BOOLEAN"],
      letter: "lowercase",
    },
    Description: {
      name: "description",
      dataType: "string",
      letter: "lowercase",
    },
    Name: {
      name: "parameterName",
      dataType: "string",
      letter: "uppercase",
    },
    Sequence: {
      name: "sequence",
      dataType: "number",
      letter: "lowercase",
    },
    "Unit of Measure": {
      name: "unitOfMeasure",
      dataType: "string",
      letter: "lowercase",
    },
    Prompt: {
      name: "parameterPrompt",
      dataType: "string",
      letter: "lowercase",
    },
    "Required Data Entries": {
      name: "requiredDataEntries",
      dataType: "number",
      letter: "lowercase",
    },
    "True Value Name": {
      name: "trueValueName",
      dataType: "string",
      letter: "lowercase",
    },
    "False Value Name": {
      name: "falseValueName",
      dataType: "string",
      letter: "lowercase",
    },
    "Minimum Value": {
      name: "minValue",
      dataType: "number",
      letter: "lowercase",
    },
    "Maximum Value": {
      name: "maxValue",
      dataType: "number",
      letter: "lowercase",
    },
    "Target Value": {
      name: "targetValue",
      dataType: "number",
      letter: "lowercase",
    },
    "Override Min/Max Value": {
      name: "overrideMinMax",
      dataType: "boolean",
      letter: "lowercase",
    },
    "Auto Log NC on Min/Max Override": {
      name: "autoLogNc",
      dataType: "boolean",
      letter: "lowercase",
    },
    "Nonconformance Code": {
      name: "ncCode",
      dataType: "string",
      letter: "lowercase",
    },
    Operation: {
      name: "operation",
      dataType: "string",
      letter: "lowercase",
    },
    "Operation Version": {
      name: "oVersion",
      dataType: "string",
      letter: "lowercase",
    },
    "Routing / Recipe": {
      name: "routing",
      dataType: "string",
      letter: "lowercase",
    },
    "Routing / Recipe Version": {
      name: "rVersion",
      dataType: "string",
      letter: "lowercase",
    },
    "Routing / Recipe Type": {
      name: "type",
      dataTranslation: {
        "Order-Specific Routing": "SHOPORDER_SPECIFIC",
        "Production Routing": "PRODUCTION",
        "NC Routing": "NC",
        "Disposition Function Routing": "DISPOSITION_FUNCTION",
        "Special Routing": "SPECIAL",
      },
      dataType: "string",
      letter: "lowercase",
    },
    "Activity / Phase ID": {
      name: "stepId",
      dataType: "string",
      letter: "lowercase",
    },
    "Shop Order": {
      name: "shopOrder",
      dataType: "string",
      letter: "lowercase",
    },
    "Work Center": {
      name: "workCenter",
      dataType: "string",
      letter: "uppercase",
    },
    Material: {
      name: "material",
      dataType: "string",
      letter: "uppercase",
    },
    "Material Version": {
      name: "mVersion",
      dataType: "string",
      letter: "lowercase",
    },
    Type: {
      name: "type",
      dataType: "string",
      letter: "lowercase",
    },
    Resource: {
      name: "resource",
      dataType: "string",
      letter: "uppercase",
    },
  };
  let ctParamAssignment = 0;

  async function groupingDCGroups(
    DCGroupWorksheet: any,
    paramWorksheet: any,
    assignmentWorksheet: any
  ) {
    //DataCollection
    DCGroupWorksheet.map((currentDCBasicAttributes: any) => {
      const currentDCname =
        currentDCBasicAttributes["Data Collection Group Name"];
      dataTypeCheckANDmapping(
        currentDCBasicAttributes,
        currentDCBasicAttributes
      );

      //Parrameters
      const currentDCparameters = collectCurrentAtributes(
        paramWorksheet,
        currentDCname,
        true
      );
      //Assignment
      const currentDCassignment = collectCurrentAtributes(
        assignmentWorksheet,
        currentDCname,
        false
      );
      // passFailGroupCheck(currentDCBasicAttributes, currentDCparameters);
      //Together
      props.allDC.push(
        assignDCGroup(
          currentDCBasicAttributes,
          currentDCparameters,
          currentDCassignment
        )
      );
    });
    if (
      ctParamAssignment !==
      paramWorksheet.length + assignmentWorksheet.length
    ) {
      props.errorsExcel.push({
        text: "PARAMETERS_OR_ASSIGNMENTS_MISSING",
        status: "Negative",
      });
      props.setExcelLog(props.errorsExcel);
    }
    props.setAllDCGroups(props.allDC);
    props.setExcelLog(props.errorsExcel);
    enableUpload();
  }

  function enableUpload() {
    if (props.allDC.length !== 0) {
      if (props.errorsExcel.length === 0) {
        props.setVisibility(true);
        props.errorsExcel.push({
          text: "DC_GROUPS_READY_TO_UPDATE_MESSAGE",
          status: "Information",
        });
        props.setExcelLog(props.errorsExcel);
      }
    } else {
      props.setVisibility(false);
      props.dcGroupFound();
    }
  }

  function assignDCGroup(
    currentDCBasicAttributes: any,
    currentDCparameters: any,
    currentDCassignment: any
  ) {
    return Object.assign(
      currentDCBasicAttributes,
      { dcParameters: currentDCparameters },
      { attachedPoints: arrangeAssignments(currentDCassignment) }
    );
  }

  function collectCurrentAtributes(worksheet: any, dcgName: any, isParam: any) {
    const collected: any[] = [];
    let sequence = 10;
    worksheet.map((rowOfAttributes: any) => {
      if (rowOfAttributes["Data Collection Group Name"] === dcgName) {
        ctParamAssignment++;
        delete rowOfAttributes["Data Collection Group Name"];
        if (isParam ? delete rowOfAttributes["Required"] : null)
          dataTypeCheckANDmapping(rowOfAttributes, dcgName);
        collected.push({ ...rowOfAttributes, sequence: sequence });
        sequence += 10;
      }
    });

    return collected;
  }

  function dataTypeCheckANDmapping(row: any, dcgName: any) {
    //go through all Attributes in one row of one Worksheet
    for (const attribute in row) {
      if (map[attribute] !== undefined) {
        mappingYesNo(row, attribute);
        //Coustom DataTypes
        if (
          Array.isArray(map[attribute].dataType) &&
          map[attribute].dataType.includes(row[attribute])
        ) {
          mapping(attribute, row);

          //String
        } else if (map[attribute].dataType === "string") {
          row[attribute] = row[attribute] + "";
          mapping(attribute, row);

          //remaining DataTypes
        } else if (map[attribute].dataType === typeof row[attribute]) {
          row[map[attribute].name] = row[attribute];
          delete row[attribute];

          //errorLog when dataType incorrect
        } else {
          props.errorsExcel.push({
            text: `DCGroup: '${dcgName}', Attribute: '${attribute}' is wrong. It should be '${
              map[attribute].dataType
            }', but is '${typeof row[attribute]}'.`,
            status: "Negative",
          });
        }

        format(row, attribute);
      }
    }
  }

  function format(row: any, attribute: any) {
    if (map[attribute].dataType === "string") {
      row[map[attribute].name] = row[map[attribute].name].trim();
    }
    if (map[attribute].letter === "uppercase") {
      row[map[attribute].name] = row[map[attribute].name].toUpperCase();
    }
  }

  function mappingYesNo(row: any, attribute: any) {
    if (map[attribute] !== undefined) {
      if (map[attribute].dataType === "boolean") {
        if (typeof row[attribute] === "boolean") {
          if (row[attribute] === true) {
            row[attribute] = "YES";
          } else {
            row[attribute] = "NO";
          }
        }
        if (
          row[attribute].trim().toUpperCase() === "YES" ||
          row[attribute].trim().toUpperCase() === "Y" ||
          row[attribute].trim().toUpperCase() === "TRUE"
        ) {
          row[attribute] = true;
        } else if (
          row[attribute].trim().toUpperCase() === "NO" ||
          row[attribute].trim().toUpperCase() === "N" ||
          row[attribute].trim().toUpperCase() === "FALSE"
        ) {
          row[attribute] = false;
        } else {
          props.errorsExcel.push(
            `Attribute ${attribute} has to be 'yes' or 'no' in the Excel-File.` //TODO: Translate
          );
          return;
        }
      }
    }
  }

  function mapping(attribute: any, row: any) {
    row[map[attribute].name] = row[attribute].trim();
    delete row[attribute];
  }

  function arrangeAssignments(assignment: any) {
    const propertyArray = assignment.map((row: any) => ({
      material:
        row["Material"] || row["Material Version"]
          ? {
              material: row["Material"].trim().toUpperCase() || null,
              version: row["Material Version"].trim().toUpperCase() || null,
            }
          : null,
      operation:
        row["Operation"] || row["Operation Version"]
          ? {
              operation: row["Operation"].trim().toUpperCase() || null,
              version: row["Operation Version"].trim().toUpperCase() || null,
            }
          : null,
      resource: row["Resource"] || null,
      routing:
        row["Routing / Recipe"] ||
        row["Routing / Recipe Type"] ||
        row["Routing / Recipe Version"]
          ? {
              routing:
                row["Routing / Recipe"]?.toString()?.trim().toUpperCase() ||
                null,
              type:
                map["Routing / Recipe Type"].dataTranslation[
                  row["Routing / Recipe Type"]
                ] || null,
              version:
                row["Routing / Recipe Version"].trim().toUpperCase() || null,
            }
          : null,
      routingStep:
        row["Routing / Recipe"] || row["Activity / Phase ID"]
          ? {
              routing: {
                routing:
                  row["Routing / Recipe"]?.toString()?.trim().toUpperCase() ||
                  null,
                type:
                  map["Routing / Recipe Type"].dataTranslation[
                    row["Routing / Recipe Type"]
                  ] || null,
                version:
                  row["Routing / Recipe Version"].trim().toUpperCase() || null,
              },
              stepId: row["Activity / Phase ID"] || null,
            }
          : null,
      sequence: row["Sequence"] || null,
      shopOrder: row["Shop Order"] || null,
      workCenter: row["Work Center"].trim().toUpperCase() || null,
    }));

    return propertyArray;
  }

  function passFailGroupCheck(
    dcGroupAttributes: any,
    currentDCparameters: any
  ) {
    if (dcGroupAttributes["passFailGroup"] === true) {
      currentDCparameters.map((attribute: any) => {
        if (
          !(
            attribute["overrideMinMax"] === true &&
            attribute["autoLogNc"] === false &&
            attribute["ncCode"] == null
          )
        ) {
          props.errorsExcel.push({
            text: `DCGroup: '${dcGroupAttributes.group}': 'Pass/Fail Group' should be turned to 'no' or otherwise change your 'Fail/Reject Number', 'Auto Log NC on Min/Max Override' and 'Nonconformance Code' settings.`,
            status: "fail",
          });
        }
      });
    }
  }

  reader.readAsArrayBuffer(file);
}
