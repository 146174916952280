import "./style/App.css";
import "@ui5/webcomponents-icons/dist/AllIcons.js";
import DataCollectionUpload from "./pages/DataCollectionUpload";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import PodTransport from "./pages/PodTranport";
import DataCollectionTransport from "./pages/DataCollectionTransport";
import ProfileManagement from "./pages/ProfileManagement";
import Footer from "./components/Footer";
import { Subscription } from "./pages/Subscription";
import { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { snackbarState } from "./state/snackbarState";
import { spinnerSelector } from "./state/spinnerState";
import { ThreeDots } from "react-loader-spinner";
import Verify from "./pages/Verify";
import DataPrivacy from "./pages/DataPrivacy";
import Imprint from "./pages/Imprint";
import stripeApi from "./service/stripe";
import usePreventZoom from "./utils/usability.utils";
import { userSelector } from "./state/userState";
import TermsAndConditions from "./pages/TermsAndConditions";
import { useMatomoTracker } from "./utils/matomo.utils";

function App() {
  usePreventZoom();
  const [snackbar, setSnackbarState] = useRecoilState(snackbarState);
  const userValue = useRecoilValue(userSelector);
  const spinner = useRecoilValue(spinnerSelector);
  let [allProducts, setAllProducts] = useState({
    data: [],
    activeProductPricesMontly: [],
    activeProductPricesYearly: [],
  });
  function showToast() {
    // Get the snackbar DIV
    if (snackbar.message) {
      var x = document.getElementById("snackbar");
      if (!x) {
        return;
      }
      if (snackbar.status === "error") {
        x.classList.add("snackbarError");
      }

      // Add the "show" class to DIV
      x.classList.add("show");

      // After 4 seconds, remove the show class from DIV
      setTimeout(() => {
        if (!x) {
          return;
        }
        x.classList.remove("show");
        x.classList.remove("snackbarError");
      }, 4000);
    }
  }
  useEffect(() => {
    showToast();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // useMatomoTracker();
    stripeApi
      .getAllProducts()
      .then((data: any) => {
        // data = data.map((product: any) => {
        //   return { ...product, duration};
        // });
        const { activeProductPricesMontly, activeProductPricesYearly } = data;
        // const usePrices =
        //   userValue.subscriptionPeriod === "monthly"
        //     ? activeProductPricesMontly
        //     : activeProductPricesYearly;
        // usePrices.unshift({
        //   name: "Free Version",
        //   priceId: undefined,
        //   details: {
        //     "Access to all functionalities": "ALL",
        //     "Datasets per Upload": "2",
        //     "Maximum Profiles": "2",
        //     "No User limit": "Yes",
        //     "Uploads / Transports per Day": "5",
        //   },
        //   price: 0,
        //   currecy: "eur",
        //   duration: 1,
        // });
        // usePrices.sort(function (a: any, b: any) {
        //   return parseFloat(a.price) - parseFloat(b.price);
        // });
        activeProductPricesMontly.unshift({
          name: "Free Version",
          priceId: undefined,
          details: {
            "Access to all functionalities": "ALL",
            "Datasets per Upload": "2",
            "Maximum Profiles": "2",
            "No User limit": "Yes",
            "Uploads / Transports per Day": "5",
          },
          price: 0,
          currecy: "eur",
          duration: 1,
        });
        activeProductPricesMontly.sort(function (a: any, b: any) {
          return parseFloat(a.price) - parseFloat(b.price);
        });
        activeProductPricesYearly.unshift({
          name: "Free Version",
          priceId: undefined,
          details: {
            "Access to all functionalities": "ALL",
            "Datasets per Upload": "2",
            "Maximum Profiles": "2",
            "No User limit": "Yes",
            "Uploads / Transports per Day": "5",
          },
          price: 0,
          currecy: "eur",
          duration: 1,
        });
        activeProductPricesYearly.sort(function (a: any, b: any) {
          return parseFloat(a.price) - parseFloat(b.price);
        });

        setAllProducts((prev: any) => ({
          ...prev,
          data: [],
          activeProductPricesMontly: activeProductPricesMontly,
          activeProductPricesYearly: activeProductPricesYearly,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [snackbar]);
  return (
    <div className="App">
      <ThreeDots
        visible={spinner.active}
        height="80"
        width="80"
        color="#c3d72b"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass="spinner"
      />
      <Router>
        <Header setMessageToast={setSnackbarState}></Header>
        <div id="snackbar">{snackbar.message}</div>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                allProducts={
                  userValue.subscriptionPeriod === "monthly"
                    ? allProducts.activeProductPricesMontly
                    : allProducts.activeProductPricesYearly
                }
              />
            }
          />
          <Route path="/profileManagement" element={<ProfileManagement />} />
          <Route
            path="/dataCollectionUpload"
            element={<DataCollectionUpload />}
          />
          <Route path="/podTransport" element={<PodTransport />} />
          <Route
            path="/dataCollectionTransfer"
            element={<DataCollectionTransport />}
          />

          <Route
            path="/subscription"
            element={
              <Subscription
                allProducts={
                  userValue.subscriptionPeriod === "monthly"
                    ? allProducts.activeProductPricesMontly
                    : allProducts.activeProductPricesYearly
                }
              />
            }
          />
          <Route path="/verify" element={<Verify />} />
          <Route path="/dataPrivacy" element={<DataPrivacy />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
        </Routes>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
