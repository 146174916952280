import { atom, selector } from "recoil";
import { ConfState } from "../model/Conf.mode";

export const confState = atom<ConfState>({
  key: "confState",
  default: { language: "en" },
});

export const confSelector = selector<ConfState>({
  key: "confSelector",
  get: ({ get }) => {
    const confStateData = get(confState);

    return confStateData;
  },
});
