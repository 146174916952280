import {
  Button,
  Label,
  Panel,
  Table,
  TableCell,
  TableColumn,
  TableRow,
} from "@ui5/webcomponents-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatomoTracker } from "../utils/matomo.utils";

export default function CookieConsent(props: any) {
  const { t } = useTranslation();

  const [cookieConsent, setCookieConsent] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;
  window.onresize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // useMatomoTracker();
    const cookieConsent = document.cookie.includes("cookieConsent=true");
    if (cookieConsent) {
      setCookieConsent(true);
    }
  }, []);
  const handleCookieConsent = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1); // Set expiration date to 1 year from now

    document.cookie = `cookieConsent=true; expires=${expirationDate.toUTCString()}`;
    document.cookie = `cookieNeccesary=true; expires=${expirationDate.toUTCString()}`;
    setCookieConsent(true);
    closeCookieSettings();
  };
  const openCookieSettings = () => {
    setCookieConsent(false);
  };
  const closeCookieSettings = () => {
    setCookieConsent(true);
    props.closeCookieDialog();
  };
  return (
    <div
      id="idDialogLogin"
      className="dialog"
      style={{
        display: !cookieConsent || props.open ? "block" : "none",
      }}
    >
      <div
        className="dialog-content animateDialog"
        style={{ width: "auto", maxWidth: "800px" }}
      >
        <span
          onClick={(event) => {
            event.preventDefault();
            closeCookieSettings();
          }}
          className="closeDialog"
        >
          &times;
        </span>
        <div className="tab">
          <button
            id="idLogin"
            className={!cookieConsent ? "active tablinks" : "tablinks"}
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            {t("COOKIE_SETTINGS")}
          </button>
        </div>
        <form>
          <div id="login" className="tabcontent" style={{ display: "block" }}>
            <p
              style={{
                fontSize: "14px",
                lineHeight: 1.4,
                marginTop: 0,
                padding: "20px",
                color: "#000",
                fontFamily: "Helvetica Neue",
                fontWeight: "light",
              }}
            >
              {t("COOKIE_SETTINGS_MAIN_TEXT")}
            </p>
            <Panel
              collapsed={true}
              accessibleRole="Form"
              headerLevel="H4"
              headerText={t("NECCESARY_COOKIES")}
              onToggle={function _a() {}}
              className="panel"
              style={{ textAlign: "start", margin: "10px" }}
            >
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: 1.4,
                  marginTop: 0,
                  padding: "5px",
                  color: "#000",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "light",
                }}
              >
                {t("COOKIE_SETTINGS_NECCESARY_TEXT")}
              </p>
              <Table
                style={{ height: "100%", maxHeight: "300px" }}
                columns={
                  <>
                    <TableColumn style={{ width: "12rem" }}>
                      <Label>{t("COOKIE")}</Label>
                    </TableColumn>
                    <TableColumn>
                      <Label>{t("DURATION")}</Label>
                    </TableColumn>
                    <TableColumn>
                      <Label>{t("DESCRIPTION")}</Label>
                    </TableColumn>
                  </>
                }
              >
                <TableRow>
                  <TableCell>
                    <Label>token</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("NO_EXPERATION_DATE")}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "200px" }}>
                    <Label wrappingType="Normal">
                      {t("COOKIE_SETTINGS_TOKEN_DESCRIPTION")}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>profiles</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("NO_EXPERATION_DATE")}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t("COOKIE_SETTINGS_PROFILES_DESCRIPTION")}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>lastActivityTime</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("NO_EXPERATION_DATE")}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t("COOKIE_SETTINGS_LAST_ACTIVE_DESCRIPTION")}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>cookieConsent</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("COOKIE_DURATION_YEAR", { year: 1 })}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t("COOKIE_SETTINGS_COOKIE_CONSENT_DESCRIPTION")}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>cookieNeccesary</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("COOKIE_DURATION_YEAR", { year: 1 })}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t("COOKIE_SETTINGS_COOKIE_NECCESARY_DESCRIPTION")}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>ARRAffinitySameSite</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("COOKIE_DURATION_SESSION")}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t("COOKIE_SETTINGS_ARRAffinitySameSite_DESCRIPTION")}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>__stripe_mid</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("COOKIE_DURATION_YEAR", { year: 1 })}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t("COOKIE_SETTINGS_STRIPE_MID_DESCRIPTION")}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>__stripe_sid</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("COOKIE_DURATION_MINUTE", { minute: 30 })}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t("COOKIE_SETTINGS_STRIPE_SID_DESCRIPTION")}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>cookie-perms</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("COOKIE_DURATION_MONTH", { month: 6 })}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t("COOKIE_SETTINGS_COOKIE_PERMS_DESCRIPTION")}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>machine_identifier</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("COOKIE_DURATION_YEAR", { year: 1 })}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t(
                        "COOKIE_SETTINGS_STRIPE_MACHINE_IDENTIFIER_DESCRIPTION"
                      )}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>private_machine_identifier</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("COOKIE_DURATION_YEAR", { year: 1 })}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t(
                        "COOKIE_SETTINGS_STRIPE_PRIVATE_MACHINE_IDENTIFIER_DESCRIPTION"
                      )}
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>stripe.csrf</Label>
                  </TableCell>
                  <TableCell>
                    <Label wrappingType="Normal">
                      {t("COOKIE_DURATION_SESSION")}
                    </Label>
                  </TableCell>
                  <TableCell style={{ width: "auto", maxWidth: "100px" }}>
                    <Label wrappingType="Normal">
                      {t("COOKIE_SETTINGS_STRIPE_CSRF_DESCRIPTION")}
                    </Label>
                  </TableCell>
                </TableRow>
              </Table>
            </Panel>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                id="idLoginButton"
                style={{ width: "auto", maxWidth: "250px" }}
                className="loginButton"
                onClick={(event) => {
                  event.preventDefault();
                  handleCookieConsent();
                }}
              >
                {t("SAVE_AND_ACCEPT")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
