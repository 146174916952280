import React, { useEffect, useRef, useState } from "react";
import "../style/excelUpload.css";
import DcUploadExcelComp from "../img/DC_Excel_Upload_Component.png";
import Log from "../components/Log";
import {
  Avatar,
  Button,
  Carousel,
  ProgressIndicator,
  Select,
  Text,
  Title,
  UploadCollection,
} from "@ui5/webcomponents-react"; // loads ui5-button wrapped in a ui5-webcomponents-react component
import Upload from "../components/Ulpoad";
import { useTranslation } from "react-i18next";
import HowTo from "../components/HowTo";

export default function DataCollectionUpload(props: any) {
  const { t } = useTranslation();

  const [SuccessStatusPost, setSuccessStatusPost] = useState("");
  const [SuccessStatusUpdate, setSuccessStatusUpdate] = useState("");
  const [excelLog, setExcelLog] = useState([]);
  const [pushLog, setPushLog] = useState([]);
  const [isProgressUpload, setProgressUpload] = useState(0);
  const [isProgressUpdate, setProgressUpdate] = useState(0);
  const messagesEndRef = useRef(null);
  const howToRef = useRef(null);

  const scrollToBottom = () => {
    (messagesEndRef.current as HTMLElement | null)?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const scrollToHowTo = () => {
    (howToRef.current as HTMLElement | null)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // scrollToBottom();
  }, [excelLog, pushLog]);

  return (
    <>
      <div className="headerBgImgApps">
        <div className="topContainer lightFont">
          <h1>{t("DATA_COLLECTION_UPLOAD_TITLE")}</h1>
          <h2>{t("DATA_COLLECTION_UPLOAD_SUBTITLE")}</h2>
          <Button className="manufacturingButton" onClick={scrollToHowTo}>
            <h2>How To?</h2>
          </Button>
        </div>
      </div>
      <div>
        <Upload
          setExcelLog={setExcelLog}
          setPushLog={setPushLog}
          setSuccessStatusPost={setSuccessStatusPost}
          setSuccessStatusUpdate={setSuccessStatusUpdate}
          setProgressUpdate={setProgressUpdate}
          setProgressUpload={setProgressUpload}
        ></Upload>
        <div className="spacebelow">
          {isProgressUpload > 0 && (
            <ProgressIndicator
              value={isProgressUpload}
              style={{ width: "70%" }}
            />
          )}
          <div> {SuccessStatusPost}</div>
          {isProgressUpdate > 0 && (
            <ProgressIndicator
              value={isProgressUpdate}
              style={{ width: "70%" }}
            />
          )}
          <div> {SuccessStatusUpdate}</div>
        </div>
        {excelLog.length > 0 || pushLog.length > 0 ? (
          <Log excelLog={excelLog} pushLog={pushLog}></Log>
        ) : (
          <></>
        )}
        <br></br>
        <div ref={messagesEndRef} />
      </div>
      <br />
      <br />
      <br />
      <HowTo view="dcUpload" />
      <br />
      <br />
      <br />
      <div ref={howToRef} />
    </>
  );
}
