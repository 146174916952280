import { Icon } from "@ui5/webcomponents-react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { headerState } from "../../state/headerState";
import { userSelector } from "../../state/userState";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { isVerificationDateOlderThanMonth } from "../../utils/user.utils";
import { useMatomoTracker } from "../../utils/matomo.utils";

export default function Tile(props: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [oHeaderState, setHeaderState] = useRecoilState(headerState);
  const userValue = useRecoilValue(userSelector);
  const clickTile = (title: string, path: string) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // useMatomoTracker();
    // if (window._paq) {
    //   window._paq.push(["trackEvent", "Tile Clicked", "Click", "Tile Button"]);
    // }
    if (userValue.user) {
      if (isVerificationDateOlderThanMonth(userValue.user)) {
        props.showMessageBox(t("FREE_PLAN_EXPIRED"), "Error");
        return;
      }
    }
    if (userValue.isLoggedIn) {
      setHeaderState({
        appName: title,
        isNavBack: true,
        isLoginPopUpOpen: false,
      });
      navigate(path);
    } else {
      // setToastText("Please Login first");
      // toast.current.show();
      setHeaderState((prev) => ({ ...prev, isLoginPopUpOpen: true }));
    }
  };
  const formattedText = props.title
    .split("\n")
    .map((line: string, index: number) => (
      <Fragment key={index}>
        {line}
        {index < props.title.length - 1 && <br />}
      </Fragment>
    ));
  return (
    <div
      className="tileContainer"
      onClick={() => clickTile(props.title, props.path)}
    >
      <div className="tile">
        <p className="tileContainerHeaderText">{formattedText}</p>
        <p className="tileContainerSubText">{props.subtitle}</p>
        <i className="tileIcon">
          <Icon
            name={props.iconName}
            style={{ width: "40px", height: "40px" }}
          />
        </i>
      </div>
      <div className="overlayTile"></div>
    </div>
  );
}
