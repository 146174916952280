import { useState } from "react";
import concircle from "../img/conLogoC_color.png";
import { useTranslation } from "react-i18next";
import { Panel } from "@ui5/webcomponents-react";
import CookieConsent from "./CookieConsent";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const [cookieSettingsOpen, setCookieSettingsOpen] = useState(false);
  const openCookieSettings = () => {
    setCookieSettingsOpen(true);
  };
  const closeCookieSettings = () => {
    setCookieSettingsOpen(false);
  };
  const navigator = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className="containerFooter">
        <img width={"25px"} src={concircle} alt="conDMWizard"></img>
        <a href="https://www.concircle.com" target="_blank">
          <p style={{ paddingLeft: "10px", color: " #00263a" }}>
            © concircle 2024
          </p>
        </a>
        <p>&nbsp;&nbsp; |</p>
        <a
          onClick={() => {
            navigator("/dataPrivacy");
          }}
        >
          <p style={{ paddingLeft: "10px", color: " #00263a" }}>
            {t("DATA_PRIVACY")}
          </p>
        </a>
        <p>&nbsp;&nbsp; |</p>
        <a
          onClick={() => {
            navigator("/imprint");
          }}
        >
          <p style={{ paddingLeft: "10px", color: " #00263a" }}>
            {t("IMPRINT")}
          </p>
        </a>
        <p>&nbsp;&nbsp; |</p>
        <a
          onClick={() => {
            navigator("/termsAndConditions");
          }}
        >
          <p style={{ paddingLeft: "10px", color: " #00263a" }}>{t("GTC")}</p>
        </a>
        <p>&nbsp;&nbsp; |</p>
        <a onClick={openCookieSettings}>
          <p style={{ paddingLeft: "10px", color: " #00263a" }}>
            {t("COOKIE_SETTINGS")}
          </p>
        </a>
      </div>

      <CookieConsent
        open={cookieSettingsOpen}
        closeCookieDialog={closeCookieSettings}
      ></CookieConsent>
    </>
  );
}
