import {
  CreateProfile,
  DeleteProfile,
  UpdateProfile,
} from "../dto/profileManagement.dto";
import { User } from "../model/User.model";
import axiosClient from "./axiosClient";

const masterDataManagementApi = {
  createProfile: async (params: CreateProfile): Promise<User> =>
    await axiosClient.post("dmProfile", params),
  updateProfile: (params: UpdateProfile) =>
    axiosClient.put("dmProfile", params),
  deleteProfile: (params: DeleteProfile) =>
    axiosClient.delete("dmProfile/" + params._id),
};

export default masterDataManagementApi;
