import { CreateSessionDto } from "../dto/stripe.dto";
import axiosClient from "./axiosClient";

const stripeApi = {
  createCheckoutSession: (params: CreateSessionDto) =>
    axiosClient.post("stripe/createCheckoutSession", params),
  createPortalSession: () => axiosClient.post("stripe/createPortalSession", {}),
  getStripeProfile: () => axiosClient.get("stripe", {}),
  getAllProducts: () => axiosClient.get("stripe/allProducts", {}),
};

export default stripeApi;
