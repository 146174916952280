import React, { useRef, useState } from "react";
import "../style/transport.css";
import SystemDetails from "../components/TransportComponents/SystemDetails";
import TableTransport from "../components/TransportComponents/TableTransport";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  Carousel,
  Select,
  Title,
} from "@ui5/webcomponents-react";
import DcUploadExcelComp from "../img/DC_Excel_Upload_Component.png";
import HowTo from "../components/HowTo";

export default function DataCollectionTransport(props: any) {
  const { t } = useTranslation();
  const [disableTransport, setDisableTransport] = useState(true);
  const howToRef = useRef(null);

  const scrollToHowTo = () => {
    (howToRef.current as HTMLElement | null)?.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div
      style={{
        minHeight: "110vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="headerBgImgApps">
        <div className="topContainer lightFont">
          <h1>{t("DATA_COLLECTION_TRANSFER_TITLE")}</h1>
          <h2>{t("DATA_COLLECTION_TRANSFER_SUBTITLE")}</h2>
          {/* <img width={"100px"} src={LogoC}></img> */}
          <Button className="manufacturingButton" onClick={scrollToHowTo}>
            <h2>How To?</h2>
          </Button>
        </div>
      </div>
      <div className="">
        <div className="cardTranport">
          <SystemDetails setDisableTransport={setDisableTransport} />
          <TableTransport
            componentName="DC"
            disableTransport={disableTransport}
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <HowTo view="dcTransfer" />
      <br />
      <br />
      <br />
      <div ref={howToRef} />
    </div>
  );
}
