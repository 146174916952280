import { DataCollectionTransport } from "../model/dataCollection";
import axiosClient from "./axiosClient";

const datacollectionApi = {
  upload: async (params: any): Promise<any> =>
    await axiosClient.post("datacollection/groups", params),
  update: async (params: any): Promise<any> =>
    await axiosClient.post("datacollection/update", params),
  delete: async (params: any): Promise<any> =>
    await axiosClient.post("datacollection/delete", params),
  tranport: async (params: DataCollectionTransport): Promise<any> =>
    await axiosClient.post("datacollection/transport", params),
};

export default datacollectionApi;
