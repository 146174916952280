import React, { useState, useEffect, useRef } from "react";
import { ProductDisplay } from "../components/subscription/ProductDisplay";
import { SuccessDisplay } from "../components/subscription/SuccessDisplay";
import { Message } from "../components/subscription/Message";
import stripeApi from "../service/stripe";
import { Button } from "@ui5/webcomponents-react";
import { useTranslation } from "react-i18next";
import HowTo from "../components/HowTo";

export const Subscription = (props: any) => {
  const { allProducts } = props;
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const { t } = useTranslation();
  const howToRef = useRef(null);

  return (
    <div>
      <div className="headerBgImgApps">
        <div className="topContainer lightFont">
          <h1>{t("SUBSCRIPTION_TITLE")}</h1>
          <h2>{t("SUBSCRIPTION_SUBTITLE")}</h2>
        </div>
      </div>
      {!success && message === "" ? (
        <ProductDisplay allProducts={allProducts} />
      ) : success && sessionId !== "" ? (
        <SuccessDisplay />
      ) : (
        <Message message={message} />
      )}
    </div>
  );
};
