"use client";
import React, { useEffect, useState } from "react";
import {
  Input,
  Label,
  Button,
  Form,
  Title,
  Dialog,
  Bar,
  FormItem,
  FormGroup,
  Select,
  Option,
  MessageBox,
  MessageBoxActions,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import masterDataManagementApi from "../service/masterDataManagement";
import { DmProfile, User, UserState } from "../model/User.model";
import { useRecoilState } from "recoil";
import { userState } from "../state/userState";
import { useTranslation } from "react-i18next";
import { spinnerState } from "../state/spinnerState";

export default function ProfileDataDialog(props: any) {
  const { setMessageToast } = props;
  const initFormValue = {
    name: "",
    description: "",
    plant: "",
    dmcUrl: "https://api.eu10.dmc.cloud.sap",
    tokenURL: "",
    clientId: "",
    clientSecret: "",
  };
  const [isProfileLimitReached, setIsProfileLimitReached] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const [spinner, setSpinnerState] = useRecoilState(spinnerState);

  const [formValues, setFormValues] = useState(initFormValue);

  const { t } = useTranslation();

  const handleChange = (event: any) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: any) => {
    setSpinnerState({ active: true });
    event.preventDefault();
    const profileData = {
      name: formValues.name,
      description: formValues.description,
      plant: formValues.plant,
      dmcUrl: formValues.dmcUrl?.replace(/\/$/, ""),
      tokenUrl: formValues.tokenURL?.replace(/\/$/, ""),
      clientId: formValues.clientId,
      clientSecret: formValues.clientSecret,
    };

    const atLeastOneProfilePropEmpty = Object.entries(profileData).some(
      ([key, value]) => key !== "description" && value === ""
    );

    if (atLeastOneProfilePropEmpty) {
      setMessageToast({
        message: t("NOT_ALL_REQUIRED_FIELDS_EXIST"),
        status: "error",
      });
      setSpinnerState({ active: false });
      return;
    }

    masterDataManagementApi
      .createProfile(profileData)
      .then((response: any) => {
        const { checkProfileSafety } = response;
        const resUser = response.user;
        const profiles = JSON.parse(localStorage.getItem("profiles") || "");
        const newProfile: DmProfile = {
          ...profileData,
          _id: resUser.dmProfile[resUser.dmProfile.length - 1],
        };
        profiles.push(newProfile);
        localStorage.setItem("profiles", JSON.stringify(profiles));
        const newUserState: User = {
          ...resUser,
          _id: resUser._id || "",
          dmProfile: profiles,
          firstName: resUser?.firstName || "", // Add a default value for firstName
          lastName: resUser?.lastName || "", // Add a default value for lastName
          email: resUser?.email || "", // Add a default value for email
          subscription: resUser?.subscription || undefined, // Add a default value for subscription
          verificationDate: resUser?.verificationDate || undefined, // Add a default value for verification
        };
        setMessageToast({
          message: t("PROFILE_CREATED_SUCCESFULLY"),
          status: "success",
        });
        if (checkProfileSafety?.counter === 3) {
          setMessageToast({
            message: t("PROFILE_SAFETY_WARNING_COUNTER_REACHED"),
            status: "error",
          });
        }
        setUser((prev: UserState) => ({ ...prev, user: newUserState }));
        setSpinnerState({ active: false });
        props.closeDialog();
      })
      .catch((error) => {
        if (error.data.message === "ERROR_TOO_MANY_PROFILE_CREATED") {
          setIsProfileLimitReached(true);
          setSpinnerState({ active: false });
          return;
        }
        setMessageToast({
          message: t(error.data.message ?? ""),
          status: "error",
        });
        setSpinnerState({ active: false });
      });
  };

  const onEdit = (id: string) => {
    setSpinnerState({ active: true });
    const profileData = {
      _id: id,
      name: formValues.name,
      description: formValues.description,
      plant: formValues.plant,
      dmcUrl: formValues.dmcUrl,
    };
    masterDataManagementApi
      .updateProfile(profileData)
      .then(() => {
        const profiles = JSON.parse(localStorage.getItem("profiles") || "");
        const index = profiles.findIndex((profile: any) => profile._id === id);
        const updatedProfileData: DmProfile = {
          ...profileData,
          tokenUrl: formValues.tokenURL,
          clientId: formValues.clientId,
          clientSecret: formValues.clientSecret,
        };
        profiles[index] = updatedProfileData;
        localStorage.setItem("profiles", JSON.stringify(profiles));
        const newUserState: User = {
          ...user,
          _id: user.user?._id || "",
          dmProfile: profiles,
          firstName: user?.user?.firstName || "", // Add a default value for firstName
          lastName: user?.user?.lastName || "", // Add a default value for lastName
          email: user?.user?.email || "", // Add a default value for email
          subscription: user?.user?.subscription || undefined, // Add a default value for subscription
          verificationDate: user?.user?.verificationDate || undefined, // Add a default value for verification
          role: user?.user?.role || undefined, // Add a default
        };

        setUser((prev: UserState) => ({ ...prev, user: newUserState }));
        setSpinnerState({ active: false });
        props.closeDialog();
      })
      .catch((error) => {
        setMessageToast({
          message: t(error.data.message ?? ""),
          status: "error",
        });
        props.closeDialog();
        setSpinnerState({ active: false });
      });
  };
  const onDelete = (id: string) => {
    setSpinnerState({ active: true });
    const profileData = {
      _id: id,
    };
    masterDataManagementApi
      .deleteProfile(profileData)
      .then(() => {
        const profiles = JSON.parse(localStorage.getItem("profiles") || "");
        const index = profiles.findIndex((profile: any) => profile._id === id);
        profiles.splice(index, 1);
        localStorage.setItem("profiles", JSON.stringify(profiles));
        const newUserState: User = {
          ...user.user,
          _id: user?.user?._id || "",
          dmProfile: profiles,
          firstName: user?.user?.firstName || "", // Add a default value for firstName
          lastName: user?.user?.lastName || "", // Add a default value for lastName
          email: user?.user?.email || "", // Add a default value for email
          subscription: user?.user?.subscription || undefined, // Add a default value for subscription
          verificationDate: user?.user?.verificationDate || undefined, // Add a default value for verification
          role: user?.user?.role || undefined, // Add a default value for role
        };

        setUser((prev: UserState) => ({ ...prev, user: newUserState }));
        props.closeDialog();
        setSpinnerState({ active: false });
      })
      .catch((error) => {
        setMessageToast({
          message: t(error.data.message ?? ""),
          status: "error",
        });
        props.closeDialog();
        setSpinnerState({ active: false });
      });
  };

  useEffect(() => {
    if (props.profileId) {
      let profiles = user.user?.dmProfile;
      if (profiles) {
        const aProfiles = profiles;
        const profile: DmProfile | undefined = aProfiles?.find(
          (profile: DmProfile) => profile._id === props.profileId
        );
        if (profile) {
          setFormValues({
            name: profile.name,
            description: profile.description,
            plant: profile.plant,
            dmcUrl: profile.dmcUrl,
            tokenURL: profile.tokenUrl || "",
            clientId: profile.clientId || "",
            clientSecret: profile.clientSecret || "",
          });
        }
      } else {
        setFormValues(initFormValue);
      }
    } else {
      setFormValues(initFormValue);
    }
  }, [props.profileId, user.user?.dmProfile]);

  return (
    <>
      <MessageBox
        key={"ERROR_TOO_MANY_PROFILE_CREATED"}
        onClose={() => setIsProfileLimitReached(false)}
        open={isProfileLimitReached}
        actions={[
          <Button
            key={"ERROR_TOO_MANY_PROFILE_CREATED_BUTTO"}
            className="manufacturingButton"
          >
            OK
          </Button>,
        ]}
        type="Error"
        style={{ width: "40%" }}
      >
        <span style={{ fontSize: "1.1rem" }}>
          {t("ERROR_TOO_MANY_PROFILE_CREATED")}
          <a href="mailto:support-condmw@concircle.com">
            support-condmw@concircle.com
          </a>
        </span>
      </MessageBox>
      <Dialog
        draggable={true}
        open={props.isDialogOpen}
        className="headerPartNoPadding footerPartNoPadding"
        footer={
          <Bar
            design="Footer"
            endContent={
              <>
                <Button
                  className="manufacturingButton"
                  style={{
                    margin: "10px",
                  }}
                  onClick={(event) => {
                    switch (props.method) {
                      case "ADD":
                        handleSubmit(event);
                        break;
                      case "DELETE":
                        onDelete(props.profileId);
                        break;
                      case "EDIT":
                        onEdit(props.profileId);
                        break;
                    }
                  }}
                >
                  OK
                </Button>
                <Button
                  className="manufacturingButton"
                  style={{
                    margin: "10px",
                  }}
                  onClick={() => {
                    props.closeDialog();
                    setFormValues(initFormValue);
                  }}
                >
                  {t("CLOSE")}
                </Button>
              </>
            }
          />
        }
        header={
          <Bar>
            <Title level="H4">{props.dialogTitle}</Title>
          </Bar>
        }
        headerText="Dialog Header"
      >
        {props.method !== "DELETE" ? (
          <Form
            columnsL={0}
            columnsM={0}
            columnsS={0}
            columnsXL={0}
            labelSpanL={0}
            labelSpanM={0}
            labelSpanS={0}
            labelSpanXL={0}
            style={{
              width: "auto",
              minWidth: "500px",
              maxWidth: "700px",
              height: "auto",
              alignItems: "center",
              containerType: "normal",
            }}
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <FormGroup>
              <Label for="name" required>
                {t("NAME")}
              </Label>
              <Input
                disabled={props.method === "DELETE" ? true : false}
                style={{ width: "100%" }}
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">{t("DESCRIPTION")}</Label>
              <Input
                disabled={props.method === "DELETE" ? true : false}
                style={{ width: "100%" }}
                id="description"
                name="description"
                value={formValues.description}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="plant" required>
                {t("PLANT")}
              </Label>
              <Input
                disabled={
                  props.method === "DELETE" || props.method === "EDIT"
                    ? true
                    : false
                }
                style={{ width: "100%" }}
                id="plant"
                name="plant"
                value={formValues.plant}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="dmcUrl" required>
                {t("DMC_URL")}
              </Label>
              {props.isDialogOpen && (
                <Select
                  name="dmcUrl"
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  children={
                    <>
                      <Option
                        selected={
                          formValues.dmcUrl === "https://api.eu10.dmc.cloud.sap"
                            ? true
                            : false
                        }
                        key={"https://api.eu10.dmc.cloud.sap"}
                      >
                        https://api.eu10.dmc.cloud.sap
                      </Option>
                      <Option
                        selected={
                          formValues.dmcUrl === "https://api.eu20.dmc.cloud.sap"
                            ? true
                            : false
                        }
                        key={"https://api.eu20.dmc.cloud.sap"}
                      >
                        https://api.eu20.dmc.cloud.sap
                      </Option>
                      <Option
                        selected={
                          formValues.dmcUrl === "https://api.us10.dmc.cloud.sap"
                            ? true
                            : false
                        }
                        key={"https://api.us10.dmc.cloud.sap"}
                      >
                        https://api.us10.dmc.cloud.sap
                      </Option>
                      <Option
                        selected={
                          formValues.dmcUrl === "https://api.us20.dmc.cloud.sap"
                            ? true
                            : false
                        }
                        key={"https://api.us20.dmc.cloud.sap"}
                      >
                        https://api.us20.dmc.cloud.sap
                      </Option>
                      <Option
                        selected={
                          formValues.dmcUrl ===
                          "https://api.test.eu10.dmc.cloud.sap"
                            ? true
                            : false
                        }
                        key={"https://api.test.eu10.dmc.cloud.sap"}
                      >
                        https://api.test.eu10.dmc.cloud.sap
                      </Option>
                      <Option
                        selected={
                          formValues.dmcUrl ===
                          "https://api.test.eu20.dmc.cloud.sap"
                            ? true
                            : false
                        }
                        key={"https://api.test.eu20.dmc.cloud.sap"}
                      >
                        https://api.test.eu20.dmc.cloud.sap
                      </Option>
                      <Option
                        selected={
                          formValues.dmcUrl ===
                          "https://api.test.us10.dmc.cloud.sap"
                            ? true
                            : false
                        }
                        key={"https://api.test.us10.dmc.cloud.sap"}
                      >
                        https://api.test.us10.dmc.cloud.sap
                      </Option>
                      <Option
                        selected={
                          formValues.dmcUrl ===
                          "https://api.test.us20.dmc.cloud.sap"
                            ? true
                            : false
                        }
                        key={"https://api.test.us20.dmc.cloud.sap"}
                      >
                        https://api.test.us20.dmc.cloud.sap
                      </Option>
                    </>
                  }
                ></Select>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="tokenURL" required>
                {t("TOKEN_URL")}
              </Label>

              <Input
                type="Password"
                disabled={props.method === "DELETE" ? true : false}
                style={{ width: "100%" }}
                id="tokenURL"
                name="tokenURL"
                value={formValues.tokenURL}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="clientId" required>
                {t("CLIENT_ID")}
              </Label>
              <Input
                type="Password"
                disabled={props.method === "DELETE" ? true : false}
                style={{ width: "100%" }}
                id="clientId"
                name="clientId"
                value={formValues.clientId}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="clientSecret" required>
                {t("CLIENT_SECRET")}
              </Label>
              <Input
                type="Password"
                disabled={props.method === "DELETE" ? true : false}
                style={{ width: "100%" }}
                id="clientSecret"
                name="clientSecret"
                value={formValues.clientSecret}
                onChange={handleChange}
              />
            </FormGroup>
          </Form>
        ) : (
          <Form
            columnsL={1}
            columnsM={1}
            columnsS={1}
            columnsXL={2}
            labelSpanL={4}
            labelSpanM={2}
            labelSpanS={12}
            labelSpanXL={4}
            style={{
              alignItems: "center",
              containerType: "normal",
            }}
            onSubmit={handleSubmit}
          >
            <FormItem label={t("NAME")}>
              <Title level="H4" style={{ width: "100%" }} id="name">
                {formValues.name}
              </Title>
            </FormItem>
            <FormItem label={t("DESCRIPTION")}>
              <Title level="H4" style={{ width: "100%" }} id="description">
                {formValues.description}
              </Title>
            </FormItem>
            <FormItem label={t("PLANT")}>
              <Title level="H4" style={{ width: "100%" }} id="plant">
                {formValues.plant}
              </Title>
            </FormItem>
            <FormItem label={t("DMC_URL")}>
              <Title level="H4" style={{ width: "100%" }} id="dmcUrl">
                {formValues.dmcUrl}
              </Title>
            </FormItem>
            {/* {formValues.tokenURL && (
            <FormItem label={t("TOKEN_URL")}>
              <Title level="H4" style={{ width: "100%" }} id="tokenURL">
                {formValues.tokenURL}
              </Title>
            </FormItem>
          )}

          {formValues.clientId && (
            <FormItem label={t("CLIENT_ID")}>
              <Title level="H4" style={{ width: "100%" }} id="clientId">
                {formValues.clientId}
              </Title>
            </FormItem>
          )}
          {formValues.clientSecret && (
            <FormItem label={t("CLIENT_SECRET")}>
              <Title level="H4" style={{ width: "100%" }} id="clientSecret">
                {formValues.clientSecret}
              </Title>
            </FormItem>
          )} */}
          </Form>
        )}
      </Dialog>
    </>
  );
}
