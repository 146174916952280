import { atom, selector } from "recoil";
import { UserState } from "../model/User.model";

export const userState = atom<UserState>({
  key: "userState",
  default: {
    user: undefined,
    isLoggedIn: false,
    subscriptionPeriod: "monthly",
  },
});

export const userSelector = selector<UserState>({
  key: "isLoggedIn",
  get: ({ get }) => {
    const userStateData = get(userState);

    return userStateData;
  },
});
