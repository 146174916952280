import { atom, selector } from "recoil";
import { ProfileState } from "../model/Profile.model";

export const profileState = atom<ProfileState>({
  key: "profileState",
  default: {
    selectedFromProfile: undefined,
    selectedToProfile: undefined,
    isProfileAvailable: undefined,
  },
});

export const profileSelector = selector<ProfileState>({
  key: "profileSelector",
  get: ({ get }) => {
    const profileStateData = get(profileState);

    return profileStateData;
  },
});
