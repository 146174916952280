import { User } from "../model/User.model";

export function deleteTokenAfterInactivity(
  tokenKey: string = "token",
  props: any
) {
  const inactivityDuration: number = 8 * 60 * 60 * 1000; // 8 hours in milliseconds

  // Function to check inactivity and delete token
  function checkInactivityAndDelete() {
    const lastActivityTime: number =
      Number(localStorage.getItem("lastActivityTime")) || 0;
    const currentTime: number = new Date().getTime();

    // If last activity time exists and exceeds inactivity duration
    if (
      lastActivityTime &&
      currentTime - lastActivityTime > inactivityDuration
    ) {
      stopInactivityCheck();
      props.handleLogout();
    }
  }

  // Update last activity time on user interaction
  function updateLastActivityTime() {
    localStorage.setItem("lastActivityTime", new Date().getTime().toString());
  }

  // Attach event listeners for user interaction
  document.addEventListener("mousemove", updateLastActivityTime);
  document.addEventListener("keydown", updateLastActivityTime);

  // Check inactivity and delete token periodically
  let intervalId: NodeJS.Timeout | undefined;

  function startInactivityCheck() {
    if (!props.intervalId) {
      intervalId = setInterval(checkInactivityAndDelete, 5 * 60 * 1000); // Check every 5 minute
      props.setIntervalId(intervalId);
    }
  }

  function stopInactivityCheck() {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = undefined;
    }
  }
  startInactivityCheck();
}

export function isVerificationDateOlderThanMonth(user: User): boolean {
  const verificationDate = new Date(user.verificationDate || 0);
  const now = new Date();
  verificationDate.setMonth(verificationDate.getMonth() + 1);

  return verificationDate.getTime() < now.getTime() && user.role !== "admin";
}
