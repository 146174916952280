import { User } from "../model/User.model";
import axiosClient from "./axiosClient";

const authApi = {
  login: (params: any) => axiosClient.post("user", params),
  logout: () => axiosClient.delete("user/logout"),
  signIn: (params: any) => axiosClient.post("user/signIn", params),
  register: (params: any) => axiosClient.post("user/register", params),
  getUserData: async (params: any): Promise<User> =>
    await axiosClient.get("user", params),
  verify: (token: string) => axiosClient.post(`user/verify/${token}`),
  supportMail: (params: any) => axiosClient.post("user/supportMail", params),
};

export default authApi;
