import { atom, selector } from "recoil";
import { SpinnerState } from "../model/Spinner.model";

export const spinnerState = atom<SpinnerState>({
  key: "spinnerState",
  default: {
    active: false,
  },
});

export const spinnerSelector = selector<SpinnerState>({
  key: "spinnerSelector",
  get: ({ get }) => {
    const spinnerStateData = get(spinnerState);
    return spinnerStateData;
  },
});
