import { MessageStrip } from "@ui5/webcomponents-react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Log(props: any) {
  const { t } = useTranslation();
  return (
    <div className="box" style={{ marginBottom: "3%" }}>
      <div className="logContainer">
        {props?.excelLog &&
          props.pushLog.length === 0 &&
          props?.excelLog.reverse().map((item: any) => {
            return (
              <MessageStrip
                key={item.text}
                style={{ margin: "7px", width: "90%" }}
                design={item.status}
                hideCloseButton
              >
                {t(item.text)}
              </MessageStrip>
            );
          })}
        {props?.pushLog
          .sort((a: any, b: any) =>
            b.datetime < a.datetime ? -1 : b.datetime > a.datetime ? 1 : 0
          )
          .map((item: any) => {
            return (
              <MessageStrip
                key={item.datetime}
                style={{ margin: "7px", width: "90%" }}
                design={item.status}
                hideCloseButton
              >
                {item.text}
              </MessageStrip>
            );
          })}
      </div>
    </div>
  );
}
