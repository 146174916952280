import { useState } from "react";
import {
  BusyIndicator,
  Icon,
  Input,
  Label,
  MessageBox,
  MessageBoxTypes,
  MessageStrip,
  Table,
  TableCell,
  TableColumn,
  TableGrowingMode,
  TableRow,
  TextArea,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/lateness.js";
import "@ui5/webcomponents-icons/dist/message-error.js";
import "@ui5/webcomponents-icons/dist/message-success.js";
import datacollectionApi from "../../service/dataCollection";
import {
  DataCollectionTransport,
  dataCollectionGroups,
} from "../../model/dataCollection";
import { PodTransport, pod } from "../../model/pod";
import podApi from "../../service/pod";
import { profileSelector } from "../../state/profileState";
import { useRecoilValue } from "recoil";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import $ from "jquery";
interface Row {
  from: string;
  to: string;
  description: string;
  version: string;
  status: string;
  error: string;
  success: string;
  params?: any;
}

export default function TableTransport(props: any) {
  const { t } = useTranslation();

  const { componentName, disableTransport, prefiledData } = props;

  const profileValue = useRecoilValue(profileSelector);
  const [open, setOpen] = useState(false);
  const [busyIndicator, setBusyIndicator] = useState(false);
  const [showListTransportInfo, setShowListTransportInfo] = useState(false);
  const [messageValues, setMessageValues] = useState({
    message: "",
    type: "",
  });
  const handleClose = () => {
    setOpen(false);
  };

  const showMessageBox = (message: string, type: string, params: any) => {
    setMessageValues({
      type: type,
      message:
        Object.keys(params).length > 0 && type === "Error"
          ? `${t("SAP_DM_ERROR_MESSAGE")}: ${t(message, params).toString()}`
          : t(message, params).toString(),
    });
    setOpen(true);
  };

  const [rows, setRows] = useState<Row[]>(
    prefiledData
      ? prefiledData
      : [
          {
            from: "",
            to: "",
            description: "",
            version: "",
            status: "",
            error: "",
            success: "",
          },
        ]
  );
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const checkLinesEmpty = (rows: Row[]) => {
    if (rows.length === 0) {
      addEmptyRow(rows);
      return;
    }
    let newRows: Row[] = [];

    rows.map((item, index) => {
      const isEmpty = Object.values(item).every((value) => value === "");
      if (!isEmpty) {
        newRows.push(item);
      }
    });
    addEmptyRow(newRows);
  };

  const addEmptyRow = (prevArray: Row[]) => {
    setRows(() => [
      ...prevArray,
      {
        from: "",
        to: "",
        description: "",
        status: "",
        version: "",
        error: "",
        success: "",
      },
    ]);
  };
  const handleChange = (index: number, property: keyof Row, value?: any) => {
    const updatedRows = [...rows];
    if (property === "from") {
      var list = String(value).split("\n");
      let i = 0;
      for (var y in list) {
        updatedRows[index + i] = {
          ...updatedRows[index + i],
          [property]: list[y] ?? "",
          status: "",
          success: "",
          error: "",
        };
        updatedRows[index + i] = {
          ...updatedRows[index + i++],
          to: list[y] ?? "",
        };
      }
    } else {
      updatedRows[index] = {
        ...updatedRows[index],
        [property]: value,
        status: "",
        success: "",
        error: "",
      };
    }
    setRows(updatedRows);
    checkLinesEmpty(updatedRows);
  };
  const handleDelete = (index: number) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
    checkLinesEmpty(updatedRows);
  };
  const onSaveOrTransport = (event: any) => {
    setBusyIndicator(true);
    if (componentName === "POD") {
      transportPod();
      return;
    }
    transportDc();
  };
  const transportPod = async () => {
    const params = formatPodData();
    if (
      !Object.values(params?.fromDmc).every((value) => value !== "") ||
      !Object.values(params?.toDmc).every((value) => value !== "")
    ) {
      showMessageBox("SELECT_PROFILE_ERROR_MESSAGE", "Error", {});
      setBusyIndicator(false);
      return;
    }
    podApi
      .tranport(params)
      .then((response) => {
        setShowStatus(true);
        const updatedRows = [...rows];
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          updatedRows[index] = {
            ...updatedRows[index],
            status: element.podId ? "OK" : "KO",
            success: "POD_TRANSPORT_SUCCES_MESSAGE",
            error: element?.response?.error,
            params: {
              podId: element.podId,
              plant: params.toDmc.plant,
            },
          };
          setRows(updatedRows);
          setBusyIndicator(false);
          setShowListTransportInfo(true);
        }
      })
      .catch((err: AxiosResponse<any, any>) => {
        console.log(err);
        setBusyIndicator(false);
        showMessageBox(err.data.message, "Error", {});
      });
  };
  const formatPodData = () => {
    let data: PodTransport;
    let dataPod: pod[] = [];
    rows.map((item: Row) => {
      if (item.from !== "") {
        dataPod.push({
          podId: item.from.toUpperCase(),
          newPodId: item.to.toUpperCase(),
          podDescription: item.description,
        });
      }
    });
    data = {
      fromDmc: {
        clientId: profileValue?.selectedFromProfile?.clientId ?? "",
        clientSecret: profileValue?.selectedFromProfile?.clientSecret ?? "",
        tokenURL:
          profileValue?.selectedFromProfile?.tokenUrl?.replace(/\/$/, "") ?? "",
        dmcURL:
          profileValue?.selectedFromProfile?.dmcUrl?.replace(/\/$/, "") ?? "",
        plant: profileValue?.selectedFromProfile?.plant ?? "",
      },
      toDmc: {
        clientId: profileValue?.selectedToProfile?.clientId ?? "",
        clientSecret: profileValue?.selectedToProfile?.clientSecret ?? "",
        tokenURL:
          profileValue?.selectedToProfile?.tokenUrl?.replace(/\/$/, "") ?? "",
        dmcURL:
          profileValue?.selectedToProfile?.dmcUrl?.replace(/\/$/, "") ?? "",
        plant: profileValue?.selectedToProfile?.plant ?? "",
      },
      podList: dataPod,
    };
    return data;
  };
  const transportDc = async () => {
    const params = formatDcData();
    if (!params?.fromDmc && !params?.toDmc) {
      showMessageBox("SELECT_PROFILE_ERROR_MESSAGE", "Error", {});
      setBusyIndicator(false);
      return;
    }
    datacollectionApi
      .tranport(params)
      .then((response) => {
        setShowStatus(true);
        const updatedRows = [...rows];
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          updatedRows[index] = {
            ...updatedRows[index],
            status: !element.response
              ? "OK"
              : [200, 201].includes(element.status)
              ? "OK"
              : "KO",
            success: "DC_TRANSPORT_SUCCES_MESSAGE",
            error: element?.response?.error,
            params: {
              to: updatedRows[index].to,
              plant: params.toDmc.plant,
            },
          };
          setRows(updatedRows);
          setShowListTransportInfo(true);
          setBusyIndicator(false);
        }
      })
      .catch((err: AxiosResponse<any, any>) => {
        console.log(err);
        setBusyIndicator(false);
        showMessageBox(err.data.message, "Error", {});
      });
  };
  const formatDcData = () => {
    let data: DataCollectionTransport;
    let dataCollection: dataCollectionGroups[] = [];
    rows.map((item: Row) => {
      if (item.from !== "") {
        dataCollection.push({
          group: item.from?.toUpperCase(),
          newGroup: item.to?.toUpperCase(),
          version: item.version,
          description: item.description,
        });
      }
    });
    data = {
      fromDmc: {
        clientId: profileValue?.selectedFromProfile?.clientId ?? "",
        clientSecret: profileValue?.selectedFromProfile?.clientSecret ?? "",
        tokenURL:
          profileValue?.selectedFromProfile?.tokenUrl?.replace(/\/$/, "") ?? "",
        dmcURL:
          profileValue?.selectedFromProfile?.dmcUrl?.replace(/\/$/, "") ?? "",
        plant: profileValue?.selectedFromProfile?.plant ?? "",
      },
      toDmc: {
        clientId: profileValue?.selectedToProfile?.clientId ?? "",
        clientSecret: profileValue?.selectedToProfile?.clientSecret ?? "",
        tokenURL:
          profileValue?.selectedToProfile?.tokenUrl?.replace(/\/$/, "") ?? "",
        dmcURL:
          profileValue?.selectedToProfile?.dmcUrl?.replace(/\/$/, "") ?? "",
        plant: profileValue?.selectedToProfile?.plant ?? "",
      },
      dataCollectionGroups: dataCollection,
    };
    return data;
  };

  const checkIfRowExists = rows.every((item) => item.from === "");
  return (
    <>
      <div
        style={{
          width: "90%",
          maxHeight: "350px",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MessageBox
          draggable
          open={open}
          style={{ maxWidth: "40%", textAlign: "left" }}
          type={
            messageValues.type as
              | MessageBoxTypes
              | "Confirm"
              | "Error"
              | "Information"
              | "Success"
              | "Warning"
              | undefined
          }
          onClose={handleClose}
        >
          <span style={{ fontSize: "1.1rem" }}>{messageValues.message}</span>
        </MessageBox>
        <Table
          mode="None"
          growing={TableGrowingMode.Scroll}
          stickyColumnHeader={true}
          style={{ marginBottom: "5%" }}
          columns={
            <>
              <TableColumn className="tableHeader" popinText="From">
                <Label>
                  {componentName === "DC" ? t("DC_GROUP_NAME") : t("POD_NAME")}
                </Label>
              </TableColumn>
              {componentName === "DC" && (
                <TableColumn className="tableHeader" popinText="Version">
                  <Label>{t("VERSION")}</Label>
                </TableColumn>
              )}
              <TableColumn className="tableHeader" popinText="To">
                <Label>
                  {componentName === "DC"
                    ? t("NEW_DC_GROUP_NAME")
                    : t("NEW_POD_NAME")}
                </Label>
              </TableColumn>
              <TableColumn className="tableHeader" popinText="Description">
                <Label>{t("DESCRIPTION")}</Label>
              </TableColumn>
              <TableColumn className="tableHeader" popinText="">
                <Label>{t("ACTIONS")}</Label>
              </TableColumn>
              {showStatus && (
                <TableColumn className="tableHeader" popinText="Status">
                  <Label>{t("STATUS")}</Label>
                </TableColumn>
              )}
            </>
          }
        >
          {rows.map((item: Row, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell style={{ textAlign: "left" }}>
                  <TextArea
                    style={{ height: "15px" }}
                    name="from"
                    onKeyDown={(e) => {
                      const test: any = e.currentTarget;
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                      handleChange(index, "from", test.value);
                    }}
                    onChange={(e) =>
                      handleChange(index, "from", e.target.value)
                    }
                    className="transportInput"
                    value={item.from}
                    placeholder={
                      componentName === "DC"
                        ? t("DC_GROUP_NAME")
                        : t("POD_NAME")
                    }
                  />
                </TableCell>
                {componentName === "DC" && (
                  <TableCell style={{ textAlign: "left" }}>
                    <Input
                      name="version"
                      onChange={(e) =>
                        handleChange(index, "version", e.target.value)
                      }
                      className="transportInput"
                      value={item.version}
                      placeholder={t("VERSION")}
                    />
                  </TableCell>
                )}
                <TableCell style={{ textAlign: "left" }}>
                  <Input
                    name="to"
                    onChange={(e) => handleChange(index, "to", e.target.value)}
                    className="transportInput"
                    // disabled={componentName === "POD"}
                    value={item.to}
                    placeholder={
                      componentName === "DC"
                        ? t("NEW_DC_GROUP_NAME")
                        : t("NEW_POD_NAME")
                    }
                  />
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Input
                    name="description"
                    onChange={(e) =>
                      handleChange(index, "description", e.target.value)
                    }
                    className="transportInput"
                    value={item.description}
                    placeholder={t("DESCRIPTION")}
                  />
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <button
                    className="buttonEditOrDelete"
                    onClick={(event) => handleDelete(index)}
                  >
                    <Icon name="delete" style={{ color: "white" }}></Icon>
                  </button>
                </TableCell>
                {showStatus && (
                  <TableCell>
                    {item.status === "OK" ? (
                      <Icon
                        className="statusIcon"
                        name="message-success"
                        design="Positive"
                        onClick={() => {
                          showMessageBox(item.success, "Success", item.params);
                        }}
                      ></Icon>
                    ) : item.status === "KO" ? (
                      <Icon
                        className="statusIcon"
                        name="message-error"
                        design="Negative"
                        onClick={() => {
                          showMessageBox(item.error, "Error", item.params);
                        }}
                      ></Icon>
                    ) : (
                      <div></div>
                    )}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </Table>
      </div>
      <br />
      <br />
      {componentName === "POD" && showListTransportInfo && (
        <MessageStrip
          style={{ width: "80%" }}
          design="Information"
          hideCloseButton
        >
          <span style={{ fontSize: "1.2rem" }}>{t("LIST_TRANSPORT_INFO")}</span>
        </MessageStrip>
      )}
      <br />
      <br />

      {!prefiledData && (
        <button
          className="buttonTranportOrSave"
          onClick={(oEvent) => onSaveOrTransport(oEvent)}
          disabled={disableTransport || checkIfRowExists || busyIndicator}
        >
          {busyIndicator ? (
            <BusyIndicator
              style={{ color: "white" }}
              active
              delay={200}
            ></BusyIndicator>
          ) : (
            t("TRANSPORT")
          )}
          {/* {showStatus ? "Transport" : "Save"} */}
        </button>
      )}
    </>
  );
}
