import { ProfileState } from "../model/Profile.model";
import { DmProfile, User, UserState } from "../model/User.model";
import authApi from "../service/auth";

export async function getAndSyncProfiles(props: any) {
  return await authApi
    .getUserData({})
    .then((data: User) => {
      const userDataProfile = data.dmProfile;
      const localStorageProfile = JSON.parse(
        localStorage.getItem("profiles") || "[]"
      );

      const mergedProfiles = [];
      for (let i = 0; i < userDataProfile.length; i++) {
        const localStorageProfileIndex = localStorageProfile.findIndex(
          (item: DmProfile) => item._id === userDataProfile[i]._id
        );
        if (localStorageProfileIndex !== -1) {
          mergedProfiles.push(localStorageProfile[localStorageProfileIndex]);
        } else {
          mergedProfiles.push(userDataProfile[i]);
        }
      }

      const syncProfile: DmProfile[] = [];
      mergedProfiles.map((item: any) => {
        if (
          data.dmProfile.some((profile: DmProfile) => profile._id === item._id)
        ) {
          syncProfile.push(item);
        }
      });

      localStorage.setItem("profiles", JSON.stringify(syncProfile));
      data.dmProfile = syncProfile;
      return data;
    })
    .catch((error) => {
      console.error(error);
      localStorage.removeItem("token");
      return error;
    });
}

export function checkIfProfilesAreValid(props: any): boolean {
  const localStorageProfile = JSON.parse(
    localStorage.getItem("profiles") || "[]"
  );
  let isValid = false;
  if (localStorageProfile.length > 0) {
    isValid = localStorageProfile.every((profile: any) => {
      for (const key in profile) {
        if (key !== "description" && !profile[key]) {
          return false;
        }
      }
      return true;
    });
  }
  if (isValid && localStorageProfile.length > 0) {
    isValid = localStorageProfile.every((profile: any) => {
      const requiredProperties = ["tokenUrl", "clientId", "clientSecret"];
      return requiredProperties.every(
        (property) => profile.hasOwnProperty(property) && profile[property]
      );
    });
  }
  props.setProfile((prev: ProfileState) => ({
    ...prev,
    isProfileAvailable: isValid,
  }));
  return isValid;
}

export const checkIfSelectedProfileHasAllNecessaryData = (
  profile: DmProfile | undefined
) => {
  if (profile) {
    if (
      !profile.clientId ||
      !profile.clientSecret ||
      !profile.dmcUrl ||
      !profile.tokenUrl
    ) {
      // setState(false);
      return false;
    }
    return true;
    // setState(true);
  } else {
    return false;
    // setState(false);
  }
};
