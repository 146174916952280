import React, { useState } from "react";
import {
  Button,
  FileUploader,
  Avatar,
  UploadCollection,
  CheckBox,
  Dialog,
  Bar,
  Table,
  TableColumn,
  Label,
  TableCell,
  TableRow,
  MessageBox,
  Ui5CustomEvent,
  Select,
  Option,
  SelectDomRef,
  MessageBoxTypes,
} from "@ui5/webcomponents-react";
import "../style/excelUpload.css";
import datacollectionApi from "../service/dataCollection";
import { useRecoilState, useRecoilValue } from "recoil";
import { userSelector } from "../state/userState";
import { DmProfile } from "../model/User.model";
import { profileState } from "../state/profileState";
import { SelectChangeEventDetail } from "@ui5/webcomponents/dist/Select";
import {
  downloadExcel as downloadingExcel,
  convertToJSON,
} from "../utils/dcUpload.utils";
import { AxiosResponse } from "axios";
import { spinnerState } from "../state/spinnerState";
import { useTranslation } from "react-i18next";
import { checkIfSelectedProfileHasAllNecessaryData } from "../utils/profile.utils";

export default function Upload(props: any) {
  const { t } = useTranslation();

  const updateDC: any[] = [];
  const allDC: any[] = [];
  const errorsExcel: any[] = [];
  const [update, setUpdate] = useState(updateDC);
  const [isDialog, setIsDialog] = useState(false);
  const [isProfileToAvailable, setIsProfileToAvailable] = useState(false);
  const [isConfirmUpdate, setIsConfirmUpdate] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [isExcelFileFound, setExcelFileFound] = useState(false);
  const [isDCGroupFound, setDCGroupFound] = useState(false);
  const [isSystemSelected, setSystemSelected] = useState(false);
  const [allDCGroups, setAllDCGroups] = useState(allDC);
  const userValue = useRecoilValue(userSelector);
  const [profile, setProfile] = useRecoilState(profileState);
  const [spinner, setSpinnerState] = useRecoilState(spinnerState);
  const [messageValues, setMessageValues] = useState({
    message: "",
    type: "",
  });
  const [open, setOpen] = useState(false);

  const downloadExcel = () => {
    // if (window._paq) {
    //   window._paq.push([
    //     "trackEvent",
    //     "Click Event",
    //     "Click",
    //     "Excel Download Click",
    //   ]);
    // }
    downloadingExcel();
  };
  const showMessageBox = (message: string, type: string, params: any) => {
    setMessageValues({
      type: type,
      message:
        Object.keys(params).length > 0
          ? `${t("SAP_DM_ERROR_MESSAGE")}: ${t(message, params).toString()}`
          : t(message, params).toString(),
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const uploadCollectionHandle = (event: any) => {
    const file = event.dataTransfer.files;
    convertExcelToJSON(file);
  };

  const uploadCollectionButton = (event: any) => {
    const file = event.target.files;
    convertExcelToJSON(file);
  };

  const excelFileFound = () => {
    setExcelFileFound((prev) => !prev);
  };

  const dcGroupFound = () => {
    setDCGroupFound((prev) => !prev);
  };

  const hideSystemNotSelected = () => {
    setSystemSelected((prev) => !prev);
  };

  const convertExcelToJSON = (excelFileInput: any) => {
    convertToJSON(excelFileInput, {
      errorsExcel: errorsExcel,
      setExcelLog: props.setExcelLog,
      setUpdate: setUpdate,
      setPushLog: props.setPushLog,
      setSuccessStatusPost: props.setSuccessStatusPost,
      setSuccessStatusUpdate: props.setSuccessStatusUpdate,
      setProgressUpdate: props.setProgressUpdate,
      setProgressUpload: props.setProgressUpload,
      allDC: allDC,
      setAllDCGroups: setAllDCGroups,
      setVisibility: setVisibility,
      dcGroupFound: dcGroupFound,
      useTranslation: useTranslation,
    });
  };

  const loadProcessUpload = () => {
    debugger;
    props.setProgressUpload((prev: any) => prev + 100 / allDCGroups.length);
  };

  const loadProcessUpdate = (selectedForUpdate: any) => {
    props.setProgressUpdate(
      (prev: any) => prev + 100 / selectedForUpdate.length
    );
  };

  const handleErrorResponse = (
    err: AxiosResponse<any, any>,
    dc: any,
    successCount: number
  ) => {
    let pushLogMessage = {};
    if (err.data.status === 401) {
      pushLogMessage = {
        text: `The Profile you selected is missing the values to retrieve a token (Token URL, Client ID, and Client Secret)`,
        status: "Negative",
        datetime: new Date().getTime(),
      };
      hideSystemNotSelected();
    } else if (err.data.status === 403) {
      pushLogMessage = {
        text: `The Profile you selected is not allowed to upload DC Group ${dc?.group} Version ${dc?.version} and Plant ${dc?.plant}`,
        status: "Negative",
        datetime: new Date().getTime(),
      };
    } else if (err.data.status === 400) {
      pushLogMessage = {
        text: `${err.data.error} - DC Group ${dc?.group}`,
        status: "Negative",
        datetime: new Date().getTime(),
      };
    } else if (err.data.status === 409) {
      const messages: { [key: string]: string } = {
        "dc.group.already.exists": "already exists",
      };
      updateDC.push(dc);
      setUpdate((prev) => [...prev, dc]);
      const messageProp: string = err.data.error;
      pushLogMessage = {
        text: messages[messageProp]
          ? `DC Group ${dc?.group} Version ${dc?.version} and Plant ${dc?.plant} ${messages[messageProp]}`
          : `Because of an unknown error, it was not possible to upload DC Group ${dc?.group} Version ${dc?.version} and Plant ${dc?.plant}`,
        status: "Negative",
        datetime: new Date().getTime(),
      };
    } else {
      pushLogMessage = {
        text: `Because of an unknown error, it was not possible to upload DC Group ${dc?.group} Version ${dc?.version} and Plant ${dc?.plant}`,
        status: "Negative",
        datetime: new Date().getTime(),
      };
    }
    props.setPushLog((prev: any) => [...prev, pushLogMessage]);
    props.setSuccessStatusPost(
      t("DC_UPLOAD_SUCCES_STATUS_MESSAGE", {
        numberOne: successCount,
        numberTwo: allDCGroups.length,
      })
    );
  };

  const postDCGs = async () => {
    // setSpinnerState({ active: true });
    let successCount = 0;
    const dmcAuth = {
      clientId: profile.selectedToProfile?.clientId,
      clientSecret: profile.selectedToProfile?.clientSecret,
      tokenURL: profile.selectedToProfile?.tokenUrl?.replace(/\/$/, ""),
      dmcURL: profile.selectedToProfile?.dmcUrl?.replace(/\/$/, ""),
    };

    if (
      !dmcAuth.clientId ||
      !dmcAuth.clientSecret ||
      !dmcAuth.tokenURL ||
      !dmcAuth.dmcURL
    ) {
      hideSystemNotSelected();
      // setSpinnerState({ active: false });
      return;
    }

    allDCGroups.forEach(async (dc) => {
      dc.plant = profile.selectedToProfile?.plant ?? null;
      await datacollectionApi
        .upload({
          dataCollectionGroup: dc,
          dmcAuth: dmcAuth,
          dcGroupsCount: allDCGroups.length,
        })
        .then((data: any) => {
          loadProcessUpload();
          successCount++;
          const pushLogMessage = {
            text: `Data Collection Group ${dc.group} Version ${data?.version} and Plant ${dc.plant} has been uploaded`,
            status: "Positive",
            datetime: new Date().getTime(),
          };
          props.setPushLog((prev: any) => [...prev, pushLogMessage]);
          props.setSuccessStatusPost(
            t("DC_UPLOAD_SUCCES_STATUS_MESSAGE", {
              numberOne: successCount,
              numberTwo: allDCGroups.length,
            })
          );
          // setSpinnerState({ active: false });
        })
        .catch((err: AxiosResponse<any, any>) => {
          if (err.data.message) {
            showMessageBox(err.data.message, "Error", {});
          } else {
            loadProcessUpload();
            setVisibility(false);
            handleErrorResponse(err, dc, successCount);
          }

          // setSpinnerState({ active: false });
        });
    });
  };

  const openUpdateDialog = () => {
    setIsDialog((prev) => !prev);
  };

  const updateDCGroups = () => {
    setIsConfirmUpdate((prev) => !prev);
  };

  const onCheckBoxSelect = (group: any, event: any) => {
    const singleGroup: any = update.find((item: any) => item.group === group);
    if (singleGroup) {
      singleGroup.selected = event.target.checked;
      setUpdate((prev) => {
        const updatedGroups = prev.map((item) =>
          item.group === singleGroup.group ? singleGroup : item
        );
        return updatedGroups;
      });
    }
  };

  const proccessUpdate = async () => {
    // setSpinnerState({ active: true });
    const selectedForUpdate = update.filter((item) => item.selected === true);
    const notSelectedForUpdate = update.filter(
      (item) => item.selected !== true
    );
    postUpdate(selectedForUpdate, notSelectedForUpdate);
    // setSpinnerState({ active: false });
    updateDCGroups();
    openUpdateDialog();
    setUpdate(notSelectedForUpdate);
    props.setProgressUpload(0);
    props.setSuccessStatusPost("");
  };

  async function postUpdate(selectedForUpdate: any, notSelectedForUpdate: any) {
    let successCount = 0;
    const dmcAuth = {
      clientId: profile.selectedToProfile?.clientId,
      clientSecret: profile.selectedToProfile?.clientSecret,
      tokenURL: profile.selectedToProfile?.tokenUrl?.replace(/\/$/, ""),
      dmcURL: profile.selectedToProfile?.dmcUrl?.replace(/\/$/, ""),
    };

    if (
      !dmcAuth.clientId ||
      !dmcAuth.clientSecret ||
      !dmcAuth.tokenURL ||
      !dmcAuth.dmcURL
    ) {
      hideSystemNotSelected();
      return;
    }
    await Promise.all(
      selectedForUpdate.map(async (dc: any) => {
        delete dc.selected;
        dc.plant = profile.selectedToProfile?.plant ?? null;
        datacollectionApi
          .update({
            dataCollectionGroup: dc,
            dmcAuth: dmcAuth,
            dcGroupsCount: selectedForUpdate.length,
          })
          .then((response) => {
            loadProcessUpdate(selectedForUpdate);
            const pushLogMessage = {
              text: `Data Collection Group ${response.updateDataCollectionGroup.group} Version ${dc?.version} and Plant ${dc?.plant} has been updated`,
              status: "Positive",
              datetime: new Date().getTime(),
            };
            props.setPushLog((prev: any) => [...prev, pushLogMessage]);
            successCount++;
            props.setSuccessStatusUpdate(
              t("DC_UPDATE_SUCCES_STATUS_MESSAGE", {
                numberOne: successCount,
                numberTwo: selectedForUpdate.length,
              })
            );
          })
          .catch((err: AxiosResponse<any, any>) => {
            loadProcessUpdate(selectedForUpdate);
            setVisibility(false);
            notSelectedForUpdate.push(dc);
            handleErrorResponse(err, dc, successCount);
          });
      })
    );
  }

  return (
    <div>
      <div className="box">
        <MessageBox
          draggable
          open={open}
          style={{ maxWidth: "40%", textAlign: "left" }}
          type={
            messageValues.type as
              | MessageBoxTypes
              | "Confirm"
              | "Error"
              | "Information"
              | "Success"
              | "Warning"
              | undefined
          }
          onClose={handleClose}
        >
          <span style={{ fontSize: "1.1rem" }}>{messageValues.message}</span>
        </MessageBox>
        <MessageBox
          key={"DC_UPLOAD_SELECT_EXCEL_FILE"}
          onClose={excelFileFound}
          open={isExcelFileFound}
          type="Confirm"
        >
          <span style={{ fontSize: "1.1rem" }}>
            {t("DC_UPLOAD_SELECT_EXCEL_FILE")}
          </span>
        </MessageBox>
        <MessageBox
          onClose={dcGroupFound}
          type="Confirm"
          open={isDCGroupFound}
          key={"DC_UPLOAD_NO_DC_GROUP_FOUND"}
        >
          <span style={{ fontSize: "1.1rem" }}>
            {t("DC_UPLOAD_NO_DC_GROUP_FOUND")}
          </span>
        </MessageBox>
        <MessageBox
          key={"DC_UPLOAD_PROFILE_NOT_SELECTED"}
          onClose={hideSystemNotSelected}
          type="Warning"
          open={isSystemSelected}
          actions={[
            <Button
              key={"DC_UPLOAD_PROFILE_NOT_SELECTED_BUTTON"}
              className="manufacturingButton"
            >
              OK
            </Button>,
          ]}
        >
          <span style={{ fontSize: "1.1rem" }}>
            {t("DC_UPLOAD_PROFILE_NOT_SELECTED")}
          </span>
        </MessageBox>
        <Dialog
          className="footerPartNoPadding"
          footer={
            <Bar
              design="Footer"
              endContent={
                <div>
                  <Button
                    className="manufacturingButton"
                    onClick={() => {
                      updateDCGroups();
                      openUpdateDialog();
                    }}
                  >
                    {t("CLOSE")}
                  </Button>{" "}
                  <Button
                    className="manufacturingButton"
                    onClick={() => proccessUpdate()}
                  >
                    {t("CONFIRM")}
                  </Button>
                </div>
              }
            />
          }
          headerText="Information"
          open={isConfirmUpdate}
          stretch={false}
          draggable={true}
        >
          {t("DC_UPDATE_CONFIRMATION_MESSAGE")}
        </Dialog>
        <Dialog
          className="footerPartNoPadding"
          footer={
            <Bar
              design="Footer"
              endContent={
                <div>
                  <Button
                    className="manufacturingButton"
                    onClick={() => openUpdateDialog()}
                  >
                    {t("CLOSE")}
                  </Button>{" "}
                  <Button
                    className="manufacturingButton"
                    onClick={() => updateDCGroups()}
                  >
                    {t("UPDATE")}
                  </Button>
                </div>
              }
            />
          }
          headerText={t("DC_UPDATE_SELECT_DIALOG_TITLE")}
          open={isDialog}
          stretch={false}
        >
          <Table
            columns={
              <>
                <TableColumn>
                  <Label>{t("SELECT")}</Label>
                </TableColumn>
                <TableColumn>
                  <Label>DC Group Name</Label>
                </TableColumn>
              </>
            }
          >
            {update.map((item) => {
              return (
                <TableRow key={item.group}>
                  <TableCell style={{ textAlign: "left" }}>
                    <CheckBox
                      onChange={(event) => onCheckBoxSelect(item.group, event)}
                      checked={false}
                    ></CheckBox>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    <Label>{item.group}</Label>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </Dialog>
        <div className="buttons">
          <div>
            <Button
              className="manufacturingButton"
              onClick={() => downloadExcel()}
            >
              {t("DOWNLOAD_EXCEL_TEMPLATE")}
            </Button>
          </div>
          {/* {userValue?.user?.dmProfile.length && userValue.isLoggedIn && ( */}
          <Select
            valueState={
              profile.isProfileAvailable || isProfileToAvailable
                ? "Success"
                : "Error"
            }
            valueStateMessage={<span>{t("CHECK_PROFILES")}</span>}
            style={{
              marginLeft: "10px",
            }}
            onChange={async (
              event: Ui5CustomEvent<SelectDomRef, SelectChangeEventDetail>
            ) => {
              console.log(event.detail.selectedOption.dataset.id);
              const index =
                userValue.user?.dmProfile.findIndex(
                  (profile: DmProfile) =>
                    profile._id === event.detail.selectedOption.dataset.id
                ) || 0;
              const selectedProfile = userValue.user?.dmProfile[index];
              const isProfileValid =
                await checkIfSelectedProfileHasAllNecessaryData(
                  selectedProfile
                );
              setIsProfileToAvailable(isProfileValid);
              setProfile((prev) => ({
                ...prev,
                selectedToProfile: selectedProfile,
              }));
            }}
          >
            <Option key="keyForPlantFromOption" data-id="0" />
            {userValue.user?.dmProfile.map((item: DmProfile) => (
              <Option
                key={item._id}
                data-id={item._id}
                additionalText={item.plant}
              >
                {item.name}
              </Option>
            ))}
          </Select>
          {/* )} */}
        </div>

        <div className="dragNdrop">
          <div className="dashedBorder">
            <UploadCollection
              className="uploadCollection"
              mode="SingleSelect"
              noDataDescription={t(
                visibility
                  ? "UPLOAD_COLLECTION_DATA_DESCRIPTION"
                  : "UPLOAD_COLLECTION_NO_DATA_DESCRIPTION"
              )}
              noDataText={t(
                visibility
                  ? "UPLOAD_COLLECTION_DATA_TEXT"
                  : "UPLOAD_COLLECTION_NO_DATA_TEXT"
              )}
              onDrop={(event) => {
                event.preventDefault();
                uploadCollectionHandle(event);
              }}
              onSelectionChange={(event) => {
                event.preventDefault();
              }}
            />
            <FileUploader
              className="spacebelow"
              onChange={(event) => {
                uploadCollectionButton(event);
              }}
              onClick={(event: any) => {
                event.target.value = null;
              }}
              hideInput
            >
              <Avatar
                style={{
                  background: "#c7d64f",
                  color: "#0a0a0a",
                  borderColor: "#c7d64f",
                }}
                icon="sap-icon://upload-to-cloud"
              />
            </FileUploader>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className="spacebetween manufacturingButton"
              style={{
                marginBottom: "20px",
              }}
              onClick={() => {
                postDCGs();
              }}
              disabled={!visibility}
            >
              {t("START_UPLOAD")}
            </Button>
            {update.length > 0 && (
              <Button
                className="spacebetween manufacturingButton"
                onClick={() => openUpdateDialog()}
              >
                {t("UPDATE")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
