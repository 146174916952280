import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { ProductDetails } from "./ProductDetails";
import "../../style/subscription.css";
import { Switch } from "@ui5/webcomponents-react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useRecoilState } from "recoil";
import { userState } from "../../state/userState";
import { useTranslation } from "react-i18next";

export const ProductDisplay = (props: any) => {
  const { t } = useTranslation();
  const { allProducts } = props;

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_KEY_V2 ??
      "pk_live_51Oqul3JAyTJR7EHyAlWAdW6Wacd46GrhcgKACy95QVJiSdlHbjJrMUUOxejhPjWYYKl1XSk68mcq0oznYmUt5QAX00IzQS2Vmo"
  );
  const [alignment, setAlignment] = useState("monthly");
  const [userValue, setUserState] = useRecoilState(userState);
  const products =
    userValue.subscriptionPeriod === "monthly"
      ? allProducts.activeProductPricesMontly
      : allProducts.activeProductPricesYearly;
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
    setUserState((prev) => ({
      ...prev,
      subscriptionPeriod: newAlignment,
    }));
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        style={{ marginTop: "50px" }}
      >
        <ToggleButton value="monthly">{t("MONTHLY")}</ToggleButton>
        <ToggleButton value="yearly">{t("YEARLY")}</ToggleButton>
      </ToggleButtonGroup>
      <section className="productsContainer">
        {allProducts?.map((item: any, index: number) => (
          <ProductDetails
            key={index}
            item={item}
            stripePromise={stripePromise}
            paymentPeriod={alignment}
          />
        ))}
        <br />
        <br />
      </section>
    </div>
  );
};
