import { useTranslation } from "react-i18next";
import "../style/dataPrivacy.css";

export default function Imprint(props: any) {
  const { t } = useTranslation();
  return (
    <>
      <div className="headerBgImgApps">
        <div className="topContainer lightFont">
          <h1>{t("IMPRINT")}</h1>
        </div>
      </div>

      <div
        className="dataPrivacyContainer "
        dangerouslySetInnerHTML={{
          __html: t("IMPRINT_CONTENT"),
        }}
      ></div>
    </>
  );
}
