import {
  Avatar,
  Button,
  Carousel,
  Label,
  Select,
  Title,
} from "@ui5/webcomponents-react";
import { useTranslation } from "react-i18next";
import DcUploadExcelComp from "../img/DC_Excel_Upload_Component.png";
import AddProfileDialog_de from "../img/AddProfile_de.png";
import EditProfileDialog_de from "../img/EditProfile_de.png";
import DeleteProfileDialog_de from "../img/DeleteProfile_de.png";
import AddProfileDialog_en from "../img/AddProfile_en.png";
import EditProfileDialog_en from "../img/EditProfile_en.png";
import DeleteProfileDialog_en from "../img/DeleteProfile_en.png";
import TableTransport from "./TransportComponents/TableTransport";
import { JsxElement } from "typescript";
import { useState } from "react";
import ProfileDataDialog from "./ProfileData";
import { confState } from "../state/confState";
import { useRecoilState } from "recoil";
interface SingleContent {
  firstElement: JSX.Element;
  secondElement?: JSX.Element;
}
interface HowToConfig {
  dcUpload: SingleContent[];
  dcTransfer: SingleContent[];
  podTransport: SingleContent[];
  profileManagement: SingleContent[];
}

export default function HowTo(props: any) {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;
  const [conf, setConf] = useRecoilState(confState);

  const { t } = useTranslation();
  const view: string = props.view ?? "";
  const config: HowToConfig = {
    dcUpload: [
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("DC_UPLOAD_DOWNLOAD_EXCEL_TEMPLATE")}
          </Title>
        ),
        secondElement: (
          <Button className="manufacturingButton">
            {t("DOWNLOAD_EXCEL_TEMPLATE")}
          </Button>
        ),
      },
      {
        firstElement: (
          <Title
            level="H3"
            wrappingType="Normal"
            style={{ textAlign: "center" }}
          >
            {t("DC_UPLOAD_FILL_OUT_THE_EXCEL_FILE")}
          </Title>
        ),
      },
      {
        firstElement: (
          <Title
            level="H3"
            wrappingType="Normal"
            style={{ textAlign: "center" }}
          >
            {t("DC_UPLOAD_SELECT_PROFILE")}
          </Title>
        ),
        secondElement: <Select valueState={"Success"}></Select>,
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("DC_UPLOAD_DRAG_AND_DROP_FILE")}
          </Title>
        ),
        secondElement: (
          <img
            alt="Data Collection Excel Upload Component"
            style={{
              maxHeight: isMobile ? "115px" : "300px",
            }}
            src={DcUploadExcelComp}
          />
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("DC_UPLOAD_SELECT_FILE")}
          </Title>
        ),
        secondElement: (
          <Avatar
            style={{
              background: "#c7d64f",
              color: "#0a0a0a",
              borderColor: "#c7d64f",
            }}
            icon="sap-icon://upload-to-cloud"
          />
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("DC_UPLOAD_START_UPLOAD")}
          </Title>
        ),
        secondElement: (
          <Button
            className="spacebetween manufacturingButton"
            style={{
              marginBottom: "20px",
            }}
          >
            {t("START_UPLOAD")}
          </Button>
        ),
      },
    ],
    dcTransfer: [
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("SELECT_PROFILE_TRANSFER_FROM")}
          </Title>
        ),
        secondElement: (
          <div style={{ flex: 1, width: "100%" }}>
            <Label
              className="labelSysDetail"
              style={{ width: "auto" }}
              for="plantTo"
            >
              {t("PROFILE_TO_TRANSPORT_FROM")}
            </Label>
            <Select
              id="plantTo"
              className="selectSysDetail"
              valueState={"Success"}
              valueStateMessage={<span>{t("CHECK_PROFILES")}</span>}
            ></Select>
          </div>
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("SELECT_PROFILE_TRANSFER_TO")}
          </Title>
        ),
        secondElement: (
          <div style={{ flex: 1, width: "100%" }}>
            <Label
              className="labelSysDetail"
              style={{ width: "auto" }}
              for="plantTo"
            >
              {t("PROFILE_TO_TRANSPORT_TO")}
            </Label>
            <Select
              id="plantTo"
              className="selectSysDetail"
              valueState={"Success"}
              valueStateMessage={<span>{t("CHECK_PROFILES")}</span>}
            ></Select>
          </div>
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("ENTER_DC_GROUP_NAME_AND_VERSION")}
          </Title>
        ),
        secondElement: (
          <TableTransport
            style={{ zoom: 0.3 }}
            componentName="DC"
            prefiledData={[
              {
                from: "TEST_01",
                to: "",
                description: "",
                version: "A",
                status: "",
                error: "",
                success: "",
              },
            ]}
          />
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("NEW_DC_NAME_AND_VERSION_SET_AUTO")}
          </Title>
        ),
        secondElement: (
          <TableTransport
            style={{ zoom: 0.3 }}
            componentName="DC"
            prefiledData={[
              {
                from: "TEST_01",
                to: "TEST_01",
                description: "",
                version: "A",
                status: "",
                error: "",
                success: "",
              },
              {
                from: "",
                to: "",
                description: "",
                version: "",
                status: "",
                error: "",
                success: "",
              },
            ]}
          />
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("CHANGE_DC_GROUP_NAME_AND_ADD_DESCRIPTION")}
          </Title>
        ),
        secondElement: (
          <TableTransport
            style={{ zoom: 0.3 }}
            componentName="DC"
            prefiledData={[
              {
                from: "TEST_01",
                to: "TEST_01_A",
                description: "conDMW",
                version: "A",
                status: "",
                error: "",
                success: "",
              },
              {
                from: "",
                to: "",
                description: "",
                version: "",
                status: "",
                error: "",
                success: "",
              },
            ]}
          />
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("DC_TRANSPORT_BUTTON")}
          </Title>
        ),
        secondElement: (
          <button className="buttonTranportOrSave" disabled={false}>
            {t("TRANSPORT")}
          </button>
        ),
      },
    ],
    podTransport: [
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("SELECT_PROFILE_TRANSPORT_FROM")}
          </Title>
        ),
        secondElement: (
          <div style={{ flex: 1, width: "100%" }}>
            <Label
              className="labelSysDetail"
              style={{ width: "auto" }}
              for="plantTo"
            >
              {t("PROFILE_TO_TRANSPORT_FROM")}
            </Label>
            <Select
              id="plantTo"
              className="selectSysDetail"
              valueState={"Success"}
              valueStateMessage={<span>{t("CHECK_PROFILES")}</span>}
            ></Select>
          </div>
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("SELECT_PROFILE_TRANSPORT_TO")}
          </Title>
        ),
        secondElement: (
          <div style={{ flex: 1, width: "100%" }}>
            <Label
              className="labelSysDetail"
              style={{ width: "auto" }}
              for="plantTo"
            >
              {t("PROFILE_TO_TRANSPORT_TO")}
            </Label>
            <Select
              id="plantTo"
              className="selectSysDetail"
              valueState={"Success"}
              valueStateMessage={<span>{t("CHECK_PROFILES")}</span>}
            ></Select>
          </div>
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("ENTER_POD_NAME")}
          </Title>
        ),
        secondElement: (
          <TableTransport
            style={{ zoom: 0.3 }}
            componentName="POD"
            prefiledData={[
              {
                from: "TEST_01",
                to: "",
                description: "",
                version: "A",
                status: "",
                error: "",
                success: "",
              },
            ]}
          />
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("NEW_POD_NAME_SET_AUTO")}
          </Title>
        ),
        secondElement: (
          <TableTransport
            style={{ zoom: 0.3 }}
            componentName="POD"
            prefiledData={[
              {
                from: "TEST_01",
                to: "TEST_01",
                description: "",
                version: "A",
                status: "",
                error: "",
                success: "",
              },
              {
                from: "",
                to: "",
                description: "",
                version: "",
                status: "",
                error: "",
                success: "",
              },
            ]}
          />
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("CHANGE_POD_NAME_AND_ADD_DESCRIPTION")}
          </Title>
        ),
        secondElement: (
          <TableTransport
            style={{ zoom: 0.3 }}
            componentName="POD"
            prefiledData={[
              {
                from: "TEST_01",
                to: "TEST_01_A",
                description: "conDMW",
                version: "A",
                status: "",
                error: "",
                success: "",
              },
              {
                from: "",
                to: "",
                description: "",
                version: "",
                status: "",
                error: "",
                success: "",
              },
            ]}
          />
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("POD_TRANSPORT_BUTTON")}
          </Title>
        ),
        secondElement: (
          <button className="buttonTranportOrSave" disabled={false}>
            {t("TRANSPORT")}
          </button>
        ),
      },
    ],
    profileManagement: [
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("PROFILE_MANAGEMENT_ADD_PROFILE_HOW_TO")}
          </Title>
        ),
        secondElement: (
          <Button className="manufacturingButton" design="Emphasized">
            {t("ADD_PROFILE")}
          </Button>
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("PROFILE_MANAGEMENT_ADD_PROFILE_DIALOG")}
          </Title>
        ),
        secondElement: (
          <img
            alt="Add Profile Dialog"
            style={{
              maxHeight: isMobile ? "115px" : "300px",
            }}
            src={
              conf.language == "en" ? AddProfileDialog_en : AddProfileDialog_de
            }
          />
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("PROFILE_MANAGEMENT_EDIT_PROFILE_HOW_TO")}
          </Title>
        ),
        secondElement: (
          <Button className="manufacturingButton" icon="edit"></Button>
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("PROFILE_MANAGEMENT_EDIT_PROFILE_DIALOG")}
          </Title>
        ),
        secondElement: (
          <img
            alt="Add Profile Dialog"
            style={{
              maxHeight: isMobile ? "115px" : "300px",
            }}
            src={
              conf.language == "en"
                ? EditProfileDialog_en
                : EditProfileDialog_de
            }
          />
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("PROFILE_MANAGEMENT_DELETE_PROFILE_HOW_TO")}
          </Title>
        ),
        secondElement: (
          <Button className="manufacturingButton" icon="delete"></Button>
        ),
      },
      {
        firstElement: (
          <Title level="H3" wrappingType="Normal">
            {t("PROFILE_MANAGEMENT_DELETE_PROFILE_DIALOG")}
          </Title>
        ),
        secondElement: (
          <img
            alt="Add Profile Dialog"
            style={{
              maxHeight: isMobile ? "115px" : "300px",
            }}
            src={
              conf.language == "en"
                ? DeleteProfileDialog_en
                : DeleteProfileDialog_de
            }
          />
        ),
      },
    ],
  };

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Carousel
        style={{
          width: "900px",
          height: "450px",
          marginBottom: "30px",
          marginLeft: "50px",
          marginRight: "20px",
        }}
        backgroundDesign="Translucent"
        hidePageIndicator={true}
        arrowsPlacement="Content"
        onNavigate={function _a() {}}
      >
        <Title level="H2" wrappingType="Normal">
          {t("HOW_DOES_IT_WORK")}
        </Title>
        {config[view as keyof typeof config].map(
          (element: SingleContent, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                margin: "15px",
              }}
            >
              {element?.firstElement ? element?.firstElement : null}
              <br></br>
              {element?.secondElement ? element?.secondElement : null}
            </div>
          )
        )}
      </Carousel>
    </div>
  );
}
