import stripeApi from "../../service/stripe";

export const SuccessDisplay = () => {
  const onClickPortalSession = () => {
    stripeApi.createPortalSession().then((response: any) => {
      if (response.url) window.location.href = response.url;
    });
  };
  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>

      <button onClick={onClickPortalSession}>
        Manage your billing information
      </button>
    </section>
  );
};
