import { useEffect } from "react";
import authApi from "../service/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "@ui5/webcomponents-react";
import { useTranslation } from "react-i18next";

export default function Verify() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const verify = () => {
    debugger;
    const token = new URLSearchParams(window.location.search).get("token");
    if (token) {
      authApi.verify(token).then(() => {
        navigate("/");
      });
    }
  };
  return (
    <>
      <header className="headerBgImg">
        <Button
          className="manufacturingButton"
          onClick={verify}
          style={{ height: "auto" }}
        >
          <h2 style={{ margin: "15px" }}>{t("VERIFY_YOUR_ACCOUNT_NOW")}</h2>
        </Button>
      </header>
      <div
        className="containerBottom"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "50vh",
          textAlign: "center",
        }}
      >
        <h1>{t("VERIFY_YOUR_ACCOUNT")}</h1>
        <h4>{t("CLICK_BUTTON_TO_VERIFY")}</h4>
      </div>
    </>
  );
}
