import React, { useEffect } from "react";
import {
  Button,
  Table,
  TableColumn,
  Label,
  TableRow,
  TableCell,
  TableGrowingMode,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import { userState } from "../state/userState";
import { useRecoilState } from "recoil";
import { DmProfile } from "../model/User.model";
import { getAndSyncProfiles } from "../utils/profile.utils";
import { useTranslation } from "react-i18next";

export default function ProfileList(props: any) {
  const { t } = useTranslation();

  const [user, setUser] = useRecoilState(userState);

  useEffect(() => {
    getAndSyncProfiles({
      setUser: setUser,
    });
  }, [setUser]);
  return (
    <div
      style={{
        width: "100%",
        height: "350px",
        overflow: "auto",
      }}
    >
      <Table
        noDataText={t("NO_PROFILES_CREATED_YET")}
        mode="None"
        growing={TableGrowingMode.Scroll}
        stickyColumnHeader={true}
        columns={
          <>
            <TableColumn
              style={{
                width: "12rem",
                textAlign: "left",
              }}
              popinText="Name"
            >
              <Label>{t("NAME")}</Label>
            </TableColumn>
            <TableColumn
              style={{
                textAlign: "left",
              }}
              demandPopin
              minWidth={800}
              popinText="Plant"
            >
              <Label>{t("PLANT")}</Label>
            </TableColumn>
            <TableColumn
              style={{ textAlign: "left" }}
              demandPopin
              minWidth={600}
              popinText="Description"
            >
              <Label>{t("DESCRIPTION")}</Label>
            </TableColumn>
            <TableColumn
              style={{ textAlign: "left" }}
              demandPopin
              minWidth={600}
              popinText="DM-API Endpoint"
            >
              <Label>{t("DMC_URL")}</Label>
            </TableColumn>
            <TableColumn
              style={{ textAlign: "left" }}
              demandPopin
              minWidth={600}
            >
              <Label></Label>
            </TableColumn>
          </>
        }
      >
        {user &&
          user.user &&
          user.user?.dmProfile?.length > 0 &&
          user.user?.dmProfile.map((item: DmProfile) => {
            return (
              <TableRow key={item._id}>
                <TableCell style={{ textAlign: "left" }}>
                  <Label>{item.name}</Label>
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Label>{item.plant}</Label>
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Label>{item.description}</Label>
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Label>{item.dmcUrl}</Label>
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  {!props.onlyDeleteAction && (
                    <Button
                      className="manufacturingButton"
                      icon="edit"
                      style={{
                        margin: "10px",
                      }}
                      onClick={() => props.openDialog("EDIT", item._id)}
                    ></Button>
                  )}
                  <Button
                    className="manufacturingButton"
                    icon="delete"
                    style={{
                      margin: "10px",
                    }}
                    onClick={() => props.openDialog("DELETE", item._id)}
                  ></Button>
                </TableCell>
              </TableRow>
            );
          })}
      </Table>
    </div>
  );
}
