"use client";
import { useEffect, useRef, useState } from "react";
import "../style/Home.css";
import conDMWWhit from "../img/conDMW_logo_white.png";
import {
  Avatar,
  CheckBox,
  CheckBoxDomRef,
  Icon,
  Label,
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
  Ui5CustomEvent,
} from "@ui5/webcomponents-react";
import authApi from "../service/auth";
import { Register, Login } from "../dto/auth.dto";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { userSelector, userState } from "../state/userState";
import { headerSelector, headerState } from "../state/headerState";
import { HeaderState } from "../model/Header.model";
import { User, UserState } from "../model/User.model";
import {
  checkIfProfilesAreValid,
  getAndSyncProfiles,
} from "../utils/profile.utils";
import { profileState } from "../state/profileState";
import { useTranslation } from "react-i18next";
import { snackbarState } from "../state/snackbarState";
import { spinnerState } from "../state/spinnerState";
import "@ui5/webcomponents-icons/dist/translate.js";
import stripeApi from "../service/stripe";
import { deleteTokenAfterInactivity } from "../utils/user.utils";
import { confState } from "../state/confState";
import { ConfState } from "../model/Conf.mode";

type ErrorMessage = { show: boolean; message: string };
export default function Header(props: any) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [snackbar, setSnackbarState] = useRecoilState(snackbarState);
  const [spinner, setSpinnerState] = useRecoilState(spinnerState);
  const scrollUpRef = useRef(null);

  const scrollUp = () => {
    (scrollUpRef.current as HTMLElement | null)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();
  const intialRegister: Register = {};
  const register: Register = {};
  const initialLogin: Login = {};
  const errorMessage: ErrorMessage = {
    show: false,
    message: "",
  };
  const login: Login = {};
  const [validLoginEmail, setValidLoginEmail] = useState(true);
  const [isEnglish, setIsEnglish] = useState(
    navigator.language.includes("de") ? false : true
  );
  const [validRegistrationEmail, setValidRegistartionEmail] = useState(true);
  const [validRegistrationEmailDebounced, setValidRegistartionEmailDebounced] =
    useState(true);
  const [validRegistrationPassword, setValidRegistrationPassword] =
    useState(true);
  const [
    validRegistrationRepeatePassword,
    setValidRegistrationRepeatePassword,
  ] = useState(true);
  const [validDataPrivacyChecked, setValidDataPrivacyChecked] = useState(false);
  const [validLoginPassword, setValidLoginPassword] = useState(true);
  const [registerError, setRegisterError] = useState(errorMessage);
  const [loginError, setLoginError] = useState(errorMessage);
  const [isTabLogin, setIsTabLogin] = useState(true);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [isLoginPressed, setIsLoginPressed] = useState(false);
  const [registrationValues, setRegistrationValues] = useState(register);
  const [loginValues, setLoginValues] = useState(login);
  const [open, setOpen] = useState(false);
  const [messageValues, setMessageValues] = useState({
    message: "",
    type: "",
  });

  const [user, setUser] = useRecoilState(userState);
  const [conf, setConf] = useRecoilState(confState);
  const [profile, setProfile] = useRecoilState(profileState);
  const [header, setHeader] = useRecoilState(headerState);
  const userValue = useRecoilValue(userSelector);
  const headerValue = useRecoilValue(headerSelector);

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const addToRefs = (el: HTMLInputElement) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const clearInputFields = () => {
    inputRefs.current.forEach((input) => {
      if (input) input.value = "";
    });
  };
  const getDropdown = () => {
    setIsShowDropdown((prev) => !prev);
  };
  const closeDropdown = () => {
    setIsShowDropdown(false);
  };

  const openLoginTab = (isTabLogin: boolean) => {
    setIsTabLogin(isTabLogin);
  };

  const onClickPortalSession = () => {
    stripeApi.createPortalSession().then((response: any) => {
      if (response.url) window.location.href = response.url;
    });
  };

  const handleRegistrationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if (name === "email") {
      checkValidEmail(value, "Register");
    }
    if (name === "password") {
      checkValidPassword(value, "Register");
    }
    if (name === "repeatPassword" || name === "password") {
      checkValidRepeatePassword(value, name === "password");
    }
    setRegistrationValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setRegisterError({ show: false, message: "" });
  };
  const checkValidEmail = (value: string, actionType: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(value);
    if (actionType === "Register") {
      setValidRegistartionEmail(!value ? true : isValidEmail);
      return;
    }
    setValidLoginEmail(!value ? true : isValidEmail);
  };

  const checkValidPassword = (value: string, actionType: string) => {
    const isValidPassword = value.length >= 8;
    if (actionType === "Register") {
      setValidRegistrationPassword(!value ? true : isValidPassword);
      return;
    }
    setValidLoginPassword(!value ? true : isValidPassword);
  };
  const checkValidRepeatePassword = (value: string, isPassword: boolean) => {
    const checkPassword = isPassword
      ? registrationValues.repeatPassword
      : registrationValues.password;
    const isValidRepeatePassword = value === checkPassword;
    setValidRegistrationRepeatePassword(!value ? true : isValidRepeatePassword);
  };

  const handleRegistrationSubmit = () => {
    setRegisterError({ show: false, message: "" });
    if (!registrationValues?.firstName || !registrationValues?.lastName) {
      setRegisterError({
        show: true,
        message: t("REGISTRATION_FILL_FORM_FIRST"),
      });
      return;
    }
    if (
      !registrationValues?.email ||
      !registrationValues?.password ||
      !registrationValues?.repeatPassword
    ) {
      setRegisterError({
        show: true,
        message: t("ENTER_EMAIL_AND_PASSWORD"),
      });
      return;
    }
    if (!validDataPrivacyChecked) {
      setValidDataPrivacyChecked(false);
      return;
    }
    if (
      !validRegistrationEmail ||
      !validRegistrationPassword ||
      !validRegistrationRepeatePassword
    ) {
      return;
    }
    setSpinnerState({ active: true });
    setRegisterError(errorMessage);
    if (registrationValues.repeatPassword) {
      delete registrationValues.repeatPassword;
    }
    authApi
      .register(registrationValues)
      .then((response) => {
        setRegisterError(errorMessage);
        setSnackbarState({
          message: t("SUCCESFULLY_REGISTERED"),
          status: "success",
        });
        if (response) {
          setStateToDefault();
          setLoginValues({
            email: registrationValues.email,
            password: registrationValues.password,
          });
          setSpinnerState({ active: false });
          return;
        }
        setRegisterError({
          show: true,
          message: "Unknown Error",
        });
        setSpinnerState({ active: false });
        setValidDataPrivacyChecked(false);
      })
      .catch((error) => {
        setSpinnerState({ active: false });
        setSnackbarState({
          status: "error",
          message: error.message ? t(error.message[0]) : t(error.data.message),
        });
        if (error.status === 404) {
          openLoginTab(true);
          setLoginValues(initialLogin);
          setRegistrationValues(intialRegister);
          clearInputFields();
        }
      });
  };

  const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "email") {
      checkValidEmail(value, "Login");
    }
    if (name === "password") {
      checkValidPassword(value, "Login");
    }
    setLoginValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleLoginSubmit = () => {
    setLoginError({
      show: false,
      message: "",
    });
    if (!validLoginEmail || !validLoginPassword) {
      return;
    }
    const loginParam = loginValues;
    if (!loginParam?.email || !loginParam?.password) {
      setLoginError({
        show: true,
        message: t("ENTER_EMAIL_AND_PASSWORD"),
      });
      return;
    }

    if (!validLoginEmail) {
      return;
    }
    setSpinnerState({ active: true });
    setLoginError(errorMessage);
    authApi
      .login(loginParam)
      .then((response: any) => {
        localStorage.setItem("token", response.token);
        authApi
          .getUserData({})
          .then((response: User) => {
            setLoginError(errorMessage);
            if (response.email) {
              setSnackbarState({
                message: t("SUCCESFULLY_LOGGED_IN"),
                status: "success",
              });
              setUser((prev: UserState) => ({
                ...prev,
                user: response,
                isLoggedIn: true,
              }));
              setHeader((prev: HeaderState) => ({
                ...prev,
                isLoginPopUpOpen: false,
              }));
              setStateToDefault();
              setSpinnerState({ active: false });
            }
          })
          .catch((error: any) => {
            setSnackbarState({
              message: t("ERROR_LOGIN"),
              status: "error",
            });
            setSpinnerState({ active: false });
          });
      })
      .catch((error: any) => {
        console.log(error);
        setSpinnerState({ active: false });
        const data = error.data;
        if (data.message === "USER_DOES_NOT_EXIST" && data.statusCode === 404) {
          openLoginTab(false);
          clearInputFields();
          setSnackbarState({
            message: t(data.message),
            status: "error",
          });
        } else if (
          data.statusCode === 404 &&
          data.message === "USER_NOT_VERIFIED"
        ) {
          setSnackbarState({
            message: t(data.message),
            status: "error",
          });
        } else if (data.statusCode === 404 || data.statusCode === 400) {
          setSnackbarState({
            message: t("WRONG_CREDENTIALS"),
            status: "error",
          });
        }
      });
  };

  const handleLogout = () => {
    setSpinnerState({ active: true });
    localStorage.removeItem("token");
    setLoginAndRegisterToDefault();
    setIsShowDropdown(false);
    setUser((prev: UserState) => ({
      ...prev,
      user: undefined,
      isLoggedIn: false,
    }));
    setSnackbarState({
      message: t("LOGOUT_SUCCESS"),
      status: "success",
    });
    setSpinnerState({ active: false });
    navigate("/");
  };

  const setStateToDefault = () => {
    openLoginTab(false);
    setIsTabLogin(true);
    setIsLoginPressed(false);
    setIsShowDropdown(false);
    clearInputFields();
    setLoginAndRegisterToDefault();
  };
  const setLoginAndRegisterToDefault = () => {
    setRegisterError(errorMessage);
    setLoginError(errorMessage);
    setLoginValues(initialLogin);
    setRegistrationValues(intialRegister);
  };

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const showMessageBox = (message: string, type: string) => {
    setMessageValues({
      type: type,
      message: message,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [intervalId, setIntervalId] = useState(undefined);
  deleteTokenAfterInactivity("token", {
    setIntervalId: setIntervalId,
    intervalId: intervalId,
    navigate: navigate,
    handleLogout: handleLogout,
  });

  const setFormToDefault = () => {
    setRegisterError({ show: false, message: "" });
    setValidRegistartionEmail(true);
    setValidRegistrationPassword(true);
    setValidRegistrationRepeatePassword(true);
    setValidDataPrivacyChecked(false);
    setValidLoginEmail(true);
    setValidLoginPassword(true);
    setLoginError({ show: false, message: "" });
    setIsLoginPressed(false);
    setHeader((prev) => ({ ...prev, isLoginPopUpOpen: false }));
    openLoginTab(true);
    setLoginValues(initialLogin);
    setRegistrationValues(intialRegister);
    clearInputFields();
  };

  useEffect(() => {
    scrollUp();

    const isTokenSet = localStorage.getItem("token") ? true : false;
    if (!user.isLoggedIn && isTokenSet) {
      getAndSyncProfiles({
        setUser: setUser,
        setProfile: setProfile,
        showMessageBox: showMessageBox,
        navigate: navigate,
      }).then((data: User) => {
        setUser((prev: UserState) => ({
          ...prev,
          isLoggedIn: true,
          user: data,
        }));
        if (
          !userValue.isLoggedIn &&
          !data &&
          window.location.pathname !== "/" &&
          window.location.pathname !== "/verify"
        ) {
          // If the user is not logged in, redirect to the home page
          navigate("/");
        }

        const isProfilesAvailabe = checkIfProfilesAreValid({
          setProfile: setProfile,
        });
        if (!isProfilesAvailabe && (userValue.isLoggedIn || data)) {
          setSnackbarState({
            message: t("PROFIL_NOT_AVAILABLE"),
            status: "error",
          });
        }

        if (
          window.location.pathname === "/" ||
          window.location.pathname === "/verify"
        ) {
          setHeader((prev: HeaderState) => ({
            ...prev,
            isNavBack: false,
            appName: "",
          }));
        } else {
          setHeader((prev: HeaderState) => ({
            ...prev,
            isNavBack: true,
            appName: "",
          }));
        }
      });
    }
    const debounceId = setTimeout(() => {
      setValidRegistartionEmailDebounced(validRegistrationEmail);
    }, 3000);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      clearTimeout(debounceId);
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [
    navigate,
    setHeader,
    setProfile,
    setSnackbarState,
    setUser,
    t,
    user.isLoggedIn,
    userValue.isLoggedIn,
    validRegistrationEmail,
  ]);
  const handleLanguageChange = () => {
    changeLanguage(isEnglish ? "de" : "en");
    setConf((prev: ConfState) => ({
      ...prev,
      language: isEnglish ? "de" : "en",
    }));
    setIsEnglish(!isEnglish);
  };
  return (
    <>
      <div ref={scrollUpRef} />

      <MessageBox
        draggable
        open={open}
        type={
          messageValues.type as
            | MessageBoxTypes
            | "Confirm"
            | "Error"
            | "Information"
            | "Success"
            | "Warning"
            | undefined
        }
        onClose={handleClose}
        actions={[MessageBoxActions.OK, MessageBoxActions.Cancel]}
      >
        <span style={{ fontSize: "1.1rem" }}>{messageValues.message}</span>
      </MessageBox>
      {isShowDropdown && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            zIndex: 10,
          }}
          onClick={() => setIsShowDropdown(false)}
        ></div>
      )}
      <div className="headerNav">
        <nav>
          <div className="headerLogoContainer">
            {headerValue.isNavBack ? (
              <Icon
                style={{
                  marginLeft: "0px",
                  marginRight: "10px",
                  fontSize: "2rem",
                  width: "30px",
                  height: "30px",
                }}
                name="nav-back"
                design="Contrast"
                onClick={() => {
                  const urlParams = new URLSearchParams(window.location.search);
                  const isCanceled: boolean = Boolean(
                    urlParams.get("canceled")
                  );
                  const isSubscription =
                    window.location.pathname.includes("subscription");
                  if (isCanceled && isSubscription) {
                    const naveBackStripPath =
                      sessionStorage.getItem("naveBackStripPath") || "/";
                    navigate(naveBackStripPath);
                    sessionStorage.setItem("naveBackStripPath", "/");
                  } else {
                    navigate(-1);
                  }
                  setTimeout(() => {
                    scrollUp();
                    setHeader((prev: HeaderState) => ({
                      ...prev,
                      appName: "",
                      isNavBack:
                        window.location.pathname !== "/" ? true : false,
                    }));
                  }, 50);
                }}
                interactive={true}
              ></Icon>
            ) : (
              <div
                style={{
                  marginLeft: "0px",
                  marginRight: "10px",
                  fontSize: "2rem",
                }}
              ></div>
            )}

            <img
              width={"150px"}
              style={{ cursor: "pointer" }}
              src={conDMWWhit}
              onClick={() => {
                if (window.location.pathname !== "/verify") {
                  setHeader((prev: HeaderState) => ({
                    ...prev,
                    appName: "",
                    isNavBack: false,
                  }));
                  navigate("/");
                  scrollUp();
                }
              }}
              alt="conDMWizard by concircle"
            ></img>
          </div>

          {/* <!-- Dropdown --> */}
          <div className="headerMenuButtonContainer dropdown">
            {userValue.isLoggedIn ? (
              <Avatar
                className="headerMenuButton"
                onClick={getDropdown}
                style={{
                  background: "#c7d64f",

                  borderColor: "#c7d64f",
                }}
                size="XS"
                initials={`${userValue.user?.firstName
                  ?.charAt(0)
                  .toUpperCase()}${userValue.user?.lastName
                  ?.charAt(0)
                  .toUpperCase()}`}
              />
            ) : (
              window.location.pathname !== "/verify" && (
                <Avatar
                  className="headerMenuButton"
                  onClick={getDropdown}
                  style={{
                    background: "#c7d64f",

                    borderColor: "#c7d64f",
                  }}
                  size="XS"
                  // icon="sap-icon://person-placeholder"
                >
                  <Icon
                    color="black"
                    name="sap-icon://person-placeholder"
                    style={{ width: "15px", height: "15px" }}
                  />
                </Avatar>
              )
            )}

            <div
              id="myDropdown"
              className={
                isShowDropdown ? "dropdown-content show" : "dropdown-content"
              }
            >
              {!userValue.isLoggedIn ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  onClick={() => {
                    setIsLoginPressed(true);
                    setIsShowDropdown(false);
                    setLoginAndRegisterToDefault();
                  }}
                >
                  <i className="dropdownIcon">
                    <Icon
                      name={"sap-icon://visits"}
                      style={{ width: "15px", height: "15px" }}
                    />
                  </i>
                  {t("LOGIN")}{" "}
                </a>
              ) : (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a onClick={() => handleLogout()}>
                  <i className="dropdownIcon">
                    <Icon
                      name={"sap-icon://log"}
                      style={{ width: "15px", height: "15px" }}
                    />
                  </i>
                  {t("LOGOUT")}{" "}
                </a>
              )}
              {userValue.isLoggedIn && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  onClick={() => {
                    setHeader((prev) => ({
                      ...prev,
                      isNavBack: true,
                      appName: "System Management",
                    }));
                    navigate("/profileManagement");
                    closeDropdown();
                    // showAlertMsg("Systems/Profile");
                  }}
                >
                  <i className="dropdownIcon">
                    <Icon
                      name={"sap-icon://factory"}
                      style={{ width: "15px", height: "15px" }}
                    />
                  </i>
                  Profile Management{" "}
                </a>
              )}
              {userValue.isLoggedIn && userValue.user?.role !== "admin" && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  onClick={() => {
                    sessionStorage.setItem(
                      "naveBackStripPath",
                      window.location.pathname
                    );
                    setHeader((prev) => ({
                      ...prev,
                      isNavBack: true,
                      appName: "Subscription",
                    }));
                    navigate("/subscription");
                    closeDropdown();
                  }}
                >
                  <i className="dropdownIcon">
                    <Icon
                      name={"sap-icon://money-bills"}
                      style={{ width: "15px", height: "15px" }}
                    />
                  </i>
                  {t("Subscription")}
                </a>
              )}

              {userValue.isLoggedIn &&
                userValue.user?.role !== "admin" &&
                userValue.user?.subscription?.priceId && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    onClick={() => {
                      setHeader((prev) => ({
                        ...prev,
                        isNavBack: true,
                        appName: "Billing Management",
                      }));
                      onClickPortalSession();
                      closeDropdown();
                    }}
                  >
                    <i className="dropdownIcon">
                      <Icon
                        name={"sap-icon://factory"}
                        style={{ width: "15px", height: "15px" }}
                      />
                    </i>
                    {t("MANAGE_BILLING")}
                  </a>
                )}
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  onClick={() => {
                    handleLanguageChange();
                    closeDropdown();
                  }}
                >
                  <i className="dropdownIcon">
                    <Icon
                      name={"sap-icon://translate"}
                      style={{ width: "15px", height: "15px" }}
                    />
                  </i>
                  {isEnglish ? "German" : "English"}
                </a>
              }
            </div>
          </div>
        </nav>
      </div>

      {/* <!-- Dialog --> */}
      <div
        id="idDialogLogin"
        className="dialog"
        style={{
          display:
            isLoginPressed || headerValue.isLoginPopUpOpen ? "block" : "none",
        }}
      >
        <div className="dialog-content animateDialog">
          <span
            onClick={(event) => {
              event.preventDefault();
              setFormToDefault();
            }}
            className="closeDialog"
          >
            &times;
          </span>
          <div className="tab">
            <button
              id="idLogin"
              className={isTabLogin ? "active tablinks" : "tablinks"}
              onClick={(event) => {
                event.preventDefault();

                openLoginTab(true);
                setLoginValues(initialLogin);
                setRegistrationValues(intialRegister);
                clearInputFields();
              }}
            >
              {t("LOGIN")}
            </button>
            <button
              className={!isTabLogin ? "active tablinks" : "tablinks"}
              onClick={(event) => {
                event.preventDefault();

                openLoginTab(false);
                setLoginValues(initialLogin);
                setRegistrationValues(intialRegister);
                clearInputFields();
              }}
            >
              {t("REGISTER")}
            </button>
          </div>

          {isTabLogin ? (
            <form>
              <div
                id="login"
                className="tabcontent"
                style={{ display: "block" }}
              >
                {/* <!-- Login Tab --> */}
                <div className="loginContainer">
                  <div className="dialogContainer">
                    <label htmlFor="email">
                      <b>{t("EMAIL")}</b>
                    </label>
                    <input
                      ref={addToRefs}
                      type="email"
                      onChange={(event) => {
                        setLoginError({ show: false, message: "" });
                        handleLoginChange(event);
                      }}
                      placeholder={t("ENTER_EMAIL")}
                      name="email"
                      required
                      pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                      style={{
                        borderColor: !validLoginEmail ? "red" : undefined,
                      }}
                    />
                    {!validLoginEmail && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {t("ENTER_VALID_EMAIL")}
                        <br />
                        <br />
                      </span>
                    )}
                    <label htmlFor="password">
                      <b>{t("PASSWORD")}</b>
                    </label>
                    <input
                      ref={addToRefs}
                      onChange={(event) => {
                        setLoginError({ show: false, message: "" });
                        handleLoginChange(event);
                      }}
                      type="password"
                      placeholder={t("ENTER_PASSWORD")}
                      name="password"
                      required
                      style={{
                        borderColor: !validLoginPassword ? "red" : undefined,
                      }}
                    />
                    {!validLoginPassword && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {t("ENTER_VALID_PASSWORD")}
                        <br />
                        <br />
                      </span>
                    )}
                    {loginError.show && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {loginError.message}
                        <br />
                        <br />
                      </span>
                    )}
                    <button
                      className="loginButton"
                      type="submit"
                      onClick={(event) => {
                        event.preventDefault();
                        handleLoginSubmit();
                      }}
                    >
                      {t("LOGIN")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <form>
              <div
                id="register"
                className="tabcontent"
                style={{ display: "block" }}
              >
                {/* <!-- Register Tab --> */}
                <div className="loginContainer">
                  <div className="dialogContainer">
                    <label htmlFor="firstName">
                      <b>{t("FIRST_NAME")}</b>
                    </label>
                    <input
                      onChange={handleRegistrationChange}
                      type="text"
                      placeholder={t("ENTER_FIRST_NAME")}
                      name="firstName"
                      required
                      style={{
                        borderColor: undefined,
                      }}
                    />
                    <label htmlFor="lastName">
                      <b>{t("LAST_NAME")}</b>
                    </label>
                    <input
                      onChange={handleRegistrationChange}
                      type="text"
                      placeholder={t("ENTER_LAST_NAME")}
                      name="lastName"
                      required
                      style={{
                        borderColor: undefined,
                      }}
                    />
                    <label htmlFor="email">
                      <b>{t("EMAIL")}</b>
                    </label>
                    <input
                      onChange={handleRegistrationChange}
                      type="email"
                      placeholder={t("ENTER_EMAIL")}
                      name="email"
                      required
                      pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                      style={{
                        borderColor:
                          !validRegistrationEmail &&
                          !validRegistrationEmailDebounced
                            ? "red"
                            : undefined,
                      }}
                    />
                    {!validRegistrationEmail &&
                      !validRegistrationEmailDebounced && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {t("ENTER_VALID_EMAIL")}
                          <br />
                          <br />
                        </span>
                      )}
                    <label htmlFor="password">
                      <b>{t("PASSWORD")}</b>
                    </label>
                    <input
                      onChange={handleRegistrationChange}
                      type="password"
                      placeholder={t("ENTER_PASSWORD")}
                      name="password"
                      required
                      style={{
                        borderColor: !validRegistrationPassword
                          ? "red"
                          : undefined,
                      }}
                    />
                    {!validRegistrationPassword && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {t("ENTER_VALID_PASSWORD")}
                        <br />
                        <br />
                      </span>
                    )}
                    <label htmlFor="repeatPassword">
                      <b>{t("REPEAT_PASSWORD")}</b>
                    </label>
                    <input
                      onChange={handleRegistrationChange}
                      type="password"
                      placeholder={t("REPEAT_PASSWORD")}
                      name="repeatPassword"
                      required
                      style={{
                        borderColor: !validRegistrationRepeatePassword
                          ? "red"
                          : undefined,
                      }}
                    />
                    {!validRegistrationRepeatePassword && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {t("PASSWORDS_DO_NOT_MATCH")}
                        <br />
                        <br />
                      </span>
                    )}
                    {registerError.show && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {registerError.message}
                        <br />
                        <br />
                      </span>
                    )}
                    <div>
                      <Label
                        wrappingType="Normal"
                        style={{ margin: "10px", marginBottom: "20px" }}
                      >
                        <CheckBox
                          valueState={
                            !validDataPrivacyChecked ? "Error" : "None"
                          }
                          name="dataPrivacyCheckbox"
                          onChange={(
                            event: Ui5CustomEvent<CheckBoxDomRef, never>
                          ) => {
                            console.log(event.target.checked);
                            setValidDataPrivacyChecked(
                              event?.target?.checked || false
                            );
                            setRegistrationValues((prevValues) => ({
                              ...prevValues,
                              dataPrivacyAccepted: event.target.checked,
                              dataPrivacyAcceptedAt: new Date(),
                            }));
                          }}
                          text={t("DSGVO_TEXT")}
                          wrappingType="Normal"
                          required={true}
                        ></CheckBox>
                        {/* <br />
                        <br /> */}
                        {/* <a href="https://www.concircle.com/datenschutz/">
                          {t("DATA_PRIVACY")}
                        </a> */}
                      </Label>
                    </div>
                    <button
                      className="loginButton"
                      onClick={(event) => {
                        event.preventDefault();
                        handleRegistrationSubmit();
                      }}
                    >
                      {t("REGISTER")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
