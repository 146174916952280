import axios, { AxiosRequestHeaders } from "axios";
import queryString from "query-string";

const baseUrl = process.env.REACT_APP_BACK_END_URL + "/api/v1/";
const axiosClient = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  paramsSerializer: (params) => queryString.stringify({ params }),
});

axiosClient.interceptors.request.use(async (config: any) => {
  // Get the token from wherever you store it
  const token = localStorage.getItem("token");

  const headersObj: any = {
    "content-type": "application/json",
  };
  // If the token exists, set it in the headers

  if (token) headersObj.Authorization = `Bearer ${token}`;
  return {
    ...config,
    headers: headersObj,
  };
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) return response.data;
    return response;
  },
  (err) => {
    if (!err.response) return alert(err);
    throw err.response;
  }
);

export default axiosClient;
