import { atom, selector } from "recoil";
import { SnackbarState } from "../model/Snackbar.model";

export const snackbarState = atom<SnackbarState>({
  key: "snackbarState",
  default: {
    message: undefined,
    status: undefined,
  },
});

export const snackbarSelector = selector<SnackbarState>({
  key: "snackbarSelector",
  get: ({ get }) => {
    const sncackbarStateData = get(snackbarState);
    // headerStateData.isNavBack = window.location.pathname === "/" ? false : true;
    return sncackbarStateData;
  },
});
