import { useTranslation } from "react-i18next";
import "../style/dataPrivacy.css";
import "../style/cookies.css";
// import Cookies from "../components/DataPrivacy/Cookies";
// import Business from "../components/DataPrivacy/Business";
// import ProcessingOverview from "../components/DataPrivacy/ProcessingOverview";
// import LegalBasis from "../components/DataPrivacy/LegalBasis";
// import OnlineOffering from "../components/DataPrivacy/OnlineOffering";
// import InquiryManagement from "../components/DataPrivacy/InquiryManagement";
// import MessengerCommunication from "../components/DataPrivacy/MessengerCommunication";
// import ScreeningPrivacy from "../components/DataPrivacy/ScreeningPrivacy";

export default function DataPrivacy(props: any) {
  const { t } = useTranslation();
  const emailAddress = "office@concircle.com";
  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  const contentTable = [
    { id: "#preamble", value: "PREAMBLE" },
    { id: "#controller", value: "CONTROLLER" },
    { id: "#contact", value: "CONTACT_INFO" },
    { id: "#processing-overview", value: "PROCESSING_OVERVIEW" },
    { id: "#legal-basis", value: "LEGAL_BASIS" },
    { id: "#security-measures", value: "SECURITY_MEASURES" },
    { id: "#data-transmission", value: "DATA_TRANSMISSION" },
    { id: "#processing-third-countries", value: "PROCESSING_THIRD_COUNTRIES" },
    { id: "#erasure_data", value: "ERASURE_DATA" },
    { id: "#cookie-usage", value: "COOKIE_USAGE" },
    { id: "#business-services", value: "BUSINESS_SERVICES" },
    { id: "#online-offering-and-web-hosting", value: "ONLINE_OFFERING" },
    { id: "#contact-inquiry-management", value: "CONTACT_INQUIRY_MANAGEMENT" },
    { id: "#messenger-communication", value: "MESSENGER_COMMUNICATION" },
    { id: "#screening-privacy", value: "SCREENING_PRIVACY" },
    { id: "#job-process", value: "JOB_PROCESS" },
    { id: "#cloud-services", value: "CLOUD_SERVICES" },
    { id: "#commercial-communication", value: "COMMERCIAL_COMMUNICATION" },
    { id: "#web-analysis", value: "WEB_ANALYSIS" },
    { id: "#online-marketing", value: "ONLINE_MARKETING" },
    { id: "#social-media", value: "SOCIAL_MEDIA" },
    { id: "#plugins-functions", value: "PLUGINS_FUNCTIONS" },
    { id: "#privacy-policy-update", value: "PRIVACY_POLICY_UPDATE" },
    { id: "#rights-data", value: "RIGHTS_DATA" },
    { id: "#definitions", value: "DEFINITIONS" },
  ];
  return (
    <>
      <div className="headerBgImgApps">
        <div className="topContainer lightFont">
          <h1>{t("DATA_PRIVACY_TITLE")}</h1>
        </div>
      </div>

      <div
        className="dataPrivacyContainer "
        dangerouslySetInnerHTML={{
          __html: t("DATA_PRIVACY_CONTENT"),
        }}
      ></div>
      {/* <div className="dataPrivacyContainer ">
        <h1 id="preamble">{t("Privacy_Policy")}</h1>
        <h1>{t("PREAMBLE")}</h1>
        <p>{t("PREAMBLE_TEXT")}</p>
        <img src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-de.png" />
        <h1>{t("Table of contents")}</h1>
        <ul>
          {contentTable.map((item, index) => (
            <li key={index}>
              <a href={item.id}>{t(item.value)}</a>
            </li>
          ))}
        </ul>
        <h1 id="controller"> {t("CONTROLLER")}</h1>
        <div className="i18n" data-i18n="[html]CONTROLLER_TEXT">
          <p>{t("CONTROLLER_TEXT")}</p>

          <a href={`mailto:${emailAddress}`} onClick={handleEmailClick}>
            {emailAddress}
          </a>
          <p>{t("EMAIL_ADRESS")}:</p>
          <a>https://www.concircle.com/en/imprint/</a>
        </div>
        <h1 id="contact"> {t("CONTACT_INFO")}</h1>
        <a href={`mailto:${emailAddress}`} onClick={handleEmailClick}>
          {emailAddress}
        </a>
        <div id="processing-overview">
          <ProcessingOverview />
        </div>
        <div id="legal-basis">
          <LegalBasis />
        </div>
        <h1 id="security-measures"> {t("SECURITY_MEASURES")}</h1>
        <p>{t("SECURITY_MEASURES_TEXT")}</p>
        <h1 id="data-transmission"> {t("DATA_TRANSMISSION")}</h1>
        <p>{t("DATA_TRANSMISSION_TEXT")}</p>
        <h1 id="processing-third-countries">
          {" "}
          {t("PROCESSING_THIRD_COUNTRIES")}
        </h1>
        <p>
          {t("PROCESSING_THIRD_COUNTRIES_TEXT")}{" "}
          <a>
            https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_en
          </a>
          ).
        </p>
        <h1 id="erasure_data"> {t("ERASURE_DATA")}</h1>
        <p>{t("ERASURE_DATA_TEXT")}</p>
        <div id="cookie-usage">
          <Cookies />
        </div>
        <div id="business-services">
          <Business />
        </div>
        <div id="online-offering-and-web-hosting">
          <OnlineOffering />
        </div>
        <div id="contact-inquiry-management">
          <InquiryManagement />
        </div>
        <div id="messenger-communication">
          <MessengerCommunication />
        </div>
        <div id="screening-privacy">
          <ScreeningPrivacy />
        </div>
        <h1> {t("JOB_PROCESS")}</h1>
        <p id="job-process">{t("JOB_PROCESS_TEXT")}</p>
        <h1> {t("CLOUD_SERVICES")}</h1>
        <p id="cloud-services">{t("CLOUD_SERVICES_TEXT")}</p>
        <h1> {t("COMMERCIAL_COMMUNICATION")}</h1>
        <p id="commercial-communication">
          {t("COMMERCIAL_COMMUNICATION_TEXT")}
        </p>
        <h1> {t("WEB_ANALYSIS")}</h1>
        <p id="web-analysis">{t("WEB_ANALYSIS_TEXT")}</p>
        <h1> {t("ONLINE_MARKETING")}</h1>
        <p id="online-marketing">{t("ONLINE_MARKETING_TEXT")}</p>
        <h1> {t("SOCIAL_MEDIA")}</h1>
        <p id="social-media">{t("SOCIAL_MEDIA_TEXT")}</p>
        <h1> {t("PLUGINS_FUNCTIONS")}</h1>
        <p id="plugins-functions">{t("PLUGINS_FUNCTIONS_TEXT")}</p>
        <h1> {t("PRIVACY_POLICY_UPDATE")}</h1>
        <p id="privacy-policy-update">{t("PRIVACY_POLICY_UPDATE_TEXT")}</p>
        <h1> {t("RIGHTS_DATA")}</h1>
        <p id="rights-data">{t("RIGHTS_DATA_TEXT")}</p>
        <h1> {t("DEFINITIONS")}</h1>
        <p id="definitions">{t("DEFINITIONS_TEXT")}</p>
      </div> */}
    </>
  );
}
