import { Panel, Title, Text } from "@ui5/webcomponents-react";
import "../style/Home.css";
import { useTranslation } from "react-i18next";
export default function FAQ(props: any) {
  const { t } = useTranslation();
  const FAQ = [
    {
      name: "FAQ_WHAT_IS_CONDMWIZARD_HEADLINE",
      questions: [
        {
          question: "FAQ_WHAT_IS_CONDMWIZARD_QUESTION1",
          answer: "FAQ_WHAT_IS_CONDMWIZARD_ANSWER1",
        },
        {
          question: "FAQ_WHAT_IS_CONDMWIZARD_QUESTION2",
          answer: "FAQ_WHAT_IS_CONDMWIZARD_ANSWER2",
        },
        {
          question: "FAQ_WHAT_IS_CONDMWIZARD_QUESTION3",
          answer: "FAQ_WHAT_IS_CONDMWIZARD_ANSWER3",
        },
        {
          question: "FAQ_WHAT_IS_CONDMWIZARD_QUESTION4",
          answer: "FAQ_WHAT_IS_CONDMWIZARD_ANSWER4",
        },
      ],
    },
    {
      name: "FAQ_SUBSCRIPTION_HEADLINE",
      questions: [
        {
          question: "FAQ_SUBSCRIPTION_QUESTION1",
          answer: "FAQ_SUBSCRIPTION_ANSWER1",
        },
        {
          question: "FAQ_SUBSCRIPTION_QUESTION2",
          answer: "FAQ_SUBSCRIPTION_ANSWER2",
        },
        {
          question: "FAQ_SUBSCRIPTION_QUESTION3",
          answer: "FAQ_SUBSCRIPTION_ANSWER3",
        },
        {
          question: "FAQ_SUBSCRIPTION_QUESTION4",
          answer: "FAQ_SUBSCRIPTION_ANSWER4",
        },
      ],
    },
    {
      name: "FAQ_TEC_QUESTIONS_HEADLINE",
      questions: [
        {
          question: "FAQ_TEC_QUESTIONS_QUESTION1",
          answer: "FAQ_TEC_QUESTIONS_ANSWER1",
        },
        {
          question: "FAQ_TEC_QUESTIONS_QUESTION2",
          answer: "FAQ_TEC_QUESTIONS_ANSWER2",
        },
        {
          question: "FAQ_TEC_QUESTIONS_QUESTION3",
          answer: "FAQ_TEC_QUESTIONS_ANSWER3",
        },
        {
          question: "FAQ_TEC_QUESTIONS_QUESTION4",
          answer: "FAQ_TEC_QUESTIONS_ANSWER4",
        },
      ],
    },
    {
      name: "FAQ_DATA_SECURITY_HEADLINE",
      questions: [
        {
          question: "FAQ_DATA_SECURITY_QUESTION1",
          answer: "FAQ_DATA_SECURITY_ANSWER1",
        },
        {
          question: "FAQ_DATA_SECURITY_QUESTION2",
          answer: "FAQ_DATA_SECURITY_ANSWER2",
        },
        {
          question: "FAQ_DATA_SECURITY_QUESTION3",
          answer: "FAQ_DATA_SECURITY_ANSWER3",
        },
        {
          question: "FAQ_DATA_SECURITY_QUESTION4",
          answer: "FAQ_DATA_SECURITY_ANSWER4",
        },
      ],
    },
  ];
  return (
    <div style={{ width: "70%", textAlign: "center" }}>
      <h1 style={{ fontSize: "3em" }}>FAQ</h1>
      {FAQ.map((category: any, index: number) => (
        <div key={index}>
          <h1
            key={t(category.name)}
            style={{ display: "flex", fontSize: "1.7em", textAlign: "start" }}
          >
            {t(category.name)}
          </h1>
          {category.questions.map((question: any, index: number) => {
            return (
              <div key={index}>
                <Panel
                  // onClick={() => {
                  //   window._paq.push([
                  //     "trackEvent",
                  //     "Click Event",
                  //     "Click",
                  //     "FAQ Panel",
                  //   ]);
                  // }}
                  key={question.question + category.name}
                  collapsed={true}
                  accessibleRole="Form"
                  headerLevel="H2"
                  headerText={t(question.question)}
                  onToggle={function _a() {}}
                  className="panel"
                  style={{ textAlign: "start" }}
                >
                  <Text
                    style={{
                      fontSize: "1.1em",
                      fontWeight: "lighter",
                      fontFamily:
                        "neuehelvetica, Helvetica, Arial, Lucida, sans-serif",
                    }}
                    key={question.answer + category.name}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(question.answer),
                      }}
                    ></div>
                  </Text>
                </Panel>
                <br key={question.answer + question.question} />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
