import { useEffect } from "react";
import authApi from "../service/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "@ui5/webcomponents-react";
import { useTranslation } from "react-i18next";

export default function TermsAndConditions() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="headerBgImgApps">
        <div className="topContainer lightFont">
          <h1>{t("TERMS_AND_CONDITIONS_TITLE")}</h1>
          <h2>{t("TERMS_AND_CONDITIONS_SUBTITLE")}</h2>
        </div>
      </div>
      <div
        className="containerBottomTermsAndConditions"
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          minHeight: "50vh",
          // textAlign: "center",
          margin: "auto",
          // marginLeft: "150px",
          maxWidth: "85%",
        }}
      >
        <p>{t("TERMS_AND_CONDITIONS_VALIDITY")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_ONE")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_ONE_TEXT")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_TWO")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_TWO_TEXT")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_THREE")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_THREE_TEXT")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_FOUR")}</h2>
        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_ONE")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_ONE")}</p>
        </div>
        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_TWO")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_TWO")}</p>
        </div>

        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_THREE")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_THREE")}</p>
        </div>

        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_FOUR")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_FOUR")}</p>
        </div>

        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_FIVE")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_FIVE")}</p>
        </div>

        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_SIX")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_SIX")}</p>
        </div>

        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_SEVEN")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_SEVEN")}</p>
        </div>

        {/* <p>{t("TERMS_AND_CONDITIONS_CHAPTER_FOUR_TEXT")}</p> */}

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_FIVE")}</h2>
        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FIVE_ONE")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FIVE_ONE")}</p>
        </div>
        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FIVE_TWO")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FIVE_TWO")}</p>
        </div>
        {/* <p>{t("TERMS_AND_CONDITIONS_CHAPTER_FIVE_TEXT")}</p> */}

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_SIX")}</h2>
        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_ONE")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_ONE")}</p>
        </div>
        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_TWO")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_TWO")}</p>
        </div>
        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_THREE")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_THREE")}</p>
        </div>
        <div>
          <h5>{t("TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_FOUR")}</h5>
          <p>{t("TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_FOUR")}</p>
        </div>
        {/* <p>{t("TERMS_AND_CONDITIONS_CHAPTER_SIX_TEXT")}</p> */}

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_SEVEN")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_SEVEN_TEXT")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_EIGHT")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_EIGHT_TEXT")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_NINE")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_NINE_TEXT")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_TEN")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_TEN_TEXT")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_ELEVEN")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_ELEVEN_TEXT")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_TWELVE")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_TWELVE_TEXT")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_THIRTEEN")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_THIRTEEN_TEXT")}</p>

        <h2>{t("TERMS_AND_CONDITIONS_SECTIONTITLE_FOURTEEN")}</h2>
        <p>{t("TERMS_AND_CONDITIONS_CHAPTER_FOURTEEN_TEXT")}</p>
      </div>
    </>
  );
}
