import { atom, selector } from "recoil";
import { HeaderState } from "../model/Header.model";

export const headerState = atom<HeaderState>({
  key: "headerState",
  default: {
    appName: "",
    isNavBack:
      window.location.pathname === "/" || window.location.pathname === "/verify"
        ? false
        : true,
    isLoginPopUpOpen: false,
  },
});

export const headerSelector = selector<HeaderState>({
  key: "headerSelector",
  get: ({ get }) => {
    const headerStateData = get(headerState);
    // headerStateData.isNavBack = window.location.pathname === "/" ? false : true;
    return headerStateData;
  },
});
