import React, { useState } from "react";
// import "./App.css"; // Assuming you have a CSS file for styling
import {
  Button,
  Icon,
  Input,
  Label,
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
  TextArea,
} from "@ui5/webcomponents-react";
import authApi from "../service/auth";

import { useTranslation } from "react-i18next";
import phone from "../img/8665646_phone_communication_icon.png";
import facebookIcon from "../img/104498_facebook_icon.png";
import linkedIn from "../img/167667_linkedin_icon.png";
import address from "../img/7791655_address_location_navigate_direction_navigation_icon.png";
import { ThreeDots } from "react-loader-spinner";
import { useRecoilState } from "recoil";
import { spinnerState } from "../state/spinnerState";

export default function ContactCard() {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;

  const { t, i18n } = useTranslation();

  // State variables to hold form data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [spinner, setSpinnerState] = useRecoilState(spinnerState);
  const [messageValues, setMessageValues] = useState({
    message: "",
    type: "",
  });

  // Handle form submission
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSpinnerState({ active: true });
    // Here you can send the form data to your backend or perform any other action
    authApi
      .supportMail({ name, email, message })
      .then(() => {
        showMessageBox(t("MESSAGE_SEND_SUCCESFULLY"), "Success");
        // Reset form fields after submission
        setName("");
        setEmail("");
        setMessage("");
        setSpinnerState({ active: false });
      })
      .catch((error) => {
        setSpinnerState({ active: false });
        showMessageBox(t("MESSAGE_SEND_ERROR"), "Error");
      });
  };
  const showMessageBox = (message: string, type: string) => {
    setMessageValues({
      type: type,
      message: message,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ width: "80%" }}>
      <ThreeDots
        visible={spinner.active}
        height="80"
        width="80"
        color="#c3d72b"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass="spinner"
      />
      <MessageBox
        draggable
        open={open}
        type={
          messageValues.type as
            | MessageBoxTypes
            | "Confirm"
            | "Error"
            | "Information"
            | "Success"
            | "Warning"
            | undefined
        }
        onClose={handleClose}
        actions={[
          <Button
            key={"genericMessageBoxContactCard"}
            className="manufacturingButton"
          >
            OK
          </Button>,
        ]}
      >
        <span style={{ fontSize: "1.1rem" }}>{messageValues.message}</span>
      </MessageBox>
      <h1
        style={{
          textAlign: "center",
        }}
      >
        {t("CONTACT_US")}
      </h1>
      <div className="contactCardContainer">
        <div className="sendUsAMessageContainer">
          <h3 className="underline">{t("SEND_US_A_MESSAGE")}</h3>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <Input
                placeholder={t("NAME")}
                type="Text"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                required
                style={{ width: "100%" }}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Input
                placeholder={t("EMAIL")}
                type="Email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                required
                style={{ width: "100%" }}
              />
            </div>
            <div style={{ width: "100%" }}>
              <TextArea
                onChange={(e: any) => setMessage(e.target.value)}
                placeholder={t("MESSAGE")}
                rows={12}
                style={{ width: "100%" }}
                value={message}
                maxlength={1024}
              ></TextArea>
            </div>
            <button
              className="subscriptionButton"
              style={{ width: "100%" }}
              type="submit"
            >
              {t("SEND")}
            </button>
          </form>
        </div>
        <div style={{ margin: "auto" }}>
          <div style={{ display: "flex" }}>
            <img
              src={phone}
              height={isMobile ? "42px" : "70px"}
              style={{ margin: isMobile ? "9px" : "15px" }}
              alt="Phone Icon"
            />

            <div>
              <h3 className="underline">{t("CALL_US")}</h3>
              <p>+43 1 2368 7473</p>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <a
              href="https://www.facebook.com/concircleVienna/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={facebookIcon}
                height={isMobile ? "60px" : "100px"}
                alt="Facebook Icon"
              />
            </a>
            <div>
              <h3 className="underline">{t("FACEBOOK")}</h3>
              <a
                style={{ color: "black" }}
                href="https://www.facebook.com/concircleVienna/"
                target="_blank"
                rel="noreferrer"
              >
                /concircleVienna
              </a>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <a
              href="https://www.linkedin.com/company/concircle/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={linkedIn}
                height={isMobile ? "60px" : "100px"}
                alt="LinkedIn Icon"
              />
            </a>
            <div>
              <h3 className="underline">{t("LINKEDIN")}</h3>
              <a
                style={{ color: "black" }}
                href="https://www.linkedin.com/company/concircle/"
                target="_blank"
                rel="noreferrer"
              >
                /company/concircle
              </a>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <img
              src={address}
              height={isMobile ? "60px" : "100px"}
              alt="LinkedIn Icon"
            />
            <div>
              <h3 className="underline">{t("OUR_ADDRESS")}</h3>
              <div>
                <p>Concircle Österreich GmbH</p>
                <p>Wienerbergstraße 11</p>
                <p>1100 Wien, Österreich</p>
                <p>+43 1 2368 7470</p>
                <a
                  href="mailto:office@concircle.com"
                  style={{ color: "black" }}
                >
                  office@concircle.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
