import { useEffect, useState } from "react";
// import "../style/transport.css";
import {
  Option,
  Select,
  Ui5CustomEvent,
  SelectDomRef,
  Label,
  MessageStrip,
} from "@ui5/webcomponents-react";
import { SelectChangeEventDetail } from "@ui5/webcomponents/dist/Select";
import "@ui5/webcomponents-icons/dist/process.js";
import { useRecoilState, useRecoilValue } from "recoil";
import { userSelector } from "../../state/userState";
import { profileState } from "../../state/profileState";
import { DmProfile } from "../../model/User.model";
import { Profile } from "../../model/Profile.model";
import { useTranslation } from "react-i18next";
import { checkIfSelectedProfileHasAllNecessaryData } from "../../utils/profile.utils";

export default function SystemDetails(props: any) {
  const { t } = useTranslation();

  const { setDisableTransport } = props;
  const userValue = useRecoilValue(userSelector);
  const [profile, setProfile] = useRecoilState(profileState);
  const [messageTrip, setMessageTrip] = useState(true);
  const [isProfileToAvailable, setIsProfileToAvailable] = useState(false);
  const [isProfileFromAvailable, setIsProfileFromAvailable] = useState(false);

  const handleSelectionChangeFrom = async (
    event: Ui5CustomEvent<SelectDomRef, SelectChangeEventDetail>
  ) => {
    const index =
      userValue.user?.dmProfile.findIndex(
        (profile: DmProfile) =>
          profile._id === event.detail.selectedOption.dataset.id
      ) || 0;
    const selectedProfile = userValue.user?.dmProfile[index];
    const isvalid = await checkIfSelectedProfileHasAllNecessaryData(
      selectedProfile
    );
    setIsProfileFromAvailable(isvalid);
    setProfile((prev) => ({
      ...prev,
      selectedFromProfile: selectedProfile,
    }));
    checkSelectedProfiles(selectedProfile, profile.selectedToProfile);
  };

  const handleSelectionChangeTo = async (
    event: Ui5CustomEvent<SelectDomRef, SelectChangeEventDetail>
  ) => {
    const index =
      userValue.user?.dmProfile.findIndex(
        (profile: DmProfile) =>
          profile._id === event.detail.selectedOption.dataset.id
      ) || 0;
    const selectedProfile = userValue.user?.dmProfile[index];
    const isvalid = await checkIfSelectedProfileHasAllNecessaryData(
      selectedProfile
    );
    setIsProfileToAvailable(isvalid);
    setProfile((prev) => ({
      ...prev,
      selectedToProfile: selectedProfile,
    }));
    checkSelectedProfiles(selectedProfile, profile.selectedFromProfile);
  };
  const checkSelectedProfiles = (
    profile1: DmProfile | undefined,
    profile2: Profile | undefined
  ) => {
    if (!profile1 || !profile2) {
      return true;
    }
    if (profile1._id == "" || profile2._id == "") {
      setDisableTransport(true);
      setMessageTrip(true);
      return false;
    }
    if (profile1?._id == profile2?._id) {
      setDisableTransport(true);
      setMessageTrip(false);
      return false;
    }
    setDisableTransport(false);
    setMessageTrip(true);
    return true;
  };

  useEffect(() => {
    if (!isProfileFromAvailable || !isProfileToAvailable) {
      setDisableTransport(true);
      return;
    }
  }, [isProfileFromAvailable, isProfileToAvailable, setDisableTransport]);
  return (
    <div id="plants" className="systemDetails">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: 1 }}>
          <Label className="labelSysDetail" for="plantFrom">
            {t("PROFILE_TO_TRANSPORT_FROM")}
          </Label>
          <Select
            id="plantFrom"
            className="selectSysDetail"
            valueState={
              profile.isProfileAvailable || isProfileFromAvailable
                ? "Success"
                : "Error"
            }
            valueStateMessage={<span>{t("CHECK_PROFILES")}</span>}
            onChange={handleSelectionChangeFrom}
          >
            <Option key="keyForPlantFromOption" data-id="0" />
            {userValue.user?.dmProfile.map((item: DmProfile) => (
              <Option
                key={item._id}
                data-id={item._id}
                additionalText={item.plant}
              >
                {item.name}
              </Option>
            ))}
          </Select>
        </div>{" "}
        <div style={{ flex: 1 }}>
          <Label className="labelSysDetail" for="plantTo">
            {t("PROFILE_TO_TRANSPORT_TO")}
          </Label>
          <Select
            id="plantTo"
            className="selectSysDetail"
            valueState={
              profile.isProfileAvailable || isProfileToAvailable
                ? "Success"
                : "Error"
            }
            valueStateMessage={<span>{t("CHECK_PROFILES")}</span>}
            onChange={handleSelectionChangeTo}
          >
            <Option key="keyForPlantToOption" data-id="0" />
            {userValue.user?.dmProfile.map((item: DmProfile) => (
              <Option
                key={item._id}
                data-id={item._id}
                additionalText={item.plant}
              >
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <MessageStrip
        design="Negative"
        hidden={messageTrip}
        hideCloseButton={true}
        style={{ marginBottom: "5%", fontSize: "1.2rem" }}
      >
        <span style={{ fontSize: "1.2rem" }}>
          {t("PROFILE_DETAILS_PROFILE_SELECTION_ERROR")}
        </span>
      </MessageStrip>
    </div>
  );
}
