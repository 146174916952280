import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Internal server error":
        "An unexpected error occurred. Please try again.",
      DATA_PRIVACY: "Data privacy",
      COOKIE: "Cookie",
      DURATION: "Duration",
      COOKIE_SETTINGS: "Cookie Settings",
      NECCESARY_COOKIES: "Necessary (always actieve)",
      NO_EXPERATION_DATE: "No Experation Date",
      COOKIE_SETTINGS_TOKEN_DESCRIPTION:
        "This cookie is set by the application and stores the token. This token is necessary to authenticate you against our application.",
      COOKIE_SETTINGS_PROFILES_DESCRIPTION:
        "This cookie is set by the application and stores the profiles. These profiles are necessary to use the application.",
      COOKIE_SETTINGS_LAST_ACTIVE_DESCRIPTION:
        "This cookie is set by the application and stores the last active time. It is used to potentially log you out.",
      COOKIE_SETTINGS_COOKIE_CONSENT_DESCRIPTION:
        "This cookie is set by the application and stores the user's consent to the cookies.",
      COOKIE_SETTINGS_COOKIE_NECCESARY_DESCRIPTION:
        'This cookie is set by the application and is used to capture the user\'s consent to the "Necessary" category cookies.',
      COOKIE_SETTINGS_ARRAffinitySameSite_DESCRIPTION:
        "This cookie is set by Microsoft Azure. It is necessary to route requests to the correct instance in a load-balanced environment.",
      COOKIE_SETTINGS_STRIPE_MID_DESCRIPTION:
        "Set for fraud prevention purposes and helps us assess the risk associated with an attempted transaction.",
      COOKIE_SETTINGS_STRIPE_SID_DESCRIPTION:
        "Set for fraud prevention purposes and helps us assess the risk associated with an attempted transaction.",
      COOKIE_SETTINGS_COOKIE_PERMS_DESCRIPTION:
        "Stores a user's cookie permissions.",
      COOKIE_SETTINGS_STRIPE_MACHINE_IDENTIFIER_DESCRIPTION:
        "To prevent fraud, enables Stripe to determine the computer being used on the Stripe Dashboard.",
      COOKIE_SETTINGS_STRIPE_PRIVATE_MACHINE_IDENTIFIER_DESCRIPTION:
        "Identifies the computer across login sessions and users to prevent fraud.",
      COOKIE_SETTINGS_STRIPE_CSRF_DESCRIPTION:
        "Prevents attackers from making requests that change user data to Stripe from other websites.",
      COOKIE_SETTINGS_MAIN_TEXT: `This website uses cookies to improve your experience while navigating through the website. Out of these, the cookies that are categorized as necessary are stored on your browser as they are essential for the basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent.`,
      COOKIE_SETTINGS_NECCESARY_TEXT: `Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.`,
      COOKIE_DURATION_MINUTE: "{{minute}} minute(s)",
      COOKIE_DURATION_HOUR: "{{hour}} hour(s)",
      COOKIE_DURATION_DAY: "{{day}} day(s)",
      COOKIE_DURATION_MONTH: "{{month}} month(s)",
      COOKIE_DURATION_YEAR: "{{year}} year(s)",
      COOKIE_DURATION_SESSION: "Session",
      LOGIN: "Login",
      LOGOUT: "Logout",
      MANAGE_BILLING: "Manage Billing",
      REGISTER: "Register",
      EMAIL: "Email",
      ENTER_EMAIL: "Enter Email",
      ENTER_VALID_EMAIL: "Please enter a valid email address.",
      PASSWORD: "Password",
      ENTER_PASSWORD: "Enter Password",
      ENTER_VALID_PASSWORD: "Password must be at least 8 characters long.",
      FIRST_NAME: "First Name",
      ENTER_FIRST_NAME: "Enter First Name",
      LAST_NAME: "Last Name",
      ENTER_LAST_NAME: "Enter Last Name",
      REPEAT_PASSWORD: "Repeat Password",
      PASSWORDS_DO_NOT_MATCH: "Password must be be same as above.",
      ENTER_EMAIL_AND_PASSWORD: "Please enter an email and a password",
      REGISTRATION_FILL_FORM_FIRST: "Please fill out the form!",
      USER_ALREADY_EXIST: "Registration failed: User already exists!",
      VERIFY_YOUR_ACCOUNT_NOW: "Verify Your Account now!",
      VERIFY_YOUR_ACCOUNT: "Verify Your Account",
      SUCCESFULLY_REGISTERED:
        "Verification E-Mail send. You have successfully registered!",
      SUCCESFULLY_LOGGED_IN: "You have successfully logged in!",
      ERROR_LOGIN: "Login failed! Please try again or check your credentials!",
      WRONG_CREDENTIALS: "Please enter valid credentials!",
      CLICK_BUTTON_TO_VERIFY:
        "Please click the Button above to verify your Account. By Verifying your email also your Free Version plan will be activated and is valid for 1 month",
      DSGVO_TEXT: `According to the General Data Protection Regulation (GDPR), we inform you about the storage of your data. The consent to store and use your data is voluntary and can be revoked at any time. By registering, you also confirm that you have read and accept the terms and conditions.`,
      SAVE_AND_ACCEPT: "Save and Accept",
      CONTACT_US: "Contact us",
      SEND_US_A_MESSAGE: "Send us a message",
      MESSAGE: "Message",
      SEND: "Send",
      MESSAGE_SEND_SUCCESFULLY: "Message was send!",
      MESSAGE_SEND_ERROR: "The message could not be sent. Please try again!",
      CALL_US: "Call us",
      FACEBOOK: "Facebook",
      LINKEDIN: "LinkedIn",
      OUR_ADDRESS: "Our Address",
      SELECT: "Select",
      CLOSE: "Close",
      CONFIRM: "Confirm",
      UPDATE: "Update",
      PROFILE_DATA_TITLE: "{{metod}} Profile",
      ADD: "Add",
      DELETE: "Delete",
      EDIT: "Edit",
      START_UPLOAD: "Start upload",
      VERSION: "Version",
      ACTIONS: "Actions",
      STATUS: "Status",
      TRANSPORT: "Transport",
      USER_NOT_VERIFIED: "Please verify your email address.",
      SELECT_PROFILE_ERROR_MESSAGE: "Please select a Profile",
      CHECK_PROFILES: "Please check your Profiles",
      PROFIL_NOT_AVAILABLE:
        "Profile(s) are not available! Please check the Profile(s) configuration in the Profile Management Page.",
      PROFILE_MANAGEMENT_TITLE: "Profile Management",
      PROFILE_MANAGEMENT_SUBTITLE:
        "Manage all your Systems and Plants in form of a conDMWizard Profile.",
      NO_PROFILES_CREATED_YET: "No Profiles created yet",
      ADD_PROFILE: "Add Profile",
      DELETE_PROFILE: "Delete Profile",
      EDIT_PROFILE: "Edit Profile",
      CHOSSEN_PLAN_ONLY_ALLOWS_X_PROFILES:
        "The choosen plan only allows {{x}} profiles. Please delete the profile you don't need anymore, otherwise the latest created Profiles get deleted automatically!",
      NAME: "Name",
      DESCRIPTION: "Description",
      PLANT: "Plant",
      DMC_URL: "DM-API Endpoint",
      TOKEN_URL: "Token URL",
      CLIENT_ID: "Client ID",
      CLIENT_SECRET: "Client Secret",
      PROFILE_SAFETY_WARNING_COUNTER_REACHED:
        "You have reached the limit for creating a profile with the same plant and tenant!",
      ERROR_TOO_MANY_PROFILE_CREATED: `The Tenant and Plant from the Profile you want to create was already created 3 times! Due to safty reasons you can't create more than 3 Profiles for the same Tenant and Plant! Please contact the Support at `,
      PROFILE_LIMIT_REACHED:
        "You have reached the limit for creating profiles!",
      UPLOAD_COLLECTION_NO_DATA_DESCRIPTION:
        "Drop files to upload, or use the Upload button.",
      UPLOAD_COLLECTION_NO_DATA_TEXT: "No File Found",
      UPLOAD_COLLECTION_DATA_DESCRIPTION:
        "Drop files to upload, or use the Upload button to upload another file.",
      DC_GROUPS_READY_TO_UPDATE_MESSAGE:
        "Ready to Upload your Data Collection Groups",
      UPLOAD_COLLECTION_DATA_TEXT: "File uploaded successfully",
      DOWNLOAD_EXCEL_TEMPLATE: "Download Excel Template",
      PLEASE_USE_EXCEL_TEMPLATE: "Please use the Excel Template",
      PARAMETERS_OR_ASSIGNMENTS_MISSING:
        "There are Parameters or Assignments without an valid Data Colletion Group Name.",
      DC_UPLOAD_SUCCES_STATUS_MESSAGE:
        "{{numberOne}} of {{numberTwo}} successfully uploaded",
      DC_UPDATE_SUCCES_STATUS_MESSAGE:
        "{{numberOne}} of {{numberTwo}} successfully updated",
      LANDING_PAGE_TITLE: "Welcome to the conDMWizard",
      LANDING_PAGE_SUBTITLE: `conDMWizard is a specialized SaaS web application for the efficient management of master data within the SAP Digital Manufacturing (SAP DM) landscape. It enables the effortless upload, migration and synchronization of master and configuration data across different plants and tenants of SAP DM.`,
      DATA_COLLECTION_UPLOAD_TITLE: "Data Collection Upload",
      DATA_COLLECTION_TRANSFER_TITLE: "Data Collection Transfer",
      POD_TRANSPORT_TITLE: `POD \nTransport`,
      DATA_COLLECTION_UPLOAD_SUBTITLE_TILE:
        "Upload your Data Collection Groups via an Excel File",
      DATA_COLLECTION_UPLOAD_SUBTITLE:
        "Upload and Update your Data Collections via an Excel File.",
      DATA_COLLECTION_TRANSFER_SUBTITLE_TILE:
        "Transfer your Data Collection Groups between Plants and Systems",
      DATA_COLLECTION_TRANSFER_SUBTITLE:
        "Transfer your Data Collection Groups between Plants and Systems",
      POD_TRANSPORT_SUBTITLE_TILE:
        "Transfer your POD's between Plants and Systems",
      POD_TRANSPORT_SUBTITLE: "Transfer your POD's between Plants and Systems",
      PLANT_DID_NOT_EXIST_IN_DM: "Plant does not exist in your SAP DM Tenant!",
      PROFILE_CREATED_SUCCESFULLY: "You have successfully created a Profile!",
      PROFILE_ALREADY_EXIST_FOR_THIS_PLANT_AND_SYSTEM:
        "Profile with same name and plant already exist!",
      ERROR_GET_TOKEN:
        "Please check your SAP DM Tenant credentials and try again!",
      PROFILE_DATA_INCORRECT:
        "Profile Data is incorrect. Please check the enterde Data",
      USER_DOES_NOT_EXIST: "User does not exist. Please register first!",
      LOGOUT_SUCCESS: "You have successfully logged out!",
      NOT_ALL_REQUIRED_FIELDS_EXIST:
        "Please fill all the required fields before submitting the form!",
      DC_UPDATE_CONFIRMATION_MESSAGE:
        "Do you realy want to Delete and Create the selected DC-Groups?",
      DC_UPLOAD_PROFILE_NOT_SELECTED: `Please select a conDMW Profile which includes the 
      
        DM-API Endpoint,
        Token URL,
        Client ID and
        Client Secret
        
        to Upload your Data Collection Groups.

        If you don't have a conDMW Profile, please create one in the System Management Page.`,
      DC_UPLOAD_NO_DC_GROUP_FOUND: "No data collection group was found!",
      DC_UPLOAD_SELECT_EXCEL_FILE: "Please select an Excel file.",
      DC_UPDATE_SELECT_DIALOG_TITLE: "Choose DC Groups for Update",
      PROFILE_TO_TRANSPORT_FROM: "Profile to Transport From:",
      PROFILE_TO_TRANSPORT_TO: "Profile to Transport To:",
      PROFILE_DETAILS_PROFILE_SELECTION_ERROR:
        "Make sure profiles for transport from and to are not empty and not the same.",
      SAP_DM_ERROR_MESSAGE: "SAP DM Error Message",
      DC_GROUP_NAME: "Data Collection Group",
      POD_NAME: "POD Name",
      NEW_DC_GROUP_NAME: "New Data Collection Group",
      NEW_POD_NAME: "New POD Name",
      POD_TRANSPORT_SUCCES_MESSAGE:
        "POD {{podId}} has been transported successfully to Plant {{plant}}",
      DC_TRANSPORT_SUCCES_MESSAGE:
        "Data Collection {{to}} has been transported successfully to Plant {{plant}}",
      HOW_DOES_IT_WORK: "How does it Work?",
      DC_UPLOAD_DOWNLOAD_EXCEL_TEMPLATE:
        "Download the Excel File with the 'Download Excel Template' button.",
      DC_UPLOAD_FILL_OUT_THE_EXCEL_FILE:
        "Fill out the Excel File with your Data Collection Groups.",
      DC_UPLOAD_SELECT_PROFILE:
        "Select the Profile where you want to upload the Data Collection Group(s) to.",
      DC_UPLOAD_DRAG_AND_DROP_FILE:
        "Drag and Drop the Excel File in the area which is surrounded by the dashed line.",
      DC_UPLOAD_SELECT_FILE:
        "Or select the Excel File with the File Upload button.",
      DC_UPLOAD_START_UPLOAD:
        'Click the "Start upload" button to upload the Data Collection Group(s).',
      SELECT_PROFILE_TRANSFER_FROM: "Select a Profile to transfer from.",
      SELECT_PROFILE_TRANSFER_TO: "Select a Profile to transfer to.",
      ENTER_DC_GROUP_NAME_AND_VERSION:
        "Enter the Data Collection Group Name and the Version.",
      NEW_DC_NAME_AND_VERSION_SET_AUTO:
        "The new Data Collection Group Name and the Version will be set automatically.",
      CHANGE_DC_GROUP_NAME_AND_ADD_DESCRIPTION:
        "You can change the Data Collection Group Name and add a Description.",
      DC_TRANSPORT_BUTTON:
        "With the Transport Button you can transport the Data Collection Group to the selected Profile.",
      SELECT_PROFILE_TRANSPORT_FROM: "Select a Profile to transport from.",
      SELECT_PROFILE_TRANSPORT_TO: "Select a Profile to transport to.",
      ENTER_POD_NAME: "Enter the POD Name.",
      NEW_POD_NAME_SET_AUTO: "New POD Name set automatically.",
      CHANGE_POD_NAME_AND_ADD_DESCRIPTION:
        "You can change the POD Name and add a Description.",
      POD_TRANSPORT_BUTTON:
        "With the Transport Button you can transport the POD to the selected Profile.",
      PROFILE_MANAGEMENT_ADD_PROFILE_HOW_TO:
        'With the "Add Profile" button you can create a new Profile.',
      PROFILE_MANAGEMENT_ADD_PROFILE_DIALOG:
        "Enter all the required (*) information and press the OK button to add the profile to your conDMW account.",
      PROFILE_MANAGEMENT_EDIT_PROFILE_HOW_TO:
        'With the "Edit Profile" button you can edit your Profile.',
      PROFILE_MANAGEMENT_EDIT_PROFILE_DIALOG:
        "Change the information and press the OK button to update the profile.",
      PROFILE_MANAGEMENT_DELETE_PROFILE_HOW_TO:
        'With the "Delete Profile" button you can delete your Profile.',
      PROFILE_MANAGEMENT_DELETE_PROFILE_DIALOG:
        "Check if you have selected the right Profile and press the OK button to delete the profile.",
      DATA_PRIVACY_TITLE: "Data Privacy Policy",
      DATA_PRIVACY_CONTENT: `<div class="et_pb_section et_pb_section_1 et_section_regular">
      <div class="et_pb_row et_pb_row_1">
        <div
          class="et_pb_column et_pb_column_4_4 et_pb_column_1 et_pb_css_mix_blend_mode_passthrough et-last-child"
        >
          <div
            class="et_pb_module et_pb_text et_pb_text_1 et_pb_text_align_left et_pb_bg_layout_light"
          >
            <div class="et_pb_text_inner">
              <h2 id="m716">Preamble</h2>
              <p>
                With the following privacy policy we would like to inform you which
                types of your personal data (hereinafter also abbreviated as “data”)
                we process for which purposes and in which scope. The privacy
                statement applies to all processing of personal data carried out by
                us, both in the context of providing our services and in particular
                on our websites, in mobile applications and within external online
                presences, such as our social media profiles (hereinafter
                collectively referred to as “online services”).
              </p>
              <p>The terms used are not gender-specific.</p>
              <p>Last Update: 21. March 2023</p>
              <p class="seal">
                <a
                  href="https://datenschutz-generator.de/"
                  title="Legal text by Dr. Schwenke - please click for further information."
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  ><img
                    fetchpriority="high"
                    decoding="async"
                    src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-en.png"
                    alt="Legal text by Dr. Schwenke - please click for further information."
                    width="250"
                    height="250"
                /></a>
              </p>
              <h2>Table of contents</h2>
              <ul class="index">
                <li><a class="index-link" href="#m716">Preamble</a></li>
                <li><a class="index-link" href="#m3">Controller</a></li>
                <li>
                  <a class="index-link" href="#m11"
                    >Contact information of the data protection officer</a
                  >
                </li>
                <li>
                  <a class="index-link" href="#mOverview"
                    >Overview of processing operations</a
                  >
                </li>
                <li>
                  <a class="index-link" href="#m13"
                    >Legal Bases for the Processing</a
                  >
                </li>
                <li><a class="index-link" href="#m27">Security Precautions</a></li>
                <li>
                  <a class="index-link" href="#m25"
                    >Transmission of Personal Data</a
                  >
                </li>
                <li>
                  <a class="index-link" href="#m24"
                    >Data Processing in Third Countries</a
                  >
                </li>
                <li><a class="index-link" href="#m12">Erasure of data</a></li>
                <li><a class="index-link" href="#m134">Use of Cookies</a></li>
                <li><a class="index-link" href="#m317">Business services</a></li>
                <li>
                  <a class="index-link" href="#m225"
                    >Provision of online services and web hosting</a
                  >
                </li>
                <li>
                  <a class="index-link" href="#m182"
                    >Contact and Inquiry Management</a
                  >
                </li>
                <li>
                  <a class="index-link" href="#m391">Communication via Messenger</a>
                </li>
                <li>
                  <a class="index-link" href="#m735"
                    >Video Conferences, Online Meetings, Webinars and
                    Screen-Sharing</a
                  >
                </li>
                <li>
                  <a class="index-link" href="#m210">Job Application Process</a>
                </li>
                <li><a class="index-link" href="#m29">Cloud Services</a></li>
                <li>
                  <a class="index-link" href="#m638"
                    >Commercial communication by E-Mail, Postal Mail, Fax or
                    Telephone</a
                  >
                </li>
                <li>
                  <a class="index-link" href="#m263"
                    >Web Analysis, Monitoring and Optimization</a
                  >
                </li>
                <li><a class="index-link" href="#m264">Online Marketing</a></li>
                <li>
                  <a class="index-link" href="#m136"
                    >Profiles in Social Networks (Social Media)</a
                  >
                </li>
                <li>
                  <a class="index-link" href="#m328"
                    >Plugins and embedded functions and content</a
                  >
                </li>
                <li>
                  <a class="index-link" href="#m15"
                    >Changes and Updates to the Privacy Policy</a
                  >
                </li>
                <li>
                  <a class="index-link" href="#m10">Rights of Data Subjects</a>
                </li>
                <li>
                  <a class="index-link" href="#m42">Terminology and Definitions</a>
                </li>
              </ul>
              <h2 id="m3">Controller</h2>
              <p>
                Dr. Konrad Pfadenhauer <br />Concircle Österreich GmbH<br />Wienerbergstraße
                11<br />1100 Wien<br />Österreich
              </p>
              <p>E-mail address:</p>
              <p>
                <a
                  href="mailto:office@concircle.com"
                  onClick="{window.location.href"
                  ="mailto:office@concircle.com"
                  }
                  >office@concircle.com</a
                >
              </p>
              <p>Legal Notice:</p>
              <p>
                <a
                  href="https://www.concircle.com/en/imprint/"
                  target="_blank"
                  rel="noopener"
                  >https://www.concircle.com/en/imprint/</a
                >
              </p>
              <h2 id="m11">Contact information of the data protection officer</h2>
              <p>
                <a
                  href="mailto:office@concircle.com"
                  onClick="{window.location.href"
                  ="mailto:office@concircle.com"
                  }
                  >office@concircle.com</a
                >
              </p>
              <h2 id="mOverview">Overview of processing operations</h2>
              <p>
                The following table summarises the types of data processed, the
                purposes for which they are processed and the concerned data
                subjects.
              </p>
              <h3>Categories of Processed Data</h3>
              <ul>
                <li>Inventory data.</li>
                <li>Payment Data.</li>
                <li>Location data.</li>
                <li>Contact data.</li>
                <li>Content data.</li>
                <li>Contract data.</li>
                <li>Usage data.</li>
                <li>Meta, communication and process data.</li>
                <li>Job applicant details.</li>
                <li>Event Data (Facebook).</li>
              </ul>
              <h3>Categories of Data Subjects</h3>
              <ul>
                <li>Customers.</li>
                <li>Employees.</li>
                <li>Prospective customers.</li>
                <li>Communication partner.</li>
                <li>Users.</li>
                <li>Job applicants.</li>
                <li>Business and contractual partners.</li>
                <li>Persons depicted.</li>
              </ul>
              <h3>Purposes of Processing</h3>
              <ul>
                <li>Provision of contractual services and customer support.</li>
                <li>Contact requests and communication.</li>
                <li>Security measures.</li>
                <li>Direct marketing.</li>
                <li>Web Analytics.</li>
                <li>Targeting.</li>
                <li>Office and organisational procedures.</li>
                <li>Remarketing.</li>
                <li>Conversion tracking.</li>
                <li>Affiliate Tracking.</li>
                <li>Managing and responding to inquiries.</li>
                <li>Job Application Process.</li>
                <li>Feedback.</li>
                <li>Marketing.</li>
                <li>Profiles with user-related information.</li>
                <li>Provision of our online services and usability.</li>
                <li>Information technology infrastructure.</li>
              </ul>
              <h3 id="m13">Legal Bases for the Processing</h3>
              <p>
                Below you will find an overview of the legal basis of the GDPR on
                the basis of which we process personal data. Please note that in
                addition to the provisions of the GDPR, national data protection
                regulations may apply in your or our country of residence or
                domicile. If, in addition, more specific legal bases are applicable
                in individual cases, we will inform you of these in the data
                protection declaration.
              </p>
              <ul>
                <li>
                  <strong>Consent (Article 6 (1) (a) GDPR)</strong> – The data
                  subject has given consent to the processing of his or her personal
                  data for one or more specific purposes.
                </li>
                <li>
                  <strong
                    >Performance of a contract and prior requests (Article 6 (1) (b)
                    GDPR)</strong
                  >
                  – Performance of a contract to which the data subject is party or
                  in order to take steps at the request of the data subject prior to
                  entering into a contract.
                </li>
                <li>
                  <strong
                    >Compliance with a legal obligation (Article 6 (1) (c)
                    GDPR)</strong
                  >
                  – Processing is necessary for compliance with a legal obligation
                  to which the controller is subject.
                </li>
                <li>
                  <strong>Legitimate Interests (Article 6 (1) (f) GDPR)</strong> –
                  Processing is necessary for the purposes of the legitimate
                  interests pursued by the controller or by a third party, except
                  where such interests are overridden by the interests or
                  fundamental rights and freedoms of the data subject which require
                  protection of personal data.
                </li>
                <li>
                  <strong
                    >Job application process as a pre-contractual or contractual
                    relationship (Article 6 (1)(b) GDPR)</strong
                  >
                  – If special categories of personal data within the meaning of
                  Article 9 (1) GDPR (e.g. health data, such as severely handicapped
                  status or ethnic origin) are requested from applicants within the
                  framework of the application procedure, so that the responsible
                  person or the person concerned can carry out the obligations and
                  exercising specific rights of the controller or of the data
                  subject in the field of employment and social security and social
                  protection law, their processing shall be carried out in
                  accordance with Article 9 (2)(b) GDPR , in the case of the
                  protection of vital interests of applicants or other persons on
                  the basis of Article 9 (2)(c) GDPR or for the purposes of
                  preventive health care or occupational medicine, for the
                  assessment of the employee’s ability to work, for medical
                  diagnostics, care or treatment in the health or social sector or
                  for the administration of systems and services in the health or
                  social sector in accordance with Article 9 (2)(d) GDPR. In the
                  case of a communication of special categories of data based on
                  voluntary consent, their processing is carried out on the basis of
                  Article 9 (2)(a) GDPR.
                </li>
              </ul>
              <p>
                In addition to the data protection regulations of the GDPR, national
                regulations apply to data protection in Austria. This includes in
                particular the Federal Act on the Protection of Individuals with
                regard to the Processing of Personal Data (Data Protection Act –
                DSG). In particular, the Data Protection Act contains special
                provisions on the right of access, rectification or cancellation,
                processing of special categories of personal data, processing for
                other purposes and transmission and automated decision making in
                individual cases.
              </p>
              <h2 id="m27">Security Precautions</h2>
              <p>
                We take appropriate technical and organisational measures in
                accordance with the legal requirements, taking into account the
                state of the art, the costs of implementation and the nature, scope,
                context and purposes of processing as well as the risk of varying
                likelihood and severity for the rights and freedoms of natural
                persons, in order to ensure a level of security appropriate to the
                risk.
              </p>
              <p>
                The measures include, in particular, safeguarding the
                confidentiality, integrity and availability of data by controlling
                physical and electronic access to the data as well as access to,
                input, transmission, securing and separation of the data. In
                addition, we have established procedures to ensure that data
                subjects’ rights are respected, that data is erased, and that we are
                prepared to respond to data threats rapidly. Furthermore, we take
                the protection of personal data into account as early as the
                development or selection of hardware, software and service
                providers, in accordance with the principle of privacy by design and
                privacy by default.
              </p>
              <p>
                Masking of the IP address: If IP addresses are processed by us or by
                the service providers and technologies used and the processing of a
                complete IP address is not necessary, the IP address is shortened
                (also referred to as “IP masking”). In this process, the last two
                digits or the last part of the IP address after a full stop are
                removed or replaced by wildcards. The masking of the IP address is
                intended to prevent the identification of a person by means of their
                IP address or to make such identification significantly more
                difficult.
              </p>
              <p>
                TLS encryption (https): To protect your data transmitted via our
                online services, we use TLS encryption. You can recognize such
                encrypted connections by the prefix https:// in the address bar of
                your browser.
              </p>
              <h2 id="m25">Transmission of Personal Data</h2>
              <p>
                In the context of our processing of personal data, it may happen
                that the data is transferred to other places, companies or persons
                or that it is disclosed to them. Recipients of this data may
                include, for example, service providers commissioned with IT tasks
                or providers of services and content that are embedded in a website.
                In such cases, the legal requirements will be respected and in
                particular corresponding contracts or agreements, which serve the
                protection of your data, will be concluded with the recipients of
                your data.
              </p>
              <p>
                Data Transmission within the Group of Companies: We may transfer
                personal data to other companies within our group of companies or
                otherwise grant them access to this data. Insofar as this disclosure
                is for administrative purposes, the disclosure of the data is based
                on our legitimate business and economic interests or otherwise, if
                it is necessary to fulfill our contractual obligations or if the
                consent of the data subjects or otherwise a legal permission is
                present.
              </p>
              <h2 id="m24">Data Processing in Third Countries</h2>
              <p>
                If we process data in a third country (i.e. outside the European
                Union (EU), the European Economic Area (EEA)) or the processing
                takes place in the context of the use of third party services or
                disclosure or transfer of data to other persons, bodies or
                companies, this will only take place in accordance with the legal
                requirements.
              </p>
              <p>
                Subject to express consent or transfer required by contract or law,
                we process or have processed the data only in third countries with a
                recognised level of data protection, on the basis of special
                guarantees, such as a contractual obligation through so-called
                standard protection clauses of the EU Commission or if
                certifications or binding internal data protection regulations
                justify the processing (Article 44 to 49 GDPR, information page of
                the EU Commission:
                <a
                  href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_en"
                  target="_blank"
                  rel="noopener"
                  >https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_en</a
                >).
              </p>
              <h2 id="m12">Erasure of data</h2>
              <p>
                The data processed by us will be erased in accordance with the
                statutory provisions as soon as their processing is revoked or other
                permissions no longer apply (e.g. if the purpose of processing this
                data no longer applies or they are not required for the purpose). If
                the data is not deleted because they are required for other and
                legally permissible purposes, their processing is limited to these
                purposes. This means that the data will be restricted and not
                processed for other purposes. This applies, for example, to data
                that must be stored for commercial or tax reasons or for which
                storage is necessary to assert, exercise or defend legal claims or
                to protect the rights of another natural or legal person. Our
                further information on individual processing operations may also
                contain additional and specific information on data retention and
                erasure applicable to the respective processing operations.
              </p>
              <h2 id="m134">Use of Cookies</h2>
              <p>
                Cookies are small text files or other data records that store
                information on end devices and read information from the end
                devices. For example, to store the login status in a user account,
                the contents of a shopping cart in an e-shop, the contents accessed
                or the functions used. Cookies can also be used for various
                purposes, e.g. for purposes of functionality, security and
                convenience of online offers as well as the creation of analyses of
                visitor flows.
              </p>
              <p>
                <strong>Information on consent: </strong>We use cookies in
                accordance with the statutory provisions. Therefore, we obtain prior
                consent from users, except when it is not required by law. In
                particular, consent is not required if the storage and reading of
                information, including cookies, is strictly necessary in order to
                provide an information society service explicitly requested by the
                subscriber or user. The revocable consent will be clearly
                communicated to the user and will contain the information on the
                respective cookie use.
              </p>
              <p>
                <strong
                  >Information on legal bases under data protection law: </strong
                >The legal basis under data protection law on which we process
                users’ personal data with the use of cookies depends on whether we
                ask users for consent. If users consent, the legal basis for
                processing their data is their declared consent. Otherwise, the data
                processed with the help of cookies is processed on the basis of our
                legitimate interests (e.g. in a business operation of our online
                services and improvement of its usability) or, if this is done in
                the context of the fulfillment of our contractual obligations, if
                the use of cookies is necessary to fulfill our contractual
                obligations. For which purposes the cookies are processed by us, we
                do clarify in the course of this privacy policy or in the context of
                our consent and processing procedures.
              </p>
              <p>
                <strong>Retention period:</strong> With regard to the retention
                period, a distinction is drawn between the following types of
                cookies:
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <strong
                    >Temporary cookies (also known as “session cookies”):</strong
                  >
                  Temporary cookies are deleted at the latest after a user has left
                  an online service and closed his or her end device (i.e. browser
                  or mobile application).&nbsp;
                </li>
                <li>
                  <strong>Permanent cookies</strong>: Permanent cookies remain
                  stored even after the terminal device is closed. For example, the
                  login status can be saved, or preferred content can be displayed
                  directly when the user visits a website again. Likewise, user data
                  collected with the help of cookies can be used for reach
                  measurement. Unless we provide users with explicit information
                  about the type and storage duration of cookies (e.g., as part of
                  obtaining consent), users should assume that cookies are permanent
                  and that the storage period can be up to two years.
                </li>
              </ul>
              <p>
                <strong
                  >General information on revocation and objection
                  (opt-out):</strong
                >
                Users can revoke the consent they have given at any time and also
                file an objection to processing in accordance with the legal
                requirements in Article 21 GDPR.&nbsp;Users can also declare their
                objection by means of the settings of their browser, e.g. by
                deactivating the use of cookies (whereby this may also limit the
                functionality of our online services). An objection to the use of
                cookies for online marketing purposes, can also be declared via the
                websites
                <a
                  href="https://optout.aboutads.info"
                  target="_blank"
                  rel="noopener"
                  >https://optout.aboutads.info</a
                >
                and
                <a
                  href="https://www.youronlinechoices.com/"
                  target="_blank"
                  rel="noopener"
                  >https://www.youronlinechoices.com/.</a
                >
              </p>
              <p><strong>Cookie Settings/ Opt-Out:</strong></p>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processing Cookie Data on the Basis of Consent: </strong
                  >We use a cookie management solution in which users’ consent to
                  the use of cookies, or the procedures and providers mentioned in
                  the cookie management solution, can be obtained, managed and
                  revoked by the users. The declaration of consent is stored so that
                  it does not have to be retrieved again and the consent can be
                  proven in accordance with the legal obligation. Storage can take
                  place server-sided and/or in a cookie (so-called opt-out cookie or
                  with the aid of comparable technologies) in order to be able to
                  assign the consent to a user or and/or his/her device. Subject to
                  individual details of the providers of cookie management services,
                  the following information applies: The duration of the storage of
                  the consent can be up to two years. In this case, a pseudonymous
                  user identifier is formed and stored with the date/time of
                  consent, information on the scope of the consent (e.g. which
                  categories of cookies and/or service providers) as well as the
                  browser, system and used end device.
                </li>
                <li>
                  <strong>List of cookies used on our website:<br /></strong>
                  <table class="cookielawinfo-row-cat-table cookielawinfo-classic">
                    <thead>
                      <tr>
                        <th class="cookielawinfo-column-1">Cookie</th>
                        <th class="cookielawinfo-column-3">Duration</th>
                        <th class="cookielawinfo-column-4">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">token</td>
                        <td class="cookielawinfo-column-3">No Experation Date</td>
                        <td class="cookielawinfo-column-4">
                            This cookie is set by the application and stores the token. This token is necessary to authenticate you against our application.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">profiles</td>
                        <td class="cookielawinfo-column-3">No Experation Date</td>
                        <td class="cookielawinfo-column-4">
                            This cookie is set by the application and stores the profiles. These profiles are necessary to use the application.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">lastActivityTime</td>
                        <td class="cookielawinfo-column-3">No Experation Date</td>
                        <td class="cookielawinfo-column-4">
                            This cookie is set by the application and stores the last active time. It is used to potentially log you out.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">cookieConsent</td>
                        <td class="cookielawinfo-column-3">1 year</td>
                        <td class="cookielawinfo-column-4">
                            This cookie is set by the application and stores the user's consent to the cookies.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">cookieNeccesary</td>
                        <td class="cookielawinfo-column-3">1 year</td>
                        <td class="cookielawinfo-column-4">
                            This cookie is set by the application and is used to capture the user's consent to the "Necessary" category cookies.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">ARRAffinitySameSite</td>
                        <td class="cookielawinfo-column-3">Session</td>
                        <td class="cookielawinfo-column-4">
                            This cookie is set by Microsoft Azure. It is necessary to route requests to the correct instance in a load-balanced environment.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">
                            __stripe_mid
                        </td>
                        <td class="cookielawinfo-column-3">1 year</td>
                        <td class="cookielawinfo-column-4">
                            Set for fraud prevention purposes and helps us assess the risk associated with an attempted transaction.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">
                          cookielawinfo-checkbox-analytics
                        </td>
                        <td class="cookielawinfo-column-3">1 year</td>
                        <td class="cookielawinfo-column-4">
                          Set by the GDPR Cookie Consent plugin, this cookie is used
                          to record the user consent for the cookies in the
                          "Analytics" category .
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">
                            __stripe_sid
                        </td>
                        <td class="cookielawinfo-column-3">30 minutes</td>
                        <td class="cookielawinfo-column-4">
                            Set for fraud prevention purposes and helps us assess the risk associated with an attempted transaction.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">
                            cookie-perms
                        </td>
                        <td class="cookielawinfo-column-3">6 months</td>
                        <td class="cookielawinfo-column-4">
                            Stores a user's cookie permissions.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">
                            machine_identifier
                        </td>
                        <td class="cookielawinfo-column-3">1 year</td>
                        <td class="cookielawinfo-column-4">
                            To prevent fraud, enables Stripe to determine the computer being used on the Stripe Dashboard.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">
                            private_machine_identifier
                        </td>
                        <td class="cookielawinfo-column-3">1 year</td>
                        <td class="cookielawinfo-column-4">
                            Identifies the computer across login sessions and users to prevent fraud.
                        </td>
                      </tr>
                      <tr class="cookielawinfo-row">
                        <td class="cookielawinfo-column-1">stripe.csrf</td>
                        <td class="cookielawinfo-column-3">Session</td>
                        <td class="cookielawinfo-column-4">
                            Prevents attackers from making requests that change user data to Stripe from other websites.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
              <h2 id="m317">Business services</h2>
              <p>
                We process data of our contractual and business partners, e.g.
                customers and interested parties (collectively referred to as
                “contractual partners”) within the context of contractual and
                comparable legal relationships as well as associated actions and
                communication with the contractual partners or pre-contractually,
                e.g. to answer inquiries.
              </p>
              <p>
                We process this data in order to fulfill our contractual
                obligations. These include, in particular, the obligations to
                provide the agreed services, any update obligations and remedies in
                the event of warranty and other service disruptions. In addition, we
                process the data to protect our rights and for the purpose of
                administrative tasks associated with these obligations and company
                organization. Furthermore, we process the data on the basis of our
                legitimate interests in proper and economical business management as
                well as security measures to protect our contractual partners and
                our business operations from misuse, endangerment of their data,
                secrets, information and rights (e.g. for the involvement of
                telecommunications, transport and other auxiliary services as well
                as subcontractors, banks, tax and legal advisors, payment service
                providers or tax authorities). Within the framework of applicable
                law, we only disclose the data of contractual partners to third
                parties to the extent that this is necessary for the aforementioned
                purposes or to fulfill legal obligations. Contractual partners will
                be informed about further forms of processing, e.g. for marketing
                purposes, within the scope of this privacy policy.
              </p>
              <p>
                Which data are necessary for the aforementioned purposes, we inform
                the contracting partners before or in the context of the data
                collection, e.g. in online forms by special marking (e.g. colors),
                and/or symbols (e.g. asterisks or the like), or personally.
              </p>
              <p>
                We delete the data after expiry of statutory warranty and comparable
                obligations, i.e. in principle after expiry of 4 years, unless the
                data is stored in a customer account or must be kept for legal
                reasons of archiving. The statutory retention period for documents
                relevant under tax law as well as for commercial books, inventories,
                opening balance sheets, annual financial statements, the
                instructions required to understand these documents and other
                organizational documents and accounting records is ten years and for
                received commercial and business letters and reproductions of sent
                commercial and business letters six years. The period begins at the
                end of the calendar year in which the last entry was made in the
                book, the inventory, the opening balance sheet, the annual financial
                statements or the management report was prepared, the commercial or
                business letter was received or sent, or the accounting document was
                created, furthermore the record was made or the other documents were
                created.
              </p>
              <p>
                If we use third-party providers or platforms to provide our
                services, the terms and conditions and privacy policies of the
                respective third-party providers or platforms shall apply in the
                relationship between the users and the providers.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Inventory data (e.g. names,
                  addresses); Payment Data (e.g. bank details, invoices, payment
                  history); Contact data (e.g. e-mail, telephone numbers); Contract
                  data (e.g. contract object, duration, customer category).
                </li>
                <li>
                  <strong>Data subjects:</strong> Prospective customers; Business
                  and contractual partners; Customers.
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Provision of contractual
                  services and customer support; Contact requests and communication;
                  Office and organisational procedures; Managing and responding to
                  inquiries.
                </li>
                <li>
                  <strong>Legal Basis:</strong> Performance of a contract and prior
                  requests (Article 6 (1) (b) GDPR); Compliance with a legal
                  obligation (Article 6 (1) (c) GDPR); Legitimate Interests (Article
                  6 (1) (f) GDPR).
                </li>
              </ul>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Consulting: </strong>We process the data of our clients,
                  clients as well as interested parties and other clients or
                  contractual partners (uniformly referred to as “clients”) in order
                  to provide them with our consulting services. The data processed,
                  the type, scope and purpose of the processing and the necessity of
                  its processing are determined by the underlying contractual and
                  client relationship.
                  <p>&nbsp;</p>
                  <p>
                    Insofar as it is necessary for the fulfilment of our contract,
                    for the protection of vital interests or by law, or with the
                    consent of the client, we disclose or transfer the client’s data
                    to third parties or agents, such as authorities, courts,
                    subcontractors or in the field of IT, office or comparable
                    services, taking into account the professional requirements;
                    <strong>Legal Basis:</strong> Performance of a contract and
                    prior requests (Article 6 (1) (b) GDPR).
                  </p>
                </li>
                <li>
                  <strong>Project and Development Services: </strong>We process the
                  data of our customers and clients (hereinafter uniformly referred
                  to as “customers”) in order to enable them to select, acquire or
                  commission the selected services or works as well as associated
                  activities and to pay for and make available such services or
                  works or to perform such services or works.
                  <p>
                    The required information is indicated as such within the
                    framework of the conclusion of the agreement, order or
                    equivalent contract and includes the information required for
                    the provision of services and invoicing as well as contact
                    information in order to be able to hold any consultations.
                    Insofar as we gain access to the information of end customers,
                    employees or other persons, we process it in accordance with the
                    legal and contractual requirements;
                    <strong>Legal Basis:</strong> Performance of a contract and
                    prior requests (Article 6 (1) (b) GDPR).
                  </p>
                </li>
                <li>
                  <strong>Software and Platform Services: </strong>We process the
                  data of our users, registered and any test users (hereinafter
                  uniformly referred to as “users”) in order to provide them with
                  our contractual services and on the basis of legitimate interests
                  to ensure the security of our offer and to develop it further. The
                  required details are identified as such within the context of the
                  conclusion of the agreement, order or comparable contract and
                  include the details required for the provision of services and
                  invoicing as well as contact information in order to be able to
                  hold any further consultations;
                  <strong>Legal Basis:</strong> Performance of a contract and prior
                  requests (Article 6 (1) (b) GDPR).
                </li>
                <li>
                  <strong>Technical and Engineering services: </strong>We process
                  the data of our customers and clients (hereinafter uniformly
                  referred to as “customers”) in order to enable them to select,
                  acquire or commission the selected services or works as well as
                  associated activities and to pay for and make available such
                  services or works or to perform such services or works.
                  <p>
                    The required information is indicated as such within the
                    framework of the conclusion of the agreement, order or
                    equivalent contract and includes the information required for
                    the provision of services and invoicing as well as contact
                    information in order to be able to hold any consultations.
                    Insofar as we gain access to the information of end customers,
                    employees or other persons, we process it in accordance with the
                    legal and contractual requirements;
                    <strong>Legal Basis:</strong> Performance of a contract and
                    prior requests (Article 6 (1) (b) GDPR).
                  </p>
                </li>
                <li>
                  <strong>Consulting: </strong>Insofar as it is necessary for our
                  contractual performance or required by law, or if the consent of
                  the customer has been obtained, we disclose or transfer the
                  customer’s data to third parties or agents, such as authorities,
                  courts or in the field of IT, office or comparable services, in
                  compliance with the contractual and legal requirements;
                  <strong>Legal Basis:</strong> Performance of a contract and prior
                  requests (Article 6 (1) (b) GDPR).
                </li>
                <li>
                  <strong>Events: </strong>We process the data of the participants
                  of the events, events and similar activities offered or organized
                  by us (hereinafter uniformly referred to as “participants” and
                  “events”) in order to enable them to participate in the events and
                  to make use of the services or actions associated with their
                  participation.
                  <p>
                    Insofar as we process health-related data, religious, political
                    or other special categories of data in this context, this is
                    done within the framework of disclosure (e.g. for thematically
                    oriented events or serves health care, security or is done with
                    the consent of the data subjects).
                  </p>
                  <p>
                    The necessary information is identified as such in the context
                    of the conclusion of the agreement, booking or comparable
                    contract and includes the information required for the provision
                    of services and billing as well as contact information in order
                    to be able to hold any enquiries. Insofar as we gain access to
                    information of end customers, employees or other persons, we
                    process this in accordance with the legal and contractual
                    requirements; <strong>Legal Basis:</strong> Performance of a
                    contract and prior requests (Article 6 (1) (b) GDPR).
                  </p>
                </li>
              </ul>
              <h2 id="m225">Provision of online services and web hosting</h2>
              <p>
                We process user data in order to be able to provide them with our
                online services. For this purpose, we process the IP address of the
                user, which is necessary to transmit the content and functions of
                our online services to the user’s browser or terminal device.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Usage data (e.g. websites
                  visited, interest in content, access times); Meta, communication
                  and process data (e.g. IP addresses, time information,
                  identification numbers, consent status).
                </li>
                <li>
                  <strong>Data subjects:</strong> Users (e.g. website visitors,
                  users of online services).
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Provision of our online
                  services and usability; Information technology infrastructure
                  (Operation and provision of information systems and technical
                  devices, such as computers, servers, etc.).); Security measures.
                </li>
                <li>
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR).
                </li>
              </ul>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Collection of Access Data and Log Files: </strong>The
                  access to our online services is logged in the form of so-called
                  “server log files”. Server log files may include the address and
                  name of the web pages and files accessed, the date and time of
                  access, data volumes transferred, notification of successful
                  access, browser type and version, the user’s operating system,
                  referrer URL (the previously visited page) and, as a general rule,
                  IP addresses and the requesting provider.<br />The server log
                  files can be used for security purposes, e.g. to avoid overloading
                  the servers (especially in the case of abusive attacks, so-called
                  DDoS attacks) and to ensure the stability and optimal load
                  balancing of the servers; <strong>Legal Basis:</strong> Legitimate
                  Interests (Article 6 (1) (f) GDPR);
                  <strong>Retention period:</strong> Log file information is stored
                  for a maximum period of 30 days and then deleted or anonymized.
                  Data, the further storage of which is necessary for evidence
                  purposes, are excluded from deletion until the respective incident
                  has been finally clarified.
                </li>
              </ul>
              <h2 id="m182">Contact and Inquiry Management</h2>
              <p>
                When contacting us (e.g. via mail, contact form, e-mail, telephone
                or via social media) as well as in the context of existing user and
                business relationships, the information of the inquiring persons is
                processed to the extent necessary to respond to the contact requests
                and any requested measures.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Contact data (e.g. e-mail,
                  telephone numbers); Content data (e.g. text input, photographs,
                  videos); Usage data (e.g. websites visited, interest in content,
                  access times); Meta, communication and process data (e.g. IP
                  addresses, time information, identification numbers, consent
                  status).
                </li>
                <li>
                  <strong>Data subjects:</strong> Communication partner (Recipients
                  of e-mails, letters, etc.).
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Contact requests and
                  communication; Managing and responding to inquiries; Feedback
                  (e.g. collecting feedback via online form); Provision of our
                  online services and usability.
                </li>
                <li>
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); Performance of a contract and prior requests (Article 6
                  (1) (b) GDPR).
                </li>
              </ul>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Contact form: </strong>When users contact us via our
                  contact form, e-mail or other communication channels, we process
                  the data provided to us in this context to process the
                  communicated request; <strong>Legal Basis:</strong> Performance of
                  a contract and prior requests (Article 6 (1) (b) GDPR), Legitimate
                  Interests (Article 6 (1) (f) GDPR).
                </li>
              </ul>
              <h2 id="m391">Communication via Messenger</h2>
              <p>
                We use messenger services for communication purposes and therefore
                ask you to observe the following information regarding the
                functionality of the messenger services, encryption, use of the
                metadata of the communication and your objection options.
              </p>
              <p>
                You can also contact us by alternative means, e.g. telephone or
                e-mail. Please use the contact options provided to you or use the
                contact options provided within our online services.
              </p>
              <p>
                In the case of encryption of content (i.e. the content of your
                message and attachments), we point out that the communication
                content (i.e. the content of the message and attachments) is
                encrypted end-to-end. This means that the content of the messages is
                not visible, not even by the messenger service providers themselves.
                You should always use a current version of the messenger service
                with activated encryption, so that the encryption of the message
                contents is guaranteed.
              </p>
              <p>
                However, we would like to point out to our communication partners
                that although messenger service providers do not see the content,
                they can find out that and when communication partners communicate
                with us and process technical information on the communication
                partner’s device used and, depending on the settings of their
                device, also location information (so-called metadata).
              </p>
              <p>
                <strong>Information on Legal basis: </strong> If we ask
                communication partners for permission before communicating with them
                via messenger services, the legal basis of our processing of their
                data is their consent. Otherwise, if we do not request consent and
                you contact us, for example, voluntarily, we use messenger services
                in our dealings with our contractual partners and as part of the
                contract initiation process as a contractual measure and in the case
                of other interested parties and communication partners on the basis
                of our legitimate interests in fast and efficient communication and
                meeting the needs of our communication partners for communication
                via messenger services. We would also like to point out that we do
                not transmit the contact data provided to us to the messenger
                service providers for the first time without your consent.
              </p>
              <p>
                <strong>Withdrawal, objection and deletion: </strong> You can
                withdraw your consent or object to communication with us via
                messenger services at any time. In the case of communication via
                messenger services, we delete the messages in accordance with our
                general data retention policy (i.e. as described above after the end
                of contractual relationships, archiving requirements, etc.) and
                otherwise as soon as we can assume that we have answered any
                information provided by the communication partners, if no reference
                to a previous conversation is to be expected and there are no legal
                obligations to store the messages to prevent their deletion.
              </p>
              <p>
                <strong
                  >Reservation of reference to other means of communication:</strong
                >
                Finally, we would like to point out that we reserve the right, for
                reasons of your safety, not to answer inquiries about messenger
                services. This is the case if, for example, internal contractual
                matters require special secrecy or if an answer via the messenger
                services does not meet the formal requirements. In such cases we
                refer you to more appropriate communication channels.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Contact data (e.g. e-mail,
                  telephone numbers); Usage data (e.g. websites visited, interest in
                  content, access times); Meta, communication and process data (e.g.
                  IP addresses, time information, identification numbers, consent
                  status).
                </li>
                <li>
                  <strong>Data subjects:</strong> Communication partner (Recipients
                  of e-mails, letters, etc.).
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Contact requests and
                  communication; Direct marketing (e.g. by e-mail or postal).
                </li>
                <li>
                  <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);
                  Legitimate Interests (Article 6 (1) (f) GDPR).
                </li>
              </ul>
              <h2 id="m735">
                Video Conferences, Online Meetings, Webinars and Screen-Sharing
              </h2>
              <p>
                We use platforms and applications of other providers (hereinafter
                referred to as “Conference Platforms”) for the purpose of conducting
                video and audio conferences, webinars and other types of video and
                audio meetings (hereinafter collectively referred to as
                “Conference”). When using the Conference Platforms and their
                services, we comply with the legal requirements.
              </p>
              <p>
                <strong>Data processed by Conference Platforms:</strong> In the
                course of participation in a Conference, the Data of the
                participants listed below are processed. The scope of the processing
                depends, on the one hand, on which data is requested in the context
                of a specific Conference (e.g., provision of access data or clear
                names) and which optional information is provided by the
                participants. In addition to processing for the purpose of
                conducting the conference, participants’ Data may also be processed
                by the Conference Platforms for security purposes or service
                optimization. The processed Date includes personal information
                (first name, last name), contact information (e-mail address,
                telephone number), access data (access codes or passwords), profile
                pictures, information on professional position/function, the IP
                address of the internet access, information on the participants’ end
                devices, their operating system, the browser and its technical and
                linguistic settings, information on the content-related
                communication processes, i.e. entries in chats and audio and video
                data, as well as the use of other available functions (e.g.
                surveys). The content of communications is encrypted to the extent
                technically provided by the conference providers. If participants
                are registered as users with the Conference Platforms, then further
                data may be processed in accordance with the agreement with the
                respective Conference Provider.
              </p>
              <p>
                <strong>Logging and recording:</strong> If text entries,
                participation results (e.g. from surveys) as well as video or audio
                recordings are recorded, this will be transparently communicated to
                the participants in advance and they will be asked – if necessary –
                for their consent.
              </p>
              <p>
                <strong>Data protection measures of the participants:</strong>
                Please refer to the data privacy information of the Conference
                Platforms for details on the processing of your data and select the
                optimum security and data privacy settings for you within the
                framework of the settings of the conference platforms. Furthermore,
                please ensure data and privacy protection in the background of your
                recording for the duration of a Conference (e.g., by notifying
                roommates, locking doors, and using the background masking function,
                if technically possible). Links to the conference rooms as well as
                access data, should not be passed on to unauthorized third parties.
              </p>
              <p>
                <strong>Notes on legal bases:</strong> Insofar as, in addition to
                the Conference Platforms, we also process users’ data and ask users
                for their consent to use contents from the Conferences or certain
                functions (e.g. consent to a recording of Conferences), the legal
                basis of the processing is this consent. Furthermore, our processing
                may be necessary for the fulfillment of our contractual obligations
                (e.g. in participant lists, in the case of reprocessing of
                Conference results, etc.). Otherwise, user data is processed on the
                basis of our legitimate interests in efficient and secure
                communication with our communication partners.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Inventory data (e.g. names,
                  addresses); Contact data (e.g. e-mail, telephone numbers); Content
                  data (e.g. text input, photographs, videos); Usage data (e.g.
                  websites visited, interest in content, access times); Meta,
                  communication and process data (e.g. IP addresses, time
                  information, identification numbers, consent status).
                </li>
                <li>
                  <strong>Data subjects:</strong> Communication partner (Recipients
                  of e-mails, letters, etc.); Users (e.g. website visitors, users of
                  online services); Persons depicted.
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Provision of contractual
                  services and customer support; Contact requests and communication;
                  Office and organisational procedures.
                </li>
                <li>
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR).
                </li>
              </ul>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Adobe Connect: </strong>Conference and webinar software;
                  <strong>Service provider</strong>: Adobe Systems Software Ireland
                  Limited, 4-6 Riverwalk, Citywest Business Campus, Dublin 24,
                  Ireland; <strong>Legal Basis:</strong> Legitimate Interests
                  (Article 6 (1) (f) GDPR); <strong>Website:</strong>
                  <a
                    href="https://www.adobe.com/de/products/adobeconnect.html"
                    target="_blank"
                    rel="noopener"
                    >https://www.adobe.com/de/products/adobeconnect.html</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://www.adobe.com/de/privacy.html"
                    target="_blank"
                    rel="noopener"
                    >https://www.adobe.com/de/privacy.html</a
                  >.
                </li>
                <li>
                  <strong>Cisco WebEx: </strong>Konferenz-Software;
                  <strong>Service provider</strong>: Webex Communications
                  Deutschland GmbH, Hansaallee 249, c/o Cisco Systems GmbH, 40549
                  Düsseldorf, parent company: Cisco Systems, Inc. 170 West Tasman
                  Dr., San Jose, CA 95134, USA;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a href="https://www.webex.com" target="_blank" rel="noopener"
                    >https://www.webex.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html"
                    target="_blank"
                    rel="noopener"
                    >https://www.cisco.com/c/en/us/about/legal/privacy-full.html</a
                  >.
                </li>
                <li>
                  <strong>Google Hangouts / Meet: </strong>Messenger and conference
                  software; <strong>Service provider</strong>: Google Ireland
                  Limited, Gordon House, Barrow Street, Dublin 4, Ireland;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a
                    href="https://hangouts.google.com/"
                    target="_blank"
                    rel="noopener"
                    >https://hangouts.google.com/</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >; <strong>Data Processing Agreement:</strong>
                  <a
                    href="https://cloud.google.com/terms/data-processing-addendum"
                    target="_blank"
                    rel="noopener"
                    >https://cloud.google.com/terms/data-processing-addendum</a
                  >;
                  <strong
                    >Standard Contractual Clauses (Safeguarding the level of data
                    protection when processing data in third countries):</strong
                  >
                  <a
                    href="https://cloud.google.com/terms/eu-model-contract-clause"
                    target="_blank"
                    rel="noopener"
                    >https://cloud.google.com/terms/eu-model-contract-clause</a
                  >.
                </li>
                <li>
                  <strong>Microsoft Teams: </strong>Messenger and conference
                  software; <strong>Service provider</strong>: Microsoft Ireland
                  Operations Limited, One Microsoft Place, South County Business
                  Park, Leopardstown, Dublin 18, Ireland, parent company: Microsoft
                  Corporation, One Microsoft Way, Redmond, WA 98052-6399 USA;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a
                    href="https://www.microsoft.com/de-de/microsoft-365"
                    target="_blank"
                    rel="noopener"
                    >https://www.microsoft.com/de-de/microsoft-365</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://privacy.microsoft.com/de-de/privacystatement"
                    target="_blank"
                    rel="noopener"
                    >https://privacy.microsoft.com/de-de/privacystatement</a
                  >, Security information:
                  <a
                    href="https://www.microsoft.com/de-de/trustcenter"
                    target="_blank"
                    rel="noopener"
                    >https://www.microsoft.com/de-de/trustcenter</a
                  >;
                  <strong
                    >Standard Contractual Clauses (Safeguarding the level of data
                    protection when processing data in third countries):</strong
                  >
                  <a
                    href="https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA"
                    target="_blank"
                    rel="noopener"
                    >https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA</a
                  >.
                </li>
                <li>
                  <strong>Skype: </strong>Messenger and conference software;
                  <strong>Service provider</strong>: Microsoft Corporation, One
                  Microsoft Way, Redmond, WA 98052-6399 USA;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a href="https://www.skype.com" target="_blank" rel="noopener"
                    >https://www.skype.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://privacy.microsoft.com/de-de/privacystatement"
                    target="_blank"
                    rel="noopener"
                    >https://privacy.microsoft.com/de-de/privacystatement</a
                  >, Security information:
                  <a
                    href="https://www.microsoft.com/de-de/trustcenter"
                    target="_blank"
                    rel="noopener"
                    >https://www.microsoft.com/de-de/trustcenter</a
                  >.
                </li>
                <li>
                  <strong>TeamViewer: </strong>Conference Software;
                  <strong>Service provider</strong>: TeamViewer GmbH, Jahnstr. 30,
                  73037 Göppingen, Germany; <strong>Legal Basis:</strong> Legitimate
                  Interests (Article 6 (1) (f) GDPR); <strong>Website:</strong>
                  <a
                    href="https://www.teamviewer.com/en/"
                    target="_blank"
                    rel="noopener"
                    >https://www.teamviewer.com/en/</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://www.teamviewer.com/en/privacy-policy/"
                    target="_blank"
                    rel="noopener"
                    >https://www.teamviewer.com/en/privacy-policy/</a
                  >.
                </li>
                <li>
                  <strong>Zoom: </strong>Video Conferencing, Web Conferencing and
                  Webinars; <strong>Service provider</strong>: Zoom Video
                  Communications, Inc., 55 Almaden Blvd., Suite 600, San Jose, CA
                  95113, USA; <strong>Legal Basis:</strong> Legitimate Interests
                  (Article 6 (1) (f) GDPR); <strong>Website:</strong>
                  <a href="https://zoom.us" target="_blank" rel="noopener"
                    >https://zoom.us</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://zoom.us/docs/de-de/privacy-and-legal.html"
                    target="_blank"
                    rel="noopener"
                    >https://zoom.us/docs/de-de/privacy-and-legal.html</a
                  >; <strong>Data Processing Agreement:</strong>
                  <a
                    href="https://zoom.us/docs/de-de/privacy-and-legal.html"
                    target="_blank"
                    rel="noopener"
                    >https://zoom.us/docs/de-de/privacy-and-legal.html</a
                  >
                  (referred to as Global DPA);
                  <strong
                    >Standard Contractual Clauses (Safeguarding the level of data
                    protection when processing data in third countries):</strong
                  >
                  <a
                    href="https://zoom.us/docs/de-de/privacy-and-legal.html"
                    target="_blank"
                    rel="noopener"
                    >https://zoom.us/docs/de-de/privacy-and-legal.html</a
                  >
                  (referred to as Global DPA).
                </li>
              </ul>
              <h2 id="m210">Job Application Process</h2>
              <p>
                The application process requires applicants to provide us with the
                data necessary for their assessment and selection. The information
                required can be found in the job description or, in the case of
                online forms, in the information contained therein.
              </p>
              <p>
                In principle, the required information includes personal information
                such as name, address, a contact option and proof of the
                qualifications required for a particular employment. Upon request,
                we will be happy to provide you with additional information.
              </p>
              <p>
                If made available, applicants can submit their applications via an
                online form. The data will be transmitted to us encrypted according
                to the state of the art. Applicants can also send us their
                applications by e-mail. Please note, however, that e-mails on the
                Internet are generally not sent in encrypted form. As a rule,
                e-mails are encrypted during transport, but not on the servers from
                which they are sent and received. We can therefore accept no
                responsibility for the transmission path of the application between
                the sender and the reception on our server. For the purposes of
                searching for applicants, submitting applications and selecting
                applicants, we may make use of the applicant management and
                recruitment software, platforms and services of third-party
                providers in compliance with legal requirements. Applicants are
                welcome to contact us about how to submit their application or send
                it to us by regular mail.
              </p>
              <p>
                <strong>Processing of special categories of data:</strong> If
                special categories of personal data within the meaning of Article 9
                (1) GDPR (e.g. health data, such as severely handicapped status or
                ethnic origin) are requested from applicants within the framework of
                the application procedure, so that the responsible person or the
                person concerned can exercise his/her rights arising from labour law
                and social security and social protection law and fulfil his/her
                duties in this regard, their processing shall be carried out in
                accordance with Article 9 (1)(b) GDPR, in the case of the protection
                of vital interests of applicants or other persons pursuant to
                Article 9 (1)(c) GDPR or for the purposes of preventive health care
                or occupational medicine, for the assessment of the employee’s
                ability to work, for medical diagnostics, care or treatment in the
                health or social sector or for the administration of systems and
                services in the health or social sector in accordance with Article 9
                (1)(h) GDPR. In the case of a communication of special categories of
                data based on voluntary consent, their processing is carried out on
                the basis of Article 9 (1)(a) GDPR.
              </p>
              <p>
                <strong>Ereasure of data:</strong> In the event of a successful
                application, the data provided by the applicants may be further
                processed by us for the purposes of the employment relationship.
                Otherwise, if the application for a job offer is not successful, the
                applicant’s data will be deleted. Applicants’ data will also be
                deleted if an application is withdrawn, to which applicants are
                entitled at any time. Subject to a justified revocation by the
                applicant, the deletion will take place at the latest after the
                expiry of a period of six months, so that we can answer any
                follow-up questions regarding the application and comply with our
                duty of proof under the regulations on equal treatment of
                applicants. Invoices for any reimbursement of travel expenses are
                archived in accordance with tax regulations.
              </p>
              <p>
                <strong>Admission to a talent pool – </strong>Admission to an talent
                pool, if offered, is based on consent. Applicants are informed that
                their consent to be included in the talent pool is voluntary, has no
                influence on the current application process and that they can
                revoke their consent at any time for the future.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Inventory data (e.g. names,
                  addresses); Contact data (e.g. e-mail, telephone numbers); Content
                  data (e.g. text input, photographs, videos); Job applicant details
                  (e.g. Personal data, postal and contact addresses and the
                  documents pertaining to the application and the information
                  contained therein, such as cover letter, curriculum vitae,
                  certificates, etc., as well as other information on the person or
                  qualifications of applicants provided with regard to a specific
                  job or voluntarily by applicants).
                </li>
                <li><strong>Data subjects:</strong> Job applicants.</li>
                <li>
                  <strong>Purposes of Processing:</strong> Job Application Process
                  (Establishment and possible later execution as well as possible
                  later termination of the employment relationship).
                </li>
                <li>
                  <strong>Legal Basis:</strong> Job application process as a
                  pre-contractual or contractual relationship (Article 6 (1)(b)
                  GDPR); Legitimate Interests (Article 6 (1) (f) GDPR).
                </li>
              </ul>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong>LinkedIn Recruiter: </strong>Job search and application
                  related services within the LinkedIn platform;
                  <strong>Service provider</strong>: LinkedIn Ireland Unlimited
                  Company, Wilton Plaza Wilton Place, Dublin 2, Ireland;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a href="https://www.linkedin.com" target="_blank" rel="noopener"
                    >https://www.linkedin.com</a
                  >; <strong>Terms &amp; Conditions:</strong>
                  <a
                    href="https://legal.linkedin.com/dpa"
                    target="_blank"
                    rel="noopener"
                    >https://legal.linkedin.com/dpa</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://www.linkedin.com/legal/privacy-policy"
                    target="_blank"
                    rel="noopener"
                    >https://www.linkedin.com/legal/privacy-policy</a
                  >; <strong>Data Processing Agreement:</strong>
                  <a
                    href="https://legal.linkedin.com/dpa"
                    target="_blank"
                    rel="noopener"
                    >https://legal.linkedin.com/dpa</a
                  >.
                </li>
                <li>
                  <strong>Xing: </strong>Job search and application related services
                  within the Xing platform; <strong>Service provider</strong>: New
                  Work SE, Am Strandkai 1, 20457 Hamburg, Germany;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a href="https://www.xing.com" target="_blank" rel="noopener"
                    >https://www.xing.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://privacy.xing.com/en/privacy-policy"
                    target="_blank"
                    rel="noopener"
                    >https://privacy.xing.com/en/privacy-policy</a
                  >.
                </li>
              </ul>
              <h2 id="m29">Cloud Services</h2>
              <p>
                We use Internet-accessible software services (so-called “cloud
                services”, also referred to as “Software as a Service”) provided on
                the servers of its providers for the storage and management of
                content (e.g. document storage and management, exchange of
                documents, content and information with certain recipients or
                publication of content and information).
              </p>
              <p>
                Within this framework, personal data may be processed and stored on
                the provider’s servers insofar as this data is part of communication
                processes with us or is otherwise processed by us in accordance with
                this privacy policy. This data may include in particular master data
                and contact data of data subjects, data on processes, contracts,
                other proceedings and their contents. Cloud service providers also
                process usage data and metadata that they use for security and
                service optimization purposes.
              </p>
              <p>
                If we use cloud services to provide documents and content to other
                users or publicly accessible websites, forms, etc., providers may
                store cookies on users’ devices for web analysis or to remember user
                settings (e.g. in the case of media control).
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Inventory data (e.g. names,
                  addresses); Contact data (e.g. e-mail, telephone numbers); Content
                  data (e.g. text input, photographs, videos); Usage data (e.g.
                  websites visited, interest in content, access times); Meta,
                  communication and process data (e.g. IP addresses, time
                  information, identification numbers, consent status).
                </li>
                <li>
                  <strong>Data subjects:</strong> Customers; Employees (e.g.
                  Employees, job applicants); Prospective customers; Communication
                  partner (Recipients of e-mails, letters, etc.).
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Office and organisational
                  procedures; Information technology infrastructure (Operation and
                  provision of information systems and technical devices, such as
                  computers, servers, etc.).).
                </li>
                <li>
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR).
                </li>
              </ul>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Apple iCloud: </strong>Cloud storage service;
                  <strong>Service provider</strong>: Apple Inc., Infinite Loop,
                  Cupertino, CA 95014, USA; <strong>Legal Basis:</strong> Legitimate
                  Interests (Article 6 (1) (f) GDPR); <strong>Website:</strong>
                  <a href="https://www.apple.com" target="_blank" rel="noopener"
                    >https://www.apple.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://www.apple.com/legal/privacy/en-ww/"
                    target="_blank"
                    rel="noopener"
                    >https://www.apple.com/legal/privacy/en-ww/</a
                  >.
                </li>
                <li>
                  <strong>Microsoft Cloud Services: </strong>Cloud storage, cloud
                  infrastructure services and cloud-based application software;
                  <strong>Service provider</strong>: Microsoft Ireland Operations
                  Limited, One Microsoft Place, South County Business Park,
                  Leopardstown, Dublin 18, Ireland, parent company: Microsoft
                  Corporation, One Microsoft Way, Redmond, WA 98052-6399 USA;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a href="https://microsoft.com" target="_blank" rel="noopener"
                    >https://microsoft.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://privacy.microsoft.com/de-de/privacystatement"
                    target="_blank"
                    rel="noopener"
                    >https://privacy.microsoft.com/de-de/privacystatement</a
                  >, Security information:
                  <a
                    href="https://www.microsoft.com/de-de/trustcenter"
                    target="_blank"
                    rel="noopener"
                    >https://www.microsoft.com/de-de/trustcenter</a
                  >; <strong>Data Processing Agreement:</strong>
                  <a
                    href="https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA"
                    target="_blank"
                    rel="noopener"
                    >https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA</a
                  >;
                  <strong
                    >Standard Contractual Clauses (Safeguarding the level of data
                    protection when processing data in third countries):</strong
                  >
                  <a
                    href="https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA"
                    target="_blank"
                    rel="noopener"
                    >https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA</a
                  >.
                </li>
              </ul>
              <h2 id="m638">
                Commercial communication by E-Mail, Postal Mail, Fax or Telephone
              </h2>
              <p>
                We process personal data for the purposes of promotional
                communication, which may be carried out via various channels, such
                as e-mail, telephone, post or fax, in accordance with the legal
                requirements.
              </p>
              <p>
                The recipients have the right to withdraw their consent at any time
                or to object to the advertising communication at any time.
              </p>
              <p>
                After revocation or objection, we store the data required to prove
                the past authorization to contact or send up to three years from the
                end of the year of revocation or objection on the basis of our
                legitimate interests. The processing of this data is limited to the
                purpose of a possible defense against claims. Based on the
                legitimate interest to permanently observe the revocation,
                respectively objection of the users, we further store the data
                necessary to avoid a renewed contact (e.g. depending on the
                communication channel, the e-mail address, telephone number, name).
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Inventory data (e.g. names,
                  addresses); Contact data (e.g. e-mail, telephone numbers).
                </li>
                <li>
                  <strong>Data subjects:</strong> Communication partner (Recipients
                  of e-mails, letters, etc.).
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Direct marketing (e.g. by
                  e-mail or postal).
                </li>
                <li>
                  <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);
                  Legitimate Interests (Article 6 (1) (f) GDPR).
                </li>
              </ul>
              <h2 id="m263">Web Analysis, Monitoring and Optimization</h2>
              <p>
                Web analysis is used to evaluate the visitor traffic on our website
                and may include the behaviour, interests or demographic information
                of users, such as age or gender, as pseudonymous values. With the
                help of web analysis we can e.g. recognize, at which time our online
                services or their functions or contents are most frequently used or
                requested for repeatedly, as well as which areas require
                optimization.
              </p>
              <p>
                In addition to web analysis, we can also use test procedures, e.g.
                to test and optimize different versions of our online services or
                their components.
              </p>
              <p>
                Unless otherwise stated below, profiles, i.e. data aggregated for a
                usage process, can be created for these purposes and information can
                be stored in a browser or in a terminal device and read from it. The
                information collected includes, in particular, websites visited and
                elements used there as well as technical information such as the
                browser used, the computer system used and information on usage
                times. If users have agreed to the collection of their location data
                from us or from the providers of the services we use, location data
                may also be processed.
              </p>
              <p>
                Unless otherwise stated below, profiles, that is data summarized for
                a usage process or user, may be created for these purposes and
                stored in a browser or terminal device (so-called “cookies”) or
                similar processes may be used for the same purpose. The information
                collected includes, in particular, websites visited and elements
                used there as well as technical information such as the browser
                used, the computer system used and information on usage times. If
                users have consented to the collection of their location data or
                profiles to us or to the providers of the services we use, these may
                also be processed, depending on the provider.
              </p>
              <p>
                The IP addresses of the users are also stored. However, we use any
                existing IP masking procedure (i.e. pseudonymisation by shortening
                the IP address) to protect the user. In general, within the
                framework of web analysis, A/B testing and optimisation, no user
                data (such as e-mail addresses or names) is stored, but pseudonyms.
                This means that we, as well as the providers of the software used,
                do not know the actual identity of the users, but only the
                information stored in their profiles for the purposes of the
                respective processes.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Usage data (e.g. websites
                  visited, interest in content, access times); Meta, communication
                  and process data (e.g. IP addresses, time information,
                  identification numbers, consent status).
                </li>
                <li>
                  <strong>Data subjects:</strong> Users (e.g. website visitors,
                  users of online services).
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Web Analytics (e.g.
                  access statistics, recognition of returning visitors); Profiles
                  with user-related information (Creating user profiles).
                </li>
                <li>
                  <strong>Security measures:</strong> IP Masking (Pseudonymization
                  of the IP address).
                </li>
                <li>
                  <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR).
                </li>
              </ul>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Google Analytics: </strong>Web Analytics;
                  <strong>Service provider</strong>: Google Ireland Limited, Gordon
                  House, Barrow Street, Dublin 4, Ireland;
                  <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);
                  <strong>Website:</strong>
                  <a
                    href="https://marketingplatform.google.com/intl/en/about/analytics/"
                    target="_blank"
                    rel="noopener"
                    >https://marketingplatform.google.com/intl/en/about/analytics/</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >; <strong>Data Processing Agreement:</strong>
                  <a
                    href="https://business.safety.google/adsprocessorterms/"
                    target="_blank"
                    rel="noopener"
                    >https://business.safety.google/adsprocessorterms/</a
                  >;
                  <strong
                    >Standard Contractual Clauses (Safeguarding the level of data
                    protection when processing data in third countries):</strong
                  >
                  <a
                    href="https://business.safety.google/adsprocessorterms"
                    target="_blank"
                    rel="noopener"
                    >https://business.safety.google/adsprocessorterms</a
                  >; <strong>Opt-Out:</strong> Opt-Out-Plugin:
                  <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=en"
                    target="_blank"
                    rel="noopener"
                    >https://tools.google.com/dlpage/gaoptout?hl=en</a
                  >, Settings for the Display of Advertisements:
                  <a
                    href="https://adssettings.google.com/authenticated"
                    target="_blank"
                    rel="noopener"
                    >https://adssettings.google.com/authenticated</a
                  >; <strong>Further Information:</strong>
                  <a
                    href="https://privacy.google.com/businesses/adsservices"
                    target="_blank"
                    rel="noopener"
                    >https://privacy.google.com/businesses/adsservices</a
                  >
                  (Types of processing and data processed).
                </li>
                <li>
                  <strong>Google Tag Manager: </strong>Google Tag Manager is a
                  solution with which we can manage so-called website tags via an
                  interface and thus integrate other services into our online
                  services (please refer to further details in this privacy policy).
                  With the Tag Manager itself (which implements the tags), for
                  example, no user profiles are created or cookies are stored.
                  Google only receives the IP address of the user, which is
                  necessary to run the Google Tag Manager;
                  <strong>Service provider</strong>: Google Ireland Limited, Gordon
                  House, Barrow Street, Dublin 4, Ireland;
                  <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);
                  <strong>Website:</strong>
                  <a
                    href="https://marketingplatform.google.com"
                    target="_blank"
                    rel="noopener"
                    >https://marketingplatform.google.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >; <strong>Data Processing Agreement:</strong>
                  <a
                    href="https://business.safety.google/adsprocessorterms"
                    target="_blank"
                    rel="noopener"
                    >https://business.safety.google/adsprocessorterms</a
                  >;
                  <strong
                    >Standard Contractual Clauses (Safeguarding the level of data
                    protection when processing data in third countries):</strong
                  >
                  <a
                    href="https://business.safety.google/adsprocessorterms"
                    target="_blank"
                    rel="noopener"
                    >https://business.safety.google/adsprocessorterms</a
                  >.
                </li>
              </ul>
              <h2 id="m264">Online Marketing</h2>
              <p>
                We process personal data for the purposes of online marketing, which
                may include in particular the marketing of advertising space or the
                display of advertising and other content (collectively referred to
                as “Content”) based on the potential interests of users and the
                measurement of their effectiveness.
              </p>
              <p>
                For these purposes, so-called user profiles are created and stored
                in a file (so-called “cookie”) or similar procedure in which the
                relevant user information for the display of the aforementioned
                content is stored. This information may include, for example,
                content viewed, websites visited, online networks used,
                communication partners and technical information such as the browser
                used, computer system used and information on usage times and used
                functions. If users have consented to the collection of their
                sideline data, these can also be processed.
              </p>
              <p>
                The IP addresses of the users are also stored. However, we use
                provided IP masking procedures (i.e. pseudonymisation by shortening
                the IP address) to ensure the protection of the user’s by using a
                pseudonym. In general, within the framework of the online marketing
                process, no clear user data (such as e-mail addresses or names) is
                secured, but pseudonyms. This means that we, as well as the
                providers of online marketing procedures, do not know the actual
                identity of the users, but only the information stored in their
                profiles.
              </p>
              <p>
                The information in the profiles is usually stored in the cookies or
                similar memorizing procedures. These cookies can later, generally
                also on other websites that use the same online marketing
                technology, be read and analyzed for purposes of content display, as
                well as supplemented with other data and stored on the server of the
                online marketing technology provider.
              </p>
              <p>
                Exceptionally, clear data can be assigned to the profiles. This is
                the case, for example, if the users are members of a social network
                whose online marketing technology we use and the network links the
                profiles of the users in the aforementioned data. Please note that
                users may enter into additional agreements with the social network
                providers or other service providers, e.g. by consenting as part of
                a registration process.
              </p>
              <p>
                As a matter of principle, we only gain access to summarised
                information about the performance of our advertisements. However,
                within the framework of so-called conversion measurement, we can
                check which of our online marketing processes have led to a
                so-called conversion, i.e. to the conclusion of a contract with us.
                The conversion measurement is used alone for the performance
                analysis of our marketing activities.
              </p>
              <p>
                Unless otherwise stated, we kindly ask you to consider that cookies
                used will be stored for a period of two years.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Content data (e.g. text
                  input, photographs, videos); Usage data (e.g. websites visited,
                  interest in content, access times); Meta, communication and
                  process data (e.g. IP addresses, time information, identification
                  numbers, consent status); Event Data (Facebook) (“Event Data” is
                  data that can be transmitted from us to Facebook, e.g. via
                  Facebook pixels (via apps or other means) and relates to persons
                  or their actions; the data includes, for example, information
                  about visits to websites, interactions with content, functions,
                  installations of apps, purchases of products, etc.; Event data is
                  processed for the purpose of creating target groups for content
                  and advertising information (Custom Audiences); Event Data does
                  not include the actual content (such as written comments), login
                  information, and Contact Information (such as names, email
                  addresses, and phone numbers). Event Data is deleted by Facebook
                  after a maximum of two years, the Custom Audiences created from
                  them with the deletion of our Facebook account).
                </li>
                <li>
                  <strong>Data subjects:</strong> Users (e.g. website visitors,
                  users of online services).
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Web Analytics (e.g.
                  access statistics, recognition of returning visitors); Targeting
                  (e.g. profiling based on interests and behaviour, use of cookies);
                  Conversion tracking (Measurement of the effectiveness of marketing
                  activities); Affiliate Tracking; Marketing; Profiles with
                  user-related information (Creating user profiles); Provision of
                  our online services and usability; Remarketing.
                </li>
                <li>
                  <strong>Security measures:</strong> IP Masking (Pseudonymization
                  of the IP address).
                </li>
                <li>
                  <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);
                  Legitimate Interests (Article 6 (1) (f) GDPR).
                </li>
                <li>
                  <strong>Opt-Out: </strong>We refer to the privacy policies of the
                  respective service providers and the possibilities for objection
                  (so-called “opt-out”). If no explicit opt-out option has been
                  specified, it is possible to deactivate cookies in the settings of
                  your browser. However, this may restrict the functions of our
                  online offer. We therefore recommend the following additional
                  opt-out options, which are offered collectively for each area:
                  <p>
                    a) Europe:
                    <a
                      href="https://www.youronlinechoices.eu"
                      target="_blank"
                      rel="noopener"
                      >https://www.youronlinechoices.eu</a
                    >.<br />b) Canada:
                    <a
                      href="https://www.youradchoices.ca/choices"
                      target="_blank"
                      rel="noopener"
                      >https://www.youradchoices.ca/choices</a
                    >.<br />c) USA:
                    <a
                      href="https://www.aboutads.info/choices"
                      target="_blank"
                      rel="noopener"
                      >https://www.aboutads.info/choices</a
                    >.<br />d) Cross-regional:
                    <a
                      href="https://optout.aboutads.info"
                      target="_blank"
                      rel="noopener"
                      >https://optout.aboutads.info</a
                    >.
                  </p>
                </li>
              </ul>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong
                    >Facebook Pixel and Custom Audiences (Custom Audiences): </strong
                  >With the help of the Facebook pixel (or equivalent functions, to
                  transfer Event-Data or Contact Information via interfaces or other
                  software in apps), Facebook is on the one hand able to determine
                  the visitors of our online services as a target group for the
                  presentation of ads (so-called “Facebook ads”). Accordingly, we
                  use Facebook pixels to display Facebook ads placed by us only to
                  Facebook users and within the services of partners cooperating
                  with Facebook (so-called “audience network”
                  <a
                    href="https://www.facebook.com/audiencenetwork/"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/audiencenetwork/</a
                  >
                  ) who have shown an interest in our online services or who have
                  certain characteristics (e.g. interests in certain topics or
                  products that are determined on the basis of the websites visited)
                  that we transmit to Facebook (so-called “custom audiences”). With
                  the help of Facebook pixels, we also want to ensure that our
                  Facebook ads correspond to the potential interest of users and do
                  not appear annoying. The Facebook pixel also enables us to track
                  the effectiveness of Facebook ads for statistical and market
                  research purposes by showing whether users were referred to our
                  website after clicking on a Facebook ad (known as “conversion
                  tracking”); <strong>Service provider</strong>: Meta Platforms
                  Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin
                  2, Ireland; <strong>Legal Basis:</strong> Consent (Article 6 (1)
                  (a) GDPR); <strong>Website:</strong>
                  <a href="https://www.facebook.com" target="_blank" rel="noopener"
                    >https://www.facebook.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://www.facebook.com/about/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/about/privacy</a
                  >; <strong>Data Processing Agreement:</strong>
                  <a
                    href="https://www.facebook.com/legal/terms/dataprocessing"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/legal/terms/dataprocessing</a
                  >; <strong>Further Information:</strong> User event data, i.e.
                  behavioral and interest data, is processed for the purposes of
                  targeted advertising and audience building on the basis of the
                  joint controllership agreement (“Controller Addendum”,
                  <a
                    href="https://www.facebook.com/legal/controller_addendum"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/legal/controller_addendum</a
                  >). The joint controllership is limited to the collection and
                  transfer of the data to Meta Platforms Ireland Limited, a company
                  located in the EU. Further processing of the data is the sole
                  responsibility of Meta Platforms Ireland Limited, which concerns
                  in particular the transfer of the data to the parent company Meta
                  Platforms, Inc. in the USA (on the basis of standard contractual
                  clauses concluded between Meta Platforms Ireland Limited and Meta
                  Platforms, Inc.).
                </li>
                <li>
                  <strong>Google Ads and Conversion Tracking: </strong>Online
                  marketing process for purposes of placing content and
                  advertisements within the provider’s advertising network (e.g., in
                  search results, in videos, on web pages, etc.) so that they are
                  displayed to users who have a presumed interest in the ads.
                  Furthermore, we measure the conversion of the ads, i.e. whether
                  the users took them as a reason to interact with the ads and make
                  use of the advertised offers (so-called conversion). However, we
                  only receive anonymous information and no personal information
                  about individual users; <strong>Service provider</strong>: Google
                  Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland;
                  <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR),
                  Legitimate Interests (Article 6 (1) (f) GDPR);
                  <strong>Website:</strong>
                  <a
                    href="https://marketingplatform.google.com"
                    target="_blank"
                    rel="noopener"
                    >https://marketingplatform.google.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >; <strong>Further Information:</strong> Types of processing and
                  data processed:
                  <a
                    href="https://privacy.google.com/businesses/adsservices"
                    target="_blank"
                    rel="noopener"
                    >https://privacy.google.com/businesses/adsservices</a
                  >; Google Ads Controller-Controller Data Protection Terms and
                  standard contractual clauses for data transfers to third
                  countries:
                  <a
                    href="https://business.safety.google/adscontrollerterms"
                    target="_blank"
                    rel="noopener"
                    >https://business.safety.google/adscontrollerterms</a
                  >.
                </li>
                <li>
                  <strong>Google Analytics Audiences: </strong>We use Google
                  Analytics to display ads placed by Google and its partnersonly to
                  users who have shown an interest in our online services or who
                  have specific characteristics (e.g. interests in specific topics
                  or products determined on the basis of the websites visited) that
                  we transmit to Google (so-called “Remarketing Audiences” or
                  “Google Analytics Audiences”). With the help of remarketing
                  audiences, we also want to ensure that our ads match the potential
                  interest of users; <strong>Service provider</strong>: Google
                  Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland;
                  <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);
                  <strong>Website:</strong>
                  <a
                    href="https://marketingplatform.google.com"
                    target="_blank"
                    rel="noopener"
                    >https://marketingplatform.google.com</a
                  >; <strong>Legal Basis:</strong>
                  <a
                    href="https://business.safety.google/adsprocessorterms/"
                    target="_blank"
                    rel="noopener"
                    >https://business.safety.google/adsprocessorterms/</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >; <strong>Data Processing Agreement:</strong>
                  <a
                    href="https://business.safety.google/adsprocessorterms/"
                    target="_blank"
                    rel="noopener"
                    >https://business.safety.google/adsprocessorterms/</a
                  >; <strong>Further Information:</strong> Types of processing and
                  data processed:
                  <a
                    href="https://privacy.google.com/businesses/adsservices"
                    target="_blank"
                    rel="noopener"
                    >https://privacy.google.com/businesses/adsservices</a
                  >; Data Processing Conditions for Google Advertising Products and
                  standard contractual clauses for data transfers to third
                  countries:
                  <a
                    href="https://business.safety.google/adsprocessorterms"
                    target="_blank"
                    rel="noopener"
                    >https://business.safety.google/adsprocessorterms</a
                  >
                  .
                </li>
              </ul>
              <h2 id="m136">Profiles in Social Networks (Social Media)</h2>
              <p>
                We maintain online presences within social networks and process user
                data in this context in order to communicate with the users active
                there or to offer information about us.
              </p>
              <p>
                We would like to point out that user data may be processed outside
                the European Union. This may entail risks for users, e.g. by making
                it more difficult to enforce users’ rights.
              </p>
              <p>
                In addition, user data is usually processed within social networks
                for market research and advertising purposes. For example, user
                profiles can be created on the basis of user behaviour and the
                associated interests of users. The user profiles can then be used,
                for example, to place advertisements within and outside the networks
                which are presumed to correspond to the interests of the users. For
                these purposes, cookies are usually stored on the user’s computer,
                in which the user’s usage behaviour and interests are stored.
                Furthermore, data can be stored in the user profiles independently
                of the devices used by the users (especially if the users are
                members of the respective networks or will become members later on).
              </p>
              <p>
                For a detailed description of the respective processing operations
                and the opt-out options, please refer to the respective data
                protection declarations and information provided by the providers of
                the respective networks.
              </p>
              <p>
                Also in the case of requests for information and the exercise of
                rights of data subjects, we point out that these can be most
                effectively pursued with the providers. Only the providers have
                access to the data of the users and can directly take appropriate
                measures and provide information. If you still need help, please do
                not hesitate to contact us.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Contact data (e.g. e-mail,
                  telephone numbers); Content data (e.g. text input, photographs,
                  videos); Usage data (e.g. websites visited, interest in content,
                  access times); Meta, communication and process data (e.g. IP
                  addresses, time information, identification numbers, consent
                  status).
                </li>
                <li>
                  <strong>Data subjects:</strong> Users (e.g. website visitors,
                  users of online services).
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Contact requests and
                  communication; Feedback (e.g. collecting feedback via online
                  form); Marketing.
                </li>
                <li>
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR).
                </li>
              </ul>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Facebook-Seiten: </strong>Profiles within the social
                  network Facebook – We are jointly responsible (so called “joint
                  controller”) with Meta Platforms Ireland Limited for the
                  collection (but not the further processing) of data of visitors to
                  our Facebook page. This data includes information about the types
                  of content users view or interact with, or the actions they take
                  (see “Things that you and others do and provide” in the Facebook
                  Data Policy:
                  <a
                    href="https://www.facebook.com/policy"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/policy</a
                  >), and information about the devices used by users (e.g., IP
                  addresses, operating system, browser type, language settings,
                  cookie information; see “Device Information” in the Facebook Data
                  Policy:
                  <a
                    href="https://www.facebook.com/policy"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/policy</a
                  >). As explained in the Facebook Data Policy under “How we use
                  this information?” Facebook also collects and uses information to
                  provide analytics services, known as “page insights,” to site
                  operators to help them understand how people interact with their
                  pages and with content associated with them. We have concluded a
                  special agreement with Facebook (“Information about
                  Page-Insights”,
                  <a
                    href="https://www.facebook.com/legal/terms/page_controller_addendum"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/legal/terms/page_controller_addendum</a
                  >), which regulates in particular the security measures that
                  Facebook must observe and in which Facebook has agreed to fulfill
                  the rights of the persons concerned (i.e. users can send
                  information access or deletion requests directly to Facebook). The
                  rights of users (in particular to access to information, erasure,
                  objection and complaint to the competent supervisory authority)
                  are not restricted by the agreements with Facebook. Further
                  information can be found in the “Information about Page Insights”
                  (<a
                    href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/legal/terms/information_about_page_insights_data</a
                  >); <strong>Service provider</strong>: Meta Platforms Ireland
                  Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
                  Ireland; <strong>Legal Basis:</strong> Legitimate Interests
                  (Article 6 (1) (f) GDPR); <strong>Website:</strong>
                  <a href="https://www.facebook.com" target="_blank" rel="noopener"
                    >https://www.facebook.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://www.facebook.com/about/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/about/privacy</a
                  >;
                  <strong
                    >Standard Contractual Clauses (Safeguarding the level of data
                    protection when processing data in third countries):</strong
                  >
                  <a
                    href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/legal/EU_data_transfer_addendum</a
                  >; <strong>Further Information:</strong> Joint Controllership
                  Agreement:
                  <a
                    href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                    target="_blank"
                    rel="noopener"
                    >https://www.facebook.com/legal/terms/information_about_page_insights_data</a
                  >. The joint controllership is limited to the collection and
                  transfer of the data to Meta Platforms Ireland Limited, a company
                  located in the EU. Further processing of the data is the sole
                  responsibility of Meta Platforms Ireland Limited, which concerns
                  in particular the transfer of the data to the parent company Meta
                  Platforms, Inc. in the USA (on the basis of standard contractual
                  clauses concluded between Meta Platforms Ireland Limited and Meta
                  Platforms, Inc.).
                </li>
                <li>
                  <strong>LinkedIn: </strong>Social network;
                  <strong>Service provider</strong>: LinkedIn Ireland Unlimited
                  Company, Wilton Plaza Wilton Place, Dublin 2, Ireland;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a href="https://www.linkedin.com" target="_blank" rel="noopener"
                    >https://www.linkedin.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://www.linkedin.com/legal/privacy-policy"
                    target="_blank"
                    rel="noopener"
                    >https://www.linkedin.com/legal/privacy-policy</a
                  >; <strong>Data Processing Agreement:</strong>
                  <a
                    href="https://legal.linkedin.com/dpa"
                    target="_blank"
                    rel="noopener"
                    >https://legal.linkedin.com/dpa</a
                  >;
                  <strong
                    >Standard Contractual Clauses (Safeguarding the level of data
                    protection when processing data in third countries):</strong
                  >
                  <a
                    href="https://legal.linkedin.com/dpa"
                    target="_blank"
                    rel="noopener"
                    >https://legal.linkedin.com/dpa</a
                  >; <strong>Opt-Out:</strong>
                  <a
                    href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                    target="_blank"
                    rel="noopener"
                    >https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a
                  >.
                </li>
                <li>
                  <strong>YouTube: </strong>Social network and video platform;
                  <strong>Service provider</strong>: Google Ireland Limited, Gordon
                  House, Barrow Street, Dublin 4, Ireland;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >; <strong>Opt-Out:</strong>
                  <a
                    href="https://adssettings.google.com/authenticated"
                    target="_blank"
                    rel="noopener"
                    >https://adssettings.google.com/authenticated</a
                  >.
                </li>
                <li>
                  <strong>Xing: </strong>Social network;
                  <strong>Service provider</strong>: New Work SE, Am Strandkai 1,
                  20457 Hamburg, Germany; <strong>Legal Basis:</strong> Legitimate
                  Interests (Article 6 (1) (f) GDPR); <strong>Website:</strong>
                  <a href="https://www.xing.com" target="_blank" rel="noopener"
                    >https://www.xing.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://privacy.xing.com/en"
                    target="_blank"
                    rel="noopener"
                    >https://privacy.xing.com/en</a
                  >.
                </li>
              </ul>
              <h2 id="m328">Plugins and embedded functions and content</h2>
              <p>
                Within our online services, we integrate functional and content
                elements that are obtained from the servers of their respective
                providers (hereinafter referred to as “third-party providers”).
                These may, for example, be graphics, videos or city maps
                (hereinafter uniformly referred to as “Content”).
              </p>
              <p>
                The integration always presupposes that the third-party providers of
                this content process the IP address of the user, since they could
                not send the content to their browser without the IP address. The IP
                address is therefore required for the presentation of these contents
                or functions. We strive to use only those contents, whose respective
                offerers use the IP address only for the distribution of the
                contents. Third parties may also use so-called pixel tags (invisible
                graphics, also known as “web beacons”) for statistical or marketing
                purposes. The “pixel tags” can be used to evaluate information such
                as visitor traffic on the pages of this website. The pseudonymous
                information may also be stored in cookies on the user’s device and
                may include technical information about the browser and operating
                system, referring websites, visit times and other information about
                the use of our website, as well as may be linked to such information
                from other sources.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Processed data types:</strong> Usage data (e.g. websites
                  visited, interest in content, access times); Meta, communication
                  and process data (e.g. IP addresses, time information,
                  identification numbers, consent status); Inventory data (e.g.
                  names, addresses); Contact data (e.g. e-mail, telephone numbers);
                  Content data (e.g. text input, photographs, videos); Location data
                  (Information on the geographical position of a device or person).
                </li>
                <li>
                  <strong>Data subjects:</strong> Users (e.g. website visitors,
                  users of online services).
                </li>
                <li>
                  <strong>Purposes of Processing:</strong> Provision of our online
                  services and usability; Provision of contractual services and
                  customer support.
                </li>
                <li>
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR).
                </li>
              </ul>
              <p>
                <strong
                  >Further information on processing methods, procedures and
                  services used:</strong
                >
              </p>
              <ul class="m-elements">
                <li>
                  <strong
                    >Integration of third-party software, scripts or frameworks: </strong
                  >We incorporate into our online services software which we
                  retrieve from servers of other providers (e.g. function libraries
                  which we use for the purpose of displaying or user-friendliness of
                  our online services). The respective providers collect the user’s
                  IP address and can process it for the purposes of transferring the
                  software to the user’s browser as well as for security purposes
                  and for the evaluation and optimisation of their services;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR).
                </li>
                <li>
                  <strong>Google Fonts (from Google Server): </strong>Obtaining
                  fonts (and symbols) for the purpose of a technically secure,
                  maintenance-free and efficient use of fonts and symbols with
                  regard to timeliness and loading times, their uniform presentation
                  and consideration of possible restrictions under licensing law.
                  The provider of the fonts is informed of the user’s IP address so
                  that the fonts can be made available in the user’s browser. In
                  addition, technical data (language settings, screen resolution,
                  operating system, hardware used) are transmitted which are
                  necessary for the provision of the fonts depending on the devices
                  used and the technical environment. This data may be processed on
                  a server of the provider of the fonts in the USA – When visiting
                  our online services, users’ browsers send their browser HTTP
                  requests to the Google Fonts Web API. The Google Fonts Web API
                  provides users with Google Fonts’ cascading style sheets (CSS) and
                  then with the fonts specified in the CCS. These HTTP requests
                  include (1) the IP address used by each user to access the
                  Internet, (2) the requested URL on the Google server, and (3) the
                  HTTP headers, including the user agent describing the browser and
                  operating system versions of the website visitors, as well as the
                  referral URL (i.e., the web page where the Google font is to be
                  displayed). IP addresses are not logged or stored on Google
                  servers and they are not analyzed. The Google Fonts Web API logs
                  details of HTTP requests (requested URL, user agent, and referring
                  URL). Access to this data is restricted and strictly controlled.
                  The requested URL identifies the font families for which the user
                  wants to load fonts. This data is logged so that Google can
                  determine how often a particular font family is requested. With
                  the Google Fonts Web API, the user agent must match the font that
                  is generated for the particular browser type. The user agent is
                  logged primarily for debugging purposes and is used to generate
                  aggregate usage statistics that measure the popularity of font
                  families. These aggregate usage statistics are published on Google
                  Fonts’ Analytics page. Finally, the referral URL is logged so that
                  the data can be used for production maintenance and to generate an
                  aggregate report on top integrations based on the number of font
                  requests. Google says it does not use any of the information
                  collected by Google Fonts to profile end users or serve targeted
                  ads; <strong>Service provider</strong>: Google Ireland Limited,
                  Gordon House, Barrow Street, Dublin 4, Ireland;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a href="https://fonts.google.com/" target="_blank" rel="noopener"
                    >https://fonts.google.com/</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >; <strong>Further Information:</strong>
                  <a
                    href="https://developers.google.com/fonts/faq/privacy?hl=en"
                    target="_blank"
                    rel="noopener"
                    >https://developers.google.com/fonts/faq/privacy?hl=en</a
                  >.
                </li>
                <li>
                  <strong>Google Maps: </strong>We integrate the maps of the service
                  “Google Maps” from the provider Google. The data processed may
                  include, in particular, IP addresses and location data of users;
                  <strong>Service provider</strong>: Google Cloud EMEA Limited, 70
                  Sir John Rogerson’s Quay, Dublin 2, Ireland;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a
                    href="https://mapsplatform.google.com/"
                    target="_blank"
                    rel="noopener"
                    >https://mapsplatform.google.com/</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >.
                </li>
                <li>
                  <strong>Google Maps APIs and SDKs: </strong>Interfaces to the map
                  and location services provided by Google, which, for example,
                  allow the addition of address entries, location determinations,
                  distance calculations or the provision of supplementary
                  information on locations and other places;
                  <strong>Service provider</strong>: Google Cloud EMEA Limited, 70
                  Sir John Rogerson’s Quay, Dublin 2, Ireland;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a
                    href="https://mapsplatform.google.com/"
                    target="_blank"
                    rel="noopener"
                    >https://mapsplatform.google.com/</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >.
                </li>
                <li>
                  <strong>reCAPTCHA: </strong>We integrate the “reCAPTCHA” function
                  to be able to recognise whether entries (e.g. in online forms) are
                  made by humans and not by automatically operating machines
                  (so-called “bots”). The data processed may include IP addresses,
                  information on operating systems, devices or browsers used,
                  language settings, location, mouse movements, keystrokes, time
                  spent on websites, previously visited websites, interactions with
                  ReCaptcha on other websites, possibly cookies and results of
                  manual recognition processes (e.g. answering questions asked or
                  selecting objects in images). The data processing is based on our
                  legitimate interest to protect our online services from abusive
                  automated crawling and spam; <strong>Service provider</strong>:
                  Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                  Ireland, , parent company: Google LLC, 1600 Amphitheatre Parkway,
                  Mountain View, CA 94043, USA;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a
                    href="https://www.google.com/recaptcha/"
                    target="_blank"
                    rel="noopener"
                    >https://www.google.com/recaptcha/</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >; <strong>Opt-Out:</strong> Opt-Out-Plugin:
                  <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=en"
                    target="_blank"
                    rel="noopener"
                    >https://tools.google.com/dlpage/gaoptout?hl=en</a
                  >, Settings for the Display of Advertisements:
                  <a
                    href="https://adssettings.google.com/authenticated"
                    target="_blank"
                    rel="noopener"
                    >https://adssettings.google.com/authenticated</a
                  >.
                </li>
                <li>
                  <strong>YouTube videos: </strong>Video contents;
                  <strong>Service provider</strong>: Google Ireland Limited, Gordon
                  House, Barrow Street, Dublin 4, Ireland, , parent company: Google
                  LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a href="https://www.youtube.com" target="_blank" rel="noopener"
                    >https://www.youtube.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >; <strong>Opt-Out:</strong> Opt-Out-Plugin:
                  <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=en"
                    target="_blank"
                    rel="noopener"
                    >https://tools.google.com/dlpage/gaoptout?hl=en</a
                  >, Settings for the Display of Advertisements:
                  <a
                    href="https://adssettings.google.com/authenticated"
                    target="_blank"
                    rel="noopener"
                    >https://adssettings.google.com/authenticated</a
                  >.
                </li>
                <li>
                  <strong>YouTube-Videos: </strong>Video content; ouTube videos are
                  integrated via a special domain (recognizable by the component
                  “youtube-nocookie”) in the so-called ” enhanced data protection
                  mode”, whereby no cookies on user activities are collected in
                  order to personalise the video playback. Nevertheless, information
                  on the user’s interaction with the video (e.g. remembering the
                  last playback point) may be stored;
                  <strong>Service provider</strong>: Google Ireland Limited, Gordon
                  House, Barrow Street, Dublin 4, Ireland, , parent company: Google
                  LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
                  <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
                  (f) GDPR); <strong>Website:</strong>
                  <a href="https://www.youtube.com" target="_blank" rel="noopener"
                    >https://www.youtube.com</a
                  >; <strong>Privacy Policy:</strong>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener"
                    >https://policies.google.com/privacy</a
                  >.
                </li>
                <li>
                  <strong>Font Awesome (Provision on own server): </strong>Display
                  of fonts and symbols; Legal Basis: Legitimate Interests (Article 6
                  (1) (f) GDPR); <strong>Service provider</strong>: The Font Awesome
                  icons are hosted on our server, no data is transmitted to the
                  provider of Font Awesome; <strong>Website:</strong> undefined;
                  <strong>Privacy Policy:</strong> undefined.
                </li>
              </ul>
              <h2 id="m15">Changes and Updates to the Privacy Policy</h2>
              <p>
                We kindly ask you to inform yourself regularly about the contents of
                our data protection declaration. We will adjust the privacy policy
                as changes in our data processing practices make this necessary. We
                will inform you as soon as the changes require your cooperation
                (e.g. consent) or other individual notification.
              </p>
              <p>
                If we provide addresses and contact information of companies and
                organizations in this privacy policy, we ask you to note that
                addresses may change over time and to verify the information before
                contacting us.
              </p>
              <h2 id="m10">Rights of Data Subjects</h2>
              <p>
                As data subject, you are entitled to various rights under the GDPR,
                which arise in particular from Articles 15 to 21 of the GDPR:
              </p>
              <ul>
                <li>
                  <strong
                    >Right to Object: You have the right, on grounds arising from
                    your particular situation, to object at any time to the
                    processing of your personal data which is based on letter (e) or
                    (f) of Article 6(1) GDPR, including profiling based on those
                    provisions. Where personal data are processed for direct
                    marketing purposes, you have the right to object at any time to
                    the processing of the personal data concerning you for the
                    purpose of such marketing, which includes profiling to the
                    extent that it is related to such direct marketing.</strong
                  >
                </li>
                <li>
                  <strong>Right of withdrawal for consents:</strong> You have the
                  right to revoke consents at any time.
                </li>
                <li>
                  <strong>Right of access:</strong> You have the right to request
                  confirmation as to whether the data in question will be processed
                  and to be informed of this data and to receive further information
                  and a copy of the data in accordance with the provisions of the
                  law.
                </li>
                <li>
                  <strong>Right to rectification:</strong> You have the right, in
                  accordance with the law, to request the completion of the data
                  concerning you or the rectification of the incorrect data
                  concerning you.
                </li>
                <li>
                  <strong
                    >Right to Erasure and Right to Restriction of
                    Processing:</strong
                  >
                  In accordance with the statutory provisions, you have the right to
                  demand that the relevant data be erased immediately or,
                  alternatively, to demand that the processing of the data be
                  restricted in accordance with the statutory provisions.
                </li>
                <li>
                  <strong>Right to data portability:</strong> You have the right to
                  receive data concerning you which you have provided to us in a
                  structured, common and machine-readable format in accordance with
                  the legal requirements, or to request its transmission to another
                  controller.
                </li>
                <li>
                  <strong>Complaint to the supervisory authority:</strong> Without
                  prejudice to any other administrative or judicial remedy, you
                  shall have the right to lodge a complaint with a supervisory
                  authority, in particular in the Member State of his or her
                  habitual residence, place of work or place of the alleged
                  infringement if you consider that the processing of personal data
                  relating to you infringes the GDPR.
                </li>
              </ul>
              <h2 id="m42">Terminology and Definitions</h2>
              <p>
                This section provides an overview of the terms used in this privacy
                policy. Many of the terms are drawn from the law and defined mainly
                in Article 4 GDPR. The legal definitions are binding. The following
                explanations, on the other hand, are intended above all for the
                purpose of comprehension. The terms are sorted alphabetically.
              </p>
              <ul class="glossary">
                <li>
                  <strong>Affiliate Tracking:</strong> Custom Audiences refers to
                  the process of determining target groups for advertising purposes,
                  e.g. the display of advertisements. For example, a user’s interest
                  in certain products or topics on the Internet may be used to
                  conclude that the user is interested in advertisements for similar
                  products or the online store in which the user viewed the
                  products. “Lookalike Audiences” is the term used to describe
                  content that is viewed as suitable by users whose profiles or
                  interests presumably correspond to the users for whom the profiles
                  were created. For the purposes of creating custom audiences and
                  lookalike audiences, cookies and web beacons are typically used.
                </li>
                <li>
                  <strong>Controller:</strong> “Controller” means the natural or
                  legal person, public authority, agency or other body which, alone
                  or jointly with others, determines the purposes and means of the
                  processing of personal data.
                </li>
                <li>
                  <strong>Conversion tracking:</strong> Conversion tracking is a
                  method used to evaluate the effectiveness of marketing measures.
                  For this purpose, a cookie is usually stored on the devices of the
                  users within the websites on which the marketing measures take
                  place and then called up again on the target website (e.g. we can
                  thus trace whether the advertisements placed by us on other
                  websites were successful).
                </li>
                <li>
                  <strong>Location data:</strong> Location data is created when a
                  mobile device (or another device with the technical requirements
                  for a location determination) connects to a radio cell, a WLAN or
                  similar technical means and functions of location determination.
                  Location data serve to indicate the geographically determinable
                  position of the earth at which the respective device is located.
                  Location data can be used, for example, to display map functions
                  or other information dependent on a location.
                </li>
                <li>
                  <strong>Personal Data:</strong> “personal data” means any
                  information relating to an identified or identifiable natural
                  person (“data subject”); an identifiable natural person is one who
                  can be identified, directly or indirectly, in particular by
                  reference to an identifier such as a name, an identification
                  number, location data, an online identifier or to one or more
                  factors specific to the physical, physiological, genetic, mental,
                  economic, cultural or social identity of that natural person.
                </li>
                <li>
                  <strong>Processing:</strong> The term “processing” covers a wide
                  range and practically every handling of data, be it collection,
                  evaluation, storage, transmission or erasure.
                </li>
                <li>
                  <strong>Profiles with user-related information:</strong> The
                  processing of “profiles with user-related information”, or
                  “profiles” for short, includes any kind of automated processing of
                  personal data that consists of using these personal data to
                  analyse, evaluate or predict certain personal aspects relating to
                  a natural person (depending on the type of profiling, this may
                  include different information concerning demographics, behaviour
                  and interests, such as interaction with websites and their
                  content, etc.) (e.g. interests in certain content or products,
                  click behaviour on a website or location). Cookies and web beacons
                  are often used for profiling purposes.
                </li>
                <li>
                  <strong>Remarketing:</strong> Remarketing” or “retargeting” is the
                  term used, for example, to indicate for advertising purposes which
                  products a user is interested in on a website in order to remind
                  the user of these products on other websites, e.g. in
                  advertisements.
                </li>
                <li>
                  <strong>Targeting:</strong> “Tracking” is the term used when the
                  behaviour of users can be traced across several websites. As a
                  rule, behavior and interest information with regard to the
                  websites used is stored in cookies or on the servers of the
                  tracking technology providers (so-called profiling). This
                  information can then be used, for example, to display
                  advertisements to users presumably corresponding to their
                  interests.
                </li>
                <li>
                  <strong>Web Analytics:</strong> Web Analytics serves the
                  evaluation of visitor traffic of online services and can determine
                  their behavior or interests in certain information, such as
                  content of websites. With the help of web analytics, website
                  owners, for example, can recognize at what time visitors visit
                  their website and what content they are interested in. This allows
                  them, for example, to optimize the content of the website to
                  better meet the needs of their visitors. For purposes of web
                  analytics, pseudonymous cookies and web beacons are frequently
                  used in order to recognise returning visitors and thus obtain more
                  precise analyses of the use of an online service.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    `,
      IMPRINT: "Imprint",
      IMPRINT_CONTENT: `<div class="et_pb_section et_pb_section_1 et_section_regular">
      <div class="et_pb_row et_pb_row_1">
      <div class="et_pb_column et_pb_column_4_4 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child">
      <div class="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">
      <div class="et_pb_text_inner"></div>
      </div><div class="et_pb_module et_pb_divider et_pb_divider_0 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div>
      </div>
      </div><div class="et_pb_row et_pb_row_2">
      <div class="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">
      <div class="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
      <div class="et_pb_text_inner"><h6>SERVICE PROVIDER</h6>
      <p>Con&shy;cir&shy;cle Österreich GmbH<br>Wie&shy;ner&shy;berg&shy;stra&shy;ße 11<br>A-1100 Vienna<br><span id="eeb-367463-739720">office@concircle.com</span><script type="text/javascript">(function(){var ml=".fiocm04nr%le",mi="31124<:764384294;<0435",o="";for(var j=0,l=mi.length;j<l;j++){o+=ml.charAt(mi.charCodeAt(j)-48);}document.getElementById("eeb-367463-739720").innerHTML = decodeURIComponent(o);}());</script><noscript>*protected email*</noscript><br>www.concircle.com</p>
      <h6>MANAGING DIRECTORS</h6>
      <p>Engineer Mag. Florian Gstir<br>Dipl.-Ing. Dr. Konrad Pfadenhauer</p>
      <h6>Company purpose</h6>
      <p>IT Consulting and IT Services</p>
      <h6>COMMERCIAL REGISTER</h6>
      <p>Register court: Commercial Court Vienna<br>Registration number: FN333525d</p>
      <h6>SALES TAX IDENTIFICATION NUMBER</h6>
      <p>ATU65204334</p></div>
      </div>
      </div>
      </div>
      </div>`,
      LIST_TRANSPORT_INFO:
        "Attention: be aware that custom lists are not transported within the POD Transport. Please check this individually",
      SUBSCRIPTION_TITLE: "Subscriptions",
      SUBSCRIPTION_SUBTITLE: "Get the right plan for your business",
      MONTH: "Month",
      YEAR: "Year",
      MONTHLY: "Monthly",
      YEARLY: "Yearly",
      FEATURES: "Features",
      FUNCTIONALITIES: "Functionalities",
      DATASET_UPLOAD: "Datasets/Upload",
      USER_LIMIT: "No User limit",
      MAX_PROFILE: "Max. Profiles",
      UPLOADS_PER_DAY: "Uploads / 24h",
      TRANSPORTS_PER_DAY: "Transports / 24h",
      YES: "Yes",
      NO: "No",
      UNLIMITED: "Unlimited",
      LIMITED: "Limited",
      eur: "€",
      ALL: "All",
      SUBSCRIPTION: "Subscribe",
      SUBSCRIBED: "Subscribed",
      PRICING_TABLE_TITLE: "Get the right plan for your business",
      FREE_PLAN_EXPIRED:
        "Your free plan has expired. Please subscribe to a plan to continue using conDMWizard",
      FREE_PLAN_LIMIT_REACHED_TRANSPORT_OR_UPLOAD:
        "You have reached the limit of your free plan which includes 10 uploads or transports per 24h. Please upgrade your plan to continue using conDMWizard.",
      FREE_PLAN_LIMIT_REACHED_DATA_COUNT:
        "You have reached the limit of your free plan which includes maximum 2 datasets per upload or transport. Please upgrade your plan to continue using conDMWizard.",
      FAQ_WHAT_IS_CONDMWIZARD_HEADLINE: "What is conDMWizard",
      FAQ_WHAT_IS_CONDMWIZARD_QUESTION1: "What is conDMWizard?",
      FAQ_WHAT_IS_CONDMWIZARD_ANSWER1:
        "conDMWizard is a specialized SaaS web application designed for the efficient management of master data within the SAP Digital Manufacturing (SAP DM) landscape. It enables effortless uploading, migration, and synchronization of master and configuration data across various plants and tenants of SAP DM. Ideal for data-intensive transition phases, conDMWizard supports companies in data creation, migration, and transport during crucial cut over stages, ensuring seamless data flow and integrity throughout the SAP DM environment.​",
      FAQ_WHAT_IS_CONDMWIZARD_QUESTION2:
        "Do I need a training to start using conDMWizard?",
      FAQ_WHAT_IS_CONDMWIZARD_ANSWER2:
        "In order to use conDMWizard no training is needed. During development we put a focus on self explanatory functionalities. On each page you can find an interactive how-to tutorial. Nevertheless, if you have any questions, do not hesitate to <a href='mailto:support-condmw@concircle.com'>contact us! </a>",
      FAQ_WHAT_IS_CONDMWIZARD_QUESTION3:
        "Which data can i migrate & transport?",
      FAQ_WHAT_IS_CONDMWIZARD_ANSWER3:
        "Currently Data Collection Groups can be <a href='/dataCollectionUpload'>migrated </a> and <a href='/dataCollectionTransfer'>transported </a>. POD Configuration can be <a href='/podTransport'>transported </a> as well. We are consistently developing new functionalities to enable the transportation and migration of additional types of master data. <a href='mailto:support-condmw@concircle.com'>Contact us</a>, if you have special requirments!",
      FAQ_WHAT_IS_CONDMWIZARD_QUESTION4:
        "I don't have SAP DM, can I still use conDMWizard?",
      FAQ_WHAT_IS_CONDMWIZARD_ANSWER4:
        "conDMWizard can only be used if you have a SAP Digital Manufacturing. Therefore conDMWizard will not be beneficial for you if you do not have the SAP DM.",

      FAQ_SUBSCRIPTION_HEADLINE: "Subscription & Trial",
      FAQ_SUBSCRIPTION_QUESTION1: "Is there a trial period?",
      FAQ_SUBSCRIPTION_ANSWER1:
        "A free version is available for everybody to use for 1 month. Please refer to the overview of available plans or <a href='mailto:support-condmw@concircle.com'>contact us</a> for custom inquiries.",
      FAQ_SUBSCRIPTION_QUESTION2: "How many subscriptions do I need?",
      FAQ_SUBSCRIPTION_ANSWER2:
        "You just need one subscriptions to start using conDMWizard or use the free plan!",
      FAQ_SUBSCRIPTION_QUESTION3: "How to subscribe?",
      FAQ_SUBSCRIPTION_ANSWER3:
        "Click on Subscribe in our billing table. There, you can buy the plan which fits to you best! <br/> <p style='font-size: 0.7rem'>Note: You can only see the Subscribe button if you are logged in to conDMWizard.</p>",
      FAQ_SUBSCRIPTION_QUESTION4: "What is the price of conDMWizard?",
      FAQ_SUBSCRIPTION_ANSWER4:
        "conDMWizard plans can be purchased monthly or yearly. You can find the plans including prices in the billing-plan overview. The choosen plan depends on the amount of profiles you want to create. One profile consists of a unique cominbation of SAP Digital Manufacutring Plant and Tenant (Q or PROD). There are no other fees!",

      FAQ_TEC_QUESTIONS_HEADLINE: "Technical Questions & How-To",
      FAQ_TEC_QUESTIONS_QUESTION1:
        "What system prerequisities you need to use conDMWizard?",
      FAQ_TEC_QUESTIONS_ANSWER1:
        "conDMWizard is an online platform - you can use a web browser of your choice.",
      FAQ_TEC_QUESTIONS_QUESTION2: "How do I start with conDMWizard?",
      FAQ_TEC_QUESTIONS_ANSWER2:
        "Register with conDMWizard to create your profile within the profile management system. Following registration, you can access and explore all functionalities offered within the free version.",
      FAQ_TEC_QUESTIONS_QUESTION3: "How do I upload data?",
      FAQ_TEC_QUESTIONS_ANSWER3:
        "An Excel Template is included within the standard functionalities of the <a href='/dataCollectionUpload'>application</a>. Use this template to upload your data seamlessly, then let conDMWizard do the rest!",
      FAQ_TEC_QUESTIONS_QUESTION4:
        "What should I do if my upload did not work?",
      FAQ_TEC_QUESTIONS_ANSWER4:
        "Please check the provided log with corresponding error message and change your excel accordingly. If you still encounter a problem, <a href='mailto:support-condmw@concircle.com'>contact us!</a>",

      FAQ_DATA_SECURITY_HEADLINE: "Data Security",
      FAQ_DATA_SECURITY_QUESTION1:
        "What happens to my SAP DM Tenant Credentials?",
      FAQ_DATA_SECURITY_ANSWER1:
        "Your SAP Digital Manufacturing Tenant Credentials are securely stored within your local session storage, ensuring that they are not retained within the application. This enhances the security of your credentials, ensuring the confidentiality of your sensitive information.",
      FAQ_DATA_SECURITY_QUESTION2: "How secure is the data transmission?",
      FAQ_DATA_SECURITY_ANSWER2:
        "The data transmission is highly secure as it is conducted over HTTPS, adhering to the latest and widely recognized security standards. This encryption protocol ensures the confidentiality and integrity of the transmitted data, significantly reducing the risk of unauthorized access.",
      FAQ_DATA_SECURITY_QUESTION3: "Which and where is my data stored?",
      FAQ_DATA_SECURITY_ANSWER3:
        "Your data, including user authentication and general Digital Manufacturing (DM) profile information such as plant, name, and DM URL, is stored securely on our Database server located in the Azure cloud. We prioritize the security of your profiles by excluding the storage of credentials associated with the DM profile.",
      FAQ_DATA_SECURITY_QUESTION4:
        "What data is stored at the payment provider?",
      FAQ_DATA_SECURITY_ANSWER4:
        "Our payment provider, Stripe, securely stores billing-related data. For more information read <a href='/dataPrivacy'>our data privacy policy</a> or the data privacy policy of <a href='https://stripe.com/privacy'> Stripe</a>.",
      GTC: "GTC",
      TERMS_AND_CONDITIONS_TITLE: "General terms and conditions (GTC)",
      TERMS_AND_CONDITIONS_SUBTITLE:
        "Concircle Österreich GmbH Software as a Service (SaaS) - Contract for conDMWizard",
      TERMS_AND_CONDITIONS_VALIDITY: `By completing the online registration form or setting up a user account, you accept the following General Terms and Conditions (GTC)
      of  
      
      Concircle Österreich GmbH 
      Wienerbergstraße 11 
      1100 Wien 
      Österreich 
      
      Services in electronic data processing  
      hereinafter referred to as "concircle" "Provider" or "we / us"  
      valid from: 25.3.2024.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_ONE: "Definitions",
      TERMS_AND_CONDITIONS_CHAPTER_ONE_TEXT: `"SaaS service" or "service": SaaS stands for "Software as a Service". A software product is operated on the IT infrastructure of the software provider or a third party (e.g. Microsoft Azure or Amazon Web Services) and can be used by customers as a service. Maintenance and updates of the software are carried out directly by the provider.
      conDMWizard is an application that does not need to be installed on the customer's computer. A modern web browser and an Internet connection are sufficient to run the program. conDMWizard is a SaaS service. 
      A "subscription" is the right to use conDMWizard as SaaS for the period of time and content agreed in the service description. The service description of the subscription specifies, for example, how many different profiles (Tenant & SAP DM plant) can be created.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_TWO: "Validity",
      TERMS_AND_CONDITIONS_CHAPTER_TWO_TEXT: `The General Terms and Conditions (GTC), as amended from time to time, apply to conDMWizard when purchasing a subscription for a fee.
      In the event of contradictory provisions, written individual agreements between contractual partners shall take precedence over these GTC.  
      The current version of these GTC can be viewed at any time on the conDMWizard website or requested at condmw-support@concircle.com.  
      Deviating contractual conditions of the contractual partner, in particular the contractual partner's purchasing conditions, do not become part of the contract, even if the provider does not expressly object to them. Other contractual conditions of the contractual partner therefore only apply if the provider expressly recognizes them in writing.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_THREE:
        "Subject matter of the contract, conclusion of the contract",
      TERMS_AND_CONDITIONS_CHAPTER_THREE_TEXT: `Concircle Österreich GmbH (hereinafter referred to as "Provider") provides Software as a Service (SaaS) services for its customers via the Internet in the area of business software.
      The subject matter of this contract is a subscription to conDMWizard as SaaS, which is provided by concircle. The exact scope of services results from the explanations in these GTC as well as from the service description of the subscription, which is selected when ordering. Individual agreements to adapt the scope of services are generally possible. These must be made in writing.  
      In principle, there is no entitlement to the development of own programs or the modification of standard software for special customer requirements, unless these have been agreed in writing in individual contracts.  
      The SaaS services offered are open to both entrepreneurs* within the meaning of Section iSd § 1 Abs 1 Z 1 of the Austrian Consumer Protection Act ("KSchG") and consumers within the meaning of Section § 1 Abs 1 Z 2 KSchG. These GTCs contain provisions that apply only to consumers or only to entrepreneurs. These are marked with "Only applicable to consumers" or "Only applicable to entrepreneurs". All provisions not marked in this way apply equally to entrepreneurs and consumers.`,

      TERMS_AND_CONDITIONS_SECTIONTITLE_FOUR: "Service Description",
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_ONE: "Access to conDMWizard",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_ONE: `For the duration of this contract, the Provider shall provide the Customer with the latest version of the conDMWizard software for use via the Internet free of charge or for a fee. Upon conclusion of this contract, you will receive access to conDMWizard within the scope of the features defined in the selected subscription.
      After further development, the current range of functions of the software can be found in the service description on the provider's website at www.condmw.concircle.com. 
      After purchasing your subscription, you can use the available functions immediately. The prerequisites for using the subscription are a modern web browser installed on your user device and permanent Internet access on your device. The SaaS services will not work if internet access is interrupted.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_TWO:
        "User authorizations, user accounts",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_TWO: `A user account has a maximum of one conDMWizard subscription at the same time, which contains functions according to the service description.
      User accounts: A user account has an entry in the user table with a user name, e-mail address and password. The password is always set by the users themselves and is never stored in the database in plain text, but only in encrypted form.  
      Entering the user data: When registering, you have the option of entering the data for the desired user account (user name and e-mail address). Changes to the user account data can only be made by the provider. You can request such a change by sending an e-mail to the relevant support e-mail address.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_THREE:
        "Availability on different web browsers",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_THREE: `The availability and correct functioning of conDMWizard is only guaranteed when using one of the following web browsers in a current version:
      Google Chrome 
      Older browsers are not supported. The use of conDMWizard is not possible with these browsers.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_FOUR:
        "Availability on different user devices and screen sizes",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_FOUR: `In principle, conDMWizard can be used on a wide variety of Internet-enabled user devices with different screen sizes. conDMWizard has some functions that require a certain screen size for meaningful use (such as the display of tables with a certain minimum width), it is possible that these functions are hidden from a certain minimum screen width and are therefore not available. This can restrict the functionality of conDMWizard on devices with a small screen width.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_FIVE:
        "Availability of the SaaS service and disruptions",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_FIVE: `The provider takes all necessary measures to keep the services available at all times. Nevertheless, one hundred percent availability cannot be guaranteed. Temporary interruptions to the service may occur as a result of urgently required maintenance work, software updates or security or capacity utilization problems as well as events beyond the control of the provider (such as a short-term server failure due to force majeure or similar).
      In the event of serious disruptions that make the use of the service impossible or at least severely restrict it, the provider will take all measures as quickly as possible during business hours Monday - Friday 9am - 5pm to rectify the underlying problem immediately, at least to the extent that the essential functions of conDMWizard once again function largely without restriction.
      The prerequisite for the rectification of a fault is immediate written notification by the customer.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_SIX:
        "Malfunctioning errors (bugs)",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_SIX: `The Provider continuously monitors the functionality of the software and eliminates all software errors immediately in accordance with the technical possibilities. A bug is deemed to exist in particular if the software does not meet the requirements set out in the service description on the Provider's website, delivers incorrect results or does not function properly in any other way, so that the use of the software is impossible or restricted.
      By concluding the contract, you acknowledge and agree that the software may still contain malfunctions (so-called bugs).  Any malfunctions that occur will be rectified by the provider if all of the following conditions are met:  
      it is a malfunction of a function that is listed in the service description of the subscription or individual customer agreement, 
      the provider receives a comprehensible error description from the customer that contains at least the following information:
      What actions (mouse clicks, keystrokes) were performed before the error occurred?  
      What was the expected result? What was the actual result? 
      Which web browser (incl. version number), what operating system (Linux, Windows, MacOS, Android, iOS, ...) and what user device was used the malfunction must be reproducible by the provider under the same / similar conditions.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_SEVEN:
        "Maintenance, modifications",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_SEVEN: `The provider occasionally creates updates of conDMWizard with bug fixes, improvements or functional enhancements. These are made available to all users without them having to become active. It is not possible to opt out of updates, as these may contain important bug fixes or security updates. The functionality of conDMWizard may change slightly as a result of updates without affecting the agreed basic functionality.`,
      TERMS_AND_CONDITIONS_CHAPTER_FOUR_TEXT: "",
      TERMS_AND_CONDITIONS_SECTIONTITLE_FIVE: "Granting of rights / copyrights",
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FIVE_ONE:
        "Rights to the software / source code of conDMWizard",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FIVE_ONE: `The provider grants you the non-exclusive, non-transferable and non-sublicensable right to use the user interface of conDMWizard, limited to the service description of your purchased subscription.
      The full rights to the software and its source code remain with the provider. In particular, you are prohibited from downloading, reproducing, modifying or making conDMWizard available to third parties in whole or in part.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FIVE_TWO:
        "Permission to use sample data and documents provided by conDMWizard",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FIVE_TWO: `In some functions of conDMWizard we provide you with initial documents. You may use this data and media within the scope of the functions of conDMWizard, print it out, adapt and modify it or delete it. You are not permitted to use the sample data provided for purposes other than those intended in conDMWizard, in particular passing it on to third parties or selling it.
      However, you do not receive any rights of use to the texts, documents, the data collection or the image and video files. The full rights of use remain with the provider.
      We accept no liability for the accuracy or appropriateness of the sample data. The use of the sample data is therefore at your own discretion and responsibility.`,
      TERMS_AND_CONDITIONS_CHAPTER_FIVE_TEXT: "",
      TERMS_AND_CONDITIONS_SECTIONTITLE_SIX: "Your obligations",
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_ONE: "Use of the SaaS service ",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_ONE: `You may only use the services you have subscribed to for the purposes intended and only within the scope of the service.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_TWO: "Access data",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_TWO: `You are prohibited from making the functions of the SaaS service available or accessible to third parties (including via remote access to the device you are using). You must protect the access data assigned to you from access by third parties and may not pass them on to unauthorized persons.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_THREE: "Content entered by you",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_THREE: `The provider does not check the data entered or uploaded by customers.
      You are also responsible for complying with all data protection regulations concerning the data you enter.
      You undertake to indemnify and hold the Provider harmless in connection with claims by third parties due to such infringements of rights that are based on unlawful use of the SaaS service by you or with your approval.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_FOUR:
        "Notification of malfunctions",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_FOUR: `You undertake to report any serious malfunctions of the SaaS service immediately in writing to the respective support e-mail address of the offer or to condmw-support@concircle.com.`,
      TERMS_AND_CONDITIONS_CHAPTER_SIX_TEXT: "",
      TERMS_AND_CONDITIONS_SECTIONTITLE_SEVEN:
        "Remuneration and terms of payment",
      TERMS_AND_CONDITIONS_CHAPTER_SEVEN_TEXT: `The amount of the one-off and/or ongoing usage fee can be seen on conDMWizard or in the payment service provider Stripe. The Provider has the right to transmit the invoice electronically.
      The Customer undertakes to pay the Provider the agreed monthly fee plus statutory VAT for the provision of the software in accordance with the respective subscription model in its current version.
      When booking a paid subscription, the respective monthly invoice amount shall be charged. If no special payment terms have been agreed in individual cases, the invoice amount is due immediately via the payment service provider, free of charges and deductions.
      The Provider is entitled to adjust the fees and service content by written notification to the customer with a notice period of 1 month, provided this is reasonable for the customer. The prerequisite and reasons for such a change in service are, in particular, technical progress and the further development of the software. The change shall be made in the manner and to the extent that the scope and performance of the software are changed. If the customer does not wish to continue the contract at the changed fee conditions, he is entitled to extraordinary termination with a notice period of 14 days from the date of the change.
      All prices are to be calculated in accordance with the latest consumer price index (CPI) published by Statistics Austria at the time the contract is concluded.
      Consumer Price Index (CPI) at the time the contract was concluded. The respective index adjustment takes place annually in September. The non-enforcement of the index adjustment does not constitute a waiver of the assertion of price increases.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_EIGHT:
        "Type and duration of use, termination and dissolution",
      TERMS_AND_CONDITIONS_CHAPTER_EIGHT_TEXT: `The SaaS contract is concluded for an indefinite period. The contractual relationship begins with the confirmation of payment by the payment service provider. The billing period can be one month or one year. Termination of the subscription can be initiated at any time via the payment service provider, with termination taking effect at the end of the month or the end of the year, depending on the billing period.
      Free use can be terminated by either party at any time; no notice period to the end of the month is required. Notice of termination must be given in writing or by e-mail. Upon termination of the contractual relationship, the customer account, including all associated data, shall be deleted.  
      The Provider also has the right to immediate extraordinary termination if there are fundamental changes to the legal or technical standards and it becomes unreasonable for the Provider to provide the services in whole or in part within the scope of the purpose of the contract.
      In principle, a distinction is made between free use (limited to one month) and paid use of conDMWizard.
      Free use of conDMWizard. The following functions can be used by the customer free of charge:
      Creation and management of two profiles 
      Transporting master data: 10 times in 24 hours  
      Transporting master data: 2 data sets per transport  
      Paid use of conDMWizard (according to the subscription model in the currently valid version) 
      Paid use begins with the customer's payment via the payment service provider.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_NINE: "Warranty",
      TERMS_AND_CONDITIONS_CHAPTER_NINE_TEXT: `Only applicable to entrepreneurs: Warranty claims expire six months after conclusion of the contract. 
      Only applicable to entrepreneurs: The notice of defects must be made in writing and contain all the information listed. 
      Only applicable to consumers: The warranty rights of the customer as a consumer are subject to the statutory provisions according to §§ 922 to 933 ABGB.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_TEN: "Liability ",
      TERMS_AND_CONDITIONS_CHAPTER_TEN_TEXT: `Only applicable to entrepreneurs: The provider shall only be liable to the customer for damages for which it is demonstrably at fault in the event of gross negligence. This shall also apply mutatis mutandis to damage attributable to third parties engaged by the Provider. In the event of personal injury caused by the provider, the provider shall be liable without limitation.
      Only applicable to entrepreneurs: Liability for indirect damages - such as loss of profit, costs associated with business interruption, loss of data or third-party claims - is expressly excluded.
      Only applicable to entrepreneurs: Claims for damages shall become time-barred in accordance with the statutory provisions, but no later than one year after knowledge of the damage and the damaging party.
      Only applicable to entrepreneurs: If the provider performs the work with the assistance of third parties and warranty and/or liability claims against these third parties arise in this context, the provider shall assign these claims to the customer(s). In this case, the customer(s) shall give priority to these third parties.
      Only applicable to entrepreneurs: Warranty claims and claims for damages by the customer that go beyond those specified in these GTC or in the individual contract - regardless of the legal grounds - are excluded.
      Only applicable to entrepreneurs: In particular, the provider is not liable for the unauthorized acquisition of personal user data by third parties.
      Only applicable to consumers: The provider's liability for slight negligence is excluded in the event of data loss or third-party claims. In the event of personal injury for which the provider is responsible, the provider shall be liable without limitation.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_ELEVEN:
        "Only applicable to consumers: Statutory right of withdrawal",
      TERMS_AND_CONDITIONS_CHAPTER_ELEVEN_TEXT: `As a consumer, you have the right to cancel your order for the conDMWizard subscription within fourteen days of concluding the contract without giving any reason. In order to exercise this right of withdrawal, you must inform the provider of your decision to withdraw from the contract by means of a clear statement (e.g. a letter sent by post or e-mail).
      To comply with the withdrawal period, it is sufficient that you send the notification of the exercise of the right of withdrawal before the expiry of the withdrawal period.
      If you withdraw from your conDMWizard subscription order, we will refund all payments we have received from you immediately and at the latest within fourteen days of the day on which we receive notification of your withdrawal.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_TWELVE: "Severability clause",
      TERMS_AND_CONDITIONS_CHAPTER_TWELVE_TEXT:
        "Should individual provisions of these GTC be or become invalid, this shall not affect the remaining content of these GTC. The contracting parties shall work together in partnership to find a provision that comes as close as possible to the invalid provisions.",
      TERMS_AND_CONDITIONS_SECTIONTITLE_THIRTEEN:
        "Applicable law, place of performance and jurisdiction",
      TERMS_AND_CONDITIONS_CHAPTER_THIRTEEN_TEXT:
        "This contract shall be governed by Austrian substantive law to the exclusion of the conflict of law rules of private international law.",
      TERMS_AND_CONDITIONS_SECTIONTITLE_FOURTEEN: "Other",
      TERMS_AND_CONDITIONS_CHAPTER_FOURTEEN_TEXT: `Amendments to the contract and these GTC must be made in writing. The same applies to any waiver of this formal requirement. Verbal collateral agreements do not exist.
      Only applicable to entrepreneurs: In the event of disputes arising from this contract that cannot be settled amicably, the contracting parties mutually agree to consult registered mediators (ZivMediatG) specializing in commercial mediation from the list of the Ministry of Justice for the out-of-court settlement of the conflict. If no agreement can be reached on the choice of commercial mediators or on the content of the dispute, legal action will be initiated at the earliest one month after the failure of the negotiations.
      In the event that the mediation does not take place or is terminated, Austrian law shall apply in any court proceedings that may be initiated. All necessary expenses incurred as a result of prior mediation, in particular those for any legal advisor(s) called in, can be claimed as "pre-litigation costs" in court or arbitration proceedings as agreed.
      Only applicable to consumers: Alternative dispute resolution. Although the Licensor is not obliged to participate in alternative dispute resolution procedures and will not necessarily participate in such procedures, the following information must be provided:
      The European Union's online dispute resolution platform regarding online transactions can be accessed here: http://ec.europa.eu/consumers/odr/.
      The two Austrian dispute resolution bodies for online consumer transactions can be found at the following internet addresses: https://ombudsmann.at/ and http://www.verbraucherschlichtung.or.at/.`,
    },
  },
  de: {
    translation: {
      "Internal server error":
        "Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es erneut.",
      DATA_PRIVACY: "Datenschutz",
      COOKIE: "Cookie",
      DURATION: "Dauer",
      COOKIE_SETTINGS: "Cookie Einstellungen",
      NECCESARY_COOKIES: "Notwendig (immer Aktiv)",
      NO_EXPERATION_DATE: "Kein Ablaufdatum",
      COOKIE_SETTINGS_TOKEN_DESCRIPTION:
        "Dieser Cookie wird von der Applikation gesetzt und speichert den Token. Dieser Token ist notwendig, um Sie gegen unsere Applikation zu Authentifizieren.",
      COOKIE_SETTINGS_PROFILES_DESCRIPTION:
        "Dieser Cookie wird von der Applikation gesetzt und speichert die Profile. Diese Profile sind notwendig, um die Applikation zu verwenden.",
      COOKIE_SETTINGS_LAST_ACTIVE_DESCRIPTION:
        "Dieser Cookie wird von der Applikation gesetzt und speichert die letzte Aktive Zeit. Diese wird verwendet, um Sie ggf. abzumelden.",
      COOKIE_SETTINGS_COOKIE_CONSENT_DESCRIPTION:
        "Dieser Cookie wird von der Applikation gesetzt und speichert die Zustimmung des Benutzers zu den Cookies.",
      COOKIE_SETTINGS_COOKIE_NECCESARY_DESCRIPTION:
        'Dieser Cookie wird von der Applikation gesetzt und dient dazu, die Zustimmung des Nutzers zu den Cookies der Kategorie "Notwendig" zu erfassen.',
      COOKIE_SETTINGS_ARRAffinitySameSite_DESCRIPTION:
        "Diser Cookie wird von Microsoft Azure gesetzt. Er ist Notwendig, um Anfragen an die richtige Instanz in einer Umgebungen mit Lastausgleich weiterzuleiten.",
      COOKIE_SETTINGS_STRIPE_MID_DESCRIPTION:
        "Wird zu Betrugspräventionszwecken gesetzt und hilft uns, das Risiko im Zusammenhang mit einer Transaktion einzuschätzen.",
      COOKIE_SETTINGS_STRIPE_SID_DESCRIPTION:
        "Wird zu Betrugspräventionszwecken gesetzt und hilft uns, das Risiko im Zusammenhang mit einer Transaktion einzuschätzen.",
      COOKIE_SETTINGS_COOKIE_PERMS_DESCRIPTION:
        "Speichert die Cookie-Einstellungen eines Benutzers.",
      COOKIE_SETTINGS_STRIPE_MACHINE_IDENTIFIER_DESCRIPTION:
        "Um Betrug zu verhindern, ermöglicht es Stripe, den Computer auf dem Stripe-Dashboard zu identifizieren.",
      COOKIE_SETTINGS_STRIPE_PRIVATE_MACHINE_IDENTIFIER_DESCRIPTION:
        "Identifiziert den Computer über Anmeldesitzungen und Benutzer hinweg, um Betrug zu verhindern.",
      COOKIE_SETTINGS_STRIPE_CSRF_DESCRIPTION:
        "Verhindert, dass Angreifer von anderen Websites aus Anfragen stellen, die Benutzerdaten an Stripe ändern.",
      COOKIE_SETTINGS_MAIN_TEXT: ` Diese Website verwendet Cookies, um die Navigation auf der Website
      für Sie zu erleichtern. Von diesen werden die als notwendig
      eingestuften Cookies in Ihrem Browser gespeichert, da sie für das
      Funktionieren der grundlegenden Funktionen der Website
      unerlässlich sind. Wir verwenden auch Cookies von Dritten, die uns
      helfen zu analysieren und zu verstehen, wie Sie diese Website
      nutzen. Diese Cookies werden nur mit Ihrer Zustimmung in Ihrem
      Browser gespeichert.`,
      COOKIE_SETTINGS_NECCESARY_TEXT: `Notwendige Cookies sind für das ordnungsgemäße Funktionieren der
      Website unbedingt erforderlich. Zu dieser Kategorie gehören nur
      Cookies, die grundlegende Funktionalitäten und
      Sicherheitsmerkmale der Website gewährleisten. Diese Cookies
      speichern keine persönlichen Informationen.`,
      COOKIE_DURATION_MINUTE: "{{minute}} Minute(n)",
      COOKIE_DURATION_HOUR: "{{hour}} Stunde(n)",
      COOKIE_DURATION_DAY: "{{day}} Tag(e)",
      COOKIE_DURATION_MONTH: "{{month}} Monat(e)",
      COOKIE_DURATION_YEAR: "{{year}} Jahr(e)",
      COOKIE_DURATION_SESSION: "Session",
      LOGIN: "Anmelden",
      LOGOUT: "Abmelden",
      MANAGE_BILLING: `Manage Billing`,
      REGISTER: "Registrieren",
      EMAIL: "E-Mail",
      ENTER_EMAIL: "E-Mail eingeben",
      ENTER_VALID_EMAIL: "Bitte gib eine gültige E-Mail-Adresse ein.",
      PASSWORD: "Passwort",
      ENTER_PASSWORD: "Passwort eingeben",
      ENTER_VALID_PASSWORD: "Das Passwort muss mindestens 8 Zeichen lang sein.",
      FIRST_NAME: "Vorname",
      ENTER_FIRST_NAME: "Vorname eingeben",
      LAST_NAME: "Nachname",
      ENTER_LAST_NAME: "Nachname eingeben",
      REPEAT_PASSWORD: "Passwort wiederholen",
      PASSWORDS_DO_NOT_MATCH:
        "Das Passwort muss mit dem oben Angegebenen übereinstimmen.",
      ENTER_EMAIL_AND_PASSWORD: "Bitte gib eine E-Mail und ein Password ein!",
      REGISTRATION_FILL_FORM_FIRST: "Bitte fülle das Formular aus!",
      USER_ALREADY_EXIST:
        "Registrierung fehlgeschlagen: Benutzer existiert bereits!",
      VERIFY_YOUR_ACCOUNT_NOW: "Verifiziere deinen Account jetzt!",
      VERIFY_YOUR_ACCOUNT: "Verifiziere deinen Account",
      SUCCESFULLY_REGISTERED:
        "Verifikations E-Mail wurde gesendet. Du hast dich erfolgreich registriert!",
      SUCCESFULLY_LOGGED_IN: "Du hast dich erfolgreich angemeldet!",
      ERROR_LOGIN:
        "Anmeldung fehlgeschlagen! Bitte versuche es erneut oder überprüfe deine Anmeldedaten!",
      WRONG_CREDENTIALS: "Bitte gib deine gültigen Anmeldeinformationen ein!",
      CLICK_BUTTON_TO_VERIFY:
        "Bitte drücke den Button oben, um deinen Account zu verifizieren. Durch die Verifizierung deiner E-Mail wird auch dein Free Version Plan aktiviert und ist für 1 Monat gültig",
      DSGVO_TEXT: `Gemäß Datenschutzgrundverordnung (DSGVO) unterrichten wir Sie über die Speicherung Ihrer Daten. Die Einwilligung zur Speicherung und Nutzung Ihrer Daten erfolgt freiwillig und kann jederzeit widerrufen werden. Mit der Regestrierung bestätigen Sie außerdem, dass Sie die AGBs gelesen haben und akzeptieren.`,
      SAVE_AND_ACCEPT: "Speichern und Akzeptieren",
      CONTACT_US: "Kontaktiere uns",
      SEND_US_A_MESSAGE: "Schreib uns eine Nachricht",
      MESSAGE: "Nachricht",
      SEND: "Senden",
      MESSAGE_SEND_SUCCESFULLY: "Nachricht wurde erfolgreich gesendet!",
      MESSAGE_SEND_ERROR:
        "Die Nachricht konnte nicht versendet werden. Bitte versuche es nochmal.",
      CALL_US: "Ruf uns an",
      FACEBOOK: "Facebook",
      LINKEDIN: "LinkedIn",
      OUR_ADDRESS: "Unsere Adresse",
      SELECT: "Auswahl",
      CLOSE: "Schließen",
      CONFIRM: "Bestätigen",
      UPDATE: "Aktualisieren",
      PROFILE_DATA_TITLE: "Profil {{metod}}",
      ADD: "Erstellen",
      DELETE: "Löschen",
      EDIT: "Bearbeiten",
      START_UPLOAD: "Upload starten",
      VERSION: "Version",
      ACTIONS: "Aktionen",
      STATUS: "Status",
      TRANSPORT: "Transport",
      USER_NOT_VERIFIED: "Bitte bestätige deine E-Mail-Adresse.",
      SELECT_PROFILE_ERROR_MESSAGE: "Bitte wähle ein Profil aus!",
      CHECK_PROFILES: "Bitte überprüfe deine Profile",
      PROFIL_NOT_AVAILABLE:
        "Profil(e) sind nicht verfügbar! Bitte überprüfe die Profilkonfiguration auf der Profil Management Seite.",
      PROFILE_MANAGEMENT_TITLE: "Profil Management",
      PROFILE_MANAGEMENT_SUBTITLE:
        "Verwalte alle deine Systeme und Werke in Form eines conDMWizard-Profils.",
      NO_PROFILES_CREATED_YET: "Bisher wurden keine Profile erstellt",
      ADD_PROFILE: "Profil erstellen",
      DELETE_PROFILE: "Profil löschen",
      EDIT_PROFILE: "Profil bearbeiten",
      CHOSSEN_PLAN_ONLY_ALLOWS_X_PROFILES:
        "Der gewählte Plan erlaubt nur {{x}} Profile. Bitte lösche die Profile, die du nicht mehr benötigst, sonst werden automatisch die zuletzt erstellten Profile gelöscht!",
      NAME: "Name",
      DESCRIPTION: "Beschreibung",
      PLANT: "Werk",
      DMC_URL: "DM-API Endpunkt",
      TOKEN_URL: "Token URL",
      CLIENT_ID: "Client ID",
      CLIENT_SECRET: "Client Secret",
      PROFILE_SAFETY_WARNING_COUNTER_REACHED:
        "Du hast das Limit zum Erstellen eines Profiles mit indentem Werk und Tenant erreicht!",
      ERROR_TOO_MANY_PROFILE_CREATED: `Der Mandant und das Werk für das Profil, das du erstellen möchtest, wurde bereits 3 Mal erstellt! Aus Sicherheitsgründen kannst du nicht mehr als 3 Profile für denselben Mandanten und dasselbe Werk erstellen! Bitte wende dich an den Support über `,
      PROFILE_LIMIT_REACHED:
        "Du hast das Limit zum Erstellen von Profilen erreicht!",
      PROFILE_CREATION_LIMIT_REACHED:
        "Dateien für Upload hierher ziehen oder Drucktaste Hochladen verwenden.",
      UPLOAD_COLLECTION_NO_DATA_TEXT: "Keine Dateien gefunden",
      UPLOAD_COLLECTION_DATA_DESCRIPTION:
        "Dateien für Upload hierher ziehen oder Drucktaste Hochladen verwenden, um eine andere Datei zu laden.",
      UPLOAD_COLLECTION_DATA_TEXT: "Datei erfolgreich hochgeladen",
      DOWNLOAD_EXCEL_TEMPLATE: "Excel Vorlage herunterladen",
      DC_GROUPS_READY_TO_UPDATE_MESSAGE:
        "Bereit zum Hochladen Ihrer Data Collection Groups",
      PLEASE_USE_EXCEL_TEMPLATE: "Bitte verwende die Excel-Vorlage",
      PARAMETERS_OR_ASSIGNMENTS_MISSING:
        "Es gibt Parameter oder Zuweisungen ohne einen gültigen Namen für die Data Collection Group.",
      DC_UPLOAD_SUCCES_STATUS_MESSAGE:
        "{{numberOne}} von {{numberTwo}} erfolgreich hochgeladen",
      DC_UPDATE_SUCCES_STATUS_MESSAGE:
        "{{numberOne}} von {{numberTwo}} erfolgreich aktualisiert",
      LANDING_PAGE_TITLE: "Willkommen im conDMWizard",
      LANDING_PAGE_SUBTITLE: `conDMWizard ist eine spezialisierte SaaS-Webanwendung für das effiziente Management von Stammdaten innerhalb der SAP Digital Manufacturing (SAP DM) Landschaft. Sie ermöglicht das mühelose Hochladen, Migrieren und Synchronisieren von Stamm- und Konfigurationsdaten über verschiedene Werke und Tenants von SAP DM hinweg.`,
      DATA_COLLECTION_UPLOAD_TITLE: "Data Collection Upload",
      DATA_COLLECTION_TRANSFER_TITLE: "Data Collection Transfer",
      POD_TRANSPORT_TITLE: `POD \n Transport`,
      DATA_COLLECTION_UPLOAD_SUBTITLE_TILE:
        "Lade deine Data Collection Groups mittels Excel-Datei hoch",
      DATA_COLLECTION_UPLOAD_SUBTITLE:
        "Lade deine Data Collection Groups mittels Excel-Datei hoch und aktualisiere sie.",
      DATA_COLLECTION_TRANSFER_SUBTITLE_TILE:
        "Transferiere deine Data Collection Groups zwischen Werken und Systemen",
      DATA_COLLECTION_TRANSFER_SUBTITLE:
        "Transferiere deine Data Collection Groups zwischen Werken und Systemen",
      POD_TRANSPORT_SUBTITLE_TILE:
        "Transportiere deine PODs zwischen Werken und Systemen",
      POD_TRANSPORT_SUBTITLE:
        "Transportiere deine PODs zwischen Werken und Systemen",
      PLANT_DID_NOT_EXIST_IN_DM:
        "Werk existiert nicht in diesem SAP DM Tenant!",
      PROFILE_ALREADY_EXIST_FOR_THIS_PLANT_AND_SYSTEM:
        "Profil mit demselben Namen und Werk existiert bereits!",
      ERROR_GET_TOKEN:
        "Bitte überprüfe deine SAP DM Tenant-Anmeldeinformationen und versuche es erneut!",
      PROFILE_CREATED_SUCCESFULLY: "Profil wurde erfolgreich erstellt!",
      PROFILE_DATA_INCORRECT:
        "Profildaten sind nicht korrekt. Bitte überprüfe deine Eingabe.",
      USER_DOES_NOT_EXIST:
        "Benutzer existiert nicht. Bitte registriere dich zuerst!",
      LOGOUT_SUCCESS: "Du hast dich erfolgreich abgemeldet!",
      NOT_ALL_REQUIRED_FIELDS_EXIST: "Bitte fülle alle Muss-Felder aus!",
      DC_UPDATE_CONFIRMATION_MESSAGE:
        "Möchtest du die ausgewählten DC-Gruppen wirklich löschen und neu erstellen?",
      DC_UPLOAD_PROFILE_NOT_SELECTED: `Bitte wähle ein conDMW-Profil aus, das

                                              den DM-API-Endpunkt,
                                              die Token-URL,
                                              die Client-ID und
                                              das Client-Secret

      enthält, um deine Data Collection Groups hochzuladen.

      Wenn du kein conDMW-Profil hast, erstelle bitte eines auf der
      Profile Management Seite.`,
      DC_UPLOAD_NO_DC_GROUP_FOUND:
        "Es wurde keine Data Collection Group gefunden!",
      DC_UPLOAD_SELECT_EXCEL_FILE: "Bitte wähle eine Excel-Datei aus.",
      DC_UPDATE_SELECT_DIALOG_TITLE: "DC-Gruppen für Aktualisierung auswählen",
      PROFILE_TO_TRANSPORT_FROM: "Transportieren von Profil:",
      PROFILE_TO_TRANSPORT_TO: "Transportieren zu Profil:",
      PROFILE_DETAILS_PROFILE_SELECTION_ERROR:
        "Stelle sicher, dass die Profile für den Transport von und nach nicht leer und nicht gleich sind.",
      SAP_DM_ERROR_MESSAGE: "SAP DM Fehlermeldung",
      DC_GROUP_NAME: "Data Collection Group",
      POD_NAME: "POD Name",
      NEW_DC_GROUP_NAME: "Neue Data Collection Group",
      NEW_POD_NAME: "Neuer POD Name",
      POD_TRANSPORT_SUCCES_MESSAGE:
        "POD {{podId}} wurde erfolgreich in das Werk {{plant}} transportiert",
      DC_TRANSPORT_SUCCES_MESSAGE:
        "Data Collection {{to}} wurde erfolgreich in das Werk {{plant}} transportiert",
      HOW_DOES_IT_WORK: "Wie funktioniert es?",
      DC_UPLOAD_DOWNLOAD_EXCEL_TEMPLATE:
        "Lade die Excel-Datei mit dem Button 'Excel Vorlage herunterladen' herunter.",
      DC_UPLOAD_FILL_OUT_THE_EXCEL_FILE:
        "Fülle die Excel-Datei mit Ihren Data Collection Groups aus.",
      DC_UPLOAD_SELECT_PROFILE:
        "Wähle das Profil aus, zu dem du die Data Collection Group(s) hochladen möchtest.",
      DC_UPLOAD_DRAG_AND_DROP_FILE:
        "Ziehe die Excel-Datei in den Bereich, der von der gestrichelten Linie umgeben ist.",
      DC_UPLOAD_SELECT_FILE:
        "Oder wähle die Excel-Datei mit dem Datei-Upload-Button aus.",
      DC_UPLOAD_START_UPLOAD:
        'Klicke auf den Button "Upload starten", um die Data Collection Group(s) hochzuladen.',
      SELECT_PROFILE_TRANSFER_FROM:
        "Wähle ein Profil von dem Übertragen werden soll aus.",
      SELECT_PROFILE_TRANSFER_TO:
        "Wähle ein Profil zu dem Übertragen werden soll aus.",
      ENTER_DC_GROUP_NAME_AND_VERSION:
        "Gib den Namen der Data Collection Group und die Version ein.",
      NEW_DC_NAME_AND_VERSION_SET_AUTO:
        "Der neue Name der Data Collection Group und die Version werden automatisch festgelegt.",
      CHANGE_DC_GROUP_NAME_AND_ADD_DESCRIPTION:
        "Du kannst den Namen der Data Collection Group ändern und eine Beschreibung hinzufügen.",
      DC_TRANSPORT_BUTTON:
        "Mit dem Transport-Button kannst du die Data Collection Group zum ausgewählten Profil transportieren.",
      SELECT_PROFILE_TRANSPORT_FROM:
        "Wähle ein Profil von dem Transportieren werden soll aus.",
      SELECT_PROFILE_TRANSPORT_TO:
        "Wähle ein Profil zu dem Transportieren werden soll aus.",
      ENTER_POD_NAME: "Gib den POD Namen ein.",
      NEW_POD_NAME_SET_AUTO: "Der neue POD Name wird automatisch gesetzt.",
      CHANGE_POD_NAME_AND_ADD_DESCRIPTION:
        "Du kannst den POD Namen ändern und eine Beschreibung hinzufügen.",
      POD_TRANSPORT_BUTTON:
        "Mit dem Transport-Button kannst du den POD zum ausgewählten Profil transportieren.",
      PROFILE_MANAGEMENT_ADD_PROFILE_HOW_TO:
        'Mit dem Button "Profil erstellen" kannst du ein neues Profil erstellen.',
      PROFILE_MANAGEMENT_ADD_PROFILE_DIALOG:
        "Gib alle erforderlichen (*) Informationen ein und klicke auf OK, um das Profil deinem conDMW-Konto hinzuzufügen.",
      PROFILE_MANAGEMENT_EDIT_PROFILE_HOW_TO:
        'Mit dem Button "Profil bearbeiten" kannst du dein Profil bearbeiten.',
      PROFILE_MANAGEMENT_EDIT_PROFILE_DIALOG:
        "Ändere die Informationen und klicke auf OK, um das Profil zu aktualisieren.",
      PROFILE_MANAGEMENT_DELETE_PROFILE_HOW_TO:
        'Mit dem Button "Profil löschen" kannst du dein Profil löschen.',
      PROFILE_MANAGEMENT_DELETE_PROFILE_DIALOG:
        "Überprüfe, ob das richtige Profil ausgewählt ist, und klicke auf OK, um das Profil zu löschen.",
      PRIVACY_POLICY: "Privacy Policy",
      DATA_PRIVACY_TITLE: "Datenschutzerklärung",
      DATA_PRIVACY_CONTENT: `<div class="et_pb_text_inner">
      <h2 id="m716">Präambel</h2>
      <p>
        Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären,
        welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als
        „Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang
        verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
        Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung
        unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen
        Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer
        Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
        „Onlineangebot“).
      </p>
      <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
      <p>Stand: 21. März 2023</p>
      <p class="seal">
        <a
          href="https://datenschutz-generator.de/"
          title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
          target="_blank"
          rel="noopener noreferrer nofollow"
          ><img
            fetchpriority="high"
            decoding="async"
            src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-de.png"
            alt="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            width="250"
            height="250"
        /></a>
      </p>
      <h2>Inhaltsübersicht</h2>
      <ul class="index">
        <li><a class="index-link" href="#m716">Präambel</a></li>
        <li><a class="index-link" href="#m3">Verantwortlicher</a></li>
        <li>
          <a class="index-link" href="#m11">Kontakt Datenschutzbeauftragter</a>
        </li>
        <li>
          <a class="index-link" href="#mOverview">Übersicht der Verarbeitungen</a>
        </li>
        <li><a class="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a></li>
        <li><a class="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
        <li>
          <a class="index-link" href="#m25"
            >Übermittlung von personenbezogenen Daten</a
          >
        </li>
        <li>
          <a class="index-link" href="#m24">Datenverarbeitung in Drittländern</a>
        </li>
        <li><a class="index-link" href="#m12">Löschung von Daten</a></li>
        <li><a class="index-link" href="#m134">Einsatz von Cookies</a></li>
        <li><a class="index-link" href="#m317">Geschäftliche Leistungen</a></li>
        <li>
          <a class="index-link" href="#m225"
            >Bereitstellung des Onlineangebotes und Webhosting</a
          >
        </li>
        <li>
          <a class="index-link" href="#m182">Kontakt- und Anfragenverwaltung</a>
        </li>
        <li><a class="index-link" href="#m391">Kommunikation via Messenger</a></li>
        <li>
          <a class="index-link" href="#m735"
            >Videokonferenzen, Onlinemeetings, Webinare und Bildschirm-Sharing</a
          >
        </li>
        <li><a class="index-link" href="#m210">Bewerbungsverfahren</a></li>
        <li><a class="index-link" href="#m29">Cloud-Dienste</a></li>
        <li>
          <a class="index-link" href="#m638"
            >Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</a
          >
        </li>
        <li>
          <a class="index-link" href="#m263"
            >Webanalyse, Monitoring und Optimierung</a
          >
        </li>
        <li><a class="index-link" href="#m264">Onlinemarketing</a></li>
        <li>
          <a class="index-link" href="#m136"
            >Präsenzen in sozialen Netzwerken (Social Media)</a
          >
        </li>
        <li>
          <a class="index-link" href="#m328"
            >Plugins und eingebettete Funktionen sowie Inhalte</a
          >
        </li>
        <li>
          <a class="index-link" href="#m15"
            >Änderung und Aktualisierung der Datenschutzerklärung</a
          >
        </li>
        <li>
          <a class="index-link" href="#m10">Rechte der betroffenen Personen</a>
        </li>
        <li><a class="index-link" href="#m42">Begriffsdefinitionen</a></li>
      </ul>
      <h2 id="m3">Verantwortlicher</h2>
      <p>
        Dr. Konrad Pfadenhauer <br />Concircle Österreich GmbH<br />Wienerbergstraße
        11<br />1100 Wien<br />Österreich
      </p>
      <p>E-Mail-Adresse:</p>
      <p>
        <a
          href="mailto:office@concircle.com"
          onClick="{window.location.href"
          ="mailto:office@concircle.com"
          }
          >office@concircle.com</a
        >
      </p>
      <p>Impressum:</p>
      <p>
        <a
          href="mailto:office@concircle.com"
          onClick="{window.location.href"
          ="mailto:office@concircle.com"
          }
          >office@concircle.com</a
        >
      </p>
      <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
      <p>
        Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die
        Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
        Personen.
      </p>
      <h3>Arten der verarbeiteten Daten</h3>
      <ul>
        <li>Bestandsdaten.</li>
        <li>Zahlungsdaten.</li>
        <li>Standortdaten.</li>
        <li>Kontaktdaten.</li>
        <li>Inhaltsdaten.</li>
        <li>Vertragsdaten.</li>
        <li>Nutzungsdaten.</li>
        <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
        <li>Bewerberdaten.</li>
        <li>Event-Daten (Facebook).</li>
      </ul>
      <h3>Kategorien betroffener Personen</h3>
      <ul>
        <li>Kunden.</li>
        <li>Beschäftigte.</li>
        <li>Interessenten.</li>
        <li>Kommunikationspartner.</li>
        <li>Nutzer.</li>
        <li>Bewerber.</li>
        <li>Geschäfts- und Vertragspartner.</li>
        <li>Abgebildete Personen.</li>
      </ul>
      <h3>Zwecke der Verarbeitung</h3>
      <ul>
        <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
        <li>Kontaktanfragen und Kommunikation.</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Direktmarketing.</li>
        <li>Reichweitenmessung.</li>
        <li>Tracking.</li>
        <li>Büro- und Organisationsverfahren.</li>
        <li>Remarketing.</li>
        <li>Konversionsmessung.</li>
        <li>Zielgruppenbildung.</li>
        <li>Verwaltung und Beantwortung von Anfragen.</li>
        <li>Bewerbungsverfahren.</li>
        <li>Feedback.</li>
        <li>Marketing.</li>
        <li>Profile mit nutzerbezogenen Informationen.</li>
        <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
        <li>Informationstechnische Infrastruktur.</li>
      </ul>
      <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
      <p>
        Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf
        deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur
        Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben
        in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im
        Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen
        diese in der Datenschutzerklärung mit.
      </p>
      <ul>
        <li>
          <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> – Die
          betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
          betreffenden personenbezogenen Daten für einen spezifischen Zweck oder
          mehrere bestimmte Zwecke gegeben.
        </li>
        <li>
          <strong
            >Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
            b) DSGVO)</strong
          >
          – Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
          Vertragspartei die betroffene Person ist, oder zur Durchführung
          vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen
          Person erfolgen.
        </li>
        <li>
          <strong
            >Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)</strong
          >
          – Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
          erforderlich, der der Verantwortliche unterliegt.
        </li>
        <li>
          <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong>
          – Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
          Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
          Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person,
          die den Schutz personenbezogener Daten erfordern, überwiegen.
        </li>
        <li>
          <strong
            >Bewerbungsverfahren als vorvertragliches bzw. vertragliches Verhältnis
            (Art. 6 Abs. 1 lit. b) DSGVO)</strong
          >
          – Soweit im Rahmen des Bewerbungsverfahrens besondere Kategorien von
          personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B.
          Gesundheitsdaten, wie Schwerbehinderteneigenschaft oder ethnische
          Herkunft) bei Bewerbern angefragt werden, damit der Verantwortliche oder
          die betroffene Person die ihm bzw. ihr aus dem Arbeitsrecht und dem Recht
          der sozialen Sicherheit und des Sozialschutzes erwachsenden Rechte ausüben
          und seinen bzw. ihren diesbezüglichen Pflichten nachkommen kann, erfolgt
          deren Verarbeitung nach Art. 9 Abs. 2 lit. b. DSGVO, im Fall des Schutzes
          lebenswichtiger Interessen der Bewerber oder anderer Personen gem. Art. 9
          Abs. 2 lit. c. DSGVO oder für Zwecke der Gesundheitsvorsorge oder der
          Arbeitsmedizin, für die Beurteilung der Arbeitsfähigkeit des
          Beschäftigten, für die medizinische Diagnostik, die Versorgung oder
          Behandlung im Gesundheits- oder Sozialbereich oder für die Verwaltung von
          Systemen und Diensten im Gesundheits- oder Sozialbereich gem. Art. 9 Abs.
          2 lit. h. DSGVO. Im Fall einer auf freiwilliger Einwilligung beruhenden
          Mitteilung von besonderen Kategorien von Daten, erfolgt deren Verarbeitung
          auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.
        </li>
      </ul>
      <p>
        Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
        Regelungen zum Datenschutz in Österreich. Hierzu gehört insbesondere das
        Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung
        personenbezogener Daten (Datenschutzgesetz – DSG). Das Datenschutzgesetz
        enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
        Richtigstellung oder Löschung, zur Verarbeitung besonderer Kategorien
        personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur
        Übermittlung sowie zur automatisierten Entscheidungsfindung im Einzelfall.
      </p>
      <h2 id="m27">Sicherheitsmaßnahmen</h2>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung
        des Stands der Technik, der Implementierungskosten und der Art, des Umfangs,
        der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
        Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und
        Freiheiten natürlicher Personen geeignete technische und organisatorische
        Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
      </p>
      <p>
        Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
        Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und
        elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs,
        der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer
        Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
        Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen
        auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
        Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von
        Hardware, Software sowie Verfahren entsprechend dem Prinzip des
        Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
        Voreinstellungen.
      </p>
      <p>
        Kürzung der IP-Adresse: Sofern IP-Adressen von uns oder von den eingesetzten
        Dienstleistern und Technologien verarbeitet werden und die Verarbeitung
        einer vollständigen IP-Adresse nicht erforderlich ist, wird die IP-Adresse
        gekürzt (auch als „IP-Masking“ bezeichnet). Hierbei werden die letzten
        beiden Ziffern, bzw. der letzte Teil der IP-Adresse nach einem Punkt
        entfernt, bzw. durch Platzhalter ersetzt. Mit der Kürzung der IP-Adresse
        soll die Identifizierung einer Person anhand ihrer IP-Adresse verhindert
        oder wesentlich erschwert werden.
      </p>
      <p>
        TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot
        übermittelten Daten zu schützen, nutzen wir eine TLS-Verschlüsselung. Sie
        erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der
        Adresszeile Ihres Browsers.
      </p>
      <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
      <p>
        Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
        dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
        Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
        offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit
        IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und
        Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen
        Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere
        entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
        dienen, mit den Empfängern Ihrer Daten ab.
      </p>
      <p>
        Datenübermittlung innerhalb der Unternehmensgruppe: Wir können
        personenbezogene Daten an andere Unternehmen innerhalb unserer
        Unternehmensgruppe übermitteln oder ihnen den Zugriff auf diese Daten
        gewähren. Sofern diese Weitergabe zu administrativen Zwecken erfolgt, beruht
        die Weitergabe der Daten auf unseren berechtigten unternehmerischen und
        betriebswirtschaftlichen Interessen oder erfolgt, sofern sie zur Erfüllung
        unserer vertragsbezogenen Verpflichtungen erforderlich ist oder wenn eine
        Einwilligung der Betroffenen oder eine gesetzliche Erlaubnis vorliegt.
      </p>
      <h2 id="m24">Datenverarbeitung in Drittländern</h2>
      <p>
        Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union
        (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die
        Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der
        Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder
        Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen
        Vorgaben.
      </p>
      <p>
        Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich
        erforderlicher Übermittlung verarbeiten oder lassen wir die Daten nur in
        Drittländern mit einem anerkannten Datenschutzniveau, vertraglichen
        Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission,
        beim Vorliegen von Zertifizierungen oder verbindlicher internen
        Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite
        der EU-Kommission:
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
          target="_blank"
          rel="noopener"
          >https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a
        >).
      </p>
      <p>&nbsp;</p>
      <h2 id="m12">Löschung von Daten</h2>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
        Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen
        widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck
        der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht
        erforderlich sind). Sofern die Daten nicht gelöscht werden, weil sie für
        andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren
        Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt
        und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
        handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
        Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
        juristischen Person erforderlich ist.
      </p>
      <p>
        Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung
        und Löschung von Daten beinhalten, die für die jeweiligen Verarbeitungen
        vorrangig gelten.
      </p>
      <h2 id="m134">Einsatz von Cookies</h2>
      <p>
        Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die
        Informationen auf Endgeräten speichern und Informationen aus den Endgeräten
        auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen
        Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete
        Funktionen eines Onlineangebotes speichern. Cookies können ferner zu
        unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der
        Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie der
        Erstellung von Analysen der Besucherströme.
      </p>
      <p>
        <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang
        mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine
        vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht gefordert
        ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern
        und das Auslesen der Informationen, also auch von Cookies, unbedingt
        erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich
        gewünschten Telemediendienst (also unser Onlineangebot) zur Verfügung zu
        stellen. Die widerrufliche Einwilligung wird gegenüber den Nutzern deutlich
        kommuniziert und enthält die Informationen zu der jeweiligen Cookie-Nutzung.
      </p>
      <p>
        <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf
        welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen
        Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir
        Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die
        Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung.
        Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
        Grundlage unserer berechtigten Interessen (z.B. an einem
        betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung
        seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung
        unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies
        erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu
        welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir
        im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
        Einwilligungs- und Verarbeitungsprozessen auf.
      </p>
      <p>
        <strong>Speicherdauer:&nbsp;</strong>Im Hinblick auf die Speicherdauer
        werden die folgenden Arten von Cookies unterschieden:
      </p>
      <ul>
        <li>
          <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong
          >&nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer
          ein Online-Angebot verlassen und sein Endgerät (z.B. Browser oder mobile
          Applikation) geschlossen hat.
        </li>
        <li>
          <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach
          dem Schließen des Endgerätes gespeichert. So können beispielsweise der
          Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden,
          wenn der Nutzer eine Website erneut besucht. Ebenso können die mit Hilfe
          von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet
          werden. Sofern wir Nutzern&nbsp;keine expliziten Angaben zur Art und
          Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der
          Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent sind
          und die Speicherdauer bis zu zwei Jahre betragen kann.
        </li>
      </ul>
      <p>
        <strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong
        >Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen
        und zudem einen Widerspruch gegen die Verarbeitung entsprechend&nbsp;den
        gesetzlichen Vorgaben im Art. 21 DSGVO einlegen.&nbsp;Nutzer können ihren
        Widerspruch auch über die Einstellungen ihres Browsers erklären, z.B. durch
        Deaktivierung der Verwendung von Cookies (wobei dadurch auch die
        Funktionalität unserer Online-Dienste eingeschränkt sein kann). Ein
        Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken
        kann auch über die Websites
        <a href="https://optout.aboutads.info" target="_blank" rel="noopener"
          >https://optout.aboutads.info</a
        >
        und
        <a href="https://www.youronlinechoices.com/" target="_blank" rel="noopener"
          >https://www.youronlinechoices.com/</a
        >
        erklärt werden.
      </p>
      <p><strong>Cookie-Einstellungen/ -Widerspruchsmöglichkeit:</strong></p>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong
            >Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong
          >Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in
          dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies,
          bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens
          genannten Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
          verwaltet und widerrufen werden können. Hierbei wird die
          Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut
          wiederholen zu müssen und die Einwilligung entsprechend der gesetzlichen
          Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig
          und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe
          vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer,
          bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben
          zu den Anbietern von Cookie-Management-Diensten, gelten die folgenden
          Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei
          Jahren betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator
          gebildet und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite
          der Einwilligung (z. B. welche Kategorien von Cookies und/oder
          Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät
          gespeichert.
        </li>
        <li>
          <strong>Liste verwendeter Cookies auf unserer Webseite: <br /></strong>
          <table class="cookielawinfo-row-cat-table cookielawinfo-classic">
            <thead>
              <tr>
                <th class="cookielawinfo-column-1">Cookie</th>
                <th class="cookielawinfo-column-3">Dauer</th>
                <th class="cookielawinfo-column-4">Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">token</td>
                <td class="cookielawinfo-column-3">Kein Ablaufdatum</td>
                <td class="cookielawinfo-column-4">
                    Dieser Cookie wird von der Applikation gesetzt und speichert den Token. Dieser Token ist notwendig, um Sie gegen unsere Applikation zu Authentifizieren.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">profiles</td>
                <td class="cookielawinfo-column-3">Kein Ablaufdatum</td>
                <td class="cookielawinfo-column-4">
                    Dieser Cookie wird von der Applikation gesetzt und speichert die Profile. Diese Profile sind notwendig, um die Applikation zu verwenden.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">lastActivityTime</td>
                <td class="cookielawinfo-column-3">Kein Ablaufdatum</td>
                <td class="cookielawinfo-column-4">
                    Dieser Cookie wird von der Applikation gesetzt und speichert die letzte Aktive Zeit. Diese wird verwendet, um Sie ggf. abzumelden.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">cookieConsent</td>
                <td class="cookielawinfo-column-3">1 Jahr</td>
                <td class="cookielawinfo-column-4">
                    Dieser Cookie wird von der Applikation gesetzt und speichert die Zustimmung des Benutzers zu den Cookies.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">cookieNeccesary</td>
                <td class="cookielawinfo-column-3">1 Jahr</td>
                <td class="cookielawinfo-column-4">
                    Dieser Cookie wird von der Applikation gesetzt und dient dazu, die Zustimmung des Nutzers zu den Cookies der Kategorie "Notwendig" zu erfassen.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">ARRAffinitySameSite</td>
                <td class="cookielawinfo-column-3">Session</td>
                <td class="cookielawinfo-column-4">
                    
    Diser Cookie wird von Microsoft Azure gesetzt. Er ist Notwendig, um Anfragen an die richtige Instanz in einer Umgebungen mit Lastausgleich weiterzuleiten.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">
                    __stripe_mid
                </td>
                <td class="cookielawinfo-column-3">1 Jahr</td>
                <td class="cookielawinfo-column-4">
                    Wird zu Betrugspräventionszwecken gesetzt und hilft uns, das Risiko im Zusammenhang mit einer Transaktion einzuschätzen.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">
                    __stripe_sid
                </td>
                <td class="cookielawinfo-column-3">30 Minuten</td>
                <td class="cookielawinfo-column-4">
                    Wird zu Betrugspräventionszwecken gesetzt und hilft uns, das Risiko im Zusammenhang mit einer Transaktion einzuschätzen.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">
                    cookie-perms
                </td>
                <td class="cookielawinfo-column-3">6 Monate</td>
                <td class="cookielawinfo-column-4">
                    Speichert die Cookie-Einstellungen eines Benutzers.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">
                    machine_identifier
                </td>
                <td class="cookielawinfo-column-3">1 Jahr</td>
                <td class="cookielawinfo-column-4">
                    Um Betrug zu verhindern, ermöglicht es Stripe, den Computer auf dem Stripe-Dashboard zu identifizieren.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">
                    private_machine_identifier
                </td>
                <td class="cookielawinfo-column-3">1 Jahr</td>
                <td class="cookielawinfo-column-4">
                    Identifiziert den Computer über Anmeldesitzungen und Benutzer hinweg, um Betrug zu verhindern.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">
                  cookielawinfo-checkbox-performance
                </td>
                <td class="cookielawinfo-column-3">1 Jahr</td>
                <td class="cookielawinfo-column-4">
                  Dieses Cookie wird vom GDPR-Cookie-Consent-Plugin gesetzt und
                  dient dazu, die Zustimmung des Nutzers für Cookies der Kategorie
                  "Leistung" zu speichern.
                </td>
              </tr>
              <tr class="cookielawinfo-row">
                <td class="cookielawinfo-column-1">stripe.csrf
                </td>
                <td class="cookielawinfo-column-3">Session</td>
                <td class="cookielawinfo-column-4">
                    Verhindert, dass Angreifer von anderen Websites aus Anfragen stellen, die Benutzerdaten an Stripe ändern.
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
      <h2 id="m317">Geschäftliche Leistungen</h2>
      <p>
        Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden
        und Interessenten (zusammenfassend bezeichnet als „Vertragspartner“) im
        Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit
        verbundenen Maßnahmen und im Rahmen der Kommunikation mit den
        Vertragspartnern (oder vorvertraglich), z.B., um Anfragen zu beantworten.
      </p>
      <p>
        Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu
        erfüllen. Dazu gehören insbesondere die Verpflichtungen zur Erbringung der
        vereinbarten Leistungen, etwaige Aktualisierungspflichten und Abhilfe bei
        Gewährleistungs- und sonstigen Leistungsstörungen. Darüber hinaus
        verarbeiten wir die Daten zur Wahrung unserer Rechte und zum Zwecke der mit
        diesen Pflichten verbundenen Verwaltungsaufgaben sowie der
        Unternehmensorganisation. Darüber hinaus verarbeiten wir die Daten auf
        Grundlage unserer berechtigten Interessen an einer ordnungsgemäßen und
        betriebswirtschaftlichen Geschäftsführung sowie an Sicherheitsmaßnahmen zum
        Schutz unserer Vertragspartner und unseres Geschäftsbetriebes vor
        Missbrauch, Gefährdung ihrer Daten, Geheimnisse, Informationen und Rechte
        (z.B. zur Beteiligung von Telekommunikations-, Transport- und sonstigen
        Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und Rechtsberatern,
        Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden Rechts
        geben wir die Daten von Vertragspartnern nur insoweit an Dritte weiter, als
        dies für die vorgenannten Zwecke oder zur Erfüllung gesetzlicher Pflichten
        erforderlich ist. Über weitere Formen der Verarbeitung, z.B. zu
        Marketingzwecken, werden die Vertragspartner im Rahmen dieser
        Datenschutzerklärung informiert.
      </p>
      <p>
        Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den
        Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in
        Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole
        (z.B. Sternchen o.ä.), oder persönlich mit.
      </p>
      <p>
        Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
        vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4 Jahren, es
        sei denn, dass die Daten in einem Kundenkonto gespeichert werden, z.B.,
        solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden
        müssen. Die gesetzliche Aufbewahrungsfrist beträgt bei steuerrechtlich
        relevanten Unterlagen sowie bei Handelsbüchern, Inventaren,
        Eröffnungsbilanzen, Jahresabschlüssen, die zum Verständnis dieser Unterlagen
        erforderlichen Arbeitsanweisungen und sonstigen Organisationsunterlagen und
        Buchungsbelegen zehn Jahre sowie bei empfangenen Handels- und
        Geschäftsbriefen und Wiedergaben der abgesandten Handels- und
        Geschäftsbriefe sechs Jahre. Die Frist beginnt mit Ablauf des
        Kalenderjahres, in dem die letzte Eintragung in das Buch gemacht, das
        Inventar, die Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht
        aufgestellt, der Handels- oder Geschäftsbrief empfangen oder abgesandt
        worden oder der Buchungsbeleg entstanden ist, ferner die Aufzeichnung
        vorgenommen worden ist oder die sonstigen Unterlagen entstanden sind.
      </p>
      <p>
        Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen
        einsetzen, gelten im Verhältnis zwischen den Nutzern und den Anbietern die
        Geschäftsbedingungen und Datenschutzhinweise der jeweiligen Drittanbieter
        oder Plattformen.
      </p>
      <p>&nbsp;</p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
          Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
          Zahlungshistorie); Kontaktdaten (z.B. E-Mail, Telefonnummern);
          Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Interessenten; Geschäfts- und
          Vertragspartner; Kunden.
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
          Leistungen und Kundenservice; Kontaktanfragen und Kommunikation; Büro- und
          Organisationsverfahren; Verwaltung und Beantwortung von Anfragen.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
          Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Rechtliche Verpflichtung
          (Art. 6 Abs. 1 S. 1 lit. c) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Consulting: </strong>Wir verarbeiten die Daten unserer Klienten,
          Mandanten sowie Interessenten und anderer Auftraggeber oder
          Vertragspartner (einheitlich bezeichnet als „Klienten“), um ihnen
          gegenüber unsere Beratungsleistungen erbringen zu können. Die
          verarbeiteten Daten, die Art, der Umfang, der Zweck und die
          Erforderlichkeit ihrer Verarbeitung bestimmen sich nach dem
          zugrundeliegenden Vertrags- und Klientenverhältnis.
          <p>&nbsp;</p>
          <p>
            Sofern es für unsere Vertragserfüllung, zum Schutz lebenswichtiger
            Interessen oder gesetzlich erforderlich ist, bzw. eine Einwilligung der
            Klienten vorliegt, offenbaren oder übermitteln wir die Daten der
            Klienten unter Beachtung der berufsrechtlichen Vorgaben an Dritte oder
            Beauftragte, wie z.B. Behörden, Subunternehmer oder im Bereich der IT,
            der Büro- oder vergleichbarer Dienstleistungen;
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </p>
        </li>
        <li>
          <strong>Projekt- und Entwicklungsleistungen: </strong>Wir verarbeiten die
          Daten unserer Kunden sowie Auftraggeber (nachfolgend einheitlich als
          „Kunden“ bezeichnet), um ihnen die Auswahl, den Erwerb bzw. die
          Beauftragung der gewählten Leistungen oder Werke sowie verbundener
          Tätigkeiten als auch deren Bezahlung und Zurverfügungstellung bzw.
          Ausführung oder Erbringung zu ermöglichen.
          <p>
            Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-,
            Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und
            umfassen die zur Leistungserbringung und Abrechnung benötigten Angaben
            sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können.
            Soweit wir Zugang zu Informationen der Endkunden, Mitarbeitern oder
            anderer Personen erhalten, verarbeiten wir diese im Einklang mit den
            gesetzlichen und vertraglichen Vorgaben;
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </p>
        </li>
        <li>
          <strong>Angebot von Software- und Plattformleistungen: </strong>Wir
          verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer
          (nachfolgend einheitlich als „Nutzer“ bezeichnet), um ihnen gegenüber
          unsere vertraglichen Leistungen erbringen zu können sowie auf Grundlage
          berechtigter Interessen, um die Sicherheit unseres Angebotes gewährleisten
          und es weiterentwickeln zu können. Die erforderlichen Angaben sind als
          solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren
          Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung
          und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige
          Rücksprachen halten zu können;
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
          Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
        <li>
          <strong>Technische Dienstleistungen: </strong>Wir verarbeiten die Daten
          unserer Kunden sowie Auftraggeber (nachfolgend einheitlich als „Kunden“
          bezeichnet), um ihnen die Auswahl, den Erwerb bzw. die Beauftragung der
          gewählten Leistungen oder Werke sowie verbundener Tätigkeiten als auch
          deren Bezahlung und Zurverfügungstellung bzw. Ausführung oder Erbringung
          zu ermöglichen.
          <p>
            Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-,
            Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und
            umfassen die zur Leistungserbringung und Abrechnung benötigten Angaben
            sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können.
            Soweit wir Zugang zu Informationen der Endkunden, Mitarbeitern oder
            anderer Personen erhalten, verarbeiten wir diese im Einklang mit den
            gesetzlichen und vertraglichen Vorgaben;
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </p>
        </li>
        <li>
          <strong>Unternehmensberatung: </strong>Wir verarbeiten die Daten unserer
          Kunden, Klienten sowie Interessenten und anderer Auftraggeber oder
          Vertragspartner (einheitlich bezeichnet als „Kunden“), um ihnen gegenüber
          unsere vertraglichen oder vorvertraglichen Leistungen, insbesondere
          Beratungsleistungen, erbringen zu können. Die verarbeiteten Daten, die
          Art, der Umfang, der Zweck und die Erforderlichkeit ihrer Verarbeitung
          bestimmen sich nach dem zugrundeliegenden Vertrags- und
          Geschäftsverhältnis.
          <p>
            Sofern es für unsere Vertragserfüllung oder gesetzlich erforderlich ist,
            bzw. eine Einwilligung der Kunden vorliegt, offenbaren oder übermitteln
            wir die Daten der Kunden unter Beachtung der berufsrechtlichen Vorgaben
            an Dritte oder Beauftragte, wie z.B. Behörden, Gerichte oder im Bereich
            der IT, der Büro- oder vergleichbarer Dienstleistungen;
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </p>
        </li>
        <li>
          <strong>Veranstaltungen und Events: </strong>Wir verarbeiten die Daten der
          Teilnehmer der von uns angebotenen oder ausgerichteten Veranstaltungen,
          Events und ähnlichen Aktivitäten (nachfolgend einheitlich als „Teilnehmer“
          und „Veranstaltungen“ bezeichnet), um ihnen die Teilnahme an den
          Veranstaltungen und Inanspruchnahme der mit der Teilnahme verbundenen
          Leistungen oder Aktionen zu ermöglichen.
          <p>
            Sofern wir in diesem Rahmen gesundheitsbezogene Daten, religiöse,
            politische oder sonstige besondere Kategorien von Daten verarbeiten,
            dann erfolgt diese im Rahmen der Offenkundigkeit (z.B. bei thematisch
            ausgerichteten Veranstaltungen oder dient der Gesundheitsvorsorge,
            Sicherheit oder erfolgt mit Einwilligung der Betroffenen).
          </p>
          <p>
            Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-,
            Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und
            umfassen die zur Leistungserbringung und Abrechnung benötigten Angaben
            sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können.
            Soweit wir Zugang zu Informationen der Endkunden, Mitarbeitern oder
            anderer Personen erhalten, verarbeiten wir diese im Einklang mit den
            gesetzlichen und vertraglichen Vorgaben;
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </p>
        </li>
      </ul>
      <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
      <p>
        Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
        Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die IP-Adresse
        des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer
        Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
          Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
          und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
          Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
          Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
          technischen Geräten (Computer, Server etc.).); Sicherheitsmaßnahmen.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff auf
          unser Onlineangebot wird in Form von so genannten „Server-Logfiles“
          protokolliert. Zu den Serverlogfiles können die Adresse und Name der
          abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs,
          übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
          nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
          besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider
          gehören.
          <p>&nbsp;</p>
          <p>
            Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt
            werden, z.B., um eine Überlastung der Server zu vermeiden (insbesondere
            im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und
            zum anderen, um die Auslastung der Server und ihre Stabilität
            sicherzustellen; <strong>Rechtsgrundlagen:</strong> Berechtigte
            Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);
            <strong>Löschung von Daten:</strong> Logfile-Informationen werden für
            die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder
            anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
            erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
            Vorfalls von der Löschung ausgenommen.
          </p>
        </li>
      </ul>
      <h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
      <p>
        Bei der Kontaktaufnahme mit uns (z.B. per Post, Kontaktformular, E-Mail,
        Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und
        Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet
        soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter
        Maßnahmen erforderlich ist.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
          Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
          Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B.
          IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
        </li>
        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
          Kommunikation; Verwaltung und Beantwortung von Anfragen; Feedback (z.B.
          Sammeln von Feedback via Online-Formular); Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); Vertragserfüllung und vorvertragliche Anfragen (Art.
          6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Kontaktformular: </strong>Wenn Nutzer über unser Kontaktformular,
          E-Mail oder andere Kommunikationswege mit uns in Kontakt treten,
          verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur
          Bearbeitung des mitgeteilten Anliegens;
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
          Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen (Art.
          6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2 id="m391">Kommunikation via Messenger</h2>
      <p>
        Wir setzen zu Zwecken der Kommunikation Messenger ein und bitten daher
        darum, die nachfolgenden Hinweise zur Funktionsfähigkeit der Messenger, zur
        Verschlüsselung, zur Nutzung der Metadaten der Kommunikation und zu Ihren
        Widerspruchsmöglichkeiten zu beachten.
      </p>
      <p>
        Sie können uns auch auf alternativen Wegen, z.B. via Telefon oder E-Mail,
        kontaktieren. Bitte nutzen Sie die Ihnen mitgeteilten Kontaktmöglichkeiten
        oder die innerhalb unseres Onlineangebotes angegebenen Kontaktmöglichkeiten.
      </p>
      <p>
        Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten (d.h., der Inhalt
        Ihrer Nachricht und Anhänge) weisen wir darauf hin, dass die
        Kommunikationsinhalte (d.h., der Inhalt der Nachricht und angehängte Bilder)
        von Ende zu Ende verschlüsselt werden. Das bedeutet, dass der Inhalt der
        Nachrichten nicht einsehbar ist, nicht einmal durch die Messenger-Anbieter
        selbst. Sie sollten immer eine aktuelle Version der Messenger mit
        aktivierter Verschlüsselung nutzen, damit die Verschlüsselung der
        Nachrichteninhalte sichergestellt ist.
      </p>
      <p>
        Wir weisen unsere Kommunikationspartner jedoch zusätzlich darauf hin, dass
        die Anbieter der Messenger zwar nicht den Inhalt einsehen, aber in Erfahrung
        bringen können, dass und wann Kommunikationspartner mit uns kommunizieren
        sowie technische Informationen zum verwendeten Gerät der
        Kommunikationspartner und je nach Einstellungen ihres Gerätes auch
        Standortinformationen (sogenannte Metadaten) verarbeitet werden.
      </p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen: </strong> Sofern wir
        Kommunikationspartner vor der Kommunikation mit ihnen via Messenger um eine
        Erlaubnis bitten, ist die Rechtsgrundlage unserer Verarbeitung ihrer Daten
        deren Einwilligung. Im Übrigen, falls wir nicht um eine Einwilligung bitten
        und sie z.B. von sich aus Kontakt mit uns aufnehmen, nutzen wir Messenger im
        Verhältnis zu unseren Vertragspartnern sowie im Rahmen der Vertragsanbahnung
        als eine vertragliche Maßnahme und im Fall anderer Interessenten und
        Kommunikationspartner auf Grundlage unserer berechtigten Interessen an einer
        schnellen und effizienten Kommunikation und Erfüllung der Bedürfnisse unser
        Kommunikationspartner an der Kommunikation via Messenger. Ferner weisen wir
        Sie darauf hin, dass wir die uns mitgeteilten Kontaktdaten ohne Ihre
        Einwilligung nicht erstmalig an die Messenger übermitteln.
      </p>
      <p>
        <strong>Widerruf, Widerspruch und Löschung:</strong> Sie können jederzeit
        eine erteilte Einwilligung widerrufen und der Kommunikation mit uns via
        Messenger jederzeit widersprechen. Im Fall der Kommunikation via Messenger
        löschen wir die Nachrichten entsprechend unseren generellen Löschrichtlinien
        (d.h. z.B., wie oben beschrieben, nach Ende vertraglicher Beziehungen, im
        Kontext von Archivierungsvorgaben etc.) und sonst, sobald wir davon ausgehen
        können, etwaige Auskünfte der Kommunikationspartner beantwortet zu haben,
        wenn kein Rückbezug auf eine vorhergehende Konversation zu erwarten ist und
        der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
      </p>
      <p>
        <strong>Vorbehalt des Verweises auf andere Kommunikationswege:</strong> Zum
        Abschluss möchten wir darauf hinweisen, dass wir uns aus Gründen Ihrer
        Sicherheit vorbehalten, Anfragen über Messenger nicht zu beantworten. Das
        ist der Fall, wenn z.B. Vertragsinterna besonderer Geheimhaltung bedürfen
        oder eine Antwort über Messenger den formellen Ansprüchen nicht genügt. In
        solchen Fällen verweisen wir Sie auf adäquatere Kommunikationswege.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
          Telefonnummern); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
          Inhalten, Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z.
          B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
        </li>
        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
          Kommunikation; Direktmarketing (z.B. per E-Mail oder postalisch).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
          a) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2 id="m735">
        Videokonferenzen, Onlinemeetings, Webinare und Bildschirm-Sharing
      </h2>
      <p>
        Wir setzen Plattformen und Anwendungen anderer Anbieter (nachfolgend
        bezeichnet als “Konferenzplattformen”) zu Zwecken der Durchführung von
        Video- und Audio-Konferenzen, Webinaren und sonstigen Arten von Video und
        Audio-Meetings (nachfolgend zusammenfassend bezeichnet als „Konferenz“) ein.
        Bei der Auswahl der Konferenzplattformen und ihrer Leistungen beachten wir
        die gesetzlichen Vorgaben.
      </p>
      <p>
        <strong>Durch Konferenzplattformen verarbeitete Daten:</strong> Im Rahmen
        der Teilnahme an einer Konferenz verarbeiten die Konferenzplattformen die im
        Folgenden genannten personenbezogene Daten der Teilnehmer. Der Umfang der
        Verarbeitung hängt zum einen davon ab, welche Daten im Rahmen einer
        konkreten Konferenz gefordert werden (z.B. Angabe von Zugangsdaten oder
        Klarnamen) und welche optionalen Angaben durch die Teilnehmer getätigt
        werden. Neben der Verarbeitung zur Durchführung der Konferenz, können die
        Daten der Teilnehmer durch die Konferenzplattformen ebenfalls zu
        Sicherheitszwecken oder Serviceoptimierung verarbeitet werden. Zu den
        verarbeiteten Daten gehören Daten zur Person (Vorname, Nachname),
        Kontaktinformationen (E-Mail-Adresse, Telefonnummer), Zugangsdaten
        (Zugangscodes oder Passwörter), Profilbilder, Angaben zur beruflichen
        Stellung/Funktion, die IP-Adresse des Internetzugangs, Angaben zu den
        Endgeräten der Teilnehmer, deren Betriebssystem, dem Browser und dessen
        technischen und sprachlichen Einstellungen, Informationen zu den
        inhaltlichen Kommunikationsvorgängen, d.h. Eingaben in Chats sowie Audio-
        und Videodaten, als auch die Nutzung anderer zur Verfügung stehender
        Funktionen (z.B. Umfragen). Inhalte der Kommunikationen werden in dem durch
        die Konferenzanbieter technisch bereitgestellten Umfang verschlüsselt. Wenn
        die Teilnehmer bei den Konferenzplattformen als Benutzer registriert sind,
        dann können weitere Daten entsprechend der Vereinbarung mit dem jeweiligen
        Konferenzanbieter verarbeitet werden.
      </p>
      <p>
        <strong>Protokollierung und Aufnahmen:</strong> Falls Texteingaben,
        Teilnahmeergebnisse (z.B. von Umfragen) sowie Video- oder Audioaufnahmen
        protokolliert werden, wird dies den Teilnehmern im Vorwege transparent
        mitgeteilt und sie werden – soweit erforderlich – um eine Zustimmung
        gebeten.
      </p>
      <p>
        <strong>Datenschutzmaßnahmen der Teilnehmer:</strong> Bitte beachten Sie zu
        den Details der Verarbeitung Ihrer Daten durch die Konferenzplattformen
        deren Datenschutzhinweise und wählen im Rahmen der Einstellungen der
        Konferenzplattformen, die für Sie optimalen Sicherheits- und
        Datenschutzeinstellungen. Bitte sorgen Sie ferner für die Dauer einer
        Videokonferenz für den Daten- und Persönlichkeitsschutz im Hintergrund Ihrer
        Aufnahme (z.B. durch Hinweise an Mitbewohner, Abschließen von Türen und
        Nutzung, soweit technisch möglich, der Funktion zur Unkenntlichmachung des
        Hintergrunds). Links zu den Konferenzräumen sowie Zugangsdaten, dürfen nicht
        an unberechtigte Dritte weitergegeben werden.
      </p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern neben den
        Konferenzplattformen auch wir die Daten der Nutzer verarbeiten und die
        Nutzer um deren Einwilligung in den Einsatz der Konferenzplattformen oder
        bestimmter Funktionen bitten (z. B. Einverständnis mit einer Aufzeichnung
        von Konferenzen), ist die Rechtsgrundlage der Verarbeitung diese
        Einwilligung. Ferner kann unsere Verarbeitung zur Erfüllung unserer
        vertraglichen Pflichten erforderlich sein (z.B. in Teilnehmerlisten, im Fall
        von Aufarbeitung von Gesprächsergebnissen, etc.). Im Übrigen werden die
        Daten der Nutzer auf Grundlage unserer berechtigten Interessen an einer
        effizienten und sicheren Kommunikation mit unseren Kommunikationspartnern
        verarbeitet.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
          Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
          Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten,
          Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und
          Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
          Einwilligungsstatus).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kommunikationspartner; Nutzer (z.B.
          Webseitenbesucher, Nutzer von Onlinediensten); Abgebildete Personen.
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
          Leistungen und Kundenservice; Kontaktanfragen und Kommunikation; Büro- und
          Organisationsverfahren.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Adobe Connect: </strong>Konferenz- und Webinarsoftware;
          <strong>Dienstanbieter:</strong> Adobe Systems Software Ireland Limited,
          4-6 Riverwalk, Citywest Business Campus, Dublin 24, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a
            href="https://www.adobe.com/de/products/adobeconnect.html"
            target="_blank"
            rel="noopener"
            >https://www.adobe.com/de/products/adobeconnect.html</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://www.adobe.com/de/privacy.html"
            target="_blank"
            rel="noopener"
            >https://www.adobe.com/de/privacy.html</a
          >.
        </li>
        <li>
          <strong>Cisco WebEx: </strong>Konferenz-Software;
          <strong>Dienstanbieter:</strong> Webex Communications Deutschland GmbH,
          Hansaallee 249, c/o Cisco Systems GmbH, 40549 Düsseldorf,
          Mutterunternehmen: Cisco Systems, Inc. 170 West Tasman Dr., San Jose, CA
          95134, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
          (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.webex.com/de" target="_blank" rel="noopener"
            >https://www.webex.com/de</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://www.cisco.com/c/de_de/about/legal/privacy-full.html"
            target="_blank"
            rel="noopener"
            >https://www.cisco.com/c/de_de/about/legal/privacy-full.html</a
          >.
        </li>
        <li>
          <strong>Google Hangouts / Meet: </strong>Messenger- und
          Konferenz-Software; <strong>Dienstanbieter:</strong> Google Ireland
          Limited, Gordon House, Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://hangouts.google.com/" target="_blank" rel="noopener"
            >https://hangouts.google.com/</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >; <strong>Auftragsverarbeitungsvertrag:</strong>
          <a
            href="https://cloud.google.com/terms/data-processing-addendum"
            target="_blank"
            rel="noopener"
            >https://cloud.google.com/terms/data-processing-addendum</a
          >;
          <strong
            >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
            Verarbeitung in Drittländern):</strong
          >
          <a
            href="https://cloud.google.com/terms/eu-model-contract-clause"
            target="_blank"
            rel="noopener"
            >https://cloud.google.com/terms/eu-model-contract-clause</a
          >.
        </li>
        <li>
          <strong>Microsoft Teams: </strong>Messenger- und Konferenz-Software;
          <strong>Dienstanbieter:</strong> Microsoft Ireland Operations Limited, One
          Microsoft Place, South County Business Park, Leopardstown, Dublin 18,
          Irland, Mutterunternehmen: Microsoft Corporation, One Microsoft Way,
          Redmond, WA 98052-6399 USA; <strong>Rechtsgrundlagen:</strong> Berechtigte
          Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a
            href="https://www.microsoft.com/de-de/microsoft-365"
            target="_blank"
            rel="noopener"
            >https://www.microsoft.com/de-de/microsoft-365</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://privacy.microsoft.com/de-de/privacystatement"
            target="_blank"
            rel="noopener"
            >https://privacy.microsoft.com/de-de/privacystatement</a
          >, Sicherheitshinweise:
          <a
            href="https://www.microsoft.com/de-de/trustcenter"
            target="_blank"
            rel="noopener"
            >https://www.microsoft.com/de-de/trustcenter</a
          >;
          <strong
            >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
            Verarbeitung in Drittländern):</strong
          >
          <a
            href="https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA"
            target="_blank"
            rel="noopener"
            >https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA</a
          >.
        </li>
        <li>
          <strong>Skype: </strong>Messenger- und Konferenz-Software;
          <strong>Dienstanbieter:</strong> Microsoft Corporation, One Microsoft Way,
          Redmond, WA 98052-6399 USA; <strong>Rechtsgrundlagen:</strong> Berechtigte
          Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.skype.com/de/" target="_blank" rel="noopener"
            >https://www.skype.com/de/</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://privacy.microsoft.com/de-de/privacystatement"
            target="_blank"
            rel="noopener"
            >https://privacy.microsoft.com/de-de/privacystatement</a
          >, Sicherheitshinweise:
          <a
            href="https://www.microsoft.com/de-de/trustcenter"
            target="_blank"
            rel="noopener"
            >https://www.microsoft.com/de-de/trustcenter</a
          >.
        </li>
        <li>
          <strong>TeamViewer: </strong>Konferenz-Software;
          <strong>Dienstanbieter:</strong> TeamViewer GmbH, Jahnstr. 30, 73037
          Göppingen, Deutschland; <strong>Rechtsgrundlagen:</strong> Berechtigte
          Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.teamviewer.com/de" target="_blank" rel="noopener"
            >https://www.teamviewer.com/de</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://www.teamviewer.com/de/datenschutzerklaerung/"
            target="_blank"
            rel="noopener"
            >https://www.teamviewer.com/de/datenschutzerklaerung/</a
          >.
        </li>
        <li>
          <strong>Zoom: </strong>Videokonferenzen, Webkonferenzen und Webinare;
          <strong>Dienstanbieter:</strong> Zoom Video Communications, Inc., 55
          Almaden Blvd., Suite 600, San Jose, CA 95113, USA;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://zoom.us" target="_blank" rel="noopener"
            >https://zoom.us</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://zoom.us/docs/de-de/privacy-and-legal.html"
            target="_blank"
            rel="noopener"
            >https://zoom.us/docs/de-de/privacy-and-legal.html</a
          >; <strong>Auftragsverarbeitungsvertrag:</strong>
          <a
            href="https://zoom.us/docs/de-de/privacy-and-legal.html"
            target="_blank"
            rel="noopener"
            >https://zoom.us/docs/de-de/privacy-and-legal.html</a
          >
          (referred to as Global DPA);
          <strong
            >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
            Verarbeitung in Drittländern):</strong
          >
          <a
            href="https://zoom.us/docs/de-de/privacy-and-legal.html"
            target="_blank"
            rel="noopener"
            >https://zoom.us/docs/de-de/privacy-and-legal.html</a
          >
          (Bezeichnet als Globale DPA).
        </li>
      </ul>
      <h2 id="m210">Bewerbungsverfahren</h2>
      <p>
        Das Bewerbungsverfahren setzt voraus, dass Bewerber uns die für deren
        Beurteilung und Auswahl erforderlichen Daten mitteilen. Welche Informationen
        erforderlich sind, ergibt sich aus der Stellenbeschreibung oder im Fall von
        Onlineformularen aus den dortigen Angaben.
      </p>
      <p>
        Grundsätzlich gehören zu den erforderlichen Angaben, die Informationen zur
        Person, wie der Name, die Adresse, eine Kontaktmöglichkeit sowie die
        Nachweise über die für eine Stelle notwendigen Qualifikationen. Auf Anfragen
        teilen wir zusätzlich gerne mit, welche Angaben benötigt werden.
      </p>
      <p>
        Sofern zur Verfügung gestellt, können uns Bewerber ihre Bewerbungen mittels
        eines Onlineformulars übermitteln. Die Daten werden entsprechend dem Stand
        der Technik verschlüsselt an uns übertragen. Ebenfalls können Bewerber uns
        ihre Bewerbungen via E-Mail übermitteln. Hierbei bitten wir jedoch zu
        beachten, dass E-Mails im Internet grundsätzlich nicht verschlüsselt
        versendet werden. Im Regelfall werden E-Mails zwar auf dem Transportweg
        verschlüsselt, aber nicht auf den Servern von denen sie abgesendet und
        empfangen werden. Wir können daher für den Übertragungsweg der Bewerbung
        zwischen dem Absender und dem Empfang auf unserem Server keine Verantwortung
        übernehmen.
      </p>
      <p>
        Für Zwecke der Bewerbersuche, Einreichung von Bewerbungen und Auswahl von
        Bewerbern können wir unter Beachtung der gesetzlichen Vorgaben,
        Bewerbermanagement-, bzw. Recruitment-Software und Plattformen und
        Leistungen von Drittanbietern in Anspruch nehmen.
      </p>
      <p>
        Bewerber können uns gerne zur Art der Einreichung der Bewerbung kontaktieren
        oder uns die Bewerbung auf dem Postweg zuzusenden.
      </p>
      <p>
        <strong>Verarbeitung besonderer Kategorien von Daten:</strong> Soweit im
        Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen
        Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B. Gesundheitsdaten, wie z.B.
        Schwerbehinderteneigenschaft oder ethnische Herkunft) bei Bewerbern
        angefragt werden, damit der Verantwortliche oder die betroffene Person die
        ihm bzw. ihr aus dem Arbeitsrecht und dem Recht der sozialen Sicherheit und
        des Sozialschutzes erwachsenden Rechte ausüben und seinen bzw. ihren
        diesbezüglichen Pflichten nachkommen kann, erfolgt deren Verarbeitung nach
        Art. 9 Abs. 2 lit. b. DSGVO, im Fall des Schutzes lebenswichtiger Interessen
        der Bewerber oder anderer Personen gem. Art. 9 Abs. 2 lit. c. DSGVO oder für
        Zwecke der Gesundheitsvorsorge oder der Arbeitsmedizin, für die Beurteilung
        der Arbeitsfähigkeit des Beschäftigten, für die medizinische Diagnostik, für
        die Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder für
        die Verwaltung von Systemen und Diensten im Gesundheits- oder Sozialbereich
        gem. Art. 9 Abs. 2 lit. h. DSGVO. Im Fall einer auf freiwilliger
        Einwilligung beruhenden Mitteilung der besonderen Kategorien von Daten
        erfolgt deren Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.
      </p>
      <p>
        <strong>Löschung von Daten:</strong> Die von den Bewerbern zur Verfügung
        gestellten Daten können im Fall einer erfolgreichen Bewerbung für die Zwecke
        des Beschäftigungsverhältnisses von uns weiterverarbeitet werden.
        Andernfalls, sofern die Bewerbung auf ein Stellenangebot nicht erfolgreich
        ist, werden die Daten der Bewerber gelöscht. Die Daten der Bewerber werden
        ebenfalls gelöscht, wenn eine Bewerbung zurückgezogen wird, wozu die
        Bewerber jederzeit berechtigt sind. Die Löschung erfolgt, vorbehaltlich
        eines berechtigten Widerrufs der Bewerber, spätestens nach dem Ablauf eines
        Zeitraums von sechs Monaten, damit wir etwaige Anschlussfragen zu der
        Bewerbung beantworten und unseren Nachweispflichten aus den Vorschriften zur
        Gleichbehandlung von Bewerbern nachkommen können. Rechnungen über etwaige
        Reisekostenerstattung werden entsprechend den steuerrechtlichen Vorgaben
        archiviert.
      </p>
      <p>
        <strong>Aufnahme in einen Bewerberpool:</strong> Die Aufnahme in einen
        Bewerber-Pool, sofern angeboten, erfolgt auf Grundlage einer Einwilligung.
        Die Bewerber werden darüber belehrt, dass ihre Zustimmung zur Aufnahme in
        den Talentpool freiwillig ist, keinen Einfluss auf das laufende
        Bewerbungsverfahren hat und sie ihre Einwilligung jederzeit für die Zukunft
        widerrufen können.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
          Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
          Eingaben in Onlineformularen); Bewerberdaten (z.B. Angaben zur Person,
          Post- und Kontaktadressen, die zur Bewerbung gehörenden Unterlagen und die
          darin enthaltenen Informationen, wie z.B. Anschreiben, Lebenslauf,
          Zeugnisse sowie weitere im Hinblick auf eine konkrete Stelle oder
          freiwillig von Bewerbern mitgeteilte Informationen zu deren Person oder
          Qualifikation).
        </li>
        <li><strong>Betroffene Personen:</strong> Bewerber.</li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Bewerbungsverfahren (Begründung
          und etwaige spätere Durchführung sowie mögliche spätere Beendigung des
          Beschäftigungsverhältnisses).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Bewerbungsverfahren als
          vorvertragliches bzw. vertragliches Verhältnis (Art. 6 Abs. 1 lit. b)
          DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>LinkedIn Recruiter: </strong>Stellengesuch- und bewerbungsbezogene
          Dienstleistungen innerhalb der LinkedIn-Plattform;
          <strong>Dienstanbieter:</strong> LinkedIn Irland Unlimited Company, Wilton
          Plaza Wilton Place, Dublin 2, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener"
            >https://www.linkedin.com</a
          >; <strong>AGB:</strong>
          <a href="https://legal.linkedin.com/dpa" target="_blank" rel="noopener"
            >https://legal.linkedin.com/dpa</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://www.linkedin.com/legal/privacy-policy"
            target="_blank"
            rel="noopener"
            >https://www.linkedin.com/legal/privacy-policy</a
          >; <strong>Auftragsverarbeitungsvertrag:</strong>
          <a href="https://legal.linkedin.com/dpa" target="_blank" rel="noopener"
            >https://legal.linkedin.com/dpa</a
          >.
        </li>
        <li>
          <strong>Xing: </strong>Stellengesuch- und Bewerbungsbezogene
          Dienstleistungen innerhalb der Xing-Plattform;
          <strong>Dienstanbieter:</strong> New Work SE, Am Strandkai 1, 20457
          Hamburg, Deutschland; <strong>Rechtsgrundlagen:</strong> Berechtigte
          Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.xing.com" target="_blank" rel="noopener"
            >https://www.xing.com</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://privacy.xing.com/de/datenschutzerklaerung"
            target="_blank"
            rel="noopener"
            >https://privacy.xing.com/de/datenschutzerklaerung</a
          >.
        </li>
      </ul>
      <h2 id="m29">Cloud-Dienste</h2>
      <p>
        Wir nutzen über das Internet zugängliche und auf den Servern ihrer Anbieter
        ausgeführte Softwaredienste (sogenannte „Cloud-Dienste“, auch bezeichnet als
        „Software as a Service“) für die Speicherung und Verwaltung von Inhalten
        (z.B. Dokumentenspeicherung und -verwaltung, Austausch von Dokumenten,
        Inhalten und Informationen mit bestimmten Empfängern oder Veröffentlichung
        von Inhalten und Informationen).
      </p>
      <p>
        In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den
        Servern der Anbieter gespeichert werden, soweit diese Bestandteil von
        Kommunikationsvorgängen mit uns sind oder von uns sonst, wie im Rahmen
        dieser Datenschutzerklärung dargelegt, verarbeitet werden. Zu diesen Daten
        können insbesondere Stammdaten und Kontaktdaten der Nutzer, Daten zu
        Vorgängen, Verträgen, sonstigen Prozessen und deren Inhalte gehören. Die
        Anbieter der Cloud-Dienste verarbeiten ferner Nutzungsdaten und Metadaten,
        die von ihnen zu Sicherheitszwecken und zur Serviceoptimierung verwendet
        werden.
      </p>
      <p>
        Sofern wir mit Hilfe der Cloud-Dienste für andere Nutzer oder öffentlich
        zugängliche Webseiten Formulare o.a. Dokumente und Inhalte bereitstellen,
        können die Anbieter Cookies auf den Geräten der Nutzer für Zwecke der
        Webanalyse oder, um sich Einstellungen der Nutzer (z.B. im Fall der
        Mediensteuerung) zu merken, speichern.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
          Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
          Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten,
          Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und
          Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
          Einwilligungsstatus).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kunden; Beschäftigte (z.B.
          Angestellte, Bewerber, ehemalige Mitarbeiter); Interessenten;
          Kommunikationspartner.
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Büro- und
          Organisationsverfahren; Informationstechnische Infrastruktur (Betrieb und
          Bereitstellung von Informationssystemen und technischen Geräten (Computer,
          Server etc.).).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Apple iCloud: </strong>Cloud-Speicher-Dienst;
          <strong>Dienstanbieter:</strong> Apple Inc., Infinite Loop, Cupertino, CA
          95014, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
          (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.apple.com/de/" target="_blank" rel="noopener"
            >https://www.apple.com/de/</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://www.apple.com/legal/privacy/de-ww/"
            target="_blank"
            rel="noopener"
            >https://www.apple.com/legal/privacy/de-ww/</a
          >.
        </li>
        <li>
          <strong>Microsoft Clouddienste: </strong>Cloudspeicher,
          Cloudinfrastrukturdienste und cloudbasierte Anwendungssoftware;
          <strong>Dienstanbieter:</strong> Microsoft Ireland Operations Limited, One
          Microsoft Place, South County Business Park, Leopardstown, Dublin 18,
          Irland, Mutterunternehmen: Microsoft Corporation, One Microsoft Way,
          Redmond, WA 98052-6399 USA; <strong>Rechtsgrundlagen:</strong> Berechtigte
          Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://microsoft.com/de-de" target="_blank" rel="noopener"
            >https://microsoft.com/de-de</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://privacy.microsoft.com/de-de/privacystatement"
            target="_blank"
            rel="noopener"
            >https://privacy.microsoft.com/de-de/privacystatement</a
          >, Sicherheitshinweise:
          <a
            href="https://www.microsoft.com/de-de/trustcenter"
            target="_blank"
            rel="noopener"
            >https://www.microsoft.com/de-de/trustcenter</a
          >; <strong>Auftragsverarbeitungsvertrag:</strong>
          <a
            href="https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA"
            target="_blank"
            rel="noopener"
            >https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA</a
          >;
          <strong
            >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
            Verarbeitung in Drittländern):</strong
          >
          <a
            href="https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA"
            target="_blank"
            rel="noopener"
            >https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA</a
          >.
        </li>
      </ul>
      <h2 id="m638">Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</h2>
      <p>
        Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen
        Kommunikation, die über diverse Kanäle, wie z.B. E-Mail, Telefon, Post oder
        Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.
      </p>
      <p>
        Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu
        widerrufen oder der werblichen Kommunikation jederzeit zu widersprechen.
      </p>
      <p>
        Nach Widerruf oder Widerspruch speichern wir die zum Nachweis der bisherigen
        Berechtigung erforderlichen Daten zur Kontaktaufnahme oder Zusendung bis zu
        drei Jahre nach Ablauf des Jahres des Widerrufs oder Widerspruchs auf der
        Grundlage unserer berechtigten Interessen. Die Verarbeitung dieser Daten ist
        auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Auf der
        Grundlage des berechtigten Interesses, den Widerruf bzw. Widerspruch der
        Nutzer dauerhaft zu beachten, speichern wir ferner die zur Vermeidung einer
        erneuten Kontaktaufnahme erforderlichen Daten (z.B. je nach
        Kommunikationskanal die E-Mail-Adresse, Telefonnummer, Name).
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
          Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern).
        </li>
        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail
          oder postalisch).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
          a) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
      <p>
        Die Webanalyse (auch als „Reichweitenmessung“ bezeichnet) dient der
        Auswertung der Besucherströme unseres Onlineangebotes und kann Verhalten,
        Interessen oder demographische Informationen zu den Besuchern, wie z.B. das
        Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe der
        Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser
        Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt
        werden oder zur Wiederverwendung einladen. Ebenso können wir nachvollziehen,
        welche Bereiche der Optimierung bedürfen.
      </p>
      <p>
        Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B.
        unterschiedliche Versionen unseres Onlineangebotes oder seiner Bestandteile
        zu testen und optimieren.
      </p>
      <p>
        Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken Profile,
        d.h. zu einem Nutzungsvorgang zusammengefasste Daten angelegt und
        Informationen in einem Browser, bzw. in einem Endgerät gespeichert und aus
        diesem ausgelesen werden. Zu den erhobenen Angaben gehören insbesondere
        besuchte Webseiten und dort genutzte Elemente sowie technische Angaben, wie
        der verwendete Browser, das verwendete Computersystem sowie Angaben zu
        Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer Standortdaten uns
        gegenüber oder gegenüber den Anbietern der von uns eingesetzten Dienste
        einverstanden erklärt haben, können auch Standortdaten verarbeitet werden.
      </p>
      <p>
        Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
        wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der
        IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von
        Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie
        z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir
        als auch die Anbieter der eingesetzten Software kennen nicht die
        tatsächliche Identität der Nutzer, sondern nur den für Zwecke der jeweiligen
        Verfahren in deren Profilen gespeicherten Angaben.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
          Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
          und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
          Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
          Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Profile mit
          nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
        </li>
        <li>
          <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
          IP-Adresse).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
          a) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Google Analytics: </strong>Reichweitenmessung und Webanalyse;
          <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House,
          Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
          a) DSGVO); <strong>Website:</strong>
          <a
            href="https://marketingplatform.google.com/intl/de/about/analytics/"
            target="_blank"
            rel="noopener"
            >https://marketingplatform.google.com/intl/de/about/analytics/</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >; <strong>Auftragsverarbeitungsvertrag:</strong>
          <a
            href="https://business.safety.google/adsprocessorterms/"
            target="_blank"
            rel="noopener"
            >https://business.safety.google/adsprocessorterms/</a
          >;
          <strong
            >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
            Verarbeitung in Drittländern):</strong
          >
          <a
            href="https://business.safety.google/adsprocessorterms"
            target="_blank"
            rel="noopener"
            >https://business.safety.google/adsprocessorterms</a
          >; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Opt-Out-Plugin:
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener"
            >https://tools.google.com/dlpage/gaoptout?hl=de</a
          >, Einstellungen für die Darstellung von Werbeeinblendungen:
          <a
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noopener"
            >https://adssettings.google.com/authenticated</a
          >; <strong>Weitere Informationen:</strong>
          <a
            href="https://privacy.google.com/businesses/adsservices"
            target="_blank"
            rel="noopener"
            >https://privacy.google.com/businesses/adsservices</a
          >
          (Arten der Verarbeitung sowie der verarbeiteten Daten).
        </li>
        <li>
          <strong>Google Tag Manager: </strong>Google Tag Manager ist eine Lösung,
          mit der wir sog. Website-Tags über eine Oberfläche verwalten und so andere
          Dienste in unser Onlineangebot einbinden können (hierzu wird auf weitere
          Angaben in dieser Datenschutzerklärung verwiesen). Mit dem Tag Manager
          selbst (welches die Tags implementiert) werden daher z. B. noch keine
          Profile der Nutzer erstellt oder Cookies gespeichert. Google erfährt
          lediglich die IP-Adresse des Nutzers, was notwendig ist, um den Google Tag
          Manager auszuführen; <strong>Dienstanbieter:</strong> Google Ireland
          Limited, Gordon House, Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
          a) DSGVO); <strong>Website:</strong>
          <a
            href="https://marketingplatform.google.com"
            target="_blank"
            rel="noopener"
            >https://marketingplatform.google.com</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >; <strong>Auftragsverarbeitungsvertrag:</strong><br /><a
            href="https://business.safety.google/adsprocessorterms"
            target="_blank"
            rel="noopener"
            >https://business.safety.google/adsprocessorterms</a
          >;
          <strong
            >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
            Verarbeitung in Drittländern):</strong
          >
          <a
            href="https://business.safety.google/adsprocessorterms"
            target="_blank"
            rel="noopener"
            >https://business.safety.google/adsprocessorterms</a
          >.
        </li>
      </ul>
      <h2 id="m264">Onlinemarketing</h2>
      <p>
        Wir verarbeiten personenbezogene Daten zu Zwecken des Onlinemarketings,
        worunter insbesondere die Vermarktung von Werbeflächen oder Darstellung von
        werbenden und sonstigen Inhalten (zusammenfassend als „Inhalte“ bezeichnet)
        anhand potentieller Interessen der Nutzer sowie die Messung ihrer
        Effektivität fallen kann.
      </p>
      <p>
        Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer
        Datei (sogenannte „Cookie“) gespeichert oder ähnliche Verfahren genutzt,
        mittels derer die für die Darstellung der vorgenannten Inhalte relevante
        Angaben zum Nutzer gespeichert werden. Zu diesen Angaben können z.B.
        betrachtete Inhalte, besuchte Webseiten, genutzte Onlinenetzwerke, aber auch
        Kommunikationspartner und technische Angaben, wie der verwendete Browser,
        das verwendete Computersystem sowie Angaben zu Nutzungszeiten und genutzten
        Funktionen gehören. Sofern Nutzer in die Erhebung ihrer Standortdaten
        eingewilligt haben, können auch diese verarbeitet werden.
      </p>
      <p>
        Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
        wir zur Verfügung stehende IP-Masking-Verfahren (d.h., Pseudonymisierung
        durch Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden im
        Rahmen des Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B.
        E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als
        auch die Anbieter der Onlinemarketingverfahren kennen nicht die tatsächliche
        Identität der Nutzer, sondern nur die in deren Profilen gespeicherten
        Angaben.
      </p>
      <p>
        Die Angaben in den Profilen werden im Regelfall in den Cookies oder mittels
        ähnlicher Verfahren gespeichert. Diese Cookies können später generell auch
        auf anderen Webseiten die dasselbe Onlinemarketingverfahren einsetzen,
        ausgelesen und zu Zwecken der Darstellung von Inhalten analysiert als auch
        mit weiteren Daten ergänzt und auf dem Server des
        Onlinemarketingverfahrensanbieters gespeichert werden.
      </p>
      <p>
        Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist der
        Fall, wenn die Nutzer z.B. Mitglieder eines sozialen Netzwerks sind, dessen
        Onlinemarketingverfahren wir einsetzen und das Netzwerk die Profile der
        Nutzer mit den vorgenannten Angaben verbindet. Wir bitten darum, zu
        beachten, dass Nutzer mit den Anbietern zusätzliche Abreden, z.B. durch
        Einwilligung im Rahmen der Registrierung, treffen können.
      </p>
      <p>
        Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen
        über den Erfolg unserer Werbeanzeigen. Jedoch können wir im Rahmen
        sogenannter Konversionsmessungen prüfen, welche unserer
        Onlinemarketingverfahren zu einer sogenannten Konversion geführt haben, d.h.
        z.B., zu einem Vertragsschluss mit uns. Die Konversionsmessung wird alleine
        zur Analyse des Erfolgs unserer Marketingmaßnahmen verwendet.
      </p>
      <p>
        Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass
        verwendete Cookies für einen Zeitraum von zwei Jahren gespeichert werden.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Eingaben in
          Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
          Inhalten, Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z.
          B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus);
          Event-Daten (Facebook) („Event-Daten“ sind Daten, die z. B. via
          Facebook-Pixel (via Apps oder auf anderen Wegen) von uns an Facebook
          übermittelt werden können und sich auf Personen oder deren Handlungen
          beziehen; Zu den Daten gehören z. B. Angaben über Besuche auf Websites,
          Interaktionen mit Inhalten, Funktionen, Installationen von Apps, Käufe von
          Produkten, etc.; die Event-Daten werden zwecks Bildung von Zielgruppen für
          Inhalte und Werbeinformationen (Custom Audiences) verarbeitet; Event Daten
          beinhalten nicht die eigentlichen Inhalte (wie z. B. verfasste
          Kommentare), keine Login-Informationen und keine Kontaktinformationen
          (also keine Namen, E-Mail-Adressen und Telefonnummern). Event Daten werden
          durch Facebook nach maximal zwei Jahren gelöscht, die aus ihnen gebildeten
          Zielgruppen mit der Löschung unseres Facebook-Kontos).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
          Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
          Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Tracking (z.B.
          interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies);
          Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen);
          Zielgruppenbildung; Marketing; Profile mit nutzerbezogenen Informationen
          (Erstellen von Nutzerprofilen); Bereitstellung unseres Onlineangebotes und
          Nutzerfreundlichkeit; Remarketing.
        </li>
        <li>
          <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
          IP-Adresse).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
          a) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
        <li>
          <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Wir verweisen auf die
          Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern
          angegebenen Widerspruchsmöglichkeiten (sog. „Opt-Out“). Sofern keine
          explizite Opt-Out-Möglichkeit angegeben wurde, besteht zum einen die
          Möglichkeit, dass Sie Cookies in den Einstellungen Ihres Browsers
          abschalten. Hierdurch können jedoch Funktionen unseres Onlineangebotes
          eingeschränkt werden. Wir empfehlen daher zusätzlich die folgenden
          Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige Gebiete gerichtet
          angeboten werden:
          <p>
            a) Europa:
            <a
              href="https://www.youronlinechoices.eu"
              target="_blank"
              rel="noopener"
              >https://www.youronlinechoices.eu</a
            >.<br />b) Kanada:
            <a
              href="https://www.youradchoices.ca/choices"
              target="_blank"
              rel="noopener"
              >https://www.youradchoices.ca/choices</a
            >.<br />c) USA:
            <a
              href="https://www.aboutads.info/choices"
              target="_blank"
              rel="noopener"
              >https://www.aboutads.info/choices</a
            >.<br />d) Gebietsübergreifend:
            <a href="https://optout.aboutads.info" target="_blank" rel="noopener"
              >https://optout.aboutads.info</a
            >.
          </p>
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Facebook-Pixel und Zielgruppenbildung (Custom Audiences): </strong
          >Mit Hilfe des Facebook-Pixels (oder vergleichbarer Funktionen, zur
          Übermittlung von Event-Daten oder Kontaktinformationen mittels von
          Schnittstellen in Apps) ist es Facebook zum einen möglich, die Besucher
          unseres Onlineangebotes als Zielgruppe für die Darstellung von Anzeigen
          (sogenannte „Facebook-Ads“) zu bestimmen. Dementsprechend setzen wir das
          Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen
          Nutzern bei Facebook und innerhalb der Dienste der mit Facebook
          kooperierenden Partner (so genanntes „Audience Network“
          <a
            href="https://www.facebook.com/audiencenetwork/"
            target="_blank"
            rel="noopener"
            >https://www.facebook.com/audiencenetwork/</a
          >
          ) anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt
          haben oder die bestimmte Merkmale (z.B. Interesse an bestimmten Themen
          oder Produkten, die anhand der besuchten Webseiten ersichtlich werden)
          aufweisen, die wir an Facebook übermitteln (sogenannte „Custom
          Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen,
          dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen
          und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir
          ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und
          Marktforschungszwecke nachvollziehen, indem wir sehen, ob Nutzer nach dem
          Klick auf eine Facebook-Werbeanzeige auf unsere Webseite weitergeleitet
          wurden (sogenannte „Konversionsmessung“);
          <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited, 4 Grand
          Canal Square, Grand Canal Harbour, Dublin 2, Irland;
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
          a) DSGVO); <strong>Website:</strong>
          <a href="https://www.facebook.com" target="_blank" rel="noopener"
            >https://www.facebook.com</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://www.facebook.com/about/privacy"
            target="_blank"
            rel="noopener"
            >https://www.facebook.com/about/privacy</a
          >; <strong>Weitere Informationen:</strong> Event Daten der Nutzer, d.h.
          Verhaltens- und Interessensangaben, werden für die Zwecke der gezielten
          Werbung und der Zielgruppenbildung auf der Grundlage der Vereinbarung über
          die gemeinsame Verantwortlichkeit („Zusatz für Verantwortliche“,
          <a
            href="https://www.facebook.com/legal/controller_addendum"
            target="_blank"
            rel="noopener"
            >https://www.facebook.com/legal/controller_addendum</a
          >) verarbeitet. Die gemeinsame Verantwortlichkeit beschränkt sich auf die
          Erhebung durch und Übermittlung von Daten an Meta Platforms Ireland
          Limited, ein Unternehmen mit Sitz in der EU. Die weitere Verarbeitung der
          Daten liegt in der alleinigen Verantwortung von Meta Platforms Ireland
          Limited, was insbesondere die Übermittlung der Daten an die
          Muttergesellschaft Meta Platforms, Inc. in den USA betrifft (auf der
          Grundlage der zwischen Meta Platforms Ireland Limited und Meta Platforms,
          Inc. geschlossenen Standardvertragsklauseln).
        </li>
        <li>
          <strong>Google Ads und Konversionsmessung: </strong
          >Online-Marketing-Verfahren zum Zwecke der Platzierung von Inhalten und
          Anzeigen innerhalb des Werbenetzwerks des Diensteanbieters (z. B. in
          Suchergebnissen, in Videos, auf Webseiten usw.), so dass sie Nutzern
          angezeigt werden, die ein mutmaßliches Interesse an den Anzeigen haben.
          Darüber hinaus messen wir die Konversion der Anzeigen, d.h. ob die Nutzer
          sie zum Anlass genommen haben, mit den Anzeigen zu interagieren und die
          beworbenen Angebote zu nutzen (sog. Conversion). Wir erhalten jedoch nur
          anonyme Informationen und keine persönlichen Informationen über einzelne
          Nutzer; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
          House, Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
          a) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);
          <strong>Website:</strong>
          <a
            href="https://marketingplatform.google.com"
            target="_blank"
            rel="noopener"
            >https://marketingplatform.google.com</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >; <strong>Weitere Informationen:</strong> Arten der Verarbeitung sowie
          der verarbeiteten Daten:
          <a
            href="https://privacy.google.com/businesses/adsservices"
            target="_blank"
            rel="noopener"
            >https://privacy.google.com/businesses/adsservices</a
          >; Datenverarbeitungsbedingungen zwischen Verantwortlichen und
          Standardvertragsklauseln für Drittlandtransfers von Daten:
          <a
            href="https://business.safety.google/adscontrollerterms"
            target="_blank"
            rel="noopener"
            >https://business.safety.google/adscontrollerterms</a
          >.
        </li>
        <li>
          <strong>Zielgruppenbildung mit Google Analytics: </strong>Wir setzen
          Google Analytics ein, um die durch innerhalb von Werbediensten Googles und
          seiner Partner geschalteten Anzeigen, nur solchen Nutzern anzuzeigen, die
          auch ein Interesse an unserem Onlineangebot gezeigt haben oder die
          bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten,
          die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an
          Google übermitteln (sog. „Remarketing-“, bzw.
          „Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences möchten
          wir auch sicherstellen, dass unsere Anzeigen dem potentiellen Interesse
          der Nutzer entsprechen; <strong>Dienstanbieter:</strong> Google Ireland
          Limited, Gordon House, Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
          a) DSGVO); <strong>Website:</strong>
          <a
            href="https://marketingplatform.google.com"
            target="_blank"
            rel="noopener"
            >https://marketingplatform.google.com</a
          >; <strong>Rechtsgrundlagen:</strong>
          <a
            href="https://business.safety.google/adsprocessorterms/"
            target="_blank"
            rel="noopener"
            >https://business.safety.google/adsprocessorterms/</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >; <strong>Auftragsverarbeitungsvertrag:</strong>
          <a
            href="https://business.safety.google/adsprocessorterms/"
            target="_blank"
            rel="noopener"
            >https://business.safety.google/adsprocessorterms/</a
          >; <strong>Weitere Informationen:</strong> Arten der Verarbeitung sowie
          der verarbeiteten Daten:
          <a
            href="https://privacy.google.com/businesses/adsservices"
            target="_blank"
            rel="noopener"
            >https://privacy.google.com/businesses/adsservices</a
          >; Datenverarbeitungsbedingungen für Google Werbeprodukte und
          Standardvertragsklauseln für Drittlandtransfers von Daten:
          <a
            href="https://business.safety.google/adsprocessorterms"
            target="_blank"
            rel="noopener"
            >https://business.safety.google/adsprocessorterms</a
          >.
        </li>
      </ul>
      <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
      <p>
        Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten
        in diesem Rahmen Daten der Nutzer, um mit den dort aktiven Nutzern zu
        kommunizieren oder um Informationen über uns anzubieten.
      </p>
      <p>
        Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der
        Europäischen Union verarbeitet werden können. Hierdurch können sich für die
        Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer
        erschwert werden könnte.
      </p>
      <p>
        Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall
        für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des
        Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer
        Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum
        verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der
        Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen.
        Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer
        gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer
        gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
        unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
        (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind
        und bei diesen eingeloggt sind).
      </p>
      <p>
        Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
        Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
        Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.
      </p>
      <p>
        Auch im Fall von Auskunftsanfragen und der Geltendmachung von
        Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei den
        Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
        Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen
        ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann
        können Sie sich an uns wenden.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
          Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
          Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B.
          IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
          Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
          Kommunikation; Feedback (z.B. Sammeln von Feedback via Online-Formular);
          Marketing.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Facebook-Seiten: </strong>Profile innerhalb des sozialen Netzwerks
          Facebook – Wir sind gemeinsam mit Meta Platforms Ireland Limited für die
          Erhebung (jedoch nicht die weitere Verarbeitung) von Daten der Besucher
          unserer Facebook-Seite (sog. „Fanpage“) verantwortlich. Zu diesen Daten
          gehören Informationen zu den Arten von Inhalten, die Nutzer sich ansehen
          oder mit denen sie interagieren, oder die von ihnen vorgenommenen
          Handlungen (siehe unter „Von dir und anderen getätigte und bereitgestellte
          Dinge“ in der Facebook-Datenrichtlinie:
          <a href="https://www.facebook.com/policy" target="_blank" rel="noopener"
            >https://www.facebook.com/policy</a
          >), sowie Informationen über die von den Nutzern genutzten Geräte (z. B.
          IP-Adressen, Betriebssystem, Browsertyp, Spracheinstellungen,
          Cookie-Daten; siehe unter „Geräteinformationen“ in der
          Facebook-Datenrichtlinie:
          <a href="https://www.facebook.com/policy" target="_blank" rel="noopener"
            >https://www.facebook.com/policy</a
          >). Wie in der Facebook-Datenrichtlinie unter „Wie verwenden wir diese
          Informationen?“ erläutert, erhebt und verwendet Facebook Informationen
          auch, um Analysedienste, so genannte „Seiten-Insights“, für
          Seitenbetreiber bereitzustellen, damit diese Erkenntnisse darüber
          erhalten, wie Personen mit ihren Seiten und mit den mit ihnen verbundenen
          Inhalten interagieren. Wir haben mit Facebook eine spezielle Vereinbarung
          abgeschlossen („Informationen zu Seiten-Insights“,
          <a
            href="https://www.facebook.com/legal/terms/page_controller_addendum"
            target="_blank"
            rel="noopener"
            >https://www.facebook.com/legal/terms/page_controller_addendum</a
          >), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
          Facebook beachten muss und in der Facebook sich bereit erklärt hat die
          Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B. Auskünfte oder
          Löschungsanfragen direkt an Facebook richten). Die Rechte der Nutzer
          (insbesondere auf Auskunft, Löschung, Widerspruch und Beschwerde bei
          zuständiger Aufsichtsbehörde), werden durch die Vereinbarungen mit
          Facebook nicht eingeschränkt. Weitere Hinweise finden sich in den
          „Informationen zu Seiten-Insights“ (<a
            href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
            target="_blank"
            rel="noopener"
            >https://www.facebook.com/legal/terms/information_about_page_insights_data</a
          >); <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited, 4
          Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.facebook.com" target="_blank" rel="noopener"
            >https://www.facebook.com</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://www.facebook.com/about/privacy"
            target="_blank"
            rel="noopener"
            >https://www.facebook.com/about/privacy</a
          >;
          <strong
            >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
            Verarbeitung in Drittländern):</strong
          >
          <a
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            target="_blank"
            rel="noopener"
            >https://www.facebook.com/legal/EU_data_transfer_addendum</a
          >; <strong>Weitere Informationen:</strong> Vereinbarung gemeinsamer
          Verantwortlichkeit:
          <a
            href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
            target="_blank"
            rel="noopener"
            >https://www.facebook.com/legal/terms/information_about_page_insights_data</a
          >. Die gemeinsame Verantwortlichkeit beschränkt sich auf die Erhebung
          durch und Übermittlung von Daten an Meta Platforms Ireland Limited, ein
          Unternehmen mit Sitz in der EU. Die weitere Verarbeitung der Daten liegt
          in der alleinigen Verantwortung von Meta Platforms Ireland Limited, was
          insbesondere die Übermittlung der Daten an die Muttergesellschaft Meta
          Platforms, Inc. in den USA betrifft (auf der Grundlage der zwischen Meta
          Platforms Ireland Limited und Meta Platforms, Inc. geschlossenen
          Standardvertragsklauseln).
        </li>
        <li>
          <strong>LinkedIn: </strong>Soziales Netzwerk;
          <strong>Dienstanbieter:</strong> LinkedIn Irland Unlimited Company, Wilton
          Plaza Wilton Place, Dublin 2, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener"
            >https://www.linkedin.com</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://www.linkedin.com/legal/privacy-policy"
            target="_blank"
            rel="noopener"
            >https://www.linkedin.com/legal/privacy-policy</a
          >; <strong>Auftragsverarbeitungsvertrag:</strong>
          <a href="https://legal.linkedin.com/dpa" target="_blank" rel="noopener"
            >https://legal.linkedin.com/dpa</a
          >;
          <strong
            >Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
            Verarbeitung in Drittländern):</strong
          >
          <a href="https://legal.linkedin.com/dpa" target="_blank" rel="noopener"
            >https://legal.linkedin.com/dpa</a
          >; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>
          <a
            href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
            target="_blank"
            rel="noopener"
            >https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a
          >.
        </li>
        <li>
          <strong>YouTube: </strong>Soziales Netzwerk und Videoplattform;
          <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House,
          Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>
          <a
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noopener"
            >https://adssettings.google.com/authenticated</a
          >.
        </li>
        <li>
          <strong>Xing: </strong>Soziales Netzwerk;
          <strong>Dienstanbieter:</strong> New Work SE, Am Strandkai 1, 20457
          Hamburg, Deutschland; <strong>Rechtsgrundlagen:</strong> Berechtigte
          Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.xing.de" target="_blank" rel="noopener"
            >https://www.xing.de</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://privacy.xing.com/de/datenschutzerklaerung"
            target="_blank"
            rel="noopener"
            >https://privacy.xing.com/de/datenschutzerklaerung</a
          >.
        </li>
      </ul>
      <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
      <p>
        Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die
        von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet als
        „Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um
        Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich bezeichnet
        als „Inhalte”).
      </p>
      <p>
        Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die
        IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte
        nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
        Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns,
        nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse
        lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können
        ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als „Web Beacons“
        bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
        „Pixel-Tags“ können Informationen, wie der Besucherverkehr auf den Seiten
        dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können
        ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter
        anderem technische Informationen zum Browser und zum Betriebssystem, zu
        verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung
        unseres Onlineangebotes enthalten als auch mit solchen Informationen aus
        anderen Quellen verbunden werden.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
          Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
          und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus); Bestandsdaten (z.B. Namen,
          Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
          Eingaben in Onlineformularen); Standortdaten (Angaben zur geografischen
          Position eines Gerätes oder einer Person).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher,
          Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit; Erbringung vertraglicher
          Leistungen und Kundenservice.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong
            >Einbindung von Drittsoftware, Skripten oder Frameworks (z. B. jQuery): </strong
          >Wir binden in unser Onlineangebot Software ein, die wir von Servern
          anderer Anbieter abrufen (z.B. Funktions-Bibliotheken, die wir zwecks
          Darstellung oder Nutzerfreundlichkeit&nbsp;unseres Onlineangebotes
          verwenden). Hierbei erheben die jeweiligen Anbieter die IP-Adresse der
          Nutzer und können diese zu Zwecken der&nbsp;Übermittlung der Software an
          den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch zur
          Auswertung und Optimierung ihres Angebotes verarbeiten. – Wir binden in
          unser Onlineangebot Software ein, die wir von Servern anderer Anbieter
          abrufen (z.B. Funktions-Bibliotheken, die wir zwecks Darstellung oder
          Nutzerfreundlichkeit&nbsp;unseres Onlineangebotes verwenden). Hierbei
          erheben die jeweiligen Anbieter die IP-Adresse der Nutzer und können diese
          zu Zwecken der&nbsp;Übermittlung der Software an den Browser der Nutzer
          sowie zu Zwecken der Sicherheit, als auch zur Auswertung und Optimierung
          ihres Angebotes verarbeiten;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO).
        </li>
        <li>
          <strong>Google Fonts (Bezug vom Google Server): </strong>Bezug von
          Schriften (und Symbolen) zum Zwecke einer technisch sicheren,
          wartungsfreien und effizienten Nutzung von Schriften und Symbolen im
          Hinblick auf Aktualität und Ladezeiten, deren einheitliche Darstellung und
          Berücksichtigung möglicher lizenzrechtlicher Beschränkungen. Dem Anbieter
          der Schriftarten wird die IP-Adresse des Nutzers mitgeteilt, damit die
          Schriftarten im Browser des Nutzers zur Verfügung gestellt werden können.
          Darüber hinaus werden technische Daten (Spracheinstellungen,
          Bildschirmauflösung, Betriebssystem, verwendete Hardware) übermittelt, die
          für die Bereitstellung der Schriften in Abhängigkeit von den verwendeten
          Geräten und der technischen Umgebung notwendig sind. Diese Daten können
          auf einem Server des Anbieters der Schriftarten in den USA verarbeitet
          werden – Beim Besuch unseres Onlineangebotes senden die Browser der Nutzer
          ihre Browser HTTP-Anfragen an die Google Fonts Web API (d.h. eine
          Softwareschnittstelle für den Abruf der Schriftarten). Die Google Fonts
          Web API stellt den Nutzern die Cascading Style Sheets (CSS) von Google
          Fonts und danach die in der CCS angegebenen Schriftarten zur Verfügung. Zu
          diesen HTTP-Anfragen gehören (1) die vom jeweiligen Nutzer für den Zugriff
          auf das Internet verwendete IP-Adresse, (2) die angeforderte URL auf dem
          Google-Server und (3) die HTTP-Header, einschließlich des User-Agents, der
          die Browser- und Betriebssystemversionen der Websitebesucher beschreibt,
          sowie die Verweis-URL (d.h. die Webseite, auf der die Google-Schriftart
          angezeigt werden soll). IP-Adressen werden weder auf Google-Servern
          protokolliert noch gespeichert und sie werden nicht analysiert. Die Google
          Fonts Web API protokolliert Details der HTTP-Anfragen (angeforderte URL,
          User-Agent und Verweis-URL). Der Zugriff auf diese Daten ist eingeschränkt
          und streng kontrolliert. Die angeforderte URL identifiziert die
          Schriftfamilien, für die der Nutzer Schriftarten laden möchte. Diese Daten
          werden protokolliert, damit Google bestimmen kann, wie oft eine bestimmte
          Schriftfamilie angefordert wird. Bei der Google Fonts Web API muss der
          User-Agent die Schriftart anpassen, die für den jeweiligen Browsertyp
          generiert wird. Der User-Agent wird in erster Linie zum Debugging
          protokolliert und verwendet, um aggregierte Nutzungsstatistiken zu
          generieren, mit denen die Beliebtheit von Schriftfamilien gemessen wird.
          Diese zusammengefassten Nutzungsstatistiken werden auf der Seite
          „Analysen“ von Google Fonts veröffentlicht. Schließlich wird die
          Verweis-URL protokolliert, sodass die Daten für die Wartung der Produktion
          verwendet und ein aggregierter Bericht zu den Top-Integrationen basierend
          auf der Anzahl der Schriftartenanfragen generiert werden kann. Google
          verwendet laut eigener Auskunft keine der von Google Fonts erfassten
          Informationen, um Profile von Endnutzern zu erstellen oder zielgerichtete
          Anzeigen zu schalten; <strong>Dienstanbieter:</strong> Google Ireland
          Limited, Gordon House, Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://fonts.google.com/" target="_blank" rel="noopener"
            >https://fonts.google.com/</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >; <strong>Weitere Informationen:</strong>
          <a
            href="https://developers.google.com/fonts/faq/privacy?hl=de"
            target="_blank"
            rel="noopener"
            >https://developers.google.com/fonts/faq/privacy?hl=de</a
          >.
        </li>
        <li>
          <strong>Google Maps: </strong>Wir binden die Landkarten des Dienstes
          “Google Maps” des Anbieters Google ein. Zu den verarbeiteten Daten können
          insbesondere IP-Adressen und Standortdaten der Nutzer gehören;
          <strong>Dienstanbieter:</strong> Google Cloud EMEA Limited, 70 Sir John
          Rogerson’s Quay, Dublin 2, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://mapsplatform.google.com/" target="_blank" rel="noopener"
            >https://mapsplatform.google.com/</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >.
        </li>
        <li>
          <strong>Google Maps APIs und SDKs: </strong>Schnittstellen zu den Karten-
          und Standortdiensten von Google, die z. B. eine Ergänzung von
          Adresseneingaben, Standortbestimmungen, Entfernungsberechnungen oder
          Bereitstellung von ergänzenden Informationen zu Stand- und sonstigen Orten
          erlauben; <strong>Dienstanbieter:</strong> Google Cloud EMEA Limited, 70
          Sir John Rogerson’s Quay, Dublin 2, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://mapsplatform.google.com/" target="_blank" rel="noopener"
            >https://mapsplatform.google.com/</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >.
        </li>
        <li>
          <strong>reCAPTCHA: </strong>Wir binden die Funktion „reCAPTCHA“ ein, um
          erkennen zu können, ob Eingaben (z.B. in Onlineformularen) von Menschen
          und nicht von automatisch agierenden Maschinen (sogenannten „Bots“)
          getätigt werden. Zu den verarbeiteten Daten können IP-Adressen,
          Informationen zu Betriebssystemen, Geräten oder verwendeten Browsern,
          Spracheinstellungen, Standort, Mausbewegungen, Tastaturanschläge,
          Verweildauer auf Webseiten, zuvor besuchte Webseiten, Interaktionen mit
          ReCaptcha auf anderen Webseiten, unter Umständen Cookies sowie Ergebnisse
          von manuellen Erkennungsvorgängen (z. B. Beantwortung von gestellten
          Fragen oder Auswahl von Objekten in Bildern) gehören. Die
          Datenverarbeitung erfolgt auf Grundlage unserer berechtigten Interesse,
          unser Onlineangebot vor missbräuchlichem automatisiertem Crawling und Spam
          zu schützen; <strong>Dienstanbieter:</strong> Google Ireland Limited,
          Gordon House, Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.google.com/recaptcha/" target="_blank" rel="noopener"
            >https://www.google.com/recaptcha/</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Opt-Out-Plugin:
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener"
            >https://tools.google.com/dlpage/gaoptout?hl=de</a
          >, Einstellungen für die Darstellung von Werbeeinblendungen:
          <a
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noopener"
            >https://adssettings.google.com/authenticated</a
          >.
        </li>
        <li>
          <strong>YouTube-Videos: </strong>Videoinhalte;
          <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House,
          Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.youtube.com" target="_blank" rel="noopener"
            >https://www.youtube.com</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Opt-Out-Plugin:
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener"
            >https://tools.google.com/dlpage/gaoptout?hl=de</a
          >, Einstellungen für die Darstellung von Werbeeinblendungen:
          <a
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noopener"
            >https://adssettings.google.com/authenticated</a
          >.
        </li>
        <li>
          <strong>YouTube-Videos: </strong>Videoinhalte; YouTube-Videos werden über
          eine spezielle Domain (erkennbar an dem Bestandteil „youtube-nocookie“) im
          sogenannten „Erweiterten Datenschutzmodus“ eingebunden, wodurch keine
          Cookies zu Nutzeraktivitäten erhoben werden, um die Videowiedergabe zu
          personalisieren. Dennoch können Angaben zur Interaktion der Nutzer mit dem
          Video (z.B. Merken der letzten Wiedergabestelle), gespeichert werden;
          <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House,
          Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO); <strong>Website:</strong>
          <a href="https://www.youtube.com" target="_blank" rel="noopener"
            >https://www.youtube.com</a
          >; <strong>Datenschutzerklärung:</strong>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >.
        </li>
        <li>
          <strong>Font Awesome (Bereitstellung auf eigenem Server): </strong
          >Darstellung von Schriftarten und Symbolen; Rechtsgrundlagen: Berechtigte
          Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);
          <strong>Dienstanbieter:</strong> Die Font Awesome Icons werden auf unserem
          Server gehostet, es werden keine Daten an den Anbieter von Font Awesome
          übermittelt; <strong>Website:</strong> undefined;
          <strong>Datenschutzerklärung:</strong> undefined.
        </li>
      </ul>
      <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
      <p>
        Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung
        zu informieren. Wir passen die Datenschutzerklärung an, sobald die
        Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich
        machen. Wir informieren Sie, sobald durch die Änderungen eine
        Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige
        individuelle Benachrichtigung erforderlich wird.
      </p>
      <p>
        Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen
        von Unternehmen und Organisationen angeben, bitten wir zu beachten, dass die
        Adressen sich über die Zeit ändern können und bitten die Angaben vor
        Kontaktaufnahme zu prüfen.
      </p>
      <h2 id="m10">Rechte der betroffenen Personen</h2>
      <p>
        Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich
        insbesondere aus Art. 15 bis 21 DSGVO ergeben:
      </p>
      <ul>
        <li>
          <strong
            >Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer
            besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie
            betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
            lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für
            ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie
            betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
            betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
            derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit
            es mit solcher Direktwerbung in Verbindung steht.</strong
          >
        </li>
        <li>
          <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht,
          erteilte Einwilligungen jederzeit zu widerrufen.
        </li>
        <li>
          <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
          darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
          Auskunft über diese Daten sowie auf weitere Informationen und Kopie der
          Daten entsprechend den gesetzlichen Vorgaben.
        </li>
        <li>
          <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
          gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
          betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen
          Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong>
          Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen,
          dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ
          nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung
          der Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie
          betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der
          gesetzlichen Vorgaben in einem strukturierten, gängigen und
          maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
          anderen Verantwortlichen zu fordern.
        </li>
        <li>
          <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet
          eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
          Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde,
          insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts,
          ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie
          der Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
        </li>
      </ul>
      <h2 id="m42">Begriffsdefinitionen</h2>
      <p>
        In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
        Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe sind
        dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
        gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen
        sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind
        alphabetisch sortiert.
      </p>
      <ul class="glossary">
        <li>
          <strong>Konversionsmessung:</strong> Die Konversionsmessung (auch als
          „Besuchsaktionsauswertung“ bezeichnet) ist ein Verfahren, mit dem die
          Wirksamkeit von Marketingmaßnahmen festgestellt werden kann. Dazu wird im
          Regelfall ein Cookie auf den Geräten der Nutzer innerhalb der Webseiten,
          auf denen die Marketingmaßnahmen erfolgen, gespeichert und dann erneut auf
          der Zielwebseite abgerufen. Beispielsweise können wir so nachvollziehen,
          ob die von uns auf anderen Webseiten geschalteten Anzeigen erfolgreich
          waren.
        </li>
        <li>
          <strong>Personenbezogene Daten:</strong> „Personenbezogene Daten“ sind
          alle Informationen, die sich auf eine identifizierte oder identifizierbare
          natürliche Person (im Folgenden „betroffene Person“) beziehen; als
          identifizierbar wird eine natürliche Person angesehen, die direkt oder
          indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
          zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B.
          Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert
          werden kann, die Ausdruck der physischen, physiologischen, genetischen,
          psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
          natürlichen Person sind.
        </li>
        <li>
          <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
          Verarbeitung von „Profilen mit nutzerbezogenen Informationen“, bzw. kurz
          „Profilen“ umfasst jede Art der automatisierten Verarbeitung
          personenbezogener Daten, die darin besteht, dass diese personenbezogenen
          Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf
          eine natürliche Person beziehen (je nach Art der Profilbildung können dazu
          unterschiedliche Informationen betreffend die Demographie, Verhalten und
          Interessen, wie z.B. die Interaktion mit Webseiten und deren Inhalten,
          etc.) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die
          Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf
          einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden
          häufig Cookies und Web-Beacons eingesetzt.
        </li>
        <li>
          <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web
          Analytics bezeichnet) dient der Auswertung der Besucherströme eines
          Onlineangebotes und kann das Verhalten oder Interessen der Besucher an
          bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit
          Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu
          welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie
          sich interessieren. Dadurch können sie z.B. die Inhalte der Webseite
          besser an die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der
          Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons
          eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere
          Analysen zur Nutzung eines Onlineangebotes zu erhalten.
        </li>
        <li>
          <strong>Remarketing:</strong> Vom „Remarketing“ bzw. „Retargeting“ spricht
          man, wenn z.B. zu Werbezwecken vermerkt wird, für welche Produkte sich ein
          Nutzer auf einer Webseite interessiert hat, um den Nutzer auf anderen
          Webseiten an diese Produkte, z.B. in Werbeanzeigen, zu erinnern.
        </li>
        <li>
          <strong>Standortdaten:</strong> Standortdaten entstehen, wenn sich ein
          mobiles Gerät (oder ein anderes Gerät mit den technischen Voraussetzungen
          einer Standortbestimmung) mit einer Funkzelle, einem WLAN oder ähnlichen
          technischen Mittlern und Funktionen der Standortbestimmung, verbindet.
          Standortdaten dienen der Angabe, an welcher geografisch bestimmbaren
          Position der Erde sich das jeweilige Gerät befindet. Standortdaten können
          z. B. eingesetzt werden, um Kartenfunktionen oder andere von einem Ort
          abhängige Informationen darzustellen.
        </li>
        <li>
          <strong>Tracking:</strong> Vom „Tracking“ spricht man, wenn das Verhalten
          von Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden kann.
          Im Regelfall werden im Hinblick auf die genutzten Onlineangebote
          Verhaltens- und Interessensinformationen in Cookies oder auf Servern der
          Anbieter der Trackingtechnologien gespeichert (sogenanntes Profiling).
          Diese Informationen können anschließend z.B. eingesetzt werden, um den
          Nutzern Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen
          entsprechen.
        </li>
        <li>
          <strong>Verantwortlicher:</strong> Als „Verantwortlicher“ wird die
          natürliche oder juristische Person, Behörde, Einrichtung oder andere
          Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
          der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
        </li>
        <li>
          <strong>Verarbeitung:</strong> „Verarbeitung“ ist jeder mit oder ohne
          Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
          Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff
          reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das
          Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.
        </li>
        <li>
          <strong>Zielgruppenbildung:</strong> Von Zielgruppenbildung (englisch
          „Custom Audiences“) spricht man, wenn Zielgruppen für Werbezwecke, z.B.
          Einblendung von Werbeanzeigen bestimmt werden. So kann z.B. anhand des
          Interesses eines Nutzers an bestimmten Produkten oder Themen im Internet
          geschlussfolgert werden, dass dieser Nutzer sich für Werbeanzeigen für
          ähnliche Produkte oder den Onlineshop, in dem er die Produkte betrachtet
          hat, interessiert. Von „Lookalike Audiences“ (bzw. ähnlichen Zielgruppen)
          spricht man wiederum, wenn die als geeignet eingeschätzten Inhalte Nutzern
          angezeigt werden, deren Profile, bzw. Interessen mutmaßlich den Nutzern zu
          denen die Profile gebildet wurden, entsprechen. Zur Zwecken der Bildung
          von Custom Audiences und Lookalike Audiences werden im Regelfall Cookies
          und Web-Beacons eingesetzt.
        </li>
      </ul>
    </div>
    `,
      IMPRINT: "Impressum",
      IMPRINT_CONTENT: `<div class="et_pb_section et_pb_section_1 et_section_regular"><div class="et_pb_row et_pb_row_1"><div class="et_pb_column et_pb_column_4_4 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child"><div class="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light"><div class="et_pb_text_inner"></div></div><div class="et_pb_module et_pb_divider et_pb_divider_0 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div></div></div><div class="et_pb_row et_pb_row_2"><div class="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child"><div class="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light"><div class="et_pb_text_inner"><h6>DIENSTLEISTER</h6><p>Con&shy;cir&shy;cle Österreich GmbH<br>Wie&shy;ner&shy;berg&shy;stra&shy;ße 11<br>A-1100 Wien<br><span id="eeb-786561-178120">office@concircle.com</span><script type="text/javascript">document.getElementById("eeb-786561-178120").innerHTML = eval(decodeURIComponent("%27%6f%66%66%69%63%65%40%63%6f%6e%63%69%72%63%6c%65%2e%63%6f%6d%27"))</script><noscript>*protected email*</noscript><br>www.concircle.com</p><h6>GESCHÄFTSFÜHRER</h6><p>Ing. Mag. Flo&shy;ri&shy;an Gstir<br>Dipl.-Ing. Dr. Kon&shy;rad Pfa&shy;den&shy;hau&shy;er</p><h6>Unternehmensgegenstand</h6><p>IT Consulting und IT Dienstleistungen</p><h6>HANDELSREGISTER</h6><p>Re&shy;gis&shy;ter&shy;ge&shy;richt: HG Wien<br>Re&shy;gis&shy;ter&shy;num&shy;mer: FN333525d</p><h6>UMSATZSTEUERIDENTIFIKATIONSNUMMER</h6><p>ATU65204334</p></div></div></div></div></div>`,
      LIST_TRANSPORT_INFO:
        "Achtung: Beachte, dass benutzerdefinierte Listen nicht innerhalb des POD-Transports transportiert werden. Bitte prüfe dies individuell",
      SUBSCRIPTION_TITLE: "Subscriptions",
      SUBSCRIPTION_SUBTITLE: "Finde den richtigen Plan für dein Unternehmen",
      MONTH: "Monat",
      YEAR: "Jahr",
      MONTHLY: "Monatlich",
      YEARLY: "Jährlich",
      FEATURES: "Merkmale",
      FUNCTIONALITIES: "Funktionen",
      DATASET_UPLOAD: "Datasets/Upload",
      USER_LIMIT: "No User limit",
      MAX_PROFILE: "Max. Profile",
      UPLOADS_PER_DAY: "Uploads / 24h",
      TRANSPORTS_PER_DAY: "Transporte / 24h",
      YES: "Ja",
      NO: "Nein",
      UNLIMITED: "Unlimitiert",
      LIMITED: "Limitiert",
      eur: "€",
      ALL: "Alle",
      SUBSCRIPTION: "Abonnieren",
      SUBSCRIBED: "Abonniert",
      PRICING_TABLE_TITLE: "Finde den richtigen Plan für dein Unternehmen",
      FREE_PLAN_EXPIRED:
        "Dein kostenloser Plan ist abgelaufen! Bitte abonniere einen Plan, um conDMWizard weiterhin nutzen zu können.",
      FREE_PLAN_LIMIT_REACHED_TRANSPORT_OR_UPLOAD:
        "Du hast das Limit deines kostenlosen Plans erreicht, der 10 Uploads oder Transporte pro 24 Stunden beinhaltet. Bitte aktualisiere deinen Plan, um conDMWizard weiterhin nutzen zu können.",
      FREE_PLAN_LIMIT_REACHED_DATA_COUNT:
        "Du hast das Limit deines kostenlosen Plans erreicht, der maximal 2 Datensätze pro Upload oder Transport beinhaltet. Bitte aktualisiere deinen Plan, um conDMWizard weiterhin nutzen zu können.",
      FAQ_WHAT_IS_CONDMWIZARD_HEADLINE: "Was ist conDMWizard",
      FAQ_WHAT_IS_CONDMWIZARD_QUESTION1: "Was ist conDMWizard?",
      FAQ_WHAT_IS_CONDMWIZARD_ANSWER1:
        "conDMWizard ist eine spezialisierte SaaS-Webanwendung, die für das effiziente Management von Stamm- und Konfigurationsdaten innerhalb der SAP Digital Manufacturing (SAP DM) Umgebung entwickelt wurde. Es ermöglicht das mühelose Hochladen, Migrieren und Synchronisieren von Stamm- und Konfigurationsdaten zwischen verschiedenen Werken und Tenants von SAP DM. Ideal für datenintensive Übergangsphasen unterstützt conDMWizard Unternehmen bei der Datenerstellung, -migration und -übertragung während entscheidender Übergangsphasen und gewährleistet einen nahtlosen Datenfluss und Integrität in der SAP DM-Umgebung.",
      FAQ_WHAT_IS_CONDMWIZARD_QUESTION2:
        "Benötige ich eine Schulung, um conDMWizard zu verwenden?",
      FAQ_WHAT_IS_CONDMWIZARD_ANSWER2:
        "Um conDMWizard zu verwenden, ist keine Schulung erforderlich. Während der Entwicklung haben wir den Fokus auf selbsterklärende Funktionalitäten gelegt. Auf jeder Seite finden Sie ein interaktives Anleitungstutorial. Wenn Sie dennoch Fragen haben, zögere nicht, uns zu <a href='mailto:support-condmw@concircle.com'>kontaktieren! </a>",
      FAQ_WHAT_IS_CONDMWIZARD_QUESTION3:
        "Welche Daten kann ich migrieren und übertragen?",
      FAQ_WHAT_IS_CONDMWIZARD_ANSWER3:
        "Derzeit können Datensammlungsgruppen <a href='/dataCollectionUpload'>migriert </a> und <a href='/dataCollectionTransfer'>übertragen </a> werden. Die POD-Konfiguration kann ebenfalls <a href='/podTransport'>übertragen </a> werden. Wir entwickeln kontinuierlich neue Funktionalitäten, um den Transport und die Migration zusätzlicher Arten von Stamm- und Konfigurationsdaten zu ermöglichen. <a href='mailto:support-condmw@concircle.com'>Kontaktieren Sie uns</a>, wenn Sie spezielle Anforderungen haben!",
      FAQ_WHAT_IS_CONDMWIZARD_QUESTION4:
        "Ich habe kein SAP DM, kann ich dennoch conDMWizard verwenden?",
      FAQ_WHAT_IS_CONDMWIZARD_ANSWER4:
        "conDMWizard kann nur verwendet werden, wenn du SAP Digital Manufacturing hast. Daher wird conDMWizard für dich nicht vorteilhaft sein, wenn du SAP DM nicht hast.",

      FAQ_SUBSCRIPTION_HEADLINE: "Abonnement & Testversion",
      FAQ_SUBSCRIPTION_QUESTION1: "Gibt es eine Testphase?",
      FAQ_SUBSCRIPTION_ANSWER1:
        "Eine kostenlose Version steht jedem zur Verfügung und kann einen Monat lang genutzt werden. Schau dir die Übersicht der verfügbaren Pläne an oder <a href='mailto:support-condmw@concircle.com'>kontaktiere uns</a> für individuelle Anfragen.",
      FAQ_SUBSCRIPTION_QUESTION2: "Wie viele Abonnements benötige ich?",
      FAQ_SUBSCRIPTION_ANSWER2:
        "Du benötigst nur ein Abonnement, um conDMWizard zu verwenden, oder du kannst den kostenlosen Plan nutzen!",
      FAQ_SUBSCRIPTION_QUESTION3: "Wie abonniere ich?",
      FAQ_SUBSCRIPTION_ANSWER3:
        "Klicke auf 'Abonieren' in unserer Plan-Übersicht. Dort kannst du den Plan kaufen, der am besten zu dir passt! <br/> <p style='font-size: 0.7rem'>Hinweis: Du kannst die Schaltfläche 'Abonnieren' nur dan sehen, wenn du bei conDMWizard angemeldet bist.</p>",
      FAQ_SUBSCRIPTION_QUESTION4: "Was kostet conDMWizard?",
      FAQ_SUBSCRIPTION_ANSWER4:
        "conDMWizard-Pläne können monatlich oder jährlich erworben werden. Die Pläne inklusive Preise findest du in der Übersicht der Abrechnungspläne. Die Wahl des Plans hängt von der Anzahl der Profile ab, die du erstellen möchtest. Ein Profil besteht aus einer einzigartigen Kombination von SAP Digital Manufacturing Plant und Tenant (Q oder PROD). Es fallen keine weiteren Gebühren an!",

      FAQ_TEC_QUESTIONS_HEADLINE: "Technische Fragen & Anleitungen",
      FAQ_TEC_QUESTIONS_QUESTION1:
        "Welche Systemvoraussetzungen benötige ich, um conDMWizard zu verwenden?",
      FAQ_TEC_QUESTIONS_ANSWER1:
        "conDMWizard ist eine Online-Plattform - du kannst einen Webbrowser deiner Wahl verwenden.",
      FAQ_TEC_QUESTIONS_QUESTION2: "Wie beginne ich mit conDMWizard?",
      FAQ_TEC_QUESTIONS_ANSWER2:
        "Registriere dich bei conDMWizard, um dein Profil im Profilmanagement-System zu erstellen. Nach der Registrierung kannst du alle Funktionen der kostenlosen Version erkunden und nutzen.",
      FAQ_TEC_QUESTIONS_QUESTION3: "Wie lade ich Daten hoch?",
      FAQ_TEC_QUESTIONS_ANSWER3:
        "Eine Excel-Vorlage ist in den Standardfunktionalitäten der <a href='/dataCollectionUpload'>Anwendung</a> enthalten. Verwende diese Vorlage, um deine Daten nahtlos hochzuladen, und lass conDMWizard den Rest erledigen!",
      FAQ_TEC_QUESTIONS_QUESTION4:
        "Was kann ich tun, wenn der Upload nicht funktioniert hat?",
      FAQ_TEC_QUESTIONS_ANSWER4:
        "Überprüfe das bereitgestellte Protokoll mit der entsprechenden Fehlermeldung und ändere dein Excel entsprechend. Wenn du immer noch ein Problem hast, <a href='mailto:support-condmw@concircle.com'>kontaktiere uns!</a>",

      FAQ_DATA_SECURITY_HEADLINE: "Datensicherheit",
      FAQ_DATA_SECURITY_QUESTION1:
        "Was passiert mit meinen SAP DM Tenant-Anmeldedaten?",
      FAQ_DATA_SECURITY_ANSWER1:
        "Deine SAP Digital Manufacturing Tenant-Anmeldedaten werden sicher in deiner lokalen Sitzungsspeicherung gespeichert, sodass sie nicht innerhalb der Anwendung aufbewahrt werden. Dies erhöht die Sicherheit deiner Anmeldedaten und gewährleistet die Vertraulichkeit deiner sensiblen Informationen.",
      FAQ_DATA_SECURITY_QUESTION2: "Wie sicher ist die Datenübertragung?",
      FAQ_DATA_SECURITY_ANSWER2:
        "Die Datenübertragung ist hochsicher, da sie über HTTPS erfolgt und den neuesten und weit verbreiteten Sicherheitsstandards entspricht. Dieses Verschlüsselungsprotokoll gewährleistet die Vertraulichkeit und Integrität der übertragenen Daten und reduziert das Risiko unbefugten Zugriffs erheblich.",
      FAQ_DATA_SECURITY_QUESTION3:
        "Welche und wo werden deine Daten gespeichert?",
      FAQ_DATA_SECURITY_ANSWER3:
        "Deine Daten, einschließlich Benutzerauthentifizierung und allgemeine Informationen zum Digital Manufacturing (DM)-Profil wie Werk, Name und DM-URL, werden sicher auf unserem Datenbankserver in der Azure-Cloud gespeichert. Wir legen Wert auf die Sicherheit deiner Profile, indem wir die Speicherung von Anmeldedaten, die mit dem DM-Profil verbunden sind, ausschließen.",
      FAQ_DATA_SECURITY_QUESTION4:
        "Welche Daten werden beim Zahlungsanbieter gespeichert?",
      FAQ_DATA_SECURITY_ANSWER4:
        "Unser Zahlungsanbieter, Stripe, speichert Zahlungsdaten sicher. Weitere Informationen findest du in <a href='/dataPrivacy'>unserer Datenschutzrichtlinie</a> oder der Datenschutzrichtlinie von <a href='https://stripe.com/privacy'>Stripe</a>.",
      GTC: "AGB",
      TERMS_AND_CONDITIONS_TITLE: "Allgemeine Geschäftsbedingungen (AGB)",
      TERMS_AND_CONDITIONS_SUBTITLE:
        "Concircle Österreich GmbH Software as a Service (SaaS) – Vertrag für conDMWizard",
      TERMS_AND_CONDITIONS_VALIDITY: `Durch Ausfüllen des Online-Anmeldeformulars bzw. Einrichten eines Benutzerkontos akzeptieren Sie die folgenden Allgemeinen Geschäftsbedingungen (AGB)
      von  
      
      Concircle Österreich GmbH 
      Wienerbergstraße 11 
      1100 Wien 
      Österreich

      Dienstleistungen in der Elektronischen Datenverarbeitung  
      im Folgenden „concircle“ „Anbieter“ oder „wir / uns“
      gültig ab: 25.3.2024.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_ONE: "Definitionen",
      TERMS_AND_CONDITIONS_CHAPTER_ONE_TEXT: `„SaaS-Dienst“ oder „Dienst“: SaaS steht für „Software as a Service“. Dabei wird ein Software- Produkt auf der IT-Infrastruktur des Software-Anbieters oder Dritter (z.B. Microsoft Azure oder Amazon Web Services) betrieben und kann von Kunden/Kundinnen* als Dienstleistung genutzt werden. Wartung und Updates der Software werden vom Anbieter direkt durchgeführt.
      conDMWizard ist ein Anwendungsprogramm, das nicht auf dem Rechner des/der Kunden/Kundin installiert werden muss. Zur Ausführung genügt ein moderner Web-Browser und eine Internetverbindung. conDMWizard stellt einen SaaS-Dienst dar. 
      Ein „Abo“ ist das Recht auf Nutzung von conDMWizard als SaaS für das in der Leistungsbeschreibung vereinbarte Ausmaß in zeitlicher und inhaltlicher Hinsicht. In der Leistungsbeschreibung des Abos wird z.B. festgehalten, wie viele verschiedene Profile (Zusammensetzung Mandant & SAP DM Werk) angelegt werden können.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_TWO: "Geltung",
      TERMS_AND_CONDITIONS_CHAPTER_TWO_TEXT: `Die Allgemeinen Geschäftsbedingungen (AGB) in der jeweils gültigen Fassung gelten für conDMWizard beim entgeltlichen Kauf eines Abos.
      Bei widersprüchlichen Regelungen gehen schriftliche Individualvereinbarungen zwischen Vertragspartnern/innen diesen AGB vor.
      Die jeweils gültige Fassung dieser AGB kann jederzeit auf der Webseite von conDMWizard abgerufen oder unter condmw-support@concircle.com angefordert werden.  
      Abweichende Vertragsbedingungen des Vertragspartners, insbesondere Einkaufsbedingungen des Vertragspartners, werden nicht Vertragsinhalt, auch wenn der Anbieter diesen nicht ausdrücklich widerspricht. Andere Vertragsbedingungen des Vertragspartners gelten daher nur, wenn der Anbieter diese ausdrücklich schriftlich anerkennt.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_THREE:
        "Vertragsgegenstand, Vertragsabschluss",
      TERMS_AND_CONDITIONS_CHAPTER_THREE_TEXT: `Concircle Österreich GmbH (in der Folge „Provider“ genannt) erbringt für ihre Kunden Software as a Service (SaaS) - Dienstleistungen über das Medium Internet im Bereich Unternehmenssoftware.
      Vertragsgegenstand dieses Vertrags ist ein Abo von conDMWizard als SaaS, welcher von concircle zur Verfügung gestellt wird. Der genaue Leistungsumfang ergibt sich aus den Ausführungen in diesen AGB sowie aus der Leistungsbeschreibung des Abos, welche bei der Bestellung gewählt wird. Individuelle Vereinbarungen zur Anpassung des Leistungsumfangs sind grundsätzlich möglich. Diese bedürfen der Schriftform.
      Ein Anspruch auf Entwicklung von eigenen Programmen bzw. die Abänderung von Standardsoftware für besondere Kundenanforderungen besteht grundsätzlich nicht, sofern diese nicht einzelvertraglich und schriftlich vereinbart worden sind.
      Die angebotenen SaaS-Dienste stehen sowohl Unternehmern/innen* iSd § 1 Abs 1 Z 1 des österreichischen Konsumentenschutzgesetzes („KSchG“), als auch Konsumentens iSd § 1 Abs 1 Z 2 KSchG offen. Die vorliegenden AGBs beinhalten dabei Bestimmungen, die nur für Konsumenten/Innen oder nur für Unternehmern/Innen gelten. Diese sind mit „Nur bei Konsumentens anwendbar“ oder „Nur bei Unternehmern/Innen anwendbar“ gekennzeichnet. Alle nicht derartig gekennzeichneten Bestimmungen gelten für Unternehmer/Innen und Konsumenten/Innen gleichermaßen.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_FOUR: "Leistungsbeschreibung",
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_ONE: "Zugang zu conDMWizard",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_ONE: `Der Provider stellt dem Kunden für die Dauer dieses Vertrages die Software conDMWizard in der jeweils aktuellen Version über das Internet unentgeltlich oder entgeltlich zur Nutzung zur Verfügung. Mit Abschluss dieses Vertrags erhalten Sie Zugriff auf conDMWizard im Umfang der im gewählten Abo definierten Merkmale.
      Nach Weiterentwicklung ergibt sich der jeweils aktuelle Funktionsumfang der Software aus der Leistungsbeschreibung auf der Website des Providers unter www.condmw.concircle.com.
      Nach dem Kauf Ihres Abos können sie umgehend die freigehaltenen Funktionen benutzen. Voraussetzungen für die Nutzung des Abos sind ein auf Ihrem Anwendergerät installierter, moderner Webbrowser sowie ein dauerhaft aufrechter Internetzugang Ihres Geräts. Bei unterbrochenem Internetzugang funktionieren die SaaS-Dienste nicht.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_TWO:
        "Benutzerberechtigungen, Benutzer-Accounts",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_TWO: `Ein Benutzer Account verfügt über maximal ein conDMWizard-Abo zur selben Zeit, welches Funktionen entsprechend der Leistungsbeschreibung enthält.
      Benutzer-Accounts: Ein Benutzer-Account verfügt über einen Eintrag in der Benutzertabelle mit Benutzernamen, E-Mail-Adresse und eigenem Passwort. Das Passwort wird dabei immer von den Benutzern/innen selbst festgelegt und nie im Klartext, sondern nur verschlüsselt, in der Datenbank gespeichert.  
      Angabe der Benutzer-Daten: Bei der Registrierung haben Sie die Möglichkeit, die Daten für den gewünschten Benutzer-Account (Benutzernamen und E-Mail-Adresse) bekannt zu geben. Änderungen der Daten der Benutzer-Accounts sind nur durch den Anbieter möglich. Sie können eine solche Änderung durch ein E-Mail an die betreffende Support-E-Mail-Adresse beantragen.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_THREE:
        "Verfügbarkeit auf unterschiedlichen Webbrowsern",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_THREE: `Die Verfügbarkeit und korrekte Funktionsweise von conDMWizard wird ausschließlich bei Verwendung eines der folgenden Webbrowser in einer aktuellen Version garantiert:
      Google Chrome 
      Ältere Browser werden nicht unterstützt. Die Verwendung von conDMWizard ist mit diesen Browsern nicht möglich.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_FOUR:
        "Verfügbarkeit auf unterschiedlichen Anwendergeräten und Bildschirmgrößen",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_FOUR: `Grundsätzlich kann conDMWizard auf verschiedensten internetfähigen Anwendergeräten mit unterschiedlichen Bildschirmgrößen verwendet werden. conDMWizard verfügt teilweise über Funktionen die eine gewisse Bildschirmgröße für einen sinnvollen Gebrauch zwingend voraussetzen (etwa die Anzeige von Tabellen mit einer gewissen Mindestbreite), ist es möglich, dass diese Funktionalitäten ab einer gewissen Mindest-Bildschirmbreite ausgeblendet werden und somit nicht verfügbar sind. Dies kann die Funktionsweise von conDMWizard auf Geräten mit niedriger Bildschirmbreite einschränken.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_FIVE:
        "Verfügbarkeit des SaaS-Dienstes und Störungen",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_FIVE: `Der Anbieter ergreift alle notwendigen Maßnahmen, um die Dienste jederzeit verfügbar zu halten. Dennoch kann eine hundertprozentige Verfügbarkeit nicht garantiert werden. Durch dringend notwendige Wartungsarbeiten, beim Softwareupdate oder durch Sicherheits- oder Auslastungsprobleme sowie durch Ereignisse, die nicht in der Sphäre des Anbieters liegen (etwa ein kurzfristiger Server-Ausfall aufgrund höherer Gewalt o.ä.) können vorübergehende Unterbrechungen des Dienstes auftreten.
      Bei schwerwiegenden Störungen, die die Nutzung des Dienstes unmöglich machen bzw. zumindest stark einschränken, werden vom Anbieter zu den Geschäftszeiten Montag - Freitag 9-17 Uhr so schnell wie möglich alle Maßnahmen gesetzt, um das zugrunde liegende Problem umgehend zu beheben, zumindest so weit, dass die wesentlichen Funktionen von conDMWizard wieder weitgehend uneingeschränkt funktionieren.
      Voraussetzung für die Behebung einer Störung ist die unverzügliche schriftliche Information durch den/der Kunden/Kundin.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_SIX:
        "Funktionsstörende Fehler (Bugs)",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_SIX: `Der Provider überwacht laufend die Funktionstüchtigkeit der Software und beseitigt nach Maßgabe der technischen Möglichkeiten unverzüglich sämtliche Softwarefehler. Ein Fehler liegt insbesondere vor, wenn die Software die in der Leistungsbeschreibung auf der Website des Providers nicht erfüllt, falsche Ergebnisse liefert oder in anderer Weise nicht funktionsgerecht arbeitet, so dass die Nutzung der Software unmöglich oder eingeschränkt ist.
      Mit dem Vertragsabschluss anerkennen Sie und stimmen zu, dass die Software sich unter Umständen noch funktionsstörende Fehler (sogenannte Bugs) beinhalten kann.  Etwaige auftretende Funktionsstörungen werden vom Anbieter jedenfalls behoben, wenn alle folgenden Bedingungen erfüllt, werden:
      es handelt sich um die Störung einer Funktion, die in der Leistungsbeschreibung des Abos oder individuellen Kundenvereinbarung aufgeführt ist,
      der Anbieter erhält von Kundenseite eine nachvollziehbare Fehlerbeschreibung, die zumindest folgende Informationen enthält:
      Welche Handlungen (Maus-Klicks, Tastatureingaben) wurden vor dem Auftreten des Fehlers durchgeführt?  
      Was war das erwartete Ergebnis? Was war das tatsächliche Ergebnis? 
      Welcher Webbrowser (inkl. Versionsnummer), was für Betriebssystem (Linux, Windows, MacOS, Android, iOS, ...) und was für ein Anwendergerät wurde verwendet die Funktionsstörung muss vom Anbieter unter gleichen / ähnlichen Voraussetzungen reproduzierbar sein.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FOUR_SEVEN: "Wartung, Änderungen",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FOUR_SEVEN: `Der Anbieter erstellt gelegentlich Updates von conDMWizard mit Fehlerbeseitigungen, Verbesserungen oder Funktionserweiterungen. Diese werden allen Benutzern/Innen verfügbar gemacht, ohne dass diese aktiv werden müssen. Eine Möglichkeit zum Verzicht auf Updates gibt es nicht, da diese wichtigen Fehlerbeseitigungen oder Sicherheitsupdates enthalten können. Die Funktionsweise von conDMWizard kann sich durch Updates leicht ändern, ohne die vereinbarte Grundfunktionalität zu beeinträchtigen.`,
      TERMS_AND_CONDITIONS_CHAPTER_FOUR_TEXT: "",
      TERMS_AND_CONDITIONS_SECTIONTITLE_FIVE:
        "Rechteeinräumung / Urheberrechte",
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FIVE_ONE:
        "Rechte an der Software / Source Code von conDMWizard",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FIVE_ONE: `Der Anbieter räumt Ihnen das nicht-exklusive, nicht übertragbare und nicht unter-lizenzierbare und auf die Leistungsbeschreibung Ihres erworbenen Abos beschränkte Recht auf Nutzung der Benutzeroberfläche von conDMWizard ein.
      Die vollen Rechte an der Software und dessen Source Code verbleiben beim Anbieter. Insbesondere ist es Ihnen verboten, conDMWizard als Ganzes oder in Teilen herunterzuladen, zu vervielfältigen, zu ändern oder Dritten zur Verfügung zu stellen.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_FIVE_TWO:
        "Nutzungsbewilligung von conDMWizard zur Verfügung gestellte Beispieldaten und Dokumente",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_FIVE_TWO: `In manchen Funktionen von conDMWizard stellen wir Ihnen initial Dokumente zur Verfügung. Sie dürfen diese Daten und Medien im Rahmen der Funktionen von conDMWizard nutzen, sie ausdrucken, sie anpassen und verändern oder diese löschen. Nicht erlaubt ist die Verwendung der bereitgestellten Beispieldaten zu anderen als der in conDMWizard vorgesehenen Zwecken, insbesondere die Weitergabe an Dritte oder deren Verkauf.
      Sie erhalten jedoch keinerlei Nutzungsrechte an den Texten, Dokumenten, der Datensammlung oder den Bild- und Videodateien. Die vollen Nutzungsrechte verbleiben beim Anbieter.  
      Wir übernehmen keinerlei Gewähr für die Richtigkeit oder Angemessenheit der Beispieldaten. Die Verwendung der Beispieldaten geschieht daher nach Ihrem eigenen Ermessen und auf eigene Verantwortung.`,
      TERMS_AND_CONDITIONS_CHAPTER_FIVE_TEXT: "",
      TERMS_AND_CONDITIONS_SECTIONTITLE_SIX: "Ihre Pflichten",
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_ONE: "Nutzung des SaaS-Diensts",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_ONE: `Sie dürfen die von Ihnen abonnierten Dienste nur für die damit vorgesehenen Zwecke und nur im Rahmen des Leistungsumfangs verwenden.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_TWO: "Zugangsdaten",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_TWO: `Insbesondere ist es Ihnen verboten, die Funktionen des SaaS-Diensts Dritten zur Verfügung zu stellen oder zugänglich zu machen (etwa auch per Fernzugriff auf das von Ihnen verwendete Gerät). Sie müssen die Ihnen zugewiesenen Zugangsdaten vor dem Zugriff Dritter schützen und dürfen diese nicht an Unberechtigte weitergeben.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_THREE:
        "Von Ihnen eingegebenen Inhalte",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_THREE: `Vom Anbieter wird keinerlei Kontrolle der von den Kunden/Innen eingegebenen oder hochgeladenen Daten vorgenommen.
      Ebenso sind Sie dafür verantwortlich, alle datenschutzrechtlichen Bestimmungen betreffend der von Ihnen eingegebenen Daten einzuhalten. 
      Sie verpflichten sich, den Anbieter im Zusammenhang mit Ansprüchen Dritter aufgrund von solchen Rechtsverletzungen schad- und klaglos zu halten, die auf einer rechtswidrigen Verwendung des SaaS-Diensts durch Sie oder mit Ihrer Billigung beruhen.`,
      TERMS_AND_CONDITIONS_SECTIONSUBTITLE_SIX_FOUR:
        "Meldung von Funktionsstörungen",
      TERMS_AND_CONDITIONS_SECTIONSUBTEXT_SIX_FOUR: `Sie verpflichten sich, etwaige schwerwiegende Funktionsstörungen des SaaS-Diensts unverzüglich schriftlich an die jeweilige Support-E-Mail-Adresse des Angebots oder an condmw-support@concircle.com zu melden.`,
      TERMS_AND_CONDITIONS_CHAPTER_SIX_TEXT: "",
      TERMS_AND_CONDITIONS_SECTIONTITLE_SEVEN:
        "Entgelt und Zahlungsbedingungen",
      TERMS_AND_CONDITIONS_CHAPTER_SEVEN_TEXT: `Die Höhe des einmaligen und/oder laufenden 
      Der Kunde verpflichtet sich, an den Provider für die Softwareüberlassung das vereinbarte monatliche Entgelt zzgl. gesetzlicher USt. gemäß dem jeweiligen Abo Modell in der gültigen Fassung zu bezahlen. 
      Bei Buchung eines entgeltlichen Abo‘s wird der jeweilige monatliche Rechnungsbetrag verrechnet. Sofern im Einzelfall keine besonderen Zahlungsbedingungenen vereinbart wurden, ist der Rechnungsbetrag spesen- und abzugsfrei sofort über den Zahlungsdienstleister fällig. 
      Der Provider ist dazu berechtigt, durch schriftliche Mitteilung an den Kunden mit einer Ankündigungsfrist von 1 Monat eine Anpassung der Entgelte und Leistungsinhalte vorzunehmen, sofern diese für den Kunden zumutbar ist. Voraussetzung und Gründe für eine solche Leistungsänderung sind insbesondere der technische Fortschritt und die Weiterentwicklung der Software. Die Änderung erfolgt in der Art und in dem Ausmaß, wie der Umfang und die Leistungsfähigkeit der Software geändert werden. Will der Kunde den Vertrag nicht zu den geänderten Entgeltbestimmungen fortführen, ist er zur außerordentlichen Kündigung mit einer Frist von 14 Tagen ab Änderungszeitpunkt berechtigt.
      Sämtliche Preise sind nach dem von der Statistik Austria zu Vertragsabschluss letzten verlautbarten
      Verbraucherpreisindex (VPI) wertgesichert. Die jeweilige Indexanpassung erfolgt jährlich im September. Die Nichtgeltendmachung der Indexanpassung bedeutet keinen Verzicht auf die Geltendmachung von Preiserhöhungen. `,
      TERMS_AND_CONDITIONS_SECTIONTITLE_EIGHT:
        "Nutzungsart und -dauer, Kündigung und Auflösung",
      TERMS_AND_CONDITIONS_CHAPTER_EIGHT_TEXT: `Der SaaS-Vertrag wird auf unbestimmte Zeit geschlossen. Das Vertragsverhältnis beginnt mit der Bestätigung der Zahlung durch den Zahlungsdienstleister. Der Abrechnungszeitraum kann ein Monat oder ein Jahr betragen. Eine Kündigung des Abos kann zu jedem Zeitpunkt über den Zahlungsdienstleister angestoßen werden, wobei die Kündigung je nach Abrechnungszeitraum am Ende des Monats oder am Ende des Jahres in Kraft tritt.  

      Die unentgeltliche Nutzung kann von beiden Parteien jederzeit gekündigt werden; der Einhaltung einer Frist zum Monatesende bedarf es nicht. Die Kündigung hat in Schriftform bzw. per E-Mail zu erfolgen. Bei Beendigung des Vertragsverhältnisses erfolgte die Löschung des Kunden-Accounts einschließlich aller damit verknüpften Daten. 
      
      Dem Provider steht zudem das Recht auf sofortige außerordentliche Kündigung zu, wenn es zu grundlegenden Änderung der rechtlichen oder technischen Standards kommt und es für den Provider dadurch unzumutbar wird, die Leistungen ganz oder teilweise im Rahmen des Vertragszweckes zu erbringen. 
      
       
      
      Grundsätzlich wird zwischen einer unentgeltlichen (beschränkt auf ein Monat) und einer entgeltlichen Nutzung von conDMWizard Unterschieden. 
      
      Unentgeltliche Nutzung von conDMWizard. Folgende Funktionen können vom Kunden unentgeltlich genutzt werden: 
      
      Anlegen und Verwaltung von zwei Profilen 
      
      Transportieren von Stammdaten: 10 Mal in 24 Stunden 
      
      Transportieren von Stammdaten: 2 Datensets pro Transport 
      
      Entgeltliche Nutzung von conDMWizard (gemäß Abo-Modell in der jeweils gültigen Fassung)  
      
      Die kostenpflichtige Nutzung beginnt mit der Zahlung via dem Zahlungsdienstleisters durch den Kunden. `,
      TERMS_AND_CONDITIONS_SECTIONTITLE_NINE: "Gewährleistung",
      TERMS_AND_CONDITIONS_CHAPTER_NINE_TEXT: `Nur bei Unternehmern/Innen anwendbar: Gewährleistungsansprüche verjähren nach sechs Monaten ab Vertragsabschluss.  
      Nur bei Unternehmern/Innen anwendbar: Die Mängelrüge muss in Schriftform erfolgen und alle in Punkt 3.8 unter b) aufgelisteten Informationen enthalten.  
      10.3 Nur bei Konsumentens anwendbar: Die Gewährleistungsrechte des Kundens als Konsumenten unterliegt den gesetzlichen Bestimmungen laut den §§ 922 bis 933 ABGB.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_TEN: "Haftung",
      TERMS_AND_CONDITIONS_CHAPTER_TEN_TEXT: `Nur bei Unternehmern/Innen anwendbar: Der Anbieter haftet der Kunden für von ihm nachweislich verschuldete Schäden nur im Falle groben Verschuldens. Dies gilt sinngemäß auch für Schäden, die auf vom Anbieter beigezogene Dritte zurückzuführen sind. Im Falle von verschuldeten Personenschäden haftet der Anbieter unbeschränkt.  
      Nur bei Unternehmern/Innen anwendbar: Die Haftung für mittelbare Schäden - wie beispielsweise entgangenen Gewinn, Kosten, die mit einer Betriebsunterbrechung verbunden sind, Datenverluste oder Ansprüche Dritter - wird ausdrücklich ausgeschlossen.  
      Nur bei Unternehmern/Innen anwendbar: Schadenersatzansprüche verjähren nach den gesetzlichen Vorschriften, jedoch spätestens mit Ablauf eines Jahres ab Kenntnis des Schadens und des Schädigers.  
      Nur bei Unternehmern/Innen anwendbar: Sofern der Anbieter das Werk unter Zuhilfenahme Dritter erbringt und in diesem Zusammenhang Gewährleistung- und/oder Haftungsansprüche gegenüber diesen Dritten entstehen, tritt der Anbieter diese Ansprüche an den/die Kunden/Innen ab. Die Kunden halten sich in diesem Fall vorrangig an diese Dritten.  
      Nur bei Unternehmern/Innen anwendbar: Weitergehender als die in diesen AGB oder im Einzelvertrag genannten Gewährleistung- und Schadenersatzansprüche der Kunden - gleich aus welchem Rechtsgrund - sind ausgeschlossen.  
      Nur bei Unternehmern/Innen anwendbar: Der Anbieter haftet insbesondere nicht für die unbefugte Kenntniserlangung von persönlichen Nutzerdaten durch Dritte.
      Nur bei Konsumentens anwendbar: Die Haftung des Anbieters für leichte Fahrlässigkeit wird im Falle von Datenverlusten oder bei Ansprüchen Dritter ausgeschlossen. Im Falle von verschuldeten Personenschäden haftet der Anbieter unbeschränkt.`,
      TERMS_AND_CONDITIONS_SECTIONTITLE_ELEVEN:
        "Nur bei Konsumentens anwendbar: Gesetzliches Rücktrittsrecht",
      TERMS_AND_CONDITIONS_CHAPTER_ELEVEN_TEXT: `Als Konsument haben Sie das Recht, die Bestellung des conDMWizard Abos binnen vierzehn Tage nach Abschluss ohne Angabe von Gründen zu widerrufen. Um dieses Rücktrittsrecht auszuüben, haben Sie dem Anbieter mittels einer eindeutigen Erklärung (zB ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss zu informieren, den Vertrag zu widerrufen.  
      Zur Wahrung der Rücktrittsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Rücktrittsrechts vor Ablauf der Widerrufsfrist absenden.  
      Wenn Sie von der Bestellung Ihres conDMWizard Abos zurücktreten, werden wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Rücktritt bei uns eingegangen ist.  `,
      TERMS_AND_CONDITIONS_SECTIONTITLE_TWELVE: "Salvatorische Klausel",
      TERMS_AND_CONDITIONS_CHAPTER_TWELVE_TEXT:
        "Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder unwirksam werden, so wird hierdurch der übrige Inhalt dieser AGB nicht berührt. Die Vertragspartner werden partnerschaftlich zusammenwirken, um eine Regelung zu finden, die den unwirksamen Bestimmungen möglichst nahekommt.",
      TERMS_AND_CONDITIONS_SECTIONTITLE_THIRTEEN:
        "Anwendbares Recht, Erfüllungsort und Gerichtsstand",
      TERMS_AND_CONDITIONS_CHAPTER_THIRTEEN_TEXT:
        "Auf diesen Vertrag ist materielles österreichisches Recht unter Ausschluss der Verweisungsnormen des internationalen Privatrechts anwendbar.",
      TERMS_AND_CONDITIONS_SECTIONTITLE_FOURTEEN: "Sonstiges",
      TERMS_AND_CONDITIONS_CHAPTER_FOURTEEN_TEXT: `Änderungen des Vertrages und dieser AGB bedürfen der Schriftform. Ebenso ein Abgehen dieses Formerfordernisses. Mündliche Nebenabreden bestehen nicht.  
      Nur bei Unternehmern/Innen anwendbar: Für den Fall von Streitigkeiten aus diesem Vertrag, die nicht einvernehmlich geregelt werden können, vereinbaren die Vertragsparteien einvernehmlich zur außergerichtlichen Beilegung des Konfliktes eingetragene Mediatoren (ZivMediatG) mit dem Schwerpunkt WirtschaftsMediation aus der Liste des Justizministeriums beizuziehen. Sollte über die Auswahl der WirtschaftsMediatoren oder inhaltlich kein Einvernehmen hergestellt werden können, werden frühestens ein Monat ab Scheitern der Verhandlungen rechtliche Schritte eingeleitet.  
      Im Falle einer nicht zustande gekommenen oder abgebrochenen Mediation, gilt in einem allfällig eingeleiteten Gerichtsverfahren österreichisches Recht. Sämtliche aufgrund einer vorherigen Mediation angelaufenen notwendigen Aufwendungen, insbesondere auch jene für eine(n) beigezogene(n) Rechtsberatern, können vereinbarungsgemäß in einem Gerichts- oder Schiedsgerichtsverfahren als „vorprozessuale Kosten“ geltend gemacht werden.  
      Nur bei Konsumentens anwendbar: Alternative Streitbeilegung. Obwohl der Lizenzgeber nicht zur Teilnahme an alternativen Streitbeilegungsverfahren verpflichtet ist und an solchen auch nicht zwingend teilnehmen wird, sind die nachstehenden Informationen zu erteilen:  
      Die Online-Streitbeilegungsplattform der Europäischen Union betreffend Online-Transaktionen kann hier aufgerufen werden: http://ec.europa.eu/consumers/odr/.    
      Die beiden österreichischen Streit-Schlichtungsstellen für Online-Verbraucher-Geschäfte sind unter den folgenden Internetadressen zu finden: https://ombudsmann.at/ und http://www.verbraucherschlichtung.or.at/.  `,
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    lng: navigator.language || navigator.languages[0] || "en", // Use browser language

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  });

export default i18n;
